import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "../../../common/Modal";


function RegisteredUsers({ users ,callbackAddStartEvent , callbackUpdateStartEvent}) {
	const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
	const [task_type, setTaskType] = useState("add");
	const [modalOpen, setModalOpen] = useState(false);
	const [telegramCode, setTelegramCode] = useState("0");
	const [otp_code, setOtpCode] = useState("0");
	const [user_name, setUserName] = useState(false);
	const [user_id, setUserId] = useState(false);
	const [password, setPassword] = useState(false);
	const [userInputTelegramCode, setUserInputTelegramCode] = useState("0");


	const [currentUserId, setCurrentUserId] = useState(null);
	
	function display_users(v2){
		return (
			<><div style={{ width: "900px", margin: "0 0.5em 0.5em 3em", border: "1px solid #203864", textAlign: "left", verticalAlign: "middle" }}>
			<span class>ID: {v2.emp_login}</span><br></br>
			<span>Registered: {v2.insert_date}  </span><br></br>
			<span>Last Login: {v2.last_loggin}  </span><br></br>
			<button className="btn btn-info btn-sm btn-space-5" onClick={(e) => openModal(v2.emp_login,setTaskType("update_password"))}>
				Update Password
			</button><br></br>
			<button className="btn btn-primary btn-sm btn-space-5" onClick={(e) => openModal(v2.emp_login,setTaskType("update_otp"))}>
				Update OTP
			</button><br></br>
			<button className="btn btn-warning btn-sm btn-space-5" onClick={(e) => openModal(v2.emp_login,setTaskType("delete"))}>
				Delete
			</button>
			</div></>
		);

	};
	function display_users_test(){
		return (
			<><button className="btn btn-primary btn-sm btn-space-5">
				Update OTP
			</button><br></br></>
		);

	};
	const openModal = (id) => {
		//alert("openModal : " + id);
		setCurrentUserId(id);
		const info_total = { msg: "telegram_code" };
		axios
			.post(`${python_server_url}/auth`, info_total)
			.then((res) => {
				//alert("telegram_code : " + res.data.msg);
				setTelegramCode(res.data.msg)
			})
			.catch((err) => {
				console.log(err);
			});
		setModalOpen(true);
	};
	const closeModal = () => {
		setModalOpen(false);
	};
	const goNext = () => {
		if (telegramCode === userInputTelegramCode && user_id !== "" && password !== "" && userInputTelegramCode !== "") {
			const info_total = { msg: "loginSpuerAdmin", user_name: user_name, user_id: user_id, password: password ,otp_code : otp_code};
			axios
				.post(`${python_server_url}/auth`, info_total)
				.then((res) => {
					const re_dt = res.data;
						if (re_dt["msg"] === "1") {

							if (task_type === "update_password" || task_type === "update_otp"  || task_type === "delete" ){

								setModalOpen(false);
								callbackUpdateStartEvent(currentUserId,task_type);
							}else {

								setModalOpen(false);
								callbackAddStartEvent();
							}
						} else {
							alert("Pleae check admin password and id and otp and telemgram code!");
						}
	
				})
				.catch((err) => {
					console.log(err);
				});
		}else {

			alert("Pleae check admin password and id and otp and telemgram code!");
		}
	}
    return (
        <div className="first_div">
            <br />
            <div className="second_div">
				<div style={{ display: "flex", margin: "0px 0px 1em" }}>
                    <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Registered Users</h5>
                    <button className="btn btn-success btn-sm" onClick={(e) => openModal("",setTaskType("add"))}>
                       Add New Users
                    </button>
                </div>
            </div>
			
            <br />
            <div class="div_aline_center" style={{ margin: "0 0 0.5em 3em" , width: "100%" }}>

			{users &&
                    users.map((v, i) => (

                            <div style={{ width: "900px", margin: "0 0.5em 0.5em 3em", border: "1px solid #203864", textAlign: "left", verticalAlign: "middle" }}>
                                <span class>ID: {v.emp_login}</span><br></br>
                                <span>Registered: {v.insert_date}  </span><br></br>
                                <span>Last Login: {v.last_loggin}  </span><br></br>
                                <button className="btn btn-info btn-sm btn-space-5" id={i+"_view"} onClick={(e) => openModal(v.emp_login,setTaskType("update_password"))}>
                                    Update Password
                                </button><br></br>
                                <button className="btn btn-primary btn-sm btn-space-5" onClick={(e) => openModal(v.emp_login,setTaskType("update_otp"))}>
                                    Update OTP
                                </button><br></br>
                                <button className="btn btn-warning btn-sm btn-space-5" onClick={(e) => openModal(v.emp_login,setTaskType("delete"))}>
                                    Delete
                                </button>
                            </div>

                    ))}


            </div>
			<div>
				<Modal
					open={modalOpen}
					close={closeModal}
					header={
						<>
							<p>
								Add New User
							</p>
							<button className="close" onClick={closeModal}>
								{" "}
								&times;{" "}
							</button>
						</>
					}
					footer={
							<>
								<button className="close" onClick={closeModal}>
									{" "}
									close{" "}
								</button>
							</>
					}>
					{/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
					
					<div style={{width : "100%" }}>
						
						<div style={{margin: "0px 0px 1em 2.5em"  , display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>User Id</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='user_id' type="text" style={{ width: "100%" }}  onChange={(e) => setUserId(e.target.value)}/>
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  , display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Password</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='password' type="text" style={{ width: "100%" }}  onChange={(e) => setPassword(e.target.value)}/>
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  ,textAlign : "left", display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Telegram Code</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='telegram_code' type="text" className="login" style={{ width: "100%" }}  onChange={(e) => setUserInputTelegramCode(e.target.value)} required/>
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  ,textAlign : "left", display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Otp Code</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='otp_code' type="text" className="login" style={{ width: "100%" }}  onChange={(e) => setOtpCode(e.target.value)} required/>
							</div>
						</div>
						<div style={{margin: "2.5em 0px 1em 2.5em"  ,textAlign : "center", display : "flex" , width : "80%" }}>
							<div style={{ width : "100%" }}>
								<button className="btn btn-info btn_l btn_center" onClick={(e) => goNext()}>Next</button>
							</div>
						</div>
						
					</div>
				</Modal>
			</div> 

        </div>

  

    );
}

export default RegisteredUsers;

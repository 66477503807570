import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

const Addmember = () => {
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;

    const [lpw, setLpw] = useState(null);
    const [id, setId] = useState(null);
    const [pw, setPw] = useState(null);
    const [fname, setFname] = useState(null);
    const [lname, setLname] = useState(null);
    const [email, setEmail] = useState(null);
    const [otpqrcode, setOtpqrcode] = useState(null);
    const [secretkey, setSecretkey] = useState(null);
    const [otpnum, setOtpnum] = useState(null);
    const [getCode, setGetCode] = useState("0")

    const inputRef = useRef();

    const set_all = () => {
        if (getCode === lpw && id !== "" && pw !== "" && fname !== "" && lname !== "" && email !== "") {
            const info_total = { msg: "member_check_and_create", lpw: lpw, id: id, pw: pw, email: email, fname: fname, lname: lname };
            axios
                .post(`${python_server_url}/auth`, info_total)
                .then((res) => {
                    const re_dt = res.data;
                    if (re_dt["msg"] === "1") {
                        setSecretkey(re_dt["secret_key"]);
                        setOtpqrcode(re_dt["otp_url"]);
                        inputRef.current.focus();
                    } else {
                        alert("Number is not correct. id > 3 , pw > 3 fname > 1 , lname > 1 , email > 3");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("Something is Empty.");
        }
    };

    const go_otp = () => {
        const info_total = { msg: "member_add_finish", id: id, secretkey: secretkey, otpnum: otpnum };
        axios
            .post(`${python_server_url}/auth`, info_total)
            .then((res) => {
                const re_dt = res.data;
                if (re_dt["msg"] === "1") {
                    window.location.replace("/");
                } else {
                    alert("Number is not correct. id > 3 , pw > 3 fname > 1 , lname > 1 , email > 3");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const info_total = { msg: "telegram_code" };
        axios
            .post(`${python_server_url}/auth`, info_total)
            .then((res) => {
                setGetCode(res.data.msg)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className="login_content_div">
            <div hidden={otpqrcode != null ? true : false}>
                <div className={"login_input_div"}>
                    <p style={{ color: "white" }}>ID :</p>
                    <input id={"input_id"} className="login" type="text" style={{ textTransform: "lowercase" }} onChange={(e) => setId(e.target.value)} required />
                    <br />
                    <br />
                    <p style={{ color: "white" }}>PW :</p>
                    <input id={"input_pwd"} className="login" type="password" onChange={(e) => setPw(e.target.value)} />
                    <br />
                    <br />
                    <br />
                    <p style={{ color: "white" }}>FIRST NAME :</p>
                    <input id={"input_fname"} className="login" type="text" style={{ textTransform: "lowercase" }} onChange={(e) => setFname(e.target.value)} required />
                    <br />
                    <br />
                    <p style={{ color: "white" }}>LAST NAME :</p>
                    <input id={"input_lname"} className="login" type="text" style={{ textTransform: "lowercase" }} onChange={(e) => setLname(e.target.value)} required />
                    <br />
                    <br />
                    <p style={{ color: "white" }}>EMAIL :</p>
                    <input id={"input_email"} className="login" type="" style={{ textTransform: "lowercase" }} onChange={(e) => setEmail(e.target.value)} required />
                    <br />
                    <br />
                    <p style={{ color: "white" }}>TELEGREM CODE :</p>
                    <input id={"input_lpw"} className="login" type="text" onChange={(e) => setLpw(e.target.value)} required />
                    <br />
                    <br />
                </div>
                <br />
                <div>
                    <button style={{ background: "red", width: "10%" }}>
                        <a href="/" style={{ color: "black" }}>
                            Login Page
                        </a>
                    </button>
                    &emsp;
                    <button style={{ width: "10%" }} onClick={set_all}>
                        Add Member
                    </button>
                </div>
            </div>
            <br />
            <div hidden={otpqrcode == null ? true : false}>
                <img src={otpqrcode} alt="" width="200px" height="200px" />
                <br />
                <input ref={inputRef} onChange={(e) => setOtpnum(e.target.value)} maxLength="6" />
                <button onClick={go_otp}>Go OTP</button>
            </div>
        </div>
    );
};

export default Addmember;

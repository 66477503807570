import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import HeaderNew from "../../../headerFooterSide/HeaderNew";
import CoinManageSpot from "./CoinManageSpot";
import CoinManageFuture from "./CoinManageFuture";

function CoinManage({handleLogoutapp}) {
    const [title, setTile] = useState()
   

    useEffect(() => {
        setTile("Coin Management")
    }, []);


    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <CoinManageSpot />
            <CoinManageFuture/>
        </div>
    );
}

export default CoinManage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import HeaderNew from "../../headerFooterSide/HeaderNew";
import { C_coins, ExchangeDiv, CoinsDiv, DateFromToDiv ,DateDiv, c_opt_exchanges} from "../../common/Commons";
import ReactPaginate from "react-paginate";

function InjectionRecords({handleLogoutapp}) {
  let total_amont = 0.0
  const [title, setTile] = useState()
  const pairs = [1];

  const [set_date, setDate] = useState(null);
  const [dateFromRecord, setDateFromRecord] = useState("all");
  const [dateToRecord, setDateToRecord] = useState("all");

  const [coin_full_list, set_Coin_full_list] = useState([]);
  const [binance_list, set_Binance_list] = useState([]);
  const [upbit_list, set_Upbit_list] = useState([]);
  const [bithumb_list, set_Bithumb_list] = useState([]);
  const [okex_list, set_Okex_list] = useState([]);
  const [mexc_list, set_Mexc_list] = useState([]);
  const [bitget_list, set_Bitget_list] = useState([]);

  
  const [injectionListFromDb, setInjectoinListFromDb] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();

  const [remountComponent, setRemountComponent] = useState();
  // id
  const [currentIdList, setCurrentIdList] = useState([]);
  //totoalAmount
  const [currentTotoalAmountList, setCurrentTotoalAmountList] = useState([]);
  
  // injection List
  const [injectionList, setInjectoinList] = useState([]);
  const handleTempInjectionList = (index,name,value) => {
    //alert("before : " + JSON.stringify(injectionList));
    //alert(index);
    //alert(name);
    //alert(value);
    console.log("handleTempInjectionList index :" + index);
    console.log("handleTempInjectionList name :" + name);
    console.log("handleTempFrom value :" + value);

    let newArr = [...injectionList]; // copying the old datas array
    newArr[index][name] = value;
    console.log("handleTempFrom injectionList,newArr :" + JSON.stringify(newArr) );

    //alert("after from injectionList[index][name]  : " + JSON.stringify(injectionList[index][name]));
    //alert(index);
    //alert(name);
    //alert(value);
    setInjectoinList(newArr)
    //alert("after : " + JSON.stringify(injectionList));
  };

  // from
  const [currentFromList, setCurrentFromList] = useState([]);
  const handleFrom = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentFromList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentFromList(newArr);
    console.log("currentFromList :" + newArr);
  };
  // to
  const [currentToList, setCurrentToList] = useState([]);
  const handleTo = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentToList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentToList(newArr);
    console.log("currentToList :" + newArr);
  };

  // trading amount
  const [currentTradingAmountList, setCurrentTradingAmountList] = useState([]);
  const default_tradingAmount = "0.1";
  const handleTradingAmount = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentTradingAmountList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentTradingAmountList(newArr);
    console.log("currentTradingAmountList :" + newArr);
  };


  // Fee
  const [currentFeeList, setCurrentFeeList] = useState([]);
  const default_Fee = "0.1";
  const handleFee = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentFeeList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentFeeList(newArr);
    console.log("currentFeeList :" + newArr);
  };

  //coin
  const [currentCoinList, setCurrentCoinList] = useState([]);
  const handleCoin = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentCoinList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentCoinList(newArr);
    console.log("currentCoinList :" + newArr);
  };

  // edit button
  const [editButtons, setEditButtons] = useState([]);
  const handleEdit = (index,id,button_text) => {
    console.log("handleEdit index :" + index);
    console.log("handleEdit id :" + id);
    console.log("handleEdit button_text :" + button_text);
    let newArr = [...editButtons]; 
    if ( button_text === "Edit" ) {
        newArr[index] = "Save"; 
        setEditButtons(newArr);
        console.log("editButtons :" + newArr);
    }else{
      newArr[index] = "Edit"; 
      if (updateInjection(index))
      {
        setEditButtons(newArr);
        console.log("editButtons :" + newArr);
      }
    }
   // replace e.target.value with whatever you want to change it to
   
  };

  /////////////////////////////////////////////////////////////////////////////////////////
 
  function loadInjectionData(){
    const limit_cnt = 20;
    const data = {
        bot_id: "arbitrage",
        msg: "get_arbitrage_injection",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        dateFrom: dateFromRecord,
        dateTo: dateToRecord
    };
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_injection`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            setInjectoinListFromDb(res.data.result[1]);
            makeSubData(res.data.result[1]);
        } else {
            setPageCount(0);
            setInjectoinListFromDb([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });

  }
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  function makeSubData(dataArbitrase_){
    let newArr_Ids = [];
    let newArr_From = [];
    let newArr_To = [];
    let newArr_Amount = [];
    let newArr_Fee = [];
    let newArr_Coin = [];
    let newArr_Buttons = [];
    let newArr_TotalAmount = [];
    let tempTotalAmount = 0.0 ;
    
    for (let key in dataArbitrase_){
      newArr_Ids[key] = dataArbitrase_[key].id;
      newArr_From[key] = dataArbitrase_[key].from_exchange;
      newArr_To[key] = dataArbitrase_[key].to_exchange;
      newArr_Amount[key] = dataArbitrase_[key].amount;
      newArr_Fee[key] = dataArbitrase_[key].fee;
      newArr_Coin[key] = dataArbitrase_[key].coin;

      // fee
      var temp_fee;
      if ( dataArbitrase_[key].fee == ""  )
      {
          temp_fee = 0.0;
      }else{
        temp_fee = parseFloat(dataArbitrase_[key].fee);
      }
      // amount
      var temp_amount;
      if ( dataArbitrase_[key].amount == ""  )
      {
        temp_amount = 0.0;
      }else{
        temp_amount = parseFloat(dataArbitrase_[key].amount);
      }


      tempTotalAmount = tempTotalAmount + temp_amount +temp_fee;
      newArr_TotalAmount[key] = tempTotalAmount;
      newArr_Buttons[key] = "Edit";
    }
    setCurrentIdList(newArr_Ids);
    setCurrentFromList(newArr_From);

    setCurrentToList(newArr_To);
    setCurrentTradingAmountList(newArr_Amount);
    setCurrentFeeList(newArr_Fee);
  
    setCurrentCoinList(newArr_Coin);

    setEditButtons(newArr_Buttons);
    setCurrentTotoalAmountList(newArr_TotalAmount)
  }

  function addInjection(){
    /*
    let list1 = []
    let item = {from:'Binance',to:'Upbit',amount: '10.00',fee:'0.001'}
    list1 = [...list1,item]
    alert(JSON.stringify(list1))
    let item2 = {from:'Binance',to:'Upbit',amount: '10.00',fee:'0.001'}
    list1 = [...list1,item]
    alert(JSON.stringify(list1))
    */
    
    let today = new Date()
    let this_date = today.toISOString().slice(0, 10)

    let tempInjsection = {create_date : this_date , from:'binance',to:'upbit',amount: 10.00,fee:0.001,coin : 'BTC'}
    setInjectoinList([...injectionList, tempInjsection])
    //alert("addInjection : " + JSON.stringify(injectionList))

  }

  
  // save Injection
  function saveInjection(index){

    // make data list to update
    const limit_cnt = 20;
    const data = {
      bot_id: "arbitrage",
      msg: "create_injection",
      injectionList: injectionList
    };

    alert("saveInjection , data :" + JSON.stringify(data));
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_injection`, data)
    .then((res) => {
        console.log(res.data)

        if (res.data.msg === "OK"){
          alert("Injection Data is saved !")
          loadInjectionData();

        }else{
          alert("ERROR ! : please check duplicate bot and the status of system !")
        }
    })
    .catch((err) => {
        console.log(err);
        alert("ERROR")
    })
    // .finally(() => {
    //    window.location.reload();
    // });
  }

  // update Injection
  function updateInjection(index){

    // make data list to update
    const limit_cnt = 20;
    const data = {
      bot_id: "arbitrage",
      msg: "update_injection",
      id: currentIdList[index],
      from_exchange: currentFromList[index],
      to_exchange: currentToList[index],
      amount: currentTradingAmountList[index],
      fee: currentFeeList[index],
      coin: currentCoinList[index]
    };

    console.log("update_injection : data " + data )
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_injection`, data)
    .then((res) => {
        console.log(res.data)

        if (res.data.msg === "OK"){
          alert("Injection Data is updated !")
          loadInjectionData();

        }else{
          alert("ERROR ! : please check duplicate bot and the status of system !")
        }
    })
    .catch((err) => {
        console.log(err);
        alert("ERROR")
    })
    // .finally(() => {
    //    window.location.reload();
    // });
  }

  
  /////////////////////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // get coin list
  useEffect(() => {

    console.log("get coin list useEffect !");
    const data = {
        bot_id: "arbitrage",
        msg: "get_coin_list",
    };

    console.log("get_coin_list data : "  + data);
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_setting`, data)
    .then((res) => {
        if (res.data.coin_full_list.length !== 0) {
            console.log("res.data.coin_full_list  : " + JSON.stringify(res.data.coin_full_list))
            //alert(JSON.stringify(res.data.coin_full_list));
            res.data.coin_full_list.map((coin,i) => {
              console.log(coin);
            });
            set_Coin_full_list(res.data.coin_full_list);
        } else {
          set_Coin_full_list([]);
        }

        if (res.data.binance_list.length !== 0) {
          console.log("res.data.binance_list  : " + JSON.stringify(res.data.binance_list))
          res.data.binance_list.map((coin,i) => {
            console.log(coin);
          });
          set_Binance_list(res.data.binance_list);
        } else {
          set_Binance_list([]);
        }

        if (res.data.upbit_list.length !== 0) {
          console.log("res.data.upbit_list  : " + JSON.stringify(res.data.upbit_list))
          res.data.upbit_list.map((coin,i) => {
            console.log(coin);
          });
          set_Upbit_list(res.data.upbit_list);
        } else {
          set_Upbit_list([]);
        }


        if (res.data.bithumb_list.length !== 0) {
          console.log("res.data.bithumb_list  : " + JSON.stringify(res.data.bithumb_list))
          res.data.bithumb_list.map((coin,i) => {
            console.log(coin);
          });
          set_Bithumb_list(res.data.bithumb_list);
        } else {
          set_Bithumb_list([]);
        }


        if (res.data.okex_list.length !== 0) {
          console.log("res.data.okex_list  : " + JSON.stringify(res.data.okex_list))
          res.data.okex_list.map((coin,i) => {
            console.log(coin);
          });
          set_Okex_list(res.data.okex_list);
        } else {
          set_Okex_list([]);
        }

        if (res.data.mexc_list.length !== 0) {
          console.log("res.data.mexc_list  : " + JSON.stringify(res.data.mexc_list))
          res.data.mexc_list.map((coin,i) => {
            console.log(coin);
          });
          set_Mexc_list(res.data.mexc_list);
        } else {
          set_Mexc_list([]);
        }

        if (res.data.bitget_list.length !== 0) {
          console.log("res.data.bitget_list  : " + JSON.stringify(res.data.bitget_list))
          res.data.bitget_list.map((coin,i) => {
            console.log(coin);
          });
          set_Bitget_list(res.data.bitget_list);
        } else {
          set_Bitget_list([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }, []);

  useEffect(() => {
    const limit_cnt = 20;
    const data = {
        bot_id: "arbitrage",
        msg: "get_arbitrage_injection",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        dateFrom: dateFromRecord,
        dateTo: dateToRecord
    };
    //alert(JSON.stringify(data));
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_injection`, data)
    .then((res) => {
       
        if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            //alert(JSON.stringify(res.data.result[1]))
            setInjectoinListFromDb(res.data.result[1]);
            
            makeSubData(res.data.result[1]);

            
            
        } else {
            setPageCount(0);
            setInjectoinListFromDb([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }, [pageNumber,dateFromRecord, dateToRecord]);
  

  useEffect(() => {
    setTile("Capital Injection")
    changePage({ selected: 0 });
    setRemountComponent(Math.random());

    let today = new Date()
    let this_date = today.toISOString().slice(0, 10)
    let tempInjsection = {create_date : this_date , from:'binance',to:'upbit',amount: 10.00,fee:0.001,coin : 'BTC'}
    setInjectoinList([...injectionList, tempInjsection])

  }, []);



  
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <><div>
      {/* tile */}
      <HeaderNew handleLogoutapp={handleLogoutapp} Title={title} />
      {/* botstatus content */}
      <div className="first_div">
        <div className="second_div">
          <div style={{ display: "flex", margin: "0px 0px 10px 0px" }}>
            <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Injection Record</h5>
            <div style={{ display: "flex", margin: "0px 0px 0px 10px" }}>
              <div>
                <DateDiv setDate={setDate} />
              </div>
              
              <button className="btn btn-success btn-sm" style={{ width: '100px', display: "float" }} onClick={() => addInjection()}>
                      Create
              </button>
            </div>
          </div>
          <div style={{ margin: "0px 0px 10px 0px" }}>
            <table>
              <thead style={{ background: "#6CA0EC" }}>
                <tr>
                  <th>#</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Transferred Amount​</th>
                  <th>Fee</th>
                  <th>Coin</th>
                </tr>
              </thead>

              <tbody style={{ background: "#283964" }}>
                {injectionList.map((injectioin, index_injection_temp) => (
                  <tr key={index_injection_temp}>
                    <td>
                      {index_injection_temp + 1}
                    </td>
                    <td>
                      <select onChange={(e) => handleTempInjectionList(index_injection_temp,'from',e.currentTarget.value )} value={injectionList[index_injection_temp]['from']} id={"temp" + index_injection_temp+"_from"}>
                      {c_opt_exchanges.map((exchage) => (
                          <option value={exchage.value} name={"temp" + exchage.name + index_injection_temp + "from"} key={"temp" + exchage.name + index_injection_temp + "from"}>
                            {exchage.value}
                          </option>
                        ))}
                        </select>
                    </td>
                    <td>    
                      <select onChange={(e) => handleTempInjectionList(index_injection_temp,'to',e.currentTarget.value )} value={injectionList[index_injection_temp]['to']} id={"temp" + index_injection_temp+"_to"}>
                        {c_opt_exchanges.map((exchage) => (
                          <option value={exchage.value} name={"temp" + exchage.name + index_injection_temp + "to"} key={"temp" + exchage.name + index_injection_temp + "to"}>
                            {exchage.value}
                          </option>
                        ))}
                        </select>
                    </td>
                    <td>
                      <input onChange={(e) => handleTempInjectionList(index_injection_temp,'amount',e.currentTarget.value )} type="number" defaultValue={injectionList[index_injection_temp]['amount']} id={"temp" + index_injection_temp + "amount"} name={"temp" + index_injection_temp + "amount"}/>
                    </td>
                    <td>
                      <input onChange={(e) => handleTempInjectionList(index_injection_temp,'fee',e.currentTarget.value )} type="number" defaultValue={injectionList[index_injection_temp]['fee']} id={"temp" + index_injection_temp + "fee"} name={"temp" + index_injection_temp + "fee"}/>
                    
                    </td>
                    <td>
                      <select onChange={(e) => handleTempInjectionList(index_injection_temp,'coin',e.currentTarget.value )} value={injectionList[index_injection_temp]['coin']}  id={"temp" + index_injection_temp+"_coin"}>
                        {coin_full_list.map((item) => (
                          <option value={item.toUpperCase()} name={"temp" + index_injection_temp + "coin"} key={"temp" + index_injection_temp + "coin"}>
                            {item.toUpperCase()}
                          </option>
                        ))}
                        </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {injectionList.length > 0 ?
            (<div style={{ textAlign: "center", margin: "0px 0px 10px 0px" }}>
              <button className="btn btn-primary btn-sm" style={{ width: '100px', display: "float" }} onClick={() => saveInjection()}>
                Save
              </button>
            </div>) : null}
        </div>
        <br></br>

      </div>
    </div>
    <br></br>
    <div>
        <div className="first_div">
          <div className="second_div">
            <div style={{ display: "flex", margin: "0px 0px 10px 0px" }}>
              <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Injection History​</h5>
              <div style={{ display: "flex", margin: "0px 0px 0px 10px" }}>
                <div>
                  <DateFromToDiv send_data_from={setDateFromRecord} send_data_to={setDateToRecord} />
                </div>
              </div>
            </div>
            <div style={{ margin: "0px 0px 10px 0px" }}>
              <table>
                <thead style={{ background: "#6CA0EC" }}>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Transferred Amount​</th>
                    <th>Fee</th>
                    <th>Coin</th>
                    <th>Total Amount</th>
                    <th>Save</th>
                  </tr>
                </thead>

                <tbody>
                 
                  {
                  injectionListFromDb.map((injectioin, index_injection) => (
                    <tr key={"log"+index_injection} style={{ background: index_injection%2 === 0 ? "#142440​" :"#233E6F" }}>
                      <td>
                        {index_injection + 1}
                      </td>
                      <td>
                        {injectioin.create_date_time}
                      </td>
                      <td>
                        <select onChange={(e) => handleFrom(e.currentTarget.value, index_injection)} disabled={editButtons[index_injection] === "Edit" ? "disabled" : ""} value={currentFromList[index_injection]} id={index_injection+"_from"}>
                        {c_opt_exchanges.map((exchage) => (
                          <option value={exchage.value} name={"load" + exchage.name + index_injection + "from"} key={"load" + exchage.name + index_injection + "from"}>
                            {exchage.value}
                          </option>
                        ))}
                        </select>
                      </td>
                      <td>
                        <select onChange={(e) => handleTo(e.currentTarget.value, index_injection)} disabled={editButtons[index_injection] === "Edit" ? "disabled" : ""} value={currentToList[index_injection]} id={index_injection+"_to"}>
                        {c_opt_exchanges.map((exchage) => (
                          <option value={exchage.value} name={"load" + exchage.name + index_injection + "to"} key={"load" + exchage.name + index_injection + "to"}>
                            {exchage.value}
                          </option>
                        ))}
                        </select>
                      </td>
                      <td>
                        <input type="number" id={"log"+index_injection + "amount"} name={"log"+index_injection + "amount"} disabled={editButtons[index_injection] === "Edit" ? "disabled" : ""}  className={editButtons[index_injection] === "Edit" ? "read-only" : ""} autoFocus defaultValue={currentTradingAmountList[index_injection]} onChange={(e) => handleTradingAmount(e.target.value, index_injection)} />
                      </td>
                      <td>
                        <input type="number" id={"log"+index_injection + "fee"} name={"log"+index_injection + "fee"} disabled={editButtons[index_injection] === "Edit" ? "disabled" : ""}  className={editButtons[index_injection] === "Edit" ? "read-only" : ""} autoFocus defaultValue={currentFeeList[index_injection]} onChange={(e) => handleFee(e.target.value, index_injection)} />
                      </td>
                      <td>
                        <select onChange={(e) => handleCoin(e.currentTarget.value, index_injection)} disabled={editButtons[index_injection] === "Edit" ? "disabled" : ""} value={currentCoinList[index_injection]} id={"log"+index_injection+"_coin"}>
                        {coin_full_list.map((item) => (
                          <option value={item.toUpperCase()} name={"load" + index_injection + "coin"} key={"load" + index_injection + "coin"}>
                            {item.toUpperCase()}
                          </option>
                        ))}
                        </select>
                      </td>
                      <td>
                        <input type="number" id={"log"+index_injection + "total_amount"} name={"log"+index_injection + "total_amount"} disabled='disabled' defaultValue={currentTotoalAmountList[index_injection]} ></input>
                      </td>
                      <td>
                        <button id={"log"+index_injection+"_edit"}  style={{ width: '100px',display: "float"}} className={editButtons[index_injection] === "Edit" ? "btn btn-primary btn-sm" : "btn btn-success btn-sm"} onClick={(e) => handleEdit(index_injection, index_injection+"_edit",e.currentTarget.innerText)}>{editButtons[index_injection] === 'Edit' ? "Edit" : "Save"}</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div key={remountComponent}>
                    <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                </div>
            </div>
          </div>
        </div>
    </div>
    </>
  );
}

export default InjectionRecords;

import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import Modal from "../../common/Modal";
import HeaderNew from "../../headerFooterSide/HeaderNew";
import StockChart from "./StockChart";
import StockChart_RealTime from "./StockChart_RealTime";
import StockChart_RealTime_switching from "./StockChart_RealTime_switching";


function Ai2_Trading_Spot({handleLogoutapp}) {
    const [title, setTile] = useState()

    const [timeframe, setTimeFrame] = useState("time_frame_1");

    const [wait_enable, set_wait_enable] = useState();
    const [wait_count, set_wait_count] = useState();

    const [force_finish_enable, set_force_finish_enable] = useState();
    const [stop_loss, set_stop_loss] = useState();
    const [take_profit, set_take_profit] = useState();

    const [skip_enable, set_skip_enable] = useState();

    const [repainting, set_repainting] = useState(1)
    
    function repaint() {
        var temprepainting = repainting
        temprepainting = temprepainting + 1
        set_repainting(temprepainting)

        console.log("repaint :temprepainting,  ==>" + temprepainting )

    }

    function saveTimeFrameToSession(timeframe_){
        window.sessionStorage.setItem("timeframe", timeframe_);
        setTimeFrame(timeframe_)

    }

    function set_wait_togle(){
        if (wait_enable == true){
            set_wait_enable(false)
        }else{
            set_wait_enable(true)
        }
    }
    function set_force_finish_togle(){
        if (force_finish_enable == true){
            set_force_finish_enable(false)
        }else{
            set_force_finish_enable(true)
        }
    }
    function set_skip_togle(){
        if (skip_enable == true){
            set_skip_enable(false)
        }else{
            set_skip_enable(true)
        }
    }

    function set_trading_type() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            wait_enable : wait_enable,
            wait_count : wait_count,
            force_finish_enable : force_finish_enable,
            stop_loss : stop_loss,
            take_profit : take_profit,
            skip_enable : skip_enable,
        };
        //console.log("python_server_url ===> ")
        //console.log(python_server_url)
        //console.log("data ===> ")
        //console.log(JSON.stringify(data))

        axios
            .post(`${python_server_url}/set_trading_type`, data)
            .then((res) => {
                //console.log(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    
                    if (res.data.data.wait_enable == "false"){
                        set_wait_enable(false)
                    }else {
                        set_wait_enable(true)
                    }
                    set_wait_count(parseInt(res.data.data.wait_count))

                    if (res.data.data.force_finish_enable == "false"){
                        set_force_finish_enable(false)
                    }else {
                        set_force_finish_enable(true)
                    }
                    set_stop_loss(parseFloat(res.data.data.stop_loss))
                    set_take_profit(parseFloat(res.data.data.take_profit))

                    if (res.data.data.skip_enable == "false"){
                        set_skip_enable(false)
                    }else {
                        set_skip_enable(true)
                    }


                } 
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });

    }



    function get_trading_type() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
        };
        //console.log("python_server_url ===> ")
        //console.log(python_server_url)
        //console.log("data ===> ")
        //console.log(JSON.stringify(data))

        axios
            .post(`${python_server_url}/get_trading_type`, data)
            .then((res) => {
                console.log(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    console.log(res.data.data.force_finish_enable)

                    if (res.data.data.wait_enable == "false"){
                        set_wait_enable(false)
                    }else {
                        set_wait_enable(true)
                    }
                    set_wait_count(parseInt(res.data.data.wait_count))

                    if (res.data.data.force_finish_enable == "false"){
                        set_force_finish_enable(false)
                    }else {
                        set_force_finish_enable(true)
                    }
                    set_stop_loss(parseFloat(res.data.data.stop_loss))
                    set_take_profit(parseFloat(res.data.data.take_profit))

                    if (res.data.data.skip_enable == "false"){
                        set_skip_enable(false)
                    }else {
                        set_skip_enable(true)
                    }

                    //{"data":{"force_finish_enable":"false","skip_enable":"false","stop_loss":"3","take_profit":"3","wait_count":"1","wait_enable":"false"},"status":"ok"}
                    set_repainting(repaint+1)

                } 
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });

    }

    useEffect(() => {
        setTile("AI2 Trading Spot")
        get_trading_type()
    }, []);


    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <br></br>
            <div>
                
                <div style={{margin: "1px 0px 0px 0px" }}>
                    {/*
                    { (
                        <div>
                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "0px 0px 0px 0px" }}>     
                                <label htmlFor="enable_waite" style={{margin: "0px 20px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Enable Wait​</label>
                                <input type="radio" style={{ width: "15px" , height : "15px" }} id="enable_waite" name="enable_waite" checked={wait_enable === true} onClick={() => set_wait_togle()}  />
                                
                                <label htmlFor="wait_count"  style={{margin: "0px 10px 0px 30px", fontSize :"12px",  color: "white" }}> Wait Count : </label>
                                <input type="text" id="wait_count" name="wait_count" style={{ width: "100px" }} defaultValue={wait_count}  onChange={(e) => set_wait_count(e.target.value)} />
                            </div>
                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "10px 0px 0px 0px" }}>     
                                <label htmlFor="enable_waite" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Enable F/T</label>
                                <input type="radio" style={{ width: "15px" , height : "15px" }} id="enable_force" name="enable_force" checked={force_finish_enable === true} onClick={() => set_force_finish_togle()}  />
                                
                                <label htmlFor="stop_loss"  style={{margin: "0px 20px 0px 30px", fontSize :"12px",  color: "white" }}> stop_loss : </label>
                                <input type="text" id="stop_loss" name="stop_loss" style={{ width: "100px" }} defaultValue={stop_loss}  onChange={(e) => set_stop_loss(e.target.value)} />

                                <label htmlFor="take_profit"  style={{margin: "0px 0px 0px 20px", fontSize :"12px",  color: "white" }}> take_profit : </label>
                                <input type="text" id="take_profit" name="take_profit" style={{ width: "100px" }} defaultValue={take_profit}   onChange={(e) => set_take_profit(e.target.value)} />
                            </div>


                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "10px 0px 10px 0px" }}>     
                                <label htmlFor="skip" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Skip pyramiding</label>
                                <input type="radio" style={{ width: "15px" , height : "15px" }} id="skip" name="skip" checked={skip_enable === true} onClick={() => set_skip_togle()}  />
                            </div>


                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "10px 0px 10px 0px" }}>     
                                <button className="btn btn-success btn-sm" style={{ width: '100px', display: "float" }} onClick={() => set_trading_type()}>
                                Apply
                                </button>
                            </div>
                        </div>
                        )
                    }
                */}
                    <div width="100%" style={{  display: "flex",margin: "0px 0px 0px 0px" }}>
                        <div style={{ width:"90%", justifyContent: "center" ,margin: "0px 20px 0px 0px" }}>
                            <StockChart_RealTime_switching/>
                        </div>
                    </div>
                </div>

                <div style={{margin: "3px 0px 0px 0px" }}>
                    <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "0px 0px 0px 0px" }}>
                    </div>
                    <div width="100%" style={{  display: "flex",margin: "0px 0px 0px 0px" }}>
                        <div style={{ width:"90%", justifyContent: "center" ,margin: "0px 20px 0px 0px" }}>
                            <StockChart_RealTime/>
                        </div>
                    </div>
                </div>
                
                <div style={{margin: "1px 0px 0px 0px" }}>
                    <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "0px 0px 0px 0px" }}>
                        <button onClick={() => saveTimeFrameToSession("time_frame_1")} style={{ background: timeframe === "time_frame_1" ? "#F7D358" :"#B18904" , width: "35px" , fontSize : "14px" }} >TF1</button>
                        <button onClick={() => saveTimeFrameToSession("time_frame_2")} disabled style={{ background: timeframe === "time_frame_2" ? "#F7D358" :"#B18904" , width: "35px" , fontSize : "14px"}}>TF2</button>
                        <button onClick={() => saveTimeFrameToSession("time_frame_3")} disabled style={{ background: timeframe === "time_frame_3" ? "#F7D358" :"#B18904" , width: "35px" , fontSize : "14px"}}>TF3</button>
                    </div>
                    <div width="100%" style={{  display: "flex",margin: "0px 0px 0px 0px" }}>
                        <div style={{ width:"90%", justifyContent: "center" ,margin: "0px 20px 0px 0px" }}>
                            <StockChart timeframe={timeframe} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Ai2_Trading_Spot;

import React, { useState, useEffect ,useRef} from "react";
import axios from "axios";

import ReactPaginate from "react-paginate";
import HeaderNew from "../../../headerFooterSide/HeaderNew";
import { locale_opt, c_opt_exchanges_all, c_opt_strategy_grp_all, c_opt_tfs_all, c_opt_order_type_all } from "../../../common/Commons";
import { C_coins, AscDesc, ExchangeDiv_Trading_Record, CoinsDiv, GroupDiv, StrategyInputDiv, TFDiv, DateFromToDiv } from "../../../common/Commons";
import { TradingTypeSelector } from "../../../common/Commons";

// import "./logRecords.css";

function TradingLog({handleLogoutapp}) {

    const [title, setTile] = useState()
    const [datalogs, setDatalogs] = useState([]);
    
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [remountComponent, setRemountComponent] = useState();
    const pairs = [1];

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    function get_loading_traindg() {
        //alert("get_loading_traindg!! ");
        
        const limit_cnt = 20;
        const data = {
            msg: "get_trading_all_data_log",
            pgn: pageNumber,
            limit_cnt: limit_cnt,

        };
       // alert(JSON.stringify(data));
       
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/get_trading_all_data_log`, data)
            .then((res) => {
                if (res.data.result.length !== 0) {
                    const page_cnt_math = Math.ceil(res.data.result[0]/ limit_cnt);
                    setPageCount(page_cnt_math);
                    setDatalogs(res.data.result[1]);
                } else {
                    setPageCount(0);
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    useEffect(() => {
        changePage({ selected: 0 });
        setRemountComponent(Math.random());
        get_loading_traindg();
    }, []);

    useEffect(() => {
        get_loading_traindg();
    }, [pageNumber]);


    useEffect(() => {
        setTile("Trading Full Log")
        
    }, []);



    return (
        <div>
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <div className="first_div">
                <br />
                <div className="second_div">
                    <h5>Trading Log</h5>

                    &emsp;&emsp;
                    <button className="btn btn-success btn-sm" style={{ marginTop: "0.2em", fontSize: "12px" }} onClick={() => window.location.reload()}>
                        Refresh
                    </button>
                    &emsp;&emsp;

                    <br />
                    <br />
                    
                    <table>
                        <thead >
                            <tr>
                                <th></th>
                                <th colSpan="1">Trading Type</th>
                                <th colSpan="6">Strategy Information</th>
                                <th colSpan="2">Initial Balance</th>
                                <th colSpan="2">End Balance</th>
                                <th colSpan="3">Price</th>
                                <th colSpan="6">Trading Information​</th>
                                <th colSpan="3">Time Information​</th>
                            </tr>
                            <tr style={{ background: "#4472c4" }}>
                                <th>#</th>
                                <th>Trading Type</th>
                                <th>Exchange</th>
                                <th>Group</th>
                                <th>Strategy</th>
                                <th>TF</th>
                                <th>Position</th>
                                <th>Coin</th>
                                <th>Crypto</th>
                                <th>Fiat</th>
                                <th>Crypto</th>
                                <th>Fiat</th>
                                <th>Trigger</th>
                                <th>Order</th>
                                <th>Trade</th>
                                <th>Operation</th>
                                <th>Order Type</th>
                                <th>Crypto Qty</th>
                                <th>Fiat Qty</th>
                                <th>Fee</th>
                                <th>Status</th>
                                <th>Msg 1</th>
                                <th>Traded</th>
                                <th>Order</th>
                                <th>Triggered</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datalogs.map((k, i) => (
                                <tr className="logTr" key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" ,cursor: "pointer", hover: { background: "red" } }}>
                                    <td>{i + 1}</td>

                                    <td>{k.trading_type}</td>
                                    <td>{k.exchange_name}</td>
                                    <td>{k.strategy_group === "1" ? "MLC" : k.strategy_group === "2" ? "P2P" : k.strategy_group === "3" ?  "TBT" : "None" }</td>
                                    <td>{k.strategy_name}</td>
                                    <td>{k.time_frame}</td>
                                    {/*<td>
                                        {k.position} {k.bot_cnt !== null ? `(${k.bot_cnt})` : "()"}
                                    </td>
                                    */}
                                    <td>
                                        {k.position}
                                    </td>
                                    <td>{k.coin_type}</td>
                                    <td>{parseFloat(k.initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.total_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.total_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.trigger_price).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.price).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.current_crypto_price).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{k.operation_type}</td>
                                    <td>{k.order_type}</td>
                                    <td>{parseFloat(k.crypto_qty).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.fiat_qty).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.fee_paid).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{k.msg}</td>
                                    <td>{k.msg_dtl}</td>
                                    <td>{String(k.transaction_time).substring(0, 19)}</td>
                                    <td>{k.alarm_received_time}</td>
                                    <td>{k.alarm_sent_time}</td>
                                </tr>
                                    ))}
                            </tbody>
                        </table>
                    <div key={remountComponent}>
                        <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TradingLog;


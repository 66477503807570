import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import Modal from "../../common/Modal";
import HeaderNew from "../../headerFooterSide/HeaderNew";
import StockChart from "./StockChart";
import StockChart_Prediction from "./StockChart_Prediction";

function Ai_Trading_Spot({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [timeframe, setTimeFrame] = useState("1m");


    function saveTimeFrameToSession(timeframe_){
        window.sessionStorage.setItem("timeframe", timeframe_);
        setTimeFrame(timeframe_)

    }

    useEffect(() => {
        setTile("AI Trading Spot")
    }, []);


    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <br></br>
            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "0px 0px 0px 0px" }}>
                <button onClick={() => saveTimeFrameToSession("1m")} style={{ background: timeframe === "1m" ? "#F7D358" :"#B18904" , width: "35px" , fontSize : "14px" }} >1m</button>
                <button onClick={() => saveTimeFrameToSession("5m")} style={{ background: timeframe === "5m" ? "#F7D358" :"#B18904" , width: "35px" , fontSize : "14px"}}>5m</button>
                <button onClick={() => saveTimeFrameToSession("15m")} style={{ background: timeframe === "15m" ? "#F7D358" :"#B18904" , width: "35px" , fontSize : "14px"}}>15m</button>
                <button onClick={() => saveTimeFrameToSession("1h")} style={{background: timeframe === "1h" ? "#F7D358" :"#B18904" , width: "35px" , fontSize : "14px"}}>1h</button>
            </div>
            <div width="100%" style={{  display: "flex",margin: "0px 0px 0px 0px" }}>
                <div style={{ width:"60%", justifyContent: "center" ,margin: "0px 20px 0px 0px" }}>
                    <StockChart timeframe={timeframe} />
                </div>
                <div style={{ width:"40%", justifyContent: "center" ,margin: "0px 20px 0px 0px" }}>
                    <StockChart_Prediction timeframe={timeframe} />
                </div>
            </div>
            <br />
           
        </div>
    );
}

export default Ai_Trading_Spot;

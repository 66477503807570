import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { locale_opt, locale_opt_8 } from "../../common/Commons";
import { DateFromToDiv_B } from "../../common/Commons";
import "./DashBoard";
import "./Dashbord.css";


import { Data } from "./Data";

function AssetStatus() {
   
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [datalogs, setDatalogs] = useState([]);
    const [totoal_value, setTotalValue] = useState("");
    const [datalogsMontly, setDatalogsMontly] = useState(null);
    
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();


    const [chartYear, setChartYear] = useState(new Date());
    const [chartMonth, setChartMonth] = useState("");


    const [searchMode, setSearchMode] = useState("all");
   
    function getDataAll() {

        const limit_cnt = 30;
        const data = {
            bot_id: "common_bot",
            msg: "get_asset_status",
            search_mode: searchMode
        };
        //alert(JSON.stringify(data))
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/common_bot`, data)
            .then((res) => {
                //alert()
                if (res.data.msg == "ok") {

                    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
                    console.log(JSON.stringify(res.data))
                    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
                    setDatalogs(res.data.data_array);
                    setTotalValue(res.data.tatal_asset);
                } else {
                    setPageCount(0);
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    useEffect(() => {
        getDataAll()
    }, [searchMode]);
    

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ display: "flex", margin: "0.2em 0em 0em 2em" }}>
                        <h5 style={{ margin: "0px 3.5em 0px 0px" }}> Asset Status</h5>
                    </div>
                    <div style={{ display: "flex", margin: "0.5em 0em 0em 2em" }}>
                        <div style={{ width: "100%" }}>
                            <div style={{ margin: "0.5em 0em 0em 0em" }}>
                                <button className={`btn btn-sm Dasbord_Button ${searchMode ==='all' ? 'btn-success' : 'btn-secondary'}`} onClick={() => setSearchMode('all')}>
                                    All
                                </button>
                                <button className={`btn btn-sm Dasbord_Button ${searchMode ==='spot' ? 'btn-success' : 'btn-secondary'}`}   onClick={() => setSearchMode('spot')}>
                                    Spot
                                </button>
                                <button className={`btn btn-sm Dasbord_Button ${searchMode ==='future' ? 'btn-success' : 'btn-secondary'}`}   onClick={() => setSearchMode('future')}>
                                    Future
                                </button>
                                <span>Today's Record : {dayjs().format("YYYY-MM-DD dddd hh:mm:ss")}</span>
                            </div>
                            <div style={{ margin: "0.5em 0em 0em 0em" }}>
                                <table>
                                    <thead>
                                        <tr style={{ background: "#4472C4" }}>
                                            <th rowSpan={3}>Part</th>
                                            <th rowSpan={3}>Type</th>
                                            <th rowSpan={3}>Exchange</th>
                                            <th colSpan={9}>Strategy</th>
                                        </tr>
                                        <tr style={{ background: "#2980B9" }}>
                                            <th colSpan={3}>MLC</th>
                                            <th colSpan={3}>P2V</th>
                                            <th colSpan={3}>TBT</th>
                                        </tr>
                                        <tr style={{ background: "#2980B9" }}>
                                            <th>Free</th>
                                            <th>Used</th>
                                            <th>Total</th>

                                            <th>Free</th>
                                            <th>Used</th>
                                            <th>Total</th>

                                            <th>Free</th>
                                            <th>Used</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        {datalogs.map((k, i) => (

                                            (k.part === "spot" || k.part === "future" ) ? 
                                                    <tr key={i} style={{ background: k.type === 'pyramiding' ? "#142440​" : k.type === 'pyramiding stk' ? "#233E6F" :k.type === 'future' ? "#142440​" :"#233E6F" , hover: { background: "red" } }} >
                                                        <td>{k.part}</td>
                                                        <td>{k.type}</td>
                                                        <td>{k.exchange}</td>
                                                        <td>{k.mlc_free}</td>
                                                        <td>{k.mlc_used}</td>
                                                        <td>{k.mlc_total}</td>
                                                        <td>{k.p2v_free}</td>
                                                        <td>{k.p2v_used}</td>
                                                        <td>{k.p2v_total}</td>
                                                        <td>{k.tbt_free}</td>
                                                        <td>{k.tbt_used}</td>
                                                        <td>{k.tbt_total}</td>
                                                    </tr>
                                            : null
                                            
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
}

export default AssetStatus;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import TempData from "./data2";

const StockChart = ({timeframe}) => {

    const [ohlc, setOhlc] = useState([]);
    const [ema1, setEma1] = useState([]);

    const [upper_band, setUpper_Band] = useState([]);
    const [middle_band, setMiddle_Band] = useState([]);
    const [lower_band, setLower_Band] = useState([]);
    const [buy_point, setBuy_Point] = useState([]);
    const [sell_point, setSell_Point] = useState([]);

    const [volume, setVolume] = useState([]);
    const [groupingUnits, setGroupingUnits] = useState([]);
    const [candleData, setCandleData] = useState([]);


    const [xAxis_min, set_xAxis_min] = useState(0);
    const [xAxis_max, set_xAxis_max] = useState(0);


    var candleDataTemp = []

    /*
    let xAxis_min = 0;
    let xAxis_max = 0;

    function setxAxis_MinMax(min, max){

        xAxis_min = min
        xAxis_max = max
        console.log("setxAxis_MinMax , xAxis_min : " + xAxis_min)
        console.log("setxAxis_MinMax , xAxis_max : " + xAxis_max)

    }
    */
    var redrawEnabled = true;
    const options = {

        chart: {
            zooming: {
                mouseWheel: false
            },
            /*
            zooming: {
                mouseWheel: {
                  enabled: false
                }
              },
              */
            backgroundColor: "#1c1b2b",
            borderRadius: 15,
            height: 400,

            events: {
                load: function () {
                    console.log("StockChart is loaded");
                    var chart = this
                    var xAxis = chart.xAxis[0]
                    var xExtremes = xAxis.getExtremes()
                    var newMin = xExtremes.dataMin * 0.9
                    var newMax = xExtremes.dataMax * 1.1
                    //console.log(` xExtremes.dataMin : ${xExtremes.dataMin}`);

                },
                redraw: function() {
                    if (redrawEnabled) {
                        var sum = 0;
                        redrawEnabled = false;
    

                        this.update({
                            subtitle: {
                               // text: sum
                               text : ""
                            }
                        });
                        /*
                        var chart = this;
                        var xAxis = chart.xAxis[0]

                        console.log("redraw : xAxis_min : " +xAxis_min )
                        console.log("redraw : xAxis_max : " +xAxis_max )
                        xAxis.setExtremes(xAxis_min,xAxis_max);
                        */
                       
                        
                        redrawEnabled = true;
                    }
                }
            }
        },
        
        colors: ['gray', 'green', 'blue', 'violet', 'yellow'],
        rangeSelector: {
            selected: 4
        },
        scrollbar: {
          enabled: false,
        },
        navigator: {
          enabled: true,
        },

        credits: {
            enabled: false
        },

        title: {
            text: "[ Current ]",
            style: {
                color: "#ffff",
            },
        },
        xAxis: {
            //min : xAxis_min,
            //max : xAxis_max,
            labels: {
                style: {
                    color: 'white'
                }
            },
            events: {
                setExtremes(e) {
                    //console.log(` xAxis, setExtremes : ${'<b>Set extremes:</b> e.min: ' + Highcharts.dateFormat(null, e.min) +' | e.max: ' + Highcharts.dateFormat(null, e.max) + ' | e.trigger: ' + e.trigger}`);

                }
            }
        },

        yAxis: [{
            labels: {
                style : {
                    color : "white",
                    fontSize : " 0.7em",
                    align: 'right',

                }
            },
            height: '80%',
            resize: {
                enabled: true
            },
            gridLineColor:'#e6e6e6',
            gridLineWidth: 0.1,
            lineColor: false,
        },
        {
            labels: {
                align: 'left'
            },
            height: '80%',
            resize: {
                enabled: true
            }
        }, {
            labels: {
                align: 'left'
            },
            top: '80%',
            height: '20%',
            offset: 0
        }
        ],
        tooltip: {
            positioner: function () {
                return {
                    x: this.chart.plotLeft,
                    y: this.chart.plotTop
                };
            },
            shared: true,
            headerFormat: '',
            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
            valueDecimals: 2,
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.8)'
        },
        tooltip: {
            enabled: false
        },
        /*
        tooltip: {
            formatter: function () {
                return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>, in series ' + this.series.name;
            }
        },
        */
        /*
        tooltip: {
            shape: 'square',
            headerShape: 'callout',
            borderWidth: 0,
            shadow: false,
            positioner: function (width, height, point) {
                const chart = this.chart;
                let position;

                if (point.isHeader) {
                    position = {
                        x: Math.max(
                            // Left side limit
                            chart.plotLeft,
                            Math.min(
                                point.plotX + chart.plotLeft - width / 2,
                                // Right side limit
                                chart.chartWidth - width - chart.marginRight
                            )
                        ),
                        y: point.plotY
                    };
                } else {
                    position = {
                        x: point.series.chart.plotLeft,
                        y: point.series.yAxis.top - chart.plotTop
                    };
                }

                return position;
            }
        },
        */
        series: [{
            type: 'candlestick',
            id: 'coin-ohlc',
            name: 'coin-ohlc',
            data: ohlc,
            events: {
            	afterAnimate: function () {
                    //alert(xAxis_min)
                    /*
                    const chart = this.chart;
                    var xAxis = chart.xAxis[0]

                    console.log("afterAnimate : xAxis_min : " +xAxis_min )
                    console.log("afterAnimate : xAxis_max : " +xAxis_max )
                    xAxis.setExtremes(parseInt(xAxis_min.toString()), parseInt(xAxis_max.toString()));
                    */

              }
            }
        },
        {
            type: 'line',
            id: "Ema1",
            name: "Ema1",
            data: ema1,
            yAxis: 0,
            color :"blue"
           
        },
        {
            type: "line",
            id: "bb_upper",
            name: "bb_upper",
            data: upper_band,
            yAxis: 0,
            color : "#E4BB32",
            lineWidth : 0.5,
        },
        {
            type: "line",
            id: "bb_middle",
            name: "bb_middle",
            data: middle_band,
            yAxis: 0,
            color : "#E4BB32",
            lineWidth : 0.5,
        },
        {
            type: "line",
            id: "bb_lower",
            name: "bb_lower",
            data: lower_band,
            yAxis: 0,
            color : "#E4BB32",
            lineWidth : 0.5,
        }
        /*,
        
        {
            type: 'column',
            id: 'coin-volume',
            name: 'Volume',
            data: volume,
            yAxis: 1
        }
        */
        ,

        {
            type: 'flags',
            name: 'Buy Point',
            data: buy_point,
            yAxis: 0,
            //shape: 'squarepin'
            shape: 'flag',
            color: '#0000FF', // same as onSeries
            fillColor: '#0000FF',
            width: 16,
            style: { // text style
                color: 'white'
            },
            title : "Buy",
            states: {
                hover: {
                    fillColor: '#0000FF' // darker
                }
            }
        },
        {
            type: 'flags',
            name: 'Sell Point',
            data: sell_point,
            yAxis: 0,
            //shape: 'squarepin'
            shape: 'flag',
            color: '#E74C3C', // same as onSeries
            fillColor: '#E74C3C',
            width: 16,
            style: { // text style
                color: 'white'
            },
            title : "Sell",
            states: {
                hover: {
                    fillColor: '#E74C3C' // darker
                }
            }
        }
        /*
        ,
        {
            type: 'flags',
            name: 'Flags on series',
            data: [{
                x: '1609459200000',
                title: 'Buy',
            },
            {
                x: '1561939200000',
                title: 'Buy',
            },
            {
                x: '1506816000000',
                title: 'Buy',
            }],
            onSeries: 'Ema1',
            //shape: 'squarepin'
            shape: 'flag',
            color: '#E74C3C', // same as onSeries
            fillColor: '#E74C3C',
            width: 16,
            style: { // text style
                color: 'white'
            },
            states: {
                hover: {
                    fillColor: '#EC7063' // darker
                }
            }
        }
        */
        /*
        */],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 800
                },
                chartOptions: {
                    rangeSelector: {
                        inputEnabled: false
                    }
                }
            }]
        }
        ,
        rangeSelector: {
            selected: 1,
            buttons: [
                {
                    type: "minutes",
                    count: 1,
                    text: "All",
                    title: "View All",
                },
            ],
        },
        plotOptions: {
            flags: {
                accessibility: {
                    exposeAsGroupOnly: true,
                    description: 'Flagged events.'
                }
            },
            /*
            scatter: {
                marker: {
                    radius: 2.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                jitter: {
                   // x: 0.005
                }
            },
            */
            line: {
                dashStyle: "Solid",
                lineWidth: 1,
               // lineColor: "#20a0b1",
            },
            candlestick: {
                lineColor: "#FB1809",
                color: "#FB1809",
                upColor: "#4EA64A",
                upLineColor: "#4EA64A",
            },
            bb: {
                lineWidth: 1,
                lineColor: "#20a0b1",
                bottomLine: {
                    styles: {
                        lineWidth: 0.5,
                        lineColor: "#fcfc27",
                    },
                },
                topLine: {
                    styles: {
                        lineWidth: 0.5,
                        lineColor: "#fcfc27",
                    },
                },
            },
            series: {
                borderColor: "red",
                marker: {
                    enabled: false,
                    radius: 0,
                },

                point: {
                    events: {
                        mouseOver: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            console.log(`plotOptions , mouseOver, to ${point.x}, ${point.y}`);
                            //self.handleMouseOver(point);
                        },

                        selection: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            console.log(`plotOptions  selection , to ${point.x}, ${point.y}`);
                            // self.handleMouseOver(point);
                        },
                    }
                }
            }
        }
    };

    (function (H) {
        Highcharts.Chart.prototype.callbacks.push(function (chart) {
            H.addEvent(chart.container, 'wheel', function (e) {
                /*
                var xAxis = chart.xAxis[0],
                    extremes = xAxis.getExtremes(),
                    newMin = extremes.min;
                console.log("wheel");
                console.log(extremes);
                console.log(newMin);


                if (e.deltaY < 0) {
                    xAxis.setExtremes(extremes.min - (extremes.min * 0.001), extremes.max, true);
                }
                else {
                    xAxis.setExtremes(extremes.min + (extremes.min * 0.001), extremes.max, true);
                }
                */
            });

            H.addEvent(chart.container, 'selection', function (e) {
                console.log("selection !!");
            });


            H.addEvent(chart.container, 'click', function (e) {
                e = chart.pointer.normalize(e);
                console.log(`Clicked11 chart at ${e.chartX}, ${e.chartY}`);

            });

            H.addEvent(chart.xAxis[0], 'afterSetExtremes', function (e) {

                //console.log(` xAxis, setExtremes : ${'<b>Set extremes:</b> e.min: ' + Highcharts.dateFormat(null, e.min) +' | e.max: ' + Highcharts.dateFormat(null, e.max) + ' | e.trigger: ' + e.trigger}`);
                //console.log(`load Set extremes to ${e.min}, ${e.max}`);

                //var extremes_min = e.min;
                //var extremes_max = e.max;
                //var extremes_start = extremes_max - 960000;
                //chart.xAxis[0].setExtremes(extremes_start, extremes_max);

                //var extremes_min_date_str = convertEpocToDateString(extremes_min);
                //console.log("extremes_min_date_str : " + extremes_min_date_str);
                //var extremes_max_date_str = convertEpocToDateString(extremes_max);
                //console.log("extremes_max_date_str : " + extremes_max_date_str);

                //console.log("new Date(extremes_start_date).getTime() : " + new Date(extremes_max_date_str).getTime());
                //console.log("extremes_min : " + extremes_min);
                //console.log("extremes_max : " + extremes_max);
                //console.log("extremes_start : " + extremes_start);

                //chart.xAxis[0].setExtremes(new Date(extremes_start_date).getTime(), new Date(extremes_max_date).getTime());
                //chart.xAxis[0].setExtremes(data[0][1].x, data[0][2000].x, true, false);

            });

            /*
            H.addEvent(H.Chart, 'load', function(e) {
            const chart = e.target;
            H.addEvent(chart.container, 'click', function(e) {
                e = chart.pointer.normalize(e);
                console.log(`Clicked chart at ${e.chartX}, ${e.chartY}`);
            });
            H.addEvent(chart.xAxis[0], 'afterSetExtremes', function(e) {
                console.log(`load Set extremes to ${e.min}, ${e.max}`);
            });
            });

            */

        });
    }(Highcharts));

    function convertEpocToDateString(epoctime) {
        let milliseconds = epoctime;
        let myDate = new Date(milliseconds);
        let dateStr = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate() + " " + myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds()
        return dateStr
    }

    function loadCandleData() {

        //console.log("candleData : " + candleData)

        if (candleData.length > 0)
        {
            
            var temp_ohlc = [];
            var temp_volume = [];
            var temp_ema1 = [];
            var temp_upper_band = [];
            var temp_middle_band = [];
            var temp_lower_band = [];
            var temp_buy_point = [];
            var temp_sell_point = [];
            var date;
            var dataLength = candleData.length;
                // set the allowed units for data grouping
            var     temp_groupingUnits = [
                [
                    "week", // unit name
                    [1] // allowed multiples
                ],
                ["month", [1, 2, 3, 4, 6]]
            ];
            var  i = 16;
                
            for (i; i < dataLength; i += 1) {
                //date = new Date(candleData[i].Date).getTime();
                //console.log("candleData[i].closetime : " + candleData[i].closetime)
                //var temp_Date = convertEpocToDateString(candleData[i].closetime)
                //console.log("temp_Date : " + temp_Date)
                //date = new Date(temp_Date).getTime();
                
                date = candleData[i].closetime;

            

                temp_ohlc.push([
                    date, // the date
                    candleData[i].open, // open
                    candleData[i].hight, // high
                    candleData[i].low, // low
                    candleData[i].close // close
                ]);
                temp_volume.push([
                    date, // the date
                    candleData[i].volume // 
                ]);
                temp_ema1.push([
                    date, // the date
                    candleData[i].ema_5 // t
                ]);
                temp_upper_band.push([
                    date, // the date
                    candleData[i].upper_band // 
                ]);
                temp_middle_band.push([
                    date, // the date
                    candleData[i].middle_band // 
                ]);
                temp_lower_band.push([
                    date, // the date
                    candleData[i].lower_band // 
                ]);
                if (parseInt(candleData[i].buy) != 0) {
                    temp_buy_point.push([
                        date, // the date
                        candleData[i].buy //  ,
                    ]);
                }
                if (parseInt(candleData[i].sell) != 0) {
                    temp_sell_point.push([
                        date, // the date
                        candleData[i].sell // 
                    ]);
                }
            }
            
            var tempMin =  Math.ceil(dataLength * 0.8)
            var tempMax = dataLength 
            console.log(" tempMin : " + tempMin)
            console.log(" tempMax : " +  tempMax)
            
            console.log("candleData[tempMin] : " + candleData[tempMin].closetime)
            console.log("candleData[tempMax] : " + candleData[tempMax-1].closetime)
            
            set_xAxis_min(candleData[tempMin].closetime)
            set_xAxis_max(candleData[tempMax-1].closetime)
            
            //setxAxis_MinMax(parseInt(candleData[tempMin].closetime.toString()),parseInt(candleData[tempMax-1].closetime.toString()));
            

        }

        setOhlc(temp_ohlc);
        setEma1(temp_ema1);
        //console.log("temp_ema1==>")
        //console.log(temp_ema1)
        setUpper_Band(temp_upper_band);
        setMiddle_Band(temp_middle_band);
        setLower_Band(temp_lower_band);
        setBuy_Point(temp_buy_point);
        setSell_Point(temp_sell_point);



        console.log("temp_volume==>")
        console.log(temp_volume)
        setVolume(temp_volume);

        setGroupingUnits(temp_groupingUnits);
        /*
        const data = [
            [1601324000000, 613, 617, 597, 597, 149800],
            [1601437600000, 614, 622, 611, 615, 164700]
        ];
        const ema = [
            [1601324000000, 613],
            [1601437600000, 622]
        ];
        */
    }

    function get_candle() {

        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI_TRADING;
        console.log("get_candle !")
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            timeframe: window.sessionStorage.getItem("timeframe"),
        };
        console.log(JSON.stringify(data))

        axios
            .post(`${python_server_url}/get_candle`, data)
            .then((res) => {
                //console.log(JSON.stringify(res.data))
                //alert(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    console.log("recevice ! ,  getCandleData !")
                    console.log(JSON.stringify(res.data.data))
                    setCandleData(res.data.data)

                } else {
                    setCandleData([])
                }
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });

    }

    useEffect(() => {
        get_candle();
        const intervalId = setInterval(() => {
            get_candle();
        }, 30000); //30 sec
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        loadCandleData()
    }, [candleData]);

    useEffect(() => {
        get_candle()
        //console.log("timeframe :" + timeframe)
    }, [timeframe]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
            />
        </div>
    );
};
export default StockChart;
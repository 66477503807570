import React, { useState, useEffect } from "react";
import axios from "axios";

import { c_opt_exchanges, c_opt_strategy_grp_name } from "../../../common/Commons";
import { C_coins, ExchangeDiv, CoinsDiv, DateFromToDiv } from "../../../common/Commons";

function DailyBalanceHistory() {
    const [exchange_name, setExchange_name] = useState("binance");
    const [coins, setCoins] = useState("ada");
    const [opt_coins, setOpt_coins] = useState([]);
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");

    const [balanceStatus_binance, setBalanceStatus_binance] = useState(null);
    const [balanceStatus_upbit, setBalanceStatus_upbit] = useState(null);
    const [balanceStatus_bithumb, setBalanceStatus_bithumb] = useState(null);
    const [balanceStatus_okex, setBalanceStatus_okex] = useState(null);
    const [balanceStatus_mexc, setBalanceStatus_mexc] = useState(null);
    const [balanceStatus_bitget, setBalanceStatus_bitget] = useState(null);


    // common
    const opt_exchanges = c_opt_exchanges;

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    function Tbody_binance({ ll, bg_color }) {
        return (
            <>
                {balanceStatus_binance[ll].map((name, i) => (
                    
                    <tr key={i+1} style={{ background: bg_color }}>
                        <td>{name.date}</td>
                        <td>{name.initial}</td>
                        <td>{name.deposit_hap}</td>
                        <td>{name.withdraw_hap}</td>
                        <td>{name.total}</td>
                        <td>{name.change}</td>
                    </tr>
                ))}
            </>
        );
    }
    function Tbody_upbit({ ll, bg_color }) {
        return (
            <>
                {balanceStatus_upbit[ll].map((name, i) => (
                    <tr key={i+1} style={{ background: bg_color }}>
                        <td>{name.date}</td>
                        <td>{name.initial}</td>
                        <td>{name.deposit_hap}</td>
                        <td>{name.withdraw_hap}</td>
                        <td>{name.total}</td>
                        <td>{name.change}</td>
                    </tr>
                ))}
            </>
        );
    }

    function Tbody_bithumb({ ll, bg_color }) {
        return (
            <>
                {balanceStatus_bithumb[ll].map((name, i) => (
                    <tr key={i+1} style={{ background: bg_color }}>
                        <td>{name.date}</td>
                        <td>{name.initial}</td>
                        <td>{name.deposit_hap}</td>
                        <td>{name.withdraw_hap}</td>
                        <td>{name.total}</td>
                        <td>{name.change}</td>
                    </tr>
                ))}
            </>
        );
    }

    function Tbody_okex({ ll, bg_color }) {
        return (
            <>
                {balanceStatus_okex[ll].map((name, i) => (
                    <tr key={i+1} style={{ background: bg_color }}>
                        <td>{name.date}</td>
                        <td>{name.initial}</td>
                        <td>{name.deposit_hap}</td>
                        <td>{name.withdraw_hap}</td>
                        <td>{name.total}</td>
                        <td>{name.change}</td>
                    </tr>
                ))}
            </>
        );
    }


    function Tbody_mexc({ ll, bg_color }) {
        return (
            <>
                {balanceStatus_mexc[ll].map((name, i) => (
                    <tr key={i+1} style={{ background: bg_color }}>
                        <td>{name.date}</td>
                        <td>{name.initial}</td>
                        <td>{name.deposit_hap}</td>
                        <td>{name.withdraw_hap}</td>
                        <td>{name.total}</td>
                        <td>{name.change}</td>
                    </tr>
                ))}
            </>
        );
    }


    function Tbody_bitget({ ll, bg_color }) {
        return (
            <>
                {balanceStatus_bitget[ll].map((name, i) => (
                    <tr key={i+1} style={{ background: bg_color }}>
                        <td>{name.date}</td>
                        <td>{name.initial}</td>
                        <td>{name.deposit_hap}</td>
                        <td>{name.withdraw_hap}</td>
                        <td>{name.total}</td>
                        <td>{name.change}</td>
                    </tr>
                ))}
            </>
        );
    }

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    useEffect(() => {

        const exchange_name_list = ["binance","upbit","bithumb","okex","mexc","bitget"]
        exchange_name_list.map((temp_item,index) => {

            const data = {
                bot_id: "a_bot",
                msg: "abot_daily_balance_history",
                exchange_name: temp_item,
                ticker_name: coins,
                dateFrom: dateFrom,
                dateTo: dateTo,
            };
    
            axios
                .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
                .then((res) => {
                    if(temp_item === 'binance' )
                        {
                            setBalanceStatus_binance(res.data.result);
                        }
                    if(temp_item === 'upbit' )
                        {
                        setBalanceStatus_upbit(res.data.result);
                        }

                    if(temp_item === 'bithumb' )
                        {
                            setBalanceStatus_bithumb(res.data.result);
                        }
                    if(temp_item === 'okex' )
                        {
                            setBalanceStatus_okex(res.data.result);
                        }
                    if(temp_item === 'mexc' )
                        {
                            setBalanceStatus_mexc(res.data.result);
                        }
                    if(temp_item === 'bitget' )
                        {
                            setBalanceStatus_bitget(res.data.result);
                        }
                    
                })
                .catch((err) => {
                    console.log(err);
                });

        }
        )
        /*
        const data = {
            bot_id: "a_bot",
            msg: "abot_daily_balance_history",
            exchange_name: exchange_name,
            ticker_name: coins,
            dateFrom: dateFrom,
            dateTo: dateTo,
        };

        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                setBalanceStatus(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
        */
    }, [exchange_name, coins, dateFrom, dateTo]);

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <h3></h3>
                    <br />
                    <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                        <div>
                            <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} _type="asset" />
                            &emsp;
                            <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                        </div>
                    </div>
                    <br />

                    <div style={{ display: "flex", marginLeft: "-1em" }}>
                        &emsp;&emsp;
                        <div style={{ marginLeft: "0.0em" ,width: "30%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>Binance</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus_binance && balanceStatus_binance ? <Tbody_binance ll="a_c" bg_color="#142440" /> : null}</tbody>
                            </table>
                        </div>
                        <div style={{ marginLeft: "0.5em" ,width: "30%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>Upbit</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus_upbit && balanceStatus_upbit ? <Tbody_upbit ll="a_c" bg_color="#52510A" /> : null}</tbody>
                            </table>
                        </div>
                        <div style={{ marginLeft: "0.5em" ,width: "30%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>Bithumb</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus_bithumb && balanceStatus_bithumb ? <Tbody_bithumb ll="a_c" bg_color="#05373E" /> : null}</tbody>
                            </table>
                        </div>
                        <div style={{ marginLeft: "0.5em" ,width: "30%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>Okex</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus_okex && balanceStatus_okex ? <Tbody_okex ll="a_c" bg_color="#1F0E2C" /> : null}</tbody>
                            </table>
                        </div>
                        <div style={{ marginLeft: "0.5em" ,width: "30%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>Mexc</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus_okex && balanceStatus_okex ? <Tbody_mexc ll="a_c" bg_color="#1F0E2C" /> : null}</tbody>
                            </table>
                        </div>
                        <div style={{ marginLeft: "0.5em" ,width: "30%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>Bitget</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus_bitget && balanceStatus_bitget ? <Tbody_bitget ll="a_c" bg_color="#1F0E2C" /> : null}</tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DailyBalanceHistory;

import React, { useState, useEffect } from "react";
import "./arbitrage.css";

import axios from "axios";
import dayjs from "dayjs";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { locale_opt } from "../../common/Commons";
import ABotStatusTodayDetail from "./ABotStatusTodayDetail";

function ABotStatusTodayMain() {
  console.log("ABotStatusTodayMain");


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const

  const [dataTodayArbitraseLog, setDataTodayArbitraseLog] = useState([]);
  const [newOld, setNewOld] = useState("asc");


  const [arbitrage_bod_id,  setArbitrage_bod_id] = useState()
  const [hasArbitrage_bod_id, setHasArbitrage_bod_id] = useState(false);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // function

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // useEffect

  // get today transaction log
  useEffect(() => {

    console.log("useEffect");

    const data = {
        bot_id: "arbitrage",
        msg: "get_arbitrage_log",
        newOld: newOld
    };

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/get_arbitrage_log`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {

            // case ==>  return dict(msg="1", result=[arbitrage_data_list])
            console.log("res.data.result  : " + res.data.result)
            console.log("res.data.result  : " + JSON.stringify(res.data.result))

            res.data.result.map((arbitrage_list,i) => {
              arbitrage_list.map((arbitrage,i) => {
                console.log(arbitrage);
              });
            });

            setDataTodayArbitraseLog(res.data.result);
        } else {
          setDataTodayArbitraseLog([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }, []);
  
  useEffect(() => {
    console.log("useEffect ABotStatusTodayMain arbitrage_bod_id  : " + arbitrage_bod_id)
  }, [hasArbitrage_bod_id]);

  ///////////////////////////////////////////////////////////////////////////////////
  // function

  function handleClickBotId(selected_value) {
    console.log("selected_value :" + selected_value);
    setArbitrage_bod_id(selected_value);
    console.log("arbitrage_bod_id :" + arbitrage_bod_id);

    setHasArbitrage_bod_id(true);
  }

  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {/* botstatus content */}
      <div className="first_div">
        <div className="second_div" style={{ justifyContent: 'center'}}>
          <div style={{ display: "flex", margin: "0px 0px 1em" }}>
              <h5 style={{ margin: "0px 3.5em 0px 0px" }}>Arbitrage Status</h5>
              <h6 style={{ margin: "0.5em 0px 0px 0em" }}>Today's Record : {dayjs().format("YYYY-MM-DD dddd hh:mm:ss")}</h6>
          </div>
          <div style={{width: "99%" }}>
             {dataTodayArbitraseLog.map((arbitrage_list,i) => (
                arbitrage_list.map((arbitrage,j) => (
                <div className="div_arbit"  style={{margin: "3.5em 0px 10px 0px" }}>
                  <><><table>
                    <thead>
                      <tr style={{ background: "#6CA0EC" }}>
                        <th>
                          Bot Id
                        </th>
                        <th>
                          Coin
                        </th>
                        <th>
                         Trade Amount
                        </th>
                        <th>
                          Order Count
                        </th>
                        <th>
                          Before Crypto Balance
                        </th>
                        <th>
                          After Crypto Balance
                        </th>
                        <th>
                          Difference
                        </th>
                        <th>
                          Net Profit Amount
                        </th>
                        <th>
                          Net Profit %
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr onClick={() => handleClickBotId(arbitrage.bot_id)} style={{ cursor: "pointer" }}>
                        <td>{arbitrage.bot_id}</td>
                        <td>{arbitrage.coin_name.toUpperCase()}</td>
                        <td>{arbitrage.total_traded_amount}</td>
                        <td>{arbitrage.total_buy_order_count}</td>
                        <td>{arbitrage.total_before_crpto_balance}</td>
                        <td>{arbitrage.total_after_crpto_balance}</td>
                        <td>{arbitrage.difference}</td>
                        <td>{arbitrage.total_profit_amount}&nbsp;USDT</td>
                        <td>​{arbitrage.total_profit_rate}&nbsp;%</td>
                      </tr>
                    </tbody>
                  </table></></>
                </div>
              ))
              ))}
          </div>
      </div> {/* end second div */}                                
    </div>  {/* end first div */}

    {hasArbitrage_bod_id && (
        <ABotStatusTodayDetail arbitrage_bod_id={arbitrage_bod_id && arbitrage_bod_id} />
      )}
  </div>
 
);
}

export default ABotStatusTodayMain;


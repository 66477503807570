import React, { useState , useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./logRecords.css";

import LogRecordsTransaction from "./LogRecordsTransaction";
import LogRecordsBot from "./LogRecordsBot";
import LogRecordsTrabo from "./LogRecordsTrabo";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function LogRecords({handleLogoutapp}) {
    const [whatRecords, setWhatRecords] = useState("1");
    const [title, setTile] = useState()

    useEffect(() => {
        setTile("Log Records")
    }, []);

    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />

            <div className="first_div">
                <div className="second_div">
                    <div style={{ color: "#6CA0EC" }}>
                        <input type="radio" id="transaction" name="transaction" value="1" checked={whatRecords === "1"} onChange={(e) => setWhatRecords(e.target.value)} />
                        <label htmlFor="transaction" style={{ cursor: "pointer" }}>
                            {" "}
                            Transaction Log
                        </label>
                        &emsp;
                        {/* 
                        <input type="radio" id="tradingbot" name="tradingbot" value="2" checked={whatRecords === "2"} onChange={(e) => setWhatRecords(e.target.value)} />
                        
                        <label htmlFor="tradingbot" style={{ cursor: "pointer" }}>
                            {" "}
                            Trading Bot Log
                        </label>
                        &emsp;
                        */}
                        <input type="radio" id="trabo" name="trabo" value="3" checked={whatRecords === "3"} onChange={(e) => setWhatRecords(e.target.value)} />
                        <label htmlFor="trabo" style={{ cursor: "pointer" }}>
                            {" "}
                            Pascal Log
                        </label>
                        &emsp;&emsp;
                        <button className="btn btn-success btn-sm" onClick={() => window.location.reload()}>
                            Refresh
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Router>{whatRecords === "1" ? <LogRecordsTransaction /> : whatRecords === "2" ? <LogRecordsBot /> : <LogRecordsTrabo />}</Router>
            </div>
            <br />
        </div>
    );
}

export default LogRecords;

import React, { useState, useEffect } from "react";
import "./arbitrage.css";

import axios from "axios";
import dayjs from "dayjs";
import moment, { relativeTimeThreshold } from 'moment';
import ReactPaginate from "react-paginate";
import { locale_opt } from "../../common/Commons";
import HeaderNew from "../../headerFooterSide/HeaderNew";

function ABotSettings({handleLogoutapp}) {
  console.log("ABotSettings");
  const [title, setTile] = useState()

  const pairs = [1];
  
  const [dataArbitrase, setDataArbitrase] = useState([]);

  const [coin_full_list, set_Coin_full_list] = useState([]);
  const [binance_list, set_Binance_list] = useState([]);
  const [upbit_list, set_Upbit_list] = useState([]);
  const [bithumb_list, set_Bithumb_list] = useState([]);
  const [okex_list, set_Okex_list] = useState([]);
  const [mexc_list, set_Mexc_list] = useState([]);
  const [bitget_list, set_Bitget_list] = useState([]);

  const [newOld, setNewOld] = useState("desc");

  const [remountComponent, setRemountComponent] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ui component
  
  // id
  const [currentIdList, setCurrentIdList] = useState([]);

  // coin
  const [currentCoinList, setCurrentCoinList] = useState([]);
  
  const handleCoins = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentCoinList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentCoinList(newArr);
    console.log("currentCoinList :" + newArr);
  };

  // buyexchange
  const [currentBuyExchangeList, setCurrentBuyExchangeList] = useState([]);
  const buyExchagelist =  ["Binance", "Okex", "Bithumb", "Upbit", "Mexc", "Bitget"];
  const handleBuyExchages = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentBuyExchangeList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentBuyExchangeList(newArr);
    console.log("currentBuyExchangeList :" + newArr);
  };

  // sell_exchange
  const [currentSellExchangeList, setCurrentSellExchangeList] = useState([]);
  const sellExchagelist =   ["Binance", "Okex", "Bithumb", "Upbit", "Mexc", "Bitget"];
  const handleSellExchages = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentSellExchangeList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentSellExchangeList(newArr);
    console.log("currentSellExchangeList :" + newArr);
  };
  // trading amount
  
  const [currentTradingAmountList, setCurrentTradingAmountList] = useState([]);
  const default_tradingAmount = "0.1";
  const handleTradingAmounts = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentTradingAmountList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentTradingAmountList(newArr);
    console.log("currentTradingAmountList :" + newArr);
  };

  //targetGap
  const [currentTargetGapList, setCurrentTargetGapList] = useState([]);
  const targetGapList = ["1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0"];
  const handleTargetGap = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentTargetGapList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentTargetGapList(newArr);
    console.log("currentTargetGapList :" + newArr);
  };

  //loopInterval
  const [currentLoopIntervalList, setCurrentLoopIntervalList] = useState([]);
  const loopIntervalList = ["1", "2", "3", "4", "5", "10", "15", "30", "60"];
  const handleLoopInterval = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentLoopIntervalList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentLoopIntervalList(newArr);
    console.log("currentLoopIntervalList :" + newArr);
  };

  //ordertype
  const [currentOrderTypeList, setCurrentOrderTypeList] = useState([]);
  const orderTypeList = ["market", "limit"];
  const handleOrderType = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentOrderTypeList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentOrderTypeList(newArr);
    console.log("currentOrderTypeList :" + newArr);
  };
  //handlePrices
  const [currentPriceList, setCurrentPriceList] = useState([]);
  const defaultPrice = "65000";
  const handlePrices = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentPriceList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentPriceList(newArr);
    console.log("currentPriceList :" + newArr);
  };


  //fiatPercentList
  const [currentFiatPercentToBuyList, setCurrentFiatPercentToBuyList] = useState([]);
  const fiatPercentList = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"];
  const handleFiat = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentFiatPercentToBuyList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentFiatPercentToBuyList(newArr);
    console.log("currentFiatPercentToBuyList :" + newArr);
  };
  //fiat qty List
  const [currentFiatQtyToBuyList, setCurrentFiatQtyToBuyList] = useState([]);

  //assetStock
  const [currentCryptoPercentToSellList, setCurrentCryptoPercentToSellList] = useState([]);
  const cryptoList = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"];
  const handleAssetStock = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentCryptoPercentToSellList]; // copying the old datas array
    newArr[index] = selected_value; // replace e.target.value with whatever you want to change it to
    setCurrentCryptoPercentToSellList(newArr);
    console.log("currentCryptoPercentToSellList :" + newArr);
  };
  const [currentCryptoQtyToSellList, setCurrentCryptoQtyToSellList] = useState([]);

  // edit button
  const [editButtons, setEditButtons] = useState([]);
  const handleEdit = (index,id,button_text) => {
    console.log("handleEdit index :" + index);
    console.log("handleEdit id :" + id);
    console.log("handleEdit button_text :" + button_text);
    let newArr = [...editButtons]; 
    if ( button_text === "Edit" ) {
        newArr[index] = "Save"; 
        setEditButtons(newArr);
        console.log("editButtons :" + newArr);
    }else{
      newArr[index] = "Edit"; 
      if (updateAbit(index))
      {
        setEditButtons(newArr);
        console.log("editButtons :" + newArr);
      }
    }
   // replace e.target.value with whatever you want to change it to
   
  };

  const [currentOperationStatus, setCurrentOperationStatus] = useState([]);
  const handleOperation = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentOperationStatus]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      setCurrentOperationStatus(newArr);
      console.log("currentOperationStatus :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      setCurrentOperationStatus(newArr);
      console.log("currentOperationStatus :" + newArr);
    }

  };

  /*
  const [currentOperationStatus, setCurrentOperationStatus] = useState([]);
  const handleOperation = (checked, index) => {
    console.log("checked :" + checked);
    console.log("index :" + id);
    if (checked) {
      console.log("checked before currentOperationStatus :" + currentOperationStatus);
      setCurrentOperationStatus([...currentOperationStatus, id]);
  
      console.log("checked after currentOperationStatus :" + currentOperationStatus);
    } else {
      // 체크 해제
      console.log("checked! before currentOperationStatus :" + currentOperationStatus);
      setCurrentOperationStatus(currentOperationStatus.filter((el) => el !== id));
      console.log("checked! after currentOperationStatus :" + currentOperationStatus);
    }
  };
  */

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // useEffect

  useEffect(() => {
    changePage({ selected: 0 });
    setRemountComponent(Math.random());
  }, [newOld]);


  useEffect(() => {
    const limit_cnt = 20;

    const data = {
        bot_id: "arbitrage",
        msg: "get_setting_arbitrage",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        dateFrom: "",
        dateTo: "",
        newOld: newOld,
    };

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_setting`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            //alert(JSON.stringify(res.data.result[1]) )
            setDataArbitrase(res.data.result[1]);
            makeSubData(res.data.result[1]);
        } else {
            setPageCount(0);
            setDataArbitrase([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }, [pageNumber, newOld]);

  // get coin list
  useEffect(() => {

    console.log("get coin list useEffect !");

    const data = {
        bot_id: "arbitrage",
        msg: "get_coin_list",
    };


    console.log("get_coin_list data : "  + data);

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_setting`, data)
    .then((res) => {
        if (res.data.coin_full_list.length !== 0) {
            console.log("res.data.coin_full_list  : " + JSON.stringify(res.data.coin_full_list))
            res.data.coin_full_list.map((coin,i) => {
              console.log(coin);
            });
            set_Coin_full_list(res.data.coin_full_list);
        } else {
          set_Coin_full_list([]);
        }

        if (res.data.binance_list.length !== 0) {
          console.log("res.data.binance_list  : " + JSON.stringify(res.data.binance_list))
          res.data.binance_list.map((coin,i) => {
            console.log(coin);
          });
          set_Binance_list(res.data.binance_list);
        } else {
          set_Binance_list([]);
        }

        if (res.data.upbit_list.length !== 0) {
          console.log("res.data.upbit_list  : " + JSON.stringify(res.data.upbit_list))
          res.data.upbit_list.map((coin,i) => {
            console.log(coin);
          });
          set_Upbit_list(res.data.upbit_list);
        } else {
          set_Upbit_list([]);
        }


        if (res.data.bithumb_list.length !== 0) {
          console.log("res.data.bithumb_list  : " + JSON.stringify(res.data.bithumb_list))
          res.data.bithumb_list.map((coin,i) => {
            console.log(coin);
          });
          set_Bithumb_list(res.data.bithumb_list);
        } else {
          set_Bithumb_list([]);
        }


        if (res.data.okex_list.length !== 0) {
          console.log("res.data.okex_list  : " + JSON.stringify(res.data.okex_list))
          res.data.okex_list.map((coin,i) => {
            console.log(coin);
          });
          set_Okex_list(res.data.okex_list);
        } else {
          set_Okex_list([]);
        }

        if (res.data.mexc_list.length !== 0) {
          console.log("res.data.mexc_list  : " + JSON.stringify(res.data.mexc_list))
          res.data.mexc_list.map((coin,i) => {
            console.log(coin);
          });
          set_Mexc_list(res.data.mexc_list);
        } else {
          set_Mexc_list([]);
        }

        if (res.data.bitget_list.length !== 0) {
          console.log("res.data.bitget_list  : " + JSON.stringify(res.data.bitget_list))
          res.data.bitget_list.map((coin,i) => {
            console.log(coin);
          });
          set_Bitget_list(res.data.bitget_list);
        } else {
          set_Bitget_list([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }, []);
  
  useEffect(() => {
    setTile("Arbitrage  Bot Settings")
  }, []);



  ///////////////////////////////////////////////////////////////////////////////////
  // function

  function makeSubData(dataArbitrase_){
    let newArr_Ids = [];
    let newArr_Coins = [];
    let newArr_Buttons = [];
    let newArr_BuyExchages = [];
    let newArr_SellExchages = [];
    let newArr_TradingAmounts = [];
    let newArr_TargetGaps = [];
    let newArr_LoopInterval = [];
    let newArr_OderTypes = [];
    let newArr_Prices = [];
    let newArr_fiatsPercentToBuy = [];
    let newArr_fiatsQtyToBuy = [];
    let newArr_crytoPercentToSell = [];
    let newArr_crytoQtyToSell = [];
    let newArr_operation = [];
    
    

    for (let key in dataArbitrase_){
      console.log(key+":"+dataArbitrase_[key].coin);

      newArr_Ids[key] = dataArbitrase_[key].id;
      newArr_Coins[key] = dataArbitrase_[key].coin;
      newArr_Buttons[key] = "Edit";
      newArr_BuyExchages[key] = dataArbitrase_[key].buy_exchange_id;
      newArr_SellExchages[key] = dataArbitrase_[key].sell_exchange_id;
      newArr_TradingAmounts[key] = dataArbitrase_[key].trading_amount;
      newArr_TargetGaps[key] =  dataArbitrase_[key].target_gap;
      newArr_LoopInterval[key] =  dataArbitrase_[key].loop_interval;
      newArr_OderTypes[key] =  dataArbitrase_[key].order_type;
      newArr_Prices[key] =  dataArbitrase_[key].price;
      newArr_fiatsPercentToBuy[key] =  dataArbitrase_[key].fiat_percent_to_buy;
      newArr_fiatsQtyToBuy[key] =  dataArbitrase_[key].fiat_qty_to_buy;
      newArr_crytoPercentToSell[key] =  dataArbitrase_[key].crypto_percent_to_sell;
      newArr_crytoQtyToSell[key] = dataArbitrase_[key].crypto_qty_to_sell;
      newArr_operation[key] =  dataArbitrase_[key].operation;
    }
    setCurrentIdList(newArr_Ids);
    setCurrentCoinList(newArr_Coins);
    setEditButtons(newArr_Buttons);
    setCurrentBuyExchangeList(newArr_BuyExchages);
    setCurrentSellExchangeList(newArr_SellExchages);
    setCurrentTradingAmountList(newArr_TradingAmounts);
    setCurrentTargetGapList(newArr_TargetGaps);
    setCurrentLoopIntervalList(newArr_LoopInterval);
    setCurrentOrderTypeList(newArr_OderTypes);
    setCurrentPriceList(newArr_Prices);
    setCurrentFiatPercentToBuyList(newArr_fiatsPercentToBuy);

    setCurrentFiatQtyToBuyList(newArr_fiatsQtyToBuy);

    setCurrentCryptoPercentToSellList(newArr_crytoPercentToSell);

    setCurrentCryptoQtyToSellList(newArr_crytoQtyToSell);

    console.log("newArr_operation :" + newArr_operation)
    setCurrentOperationStatus(newArr_operation);
  }
  // create new abit
  function createNewAbit(){
    const limit_cnt = 20;
    const data = {
        bot_id: "arbitrage",
        msg: "create_arbitrage", 
        which: "register",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        newOld: newOld,
    };

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/create_arbitrage`, data)
    .then((res) => {
        console.log(res.data)

        if (res.data.result[0] === "OK"){
          //alert("OK")
          if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[1].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            setDataArbitrase(res.data.result[2]);

            makeSubData(res.data.result[2]);
          } else {
              setPageCount(0);
              setDataArbitrase([]);
          }

        }else{
            alert("ERROR ! : please check duplicate bot and the status of system !")
        }
    })
    .catch((err) => {
        console.log(err);
        alert("ERROR")
    })
    // .finally(() => {
    //    window.location.reload();
    // });
  }

  function check_Validate_Coin(index){
    let buy_exchange_id  = currentBuyExchangeList[index] ;
    let sell_exchange_id  = currentSellExchangeList[index] ;

    let rval = true;

    // check buy
    if ( buy_exchange_id.toUpperCase() == 'BINANCE' )
    {
      if(!binance_list.includes(currentCoinList[index]))
        rval = false;
    }
    
    if ( buy_exchange_id.toUpperCase() == 'UPBIT' )
    {
      if(!upbit_list.includes(currentCoinList[index]))
        rval = false;

    }
    
    if ( buy_exchange_id.toUpperCase() == 'BITHUMB' )
    {
      if(!bithumb_list.includes(currentCoinList[index]))
        rval = false;

    }

    if ( buy_exchange_id.toUpperCase() == 'OKEX' )
    {
      if(!okex_list.includes(currentCoinList[index]))
        rval = false;

    }

    if ( buy_exchange_id.toUpperCase() == 'MEXC' )
    {
      if(!mexc_list.includes(currentCoinList[index]))
        rval = false;

    }

    if ( buy_exchange_id.toUpperCase() == 'BITGET' )
    {
      if(!bitget_list.includes(currentCoinList[index]))
        rval = false;

    }

    // check sell
    if ( sell_exchange_id.toUpperCase() == 'BINANCE' )
    {
        if(!binance_list.includes(currentCoinList[index]))
          rval = false;
    }
    
    if ( sell_exchange_id.toUpperCase() == 'UPBIT' )
    {
      if(!upbit_list.includes(currentCoinList[index]))
        rval = false;

    }
    
    if ( sell_exchange_id.toUpperCase() == 'BITHUMB' )
    {
      if(!bithumb_list.includes(currentCoinList[index]))
        rval = false;
    }

    if ( sell_exchange_id.toUpperCase() == 'OKEX' )
    {
      if(!okex_list.includes(currentCoinList[index]))
        rval = false;
    }

    if ( sell_exchange_id.toUpperCase() == 'MEXC' )
    {
      if(!mexc_list.includes(currentCoinList[index]))
        rval = false;
    }

    if ( sell_exchange_id.toUpperCase() == 'BITGET' )
    {
      if(!bitget_list.includes(currentCoinList[index]))
        rval = false;
    }

    return rval;

  }

  // update abit
  function updateAbit(index){

    if ( !check_Validate_Coin(index) )
    {
      alert("Check the coin, may be coin is not validate to sell or buy at exchages !")
      return false;
    }
    if (currentBuyExchangeList[index] ==  currentSellExchangeList[index] )
    {
      alert("Check the coin !")
      return false;
    }

    // make data list to update
    const limit_cnt = 20;
    const data = {
        bot_id: "arbitrage",
        msg: "update_arbitrage", 
        which: "update",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        newOld: newOld,
        
        id : currentIdList[index],
        coin : currentCoinList[index],
        buy_exchange_id : currentBuyExchangeList[index],
        sell_exchange_id :  currentSellExchangeList[index],
    
        strategy_grp : "",
        strategy_grp_name : "",
        trading_amount : currentTradingAmountList[index],
        target_gap : currentTargetGapList[index],
        loop_interval : currentLoopIntervalList[index],
        order_type : currentOrderTypeList[index],
        price :  currentPriceList[index],
        fiat_percent_to_buy : currentFiatPercentToBuyList[index],
        fiat_qty_to_buy : "",
        crypto_percent_to_sell :  currentCryptoPercentToSellList[index],
        crypto_qty_to_sell : "",
        operation : currentOperationStatus[index]
    };
    console.log("update_arbitrage : data " + data )
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/update_arbitrage`, data)
    .then((res) => {
        console.log("aaaa")
        console.log(res.data)

        if (res.data.result[0] === "OK"){
          //alert("OK")
          if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[1].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            setDataArbitrase(res.data.result[2]);
            makeSubData(res.data.result[2]);
          } else {
              setPageCount(0);
              setDataArbitrase([]);
          }

        }else{
          alert("ERROR ! : please check duplicate bot and the status of system !")
        }
    })
    .catch((err) => {
        console.log(err);
        alert("ERROR")
    })
    // .finally(() => {
    //    window.location.reload();
    // });
  }
  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {/* tile */}
      <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
      {/* botstatus content */}
      <div className="first_div">
        <div className="second_div">
          <div style={{ display: "flex", margin: "0px 0px 10px 0px" }}>
              <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Bot Status</h5>
              &emsp;
              <h6>(updated : {dayjs().format("YYYY-MM-DD dddd hh:mm:ss")})</h6>
              <div style={{ display: "flex", margin: "0px 0px 0px 530px" }}>
                <button className="btn btn-success btn-sm"  style={{ width: '100px',display: "float"}} onClick={() => createNewAbit() }>
                 Create
                </button>
              </div>
          </div>
          <div style={{ display: "flex", marginLeft: "-1em" }}>
            &emsp;&emsp;
            <div style={{ width: "95%" }}>
                <table>
                    <thead style={{ background: "#6CA0EC" }}>
                        <tr>
                            <th>#</th>
                            <th>Created</th>
                            <th>Coin</th>
                            <th>Sell</th>
                            <th>Buy</th>
                            <th>Trading Amount</th>
                            <th>Target Gap(%)</th>
                            <th>Loop Interval</th>
                            <th>Order Type</th>
                             {/*
                             <th>Price</th>
                            */}
                            <th>Flat(%)</th>
                            <th>Flat(qty)</th>
                            <th>Asset(%)</th>
                            <th>Asset(qty)</th>
                            <th>Operation on</th>
                            <th>Edit</th>
                        </tr>
                    </thead>

                    <tbody>
                        {dataArbitrase.map((k, i) => (
                            <tr key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                <td>{i + 1}</td>
                                <td>{moment(k.create_date_time).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>
                                  <select onChange={(e) => handleCoins(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentCoinList[i]} id={i+"_coins"}>
                                    {coin_full_list.map((item) => (
                                    <option value={item} key={item}>
                                    {item.toUpperCase()}
                                    </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select onChange={(e) => handleSellExchages(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentSellExchangeList[i]} id={i+"_sell_exchage"}>
                                    {sellExchagelist.map((item) => (
                                    <option value={item} key={item}>
                                    {item}
                                    </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select onChange={(e) => handleBuyExchages(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentBuyExchangeList[i]} id={i+"_buy_exchage"}>
                                    {buyExchagelist.map((item) => (
                                    <option value={item} key={item}>
                                    {item}
                                    </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input type="number" id={i+"_trading_amount"} name={i+"_trading_amount"} disabled={editButtons[i] === "Edit" ? "disabled" : ""}  className={editButtons[i] === "Edit" ? "read-only" : ""} autoFocus defaultValue={default_tradingAmount} value={currentTradingAmountList[i]} onChange={(e) => handleTradingAmounts(e.target.value, i)} />
                                </td>
                                <td>
                                  <select onChange={(e) => handleTargetGap(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentTargetGapList[i]} id={i+"_target_gap"}>
                                    {targetGapList.map((item) => (
                                    <option value={item} key={item}>
                                    {item}&nbsp;%
                                    </option>
                                    ))}
                                  </select></td>
                                <td> <select onChange={(e) => handleLoopInterval(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentLoopIntervalList[i]} id={i+"_loop_interval"}>
                                    {loopIntervalList.map((item) => (
                                    <option value={item} key={item}>
                                    {item}&nbsp;min
                                    </option>
                                    ))}
                                  </select></td>
                                <td> <select onChange={(e) => handleOrderType(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentOrderTypeList[i]} id={i+"_order_type"}>
                                    {orderTypeList.map((item) => (
                                    <option value={item} key={item}>
                                    {item}
                                    </option>
                                    ))}
                                  </select></td>
                                {/*
                                <td>
                                  <input type="number" id={i+"_price"} name={i+"_price"} disabled={editButtons[i] === "Edit" ? "disabled" : ""}  className={editButtons[i] === "Edit" ? "read-only" : ""} autoFocus defaultValue={defaultPrice} value={currentPriceList[i]} onChange={(e) => handlePrices(e.target.value, i)} />
                                  &nbsp;USDT
                                </td>
                                */}
                                <td><select onChange={(e) => handleFiat(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentFiatPercentToBuyList[i]} id={i+"_fiat"}>
                                    {fiatPercentList.map((item) => (
                                    <option value={item} key={item}>
                                    {item}&nbsp;%
                                    </option>
                                    ))}
                                  </select></td>
                                  <td>{currentFiatQtyToBuyList[i]}</td>

                                <td><select onChange={(e) => handleAssetStock(e.currentTarget.value, i)} disabled={editButtons[i] === "Edit" ? "disabled" : ""} value={currentCryptoPercentToSellList[i]} id={i+"_asset_stock"}>
                                    {cryptoList.map((item) => (
                                    <option value={item} key={item}>
                                    {item}&nbsp;%
                                    </option>
                                    ))}
                                  </select></td>
                                  <td>{currentCryptoQtyToSellList[i]}</td>
                                <td>
                                  <label className="switch">
                                    <input type="checkbox" id={i+"_operation"}  disabled={editButtons[i] === "Edit" ? "disabled" : ""} checked={currentOperationStatus[i] === "true" ? true : false}  onChange={(e) => handleOperation(e.currentTarget.checked, i)}/>
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td><button id={i+"_edit"}  style={{ width: '100px',display: "float"}} className={editButtons[i] === "Edit" ? "btn btn-primary btn-sm" : "btn btn-success btn-sm"} onClick={(e) => handleEdit(i, i+"_edit",e.currentTarget.innerText)}>{editButtons[i] === 'Edit' ? "Edit" : "Save"}</button></td>
                                
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div key={remountComponent}>
                    <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                </div>
            </div>
          </div>
        </div> {/* end second div */}
      </div>  {/* end first div */}
    </div> 
);
}

export default ABotSettings;


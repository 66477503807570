
import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

const TradingLog = ({strategy_id}) => {

    const [datalogs, setDatalogs] = useState([]);

    function get_trading_log() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)


        const data = {
            strategy_id: strategy_id,
        };
        axios
            .post(`${python_server_url}/get_trading_log`, data)
            .then((res) => {
                //console.log("res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {
                    setDatalogs(res.data.result);

                    //console.log("datalogs ==> " + JSON.stringify(datalogs))
                } else {
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
                setDatalogs([]);
            });
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
        //window.location.reload()
        get_trading_log()
        }, 1000*3);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <di>
            <div style={{margin: "10px 0px 0px 0px",width:"99%"}}>
                <label htmlFor="from_date" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>Trading Log</label> 
            </div>
            <div style={{margin: "10px 0px 20px 0px" ,width:"99%"}}>
                <table style={{ fontSize :"14px"}}>
                    <thead>
                        <tr>
                            <th style={{background: "#366CD0"}} >#</th>
                            <th style={{background: "#366CD0"}} >ID</th>
                            {/*<th style={{background: "#366CD0"}} >Stragety ID</th>*/}
                            <th style={{background: "#366CD0"}} >Stragety Name</th>
                            <th style={{background: "#366CD0"}} >Stragety Group</th>
                            <th style={{background: "#366CD0"}} >Exchange</th>
                            <th style={{background: "#366CD0"}} >Position</th>
                            <th style={{background: "#366CD0"}} >Coin Type</th>
                            <th style={{background: "#366CD0"}} >Time Frame</th>
                            <th style={{background: "#366CD0"}} >Price</th>
                            {/*<th style={{background: "#366CD0"}} >Order Type</th>*/}
                            <th style={{background: "#366CD0"}} >Order Time</th>
                            <th style={{background: "#366CD0"}} >Status</th>

                        </tr>
                    </thead>
                    { datalogs.map((strategy, i) => (
                        <tbody>
                            <tr className="logTr" key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                <td>{i + 1}</td>
                                <td>{strategy.id}</td>
                                {/*<td>{strategy.live_id}</td>*/}
                                <td>{strategy.strategy_name.toUpperCase()}</td>
                                <td>{strategy.strategy_group === "1" ? "MLC" : strategy.strategy_group === "2" ? "P2V" :  strategy.strategy_group === "3" ? "TBT" : strategy.strategy_group === "4" ? "A_C" : "MANUAL" }</td>
                                <td>{strategy.exchange_name.toUpperCase()}</td>
                                <td>{strategy.position.toUpperCase()}</td>
                                <td>{strategy.coin_type.toUpperCase()+"USDT"}</td>
                                <td>{strategy.time_frame}</td>
                                <td>{strategy.price}</td>
                                {/*<td>{strategy.order_type}</td>*/}
                                <td>{strategy.alarm_sent_time}</td>
                                <td>{strategy.status.toUpperCase()}</td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </di>
  );

}

export default TradingLog;
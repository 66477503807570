import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "../../../common/Modal";
import ReactPaginate from "react-paginate";
import { c_opt_exchanges_all, c_opt_strategy_grp_name_all, c_opt_order_type_all, locale_opt, locale_opt_8 } from "../../../common/Commons";
import { C_coins, AscDesc, ExchangeDiv, CoinsDiv, GroupDiv, StrategyInputDiv, DateFromToDiv } from "../../../common/Commons";

import "./sendPascal.css";

function LogRecordsTrabo() {
    const pairs = [1];

     // send data to paskal
	const [modalOpen, setModalOpen] = useState(false);
    const [task_type, setTaskType] = useState("add");

    const [pairid, setPairId] = useState("");
    const [strategy_name, setStrategy_name] = useState("");
    const [transactiontime_day, setTransactionTime_Day] = useState("");
    const [transactiontime_hour, setTransactionTime_Hour] = useState("");
    const [transactiontime_min, setTransactionTime_Min] = useState("");
    const [transactiontime_sec, setTransactionTime_Sec] = useState("");
    const [profit_amount, setProfitAmount] = useState("");
    const [profit_percent, setPorfitPercent] = useState("");

    const [exchange_name_paskal, setExchange_name_paskal] = useState("all");
    const [coins_paskal, setCoins_paskal] = useState("all");
    const [groups_paskal, setGroups_paskal] = useState("all");


    // select box valuable
    const [exchange_name, setExchange_name] = useState("all");
    const [coins, setCoins] = useState("all");
    const [opt_coins, setOpt_coins] = useState([]);
    const [groups, setGroups] = useState("all");
    const [strategy, setStrategy] = useState("");
    const [operation, setOperation] = useState("all");
    const [selResults, setSelResults] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [newOld, setNewOld] = useState("desc");

    const [datalogs, setDatalogs] = useState([]);

    const [remountComponent, setRemountComponent] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    // common
    const opt_exchanges = c_opt_exchanges_all;
    const opt_groups = c_opt_strategy_grp_name_all;
    // common

    const otp_operation = [
        { value: "all", name: "All" },
        { value: "auto", name: "AUTO" },
        { value: "manual", name: "MANUAL" },
        { value: "close", name: "CLOSE" },
        { value: "terminate", name: "TERMINATE" },
    ];

    const otp_selResults = [
        { value: "all", name: "ALL" },
        { value: "finished_all", name: "Finished All" },
        { value: "error_all", name: "Error All" },
    ];
    // END SELECT

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    ////
    // send to paskal

    function getTempUniqValue(){

        return Date.now();

    }

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

	const openModal = (id) => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

    
    const SendData = () => {

        
        const pairid_temp = document.getElementById("pair_id").value;
        setPairId(pairid_temp)
        
        if ( pairid_temp == "" ) {
           alert("Please, Check PairId")
           return false
         }
        
         if ( strategy_name == "" ) {
            alert("Please, Check Strategy Name")
            return false
          }

         
        if ( exchange_name_paskal == ""  || exchange_name_paskal == "all") {
            alert("Please, Check Exchange name")
            return false
         }
        if ( coins_paskal == ""   || coins_paskal == "all") {
             alert("Please, Check Coins")
             return false
        }
        if ( groups_paskal == ""  || groups_paskal == "all" ) {
            alert("Please, Check Groups")
            return false
        }

        if ( groups_paskal != "mlc"  && groups_paskal != "p2v" &&  groups_paskal != "tbt") {
            alert("Please, Check Groups")
            return false
        }

        if ( transactiontime_day == "" ) {
            alert("Please, Check Transactiontime Day")
            return false
            }
        if ( transactiontime_hour == "" ) {
            alert("Please, Check Transactiontime Hour")
            return false
            }
        if ( transactiontime_min == "" ) {
            alert("Please, Check transactiontime Min")
            return false
            }
        if ( transactiontime_sec == "" ) {
            alert("Please, Check transactiontime Sec")
            return false
            }
        if ( profit_amount == "" ) {
            alert("Please, Check Profit amount")
            return false
            }
        if ( profit_percent == "" ) {
            alert("Please, Check Profit Percent")
            return false
            }

        let transactiontime;
      
        let transactiontime_hour_s = ""
        if (transactiontime_hour >= 0 ) {
            if ( transactiontime_hour.toString().length < 2 )
                {
                    transactiontime_hour_s = "0" + transactiontime_hour.toString()
                }
            else {
                transactiontime_hour_s = transactiontime_hour.toString()
            }

        }else {
            alert("Please, Check transactiontime Hour")
            return false
        }

        let transactiontime_min_s = ""
        if (transactiontime_min >= 0 ) {
            if ( transactiontime_min.toString().length < 2 )
                {
                    transactiontime_min_s = "0" + transactiontime_min.toString()
                }
            else {
                transactiontime_min_s = transactiontime_min.toString()
            }

        }else {
            alert("Please, Check transactiontime Hour")
            return false
        }


        let transactiontime_sec_s = ""
        if (transactiontime_sec >= 0 ) {
            if ( transactiontime_sec.toString().length < 2 )
                {
                    transactiontime_sec_s = "0" + transactiontime_sec.toString()
                }
            else {
                transactiontime_sec_s = transactiontime_sec.toString()
            }

        }else {
            alert("Please, Check transactiontime Sec")
            return false
        }
        
        transactiontime = transactiontime_day + " " + transactiontime_hour_s + ":" + transactiontime_min_s + ":" + transactiontime_sec_s 


        const data = {
            bot_id: "p_bot",
            msg: "sendDataToPaskal", 
            pairid: pairid_temp,
            strategy_name: strategy_name,
            
            exchange_name: exchange_name_paskal,
            coins: coins_paskal,
            strategy_group_name: groups_paskal,
            transactiontime : transactiontime,
            profit_amount : profit_amount,
            profit_percent : profit_percent
        };
        
        //alert(JSON.stringify(data))
        //return true;

        axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
        .then((res) => {
            //alert(JSON.stringify(res.data.msg));
            if (res.data.msg.toString().length > 0 ){
                if ( res.data.msg == 'ok' ){
                    alert("Data is sent to paskal successfuly !")
                    window.location.reload()
                }
                else {
                    alert("Data is not sent to paskal successfuly !")
                    return false;
                }
            }else{
                alert("Data is not sent to paskal successfuly !")
                return false;
            }
        })
        .catch((err) => {
            alert("ERROR")
            return false;
        })

	}

    ///

    useEffect(() => {
        const limit_cnt = 30;
        const data = {
            bot_id: "p_bot",
            msg: "get_trabo_log_records",
            pgn: pageNumber,
            limit_cnt: limit_cnt,
            exchange_name: exchange_name,
            coins: coins,
            strategy_group_name: groups,
            strategy: strategy,
            operation_type: operation,
            selResults: selResults,
            dateFrom: dateFrom,
            dateTo: dateTo,
            newOld: newOld,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                if (res.data.result.length !== 0) {
                    const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
                    setPageCount(page_cnt_math);
                    setDatalogs(res.data.result);
                } else {
                    setPageCount(0);
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [pageNumber, exchange_name, coins, groups, strategy, operation, selResults, dateFrom, dateTo, newOld]);

    useEffect(() => {
        changePage({ selected: 0 });
        setRemountComponent(Math.random());
    }, [exchange_name, coins, groups, strategy, selResults, dateFrom, dateTo, newOld]);

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    return (
        <div className="first_div">
            <div className="second_div">
                <div>
                    <button className="btn btn-primary btn-sm" onClick={(e) => openModal("",setTaskType("add"))}>
                                Send Data
                    </button>
                </div>
                <br />
                <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                    <div>
                        <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name} />
                        &emsp;
                        <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} />
                        &emsp;
                        <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups} />
                        &emsp;
                        <StrategyInputDiv send_setstrategy_name={setStrategy} />
                        &emsp;
                        <span>OPERATION: </span>
                        <select
                            onChange={(e) => {
                                setOperation(e.target.value);
                            }}>
                            {otp_operation.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>{" "}
                        &emsp;
                        <span>Results: </span>
                        <select
                            onChange={(e) => {
                                setSelResults(e.target.value);
                            }}>
                            {otp_selResults.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <div>
                        <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                    </div>
                    <br />
                    <AscDesc newOld={newOld} send_setNewOld={setNewOld} />
                    <br />
                </div>
                <table>
                    <thead style={{ background: "#4472c4" }}>
                        <tr>
                            <th>#</th>
                            <th>Pair ID</th>
                            <th>exchange_name</th>
                            <th>Stragety Group</th>
                            <th>Stragety Name</th>
                            <th>Bot Count</th>
                            <th>Operation Type</th>
                            <th>Crypto Type</th>
                            <th>Share Rate</th>

                            <th>Total Crypto Amount</th>
                            
                            <th>Profit Amount</th>
                            <th>Paid Profit Amount</th>
                            <th>Paid Profit %</th>
                            <th>MSG</th>
                            <th>Transaction</th>
                            <th>Sent Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datalogs.map((k, i) => (
                            <tr className="logTr" key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F"  ,cursor: "pointer", hover: { background: "red" } }}>
                                <td>{i + 1}</td>
                                <td>{k.pair_id}</td>
                                <td>{k.exchange_name}</td>
                                <td>{k.strategy_group_name === '1' ? 'MLC' : k.strategy_group_name === '2' ? 'P2V' : 'TBT'}</td>
                                <td>{k.strategy_name}</td>
                                <td>{k.bot_cnt}</td>
                                <td>{k.operation_type}</td>
                                <td>{k.crypto_name}</td>
                                <td>{k.share_rate}%</td>

                                <td>{k.initial_total_crypto_qty_at_all_exchages}</td>
                                
                                <td>{parseFloat(k.trading_profit_crypto).toLocaleString("ko-KR", locale_opt_8)}</td>
                                
                                <td>{parseFloat(k.paid_profit_amount).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.paid_profit_percent).toLocaleString("ko-KR", locale_opt)}%</td>
                                <td>{k.msg}</td>
                                <td>{k.trading_date}</td>
                                <td>{k.insert_date}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div key={remountComponent}>
                    <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                </div>
            </div>
            <div>
				<Modal
					open={modalOpen}
					close={closeModal}
					header={
						<>
							<p>
								Input Data
							</p>
							<button className="close" onClick={closeModal}>
								{" "}
								&times;{" "}
							</button>
						</>
					}
					footer={
							<>
								<button className="close" onClick={closeModal}>
									{" "}
									close{" "}
								</button>
							</>
					}>
					{/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
					
					<div style={{width : "100%" }}>
						
						<div style={{margin: "0px 0px 1em 2.5em"  , display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Pair ID</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
                                <input id='pair_id' type="text" defaultValue={getTempUniqValue()} style={{ width: "100%" }} />
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  , display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Stragety Name</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='strategy_name' type="text" style={{ width: "100%" }}  onChange={(e) => {setStrategy_name(e.target.value);}}/>
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  , display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Exchange Name</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
                                <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name_paskal} />
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  ,textAlign : "left", display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Stragtegy Group Name</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
                                <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups_paskal} />
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  ,textAlign : "left", display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Coin Type</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
                                <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins_paskal} />
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  ,textAlign : "left", display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Transanction Time</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='transaction_time' type="date" className="login" style={{ width: "50%" }} onChange={(e) => {setTransactionTime_Day(e.target.value);}} required/>
                                &emsp; &emsp;
                                <input id="transaction_hour" type="number"  style={{ width: "10%" }}  onChange={(e) => {setTransactionTime_Hour(e.target.value);}}/> H &emsp;
                                <input id="transaction_min" type="number" style={{ width: "10%" }}  onChange={(e) => {setTransactionTime_Min(e.target.value);}}/> M &emsp;
                                <input id="transaction_sec" type="number"  style={{ width: "10%" }}  onChange={(e) => {setTransactionTime_Sec(e.target.value);}}/> S &emsp;


							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  ,textAlign : "left", display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Profit Amount</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='profit_amount' type="text" className="login" style={{ width: "100%" }}  onChange={(e) => {setProfitAmount(e.target.value);}} required/>
							</div>
						</div>
						<div style={{margin: "0px 0px 1em 2.5em"  ,textAlign : "left", display : "flex" , width : "80%" }}>
							<div style={{ width : "40%" }}>
								<span>Profit Percent</span><br></br>
							</div>
							<div style={{ width : "60%" }}>
								<input id='profit_percent' type="text" className="login" style={{ width: "100%" }}  onChange={(e) => {setPorfitPercent(e.target.value);}} required/>
							</div>
						</div>
						<div style={{margin: "2.5em 0px 1em 2.5em"  ,textAlign : "center", display : "flex" , width : "80%" }}>
							<div style={{ width : "100%" }}>
								<button className="btn btn-info btn_l btn_center" onClick={(e) => SendData()}>Send</button>
							</div>
						</div>
						
					</div>
				</Modal>
			</div> 
        </div>
    );
}

export default LogRecordsTrabo;

import React, { } from "react";
// import "./arbitrage.css";


function SharingTable({ exchanges, shareRate, shareRateSave }) {

  
  return (
        <div style={{margin: "1em 1em 1em 1em" ,textAlign:"center", width:"95%" ,display: "flex" }}>
            <table style={{margin: "0em 0em 0em 1em" , textAlign:"center" ,width:"33%"}}>
                <thead class="table_header">
                    <tr>
                        <th colSpan="2">MLC</th>
                    </tr>
                    <tr>
                        <th>Crypto</th>
                        <th>Share Rate</th>
                    </tr>
                </thead>
                <tbody class="table_header mlc">
                    {shareRate &&
                        shareRate.map((name, i) =>
                            name.exchanges === exchanges && name.strategy_grp === "1" ? (
                                <tr key={i}>
                                    <td>{(name.coin_ticker).toUpperCase()}</td>
                                    <td>
                                        <input  id={"coin_id" + name.id} type="number" defaultValue={name.share_rate} style={{ width: "20%" }} />%
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button class="btn_save save" style={{ width: "40%" }} onClick={() => shareRateSave(name.id, document.getElementById("coin_id"+name.id).value)}>
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            ) : null
                        )}
                </tbody>
            </table>
            <table style={{margin: "0em 0em 0em 1em" , textAlign:"center" ,width:"33%"}}>
            <thead class="table_header">
                    <tr>
                        <th colSpan="2">P2V</th>
                    </tr>
                    <tr>
                        <th>Crypto</th>
                        <th>Share Rate</th>
                    </tr>
                </thead>
                <tbody class="table_header p2v">
                    {shareRate &&
                        shareRate.map((name, i) =>
                            name.exchanges === exchanges && name.strategy_grp === "2" ? (
                                <tr key={i}>
                                    <td>{(name.coin_ticker).toUpperCase()}</td>
                                    <td>
                                        <input id={name.id} type="number" defaultValue={name.share_rate} style={{ width: "20%" }} />%
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button class="btn_save save" style={{ width: "40%" }} onClick={() => shareRateSave(name.id, document.getElementById(name.id).value)}>
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            ) : null
                        )}
                </tbody>
            </table>
            <table style={{margin: "0em 0em 0em 1em" , textAlign:"center" ,width:"33%"}}>
                <thead class="table_header">
                    <tr>
                        <th colSpan="2">TBT</th>
                    </tr>
                    <tr>
                        <th>Crypto</th>
                        <th>Share Rate</th>
                    </tr>
                </thead>
                <tbody class="table_header tbt">
                    {shareRate &&
                        shareRate.map((name, i) =>
                            name.exchanges === exchanges && name.strategy_grp === "3" ? (
                                <tr key={i}>
                                    <td>{(name.coin_ticker).toUpperCase()}</td>
                                    <td>
                                        <input id={name.id} type="number" defaultValue={name.share_rate} style={{ width: "20%" }} />%
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button class="btn_save save" style={{ width: "40%" }} onClick={() => shareRateSave(name.id, document.getElementById(name.id).value)}>
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            ) : null
                        )}
                </tbody>
            </table>
        </div>
  );
}

export default SharingTable;

import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import Modal from "../../../common/Modal";

import { locale_opt } from "../../../common/Commons";
import { ExchangesSelector } from "../../../common/Commons";

function DailyBalance() {
    const [modalOpen, setModalOpen] = useState(false);

    const [exchangesSelect, setExchangesSelect] = useState(sessionStorage.getItem("sbot_daily_balance_ex"));
    const [times, setTimes] = useState("");
    const [times_pre_1, setTimes_Pre_1] = useState("");
    const [times_pre_2, setTimes_Pre_2] = useState("");
    const [times_pre_3, setTimes_Pre_3] = useState("");
    const [fiat_type, setFiat_type] = useState("UDST");
    const [balanceStatus, setBalanceStatus] = useState(null);

    const openModal = () => {
        setModalOpen(true);
        const info_total = { bot_id: "s_bot", msg: "refresh_balance", event_type: "dash_board", order_type: "", ticker_name: "", event_amount: "", transaction_date: dayjs().format("YYYY-MM-DD hh:mm:ss") };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
            .catch((err) => {
                alert("ERROR");
                console.log(err);
            })
            .finally(() => {
                window.location.reload();
            });
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    function Tbody_mlc({ ll }) {
        if (balanceStatus !== null) {
            let sum_balance = 0;
            balanceStatus[exchangesSelect - 1][ll].forEach((element) => {
                sum_balance += element.value[0];
            });
            sum_balance = parseFloat(sum_balance).toLocaleString("ko-KR", locale_opt)
            ll === "0" ? (document.getElementById("p_id_1").textContent = sum_balance) : ll === "1" ? (document.getElementById("p_id_2").textContent = sum_balance) : (document.getElementById("p_id_3").textContent = sum_balance);
        }

        return (
            <>
                {balanceStatus[exchangesSelect - 1][ll].map((name, i) => (
                    <tr key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                        <td>{(name.crypto_name).toUpperCase()}</td>
                        <td>{name.value[5]}</td>
                        <td>{name.value[4]}</td>
                        <td>{name.value[3]}</td>
                        <td>{name.value[2]}</td>
                        <td>{name.value[1]}</td>
                        <td>{name.value[0]}</td>
                    </tr>
                ))}
            </>
        );
    }

    function Tbody_p2v({ ll }) {
        if (balanceStatus !== null) {
            let sum_balance = 0;
            balanceStatus[exchangesSelect - 1][ll].forEach((element) => {
                sum_balance += element.value[0];
            });
            sum_balance = parseFloat(sum_balance).toLocaleString("ko-KR", locale_opt)
            ll === "0" ? (document.getElementById("p_id_1").textContent = sum_balance) : ll === "1" ? (document.getElementById("p_id_2").textContent = sum_balance) : (document.getElementById("p_id_3").textContent = sum_balance);
        }

        return (
            <>
                {balanceStatus[exchangesSelect - 1][ll].map((name, i) => (
                    <tr key={i} style={{ background: i%2 === 0 ? "#0B2F24​" :"#114B39" }}>
                        <td>{(name.crypto_name).toUpperCase()}</td>
                        <td>{name.value[5]}</td>
                        <td>{name.value[4]}</td>
                        <td>{name.value[3]}</td>
                        <td>{name.value[2]}</td>
                        <td>{name.value[1]}</td>
                        <td>{name.value[0]}</td>
                    </tr>
                ))}
            </>
        );
    }
    function Tbody_tbt({ ll }) {
        if (balanceStatus !== null) {
            let sum_balance = 0;
            balanceStatus[exchangesSelect - 1][ll].forEach((element) => {
                sum_balance += element.value[0];
            });
            sum_balance = parseFloat(sum_balance).toLocaleString("ko-KR", locale_opt)
            ll === "0" ? (document.getElementById("p_id_1").textContent = sum_balance) : ll === "1" ? (document.getElementById("p_id_2").textContent = sum_balance) : (document.getElementById("p_id_3").textContent = sum_balance);
        }

        return (
            <>
                {balanceStatus[exchangesSelect - 1][ll].map((name, i) => (
                    <tr key={i} style={{ background: i%2 === 0 ? "#271137​" :"#3C1B55" }}>
                        <td>{(name.crypto_name).toUpperCase()}</td>
                        <td>{name.value[5]}</td>
                        <td>{name.value[4]}</td>
                        <td>{name.value[3]}</td>
                        <td>{name.value[2]}</td>
                        <td>{name.value[1]}</td>
                        <td>{name.value[0]}</td>
                    </tr>
                ))}
            </>
        );
    }
    

    function Check_balance() {
        const info_total = { bot_id: "s_bot", msg: "sbot_daily_balance" };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
            .then((res) => {
                const bsj = res.data.result;
                // 0 : mlc , 1 : p2v , 2 : tpt
                const binance_list = [bsj["binance_0"], bsj["binance_1"], bsj["binance_2"]];
                const upbit_list = [bsj["upbit_5"], bsj["upbit_6"], bsj["upbit_7"]];
                const bithumb_list = [bsj["bithumb_10"], bsj["bithumb_11"], bsj["bithumb_12"]];
                const okex_list = [bsj["okex_15"], bsj["okex_16"], bsj["okex_17"]];
                const mexc_list = [bsj["mexc_20"], bsj["mexc_21"], bsj["mexc_22"]];
                const bitget_list = [bsj["bitget_25"], bsj["bitget_26"], bsj["bitget_27"]];
                const bsArray = [binance_list, upbit_list, bithumb_list, okex_list, mexc_list, bitget_list];
                setBalanceStatus(bsArray);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6"  ? setFiat_type("USDT") : setFiat_type("KRW");
    }, [exchangesSelect]);

    useEffect(() => {
        Check_balance();
        var today_date = dayjs().format("YYYY-MM-DD dddd hh:mm:ss");
        var date_pre_1;
        var date_pre_2;
        var date_pre_3;
       
        date_pre_1 = dayjs(new Date()).subtract(1, "day").format("YYYY-MM-DD dddd");
        date_pre_2 = dayjs(new Date()).subtract(2, "day").format("YYYY-MM-DD dddd");
        date_pre_3 = dayjs(new Date()).subtract(3, "day").format("YYYY-MM-DD dddd");

        setTimes(today_date);
        setTimes_Pre_1(date_pre_1);
        setTimes_Pre_2(date_pre_2);
        setTimes_Pre_3(date_pre_3);
        const intervalId = setInterval(() => {
            setTimes(today_date);
            Check_balance();
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ display: "flex" }}>
                        <button className="btn btn-success btn-sm" onClick={(e) => openModal()}>
                            Refresh Balance
                        </button>
                        &emsp;&emsp;&emsp;&emsp;
                        <h6 style={{ marginTop: "5px" }}>{times}</h6>
                    </div>
                    <ExchangesSelector exchangesSelect={exchangesSelect} send_esSet={setExchangesSelect} session_name={"sbot_daily_balance_ex"} />

                    <div style={{ display: "flex", marginLeft: "-1em" }}>
                        &emsp;&emsp;
                        <div style={{ width: "31%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>MLC</h5>
                                &emsp;&emsp;&emsp;&emsp;
                                <h6>Total market value:</h6>
                                &emsp;
                                <h6 id="p_id_1" style={{ color: "white" }}>
                                    0
                                </h6>
                                &emsp;
                                <h6>{fiat_type}</h6>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th></th>
                                        <th colSpan="4">MLC Balance History​</th>
                                        <th colSpan="2">Market Value ({fiat_type})</th>
                                    </tr>
                                    <tr style={{ background: "#0070C0" }}>
                                    <th>Crypto​</th>
                                    <th>Day-3 <br/> ({times_pre_3})</th>
                                    <th>Day-2 <br/> ({times_pre_2})</th>
                                    <th>Day-1 <br/> ({times_pre_1})</th>
                                    <th>Current <br/> ({times}) </th>
                                    <th>Price</th>
                                    <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus && balanceStatus ? <Tbody_mlc ll="0" /> : null}</tbody>
                            </table>
                        </div>
                        &emsp;&emsp;
                        <div style={{ width: "31%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>P2V</h5>
                                &emsp;&emsp;&emsp;&emsp;
                                <h6>Total market value:</h6>
                                &emsp;
                                <h6 id="p_id_2" style={{ color: "white" }}>
                                    0
                                </h6>
                                &emsp;
                                <h6>{fiat_type}</h6>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th></th>
                                        <th colSpan="4">P2V Balance History​</th>
                                        <th colSpan="2">Market Value ({fiat_type})</th>
                                    </tr>
                                    <tr style={{ background: "#0070C0" }}>
                                        <th>Crypto​</th>
                                        <th>Day-3 <br/> ({times_pre_3})</th>
                                        <th>Day-2 <br/> ({times_pre_2})</th>
                                        <th>Day-1 <br/> ({times_pre_1})</th>
                                        <th>Current <br/> ({times}) </th>
                                        <th>Price</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus && balanceStatus !== null ? <Tbody_p2v ll="1" /> : null}</tbody>
                            </table>
                        </div>
                        &emsp;&emsp;
                        <div style={{ width: "31%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>TBT</h5>
                                &emsp;&emsp;&emsp;&emsp;
                                <h6>Total market value:</h6>
                                &emsp;
                                <h6 id="p_id_3" style={{ color: "white" }}>
                                    0
                                </h6>
                                &emsp;
                                <h6>{fiat_type}</h6>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th></th>
                                        <th colSpan="4">TBT Balance History​</th>
                                        <th colSpan="2">Market Value ({fiat_type})</th>
                                    </tr>
                                    <tr style={{ background: "#0070C0" }}>
                                        <th>Crypto​</th>
                                        <th>Day-3 <br/> ({times_pre_3})</th>
                                        <th>Day-2 <br/> ({times_pre_2})</th>
                                        <th>Day-1 <br/> ({times_pre_1})</th>
                                        <th>Current <br/> ({times}) </th>
                                        <th>Price</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus && balanceStatus ? <Tbody_tbt ll="2" /> : null}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal open={modalOpen} close={closeModal} header="Balance Statues" footer={<></>}>
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <h3>Balance Updating...</h3>
                    <br />
                    <h4>Local Time is : {times}</h4>
                </Modal>
            </div>
        </div>
    );
}

export default DailyBalance;

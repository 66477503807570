import React, { useState, useEffect } from "react";
import "./pairingRecords.css";

import ReactPaginate from "react-paginate";
import Switch from "react-switch";
import axios from "axios";
import Modal_list_full from "../../../common/Modal_list_full";

import { locale_opt, c_opt_exchanges_all, c_opt_strategy_grp_all, c_opt_tfs_all } from "../../../common/Commons";
import { AscDesc, ExchangeDiv, CoinsDiv, GroupDiv, StrategyInputDiv, TFDiv, DateFromToDiv } from "../../../common/Commons";

const Records = ({ opt_coins }) => {
    const chartsPerPage = 30;
    const [modalOpen, setModalOpen] = useState(false);
    const [datalogs, setDetailLog] = useState([]);
    

    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * chartsPerPage;
    const [pageCount, setPageCount] = useState(0);
    const [pairs, setPairs] = useState([]);
    const [remountComponent, setRemountComponent] = useState();
    const [toggle, setToggle] = useState(false);

    // OPTIONS
    const [exchanges, setExchanges] = useState("all");
    const [coins, setCoins] = useState("all");
    const [groups, setGroups] = useState("all");
    const [strategy, setStrategy] = useState("");
    const [tfs, setTfs] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [newOld, setNewOld] = useState("desc");

    // common
    const opt_exchanges = c_opt_exchanges_all;
    const opt_groups = c_opt_strategy_grp_all;
    const opt_tfs = c_opt_tfs_all;
    // common

    // END OPTION
    const openModal = (id) => {
		//alert("openModal : " + id);
		
		setModalOpen(true);
        getDetailLog(id);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const switchToggle = (checked) => {
        setToggle(checked);
    };

    function getDetailLog(id){

        const limit_cnt = 30;
        const data = {
            bot_id: "p_bot",
            msg: "get_transaction_log_records_by_id",
            id: id,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                if (res.data.result.length !== 0) {
                    //alert(JSON.stringify(res.data.result))
                    setDetailLog(res.data.result);
                } else {
                    alert("Can't load data !")
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }

    useEffect(() => {
        const data = {
            bot_id: "p_bot",
            msg: "get_transaction_pair",
            table: !toggle ? "simple" : "compound",
            exchanges: exchanges,
            coins: coins,
            groups: groups,
            strategy: strategy,
            tfs: tfs,
            dateFrom: dateFrom,
            dateTo: dateTo,
            newOld: newOld,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                setPairs(res.data.result);
                //alert(JSON.stringify(res.data.result[0].sell_id))
                const page_cnt_math = Math.ceil(res.data.result.length / chartsPerPage)
                setPageCount(page_cnt_math);

                changePage({ selected: 0 });
                setRemountComponent(Math.random());
            })
            .catch((err) => {
                setPairs([]);
                setPageCount(0);
            });
    }, [toggle, exchanges, coins, groups, strategy, tfs, dateFrom, dateTo, newOld]);

    const displayCharts = pairs.slice(pagesVisited, pagesVisited + chartsPerPage).map((pair, i) => (
        <React.Fragment key={i}>
            <tr style={{ cursor: "pointer", background: i%2 === 0 ? "#142440​" :"#233E6F" }} onClick={(e) => openModal(pair.buy_id)}>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {i + 1}
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {/* exchanges */}
                    {pair.exchange_name}
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {/* strategy */}
                    {pair.strategy_name}
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {/* strategy */}
                    {pair.strategy_group === "1" ? "MLC" : pair.strategy_group === "2" ? "P2P" : pair.strategy_group === "3" ?  "TBT" : "None" }
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {/* Crypto Type */}
                    {pair.coin_type}
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {pair.time_frame} M
                </td>
                <td>{/* position */}
                    { pair.sequence === pair.sell_position ? (pair.buy_position !== undefined ? pair.buy_position : "No") : (pair.sell_position !== undefined ? pair.sell_position : "No")}
                </td>
                <td>
                    {/* qty */}
                    { pair.sequence === pair.sell_position ? (pair.buy_crypto_qty !== undefined ? parseFloat(pair.buy_crypto_qty).toFixed(8).toLocaleString("ko-KR", locale_opt) : "No") : (pair.sell_crypto_qty !== undefined ? parseFloat(pair.sell_crypto_qty).toFixed(8).toLocaleString("ko-KR", locale_opt) : "No")}
                </td>
                
                <td>
                    {/* traded price */}
                    { pair.sequence === pair.sell_position ? (pair.buy_current_crypto_price !== undefined ? parseFloat(pair.buy_current_crypto_price).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_current_crypto_price !== undefined ? parseFloat(pair.sell_current_crypto_price).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                    
                </td>
                <td>
                    {/* net traded amount */}
                    { pair.sequence === pair.sell_position ? (pair.buy_total_traded_amt !== undefined ? parseFloat(pair.buy_total_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_total_traded_amt !== undefined ? parseFloat(pair.sell_total_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                   
                </td>
                <td>
                    {/* Fee paid */}
                    { pair.sequence === pair.sell_position ? (pair.buy_gross_fee !== undefined ? parseFloat(pair.buy_gross_fee).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_gross_fee !== undefined ? parseFloat(pair.sell_gross_fee).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                </td>
                <td> 
                    {/* Gross traded amount */}
                    { pair.sequence === pair.sell_position ? (pair.buy_gross_traded_amt !== undefined ? parseFloat(pair.buy_gross_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_gross_traded_amt !== undefined ? parseFloat(pair.sell_gross_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    { pair.crypto_profit_amt !== undefined ? parseFloat(pair.crypto_profit_amt).toFixed(8).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No" }
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    { pair.fiat_profit_amt !== undefined ? parseFloat(pair.fiat_profit_amt).toFixed(8).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No" }
                </td>
                
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    { pair.crypto_cum_profit !== undefined ? parseFloat(pair.crypto_cum_profit).toFixed(8).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No" }
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    { pair.fiat_cum_profit !== undefined ? parseFloat(pair.fiat_cum_profit).toFixed(8).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No" }
                </td>

                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    { pair.crypto_profit_ror !== undefined ? parseFloat(pair.crypto_profit_ror).toFixed(8).toLocaleString("ko-KR", locale_opt)+" "+ "%" : "No" }
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    { pair.fiat_profit_ror !== undefined ? parseFloat(pair.fiat_profit_ror).toFixed(8).toLocaleString("ko-KR", locale_opt)+" "+ "%": "No" }
                </td>
                
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {/* hpr */}
                    {pair.hpr !== undefined ? parseFloat(pair.hpr).toFixed(8).toLocaleString("ko-KR", locale_opt) : "No"}
                </td>
                <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {/* fiat hpr */}
                    {pair.fiat_hpr !== undefined ? parseFloat(pair.fiat_hpr).toFixed(8).toLocaleString("ko-KR", locale_opt) : "No"}
                </td>

                <td>
                    {/* before balance crypto */}
                    { pair.sequence === pair.sell_position ? (pair.buy_initial_wallet_crypto !== undefined ? parseFloat(pair.buy_initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No") : (pair.sell_initial_wallet_crypto !== undefined ? parseFloat(pair.sell_initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No")}
                    
                </td>
                <td>{/* before balance fiat*/}
                    { pair.sequence === pair.sell_position ? (pair.buy_initial_wallet_fiat !== undefined ? parseFloat(pair.buy_initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_initial_wallet_fiat !== undefined ? parseFloat(pair.sell_initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                    
                </td>
                <td>
                    {/* after balance crypto */}
                    { pair.sequence === pair.sell_position ? (pair.buy_total_wallet_crypto !== undefined ? parseFloat(pair.buy_total_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No") : (pair.sell_total_wallet_crypto !== undefined ? parseFloat(pair.sell_total_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No")}    
                </td>
                <td>
                     {/* after balance fiat */}
                     { pair.sequence === pair.sell_position ? (pair.buy_total_wallet_fiat !== undefined ? parseFloat(pair.buy_total_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_total_wallet_fiat !== undefined ? parseFloat(pair.sell_total_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}    
                   
                </td>
                <td>
                    {/* transaction time */}
                    { pair.sequence === pair.sell_position ? (pair.buy_transaction_time !== undefined ? String(pair.buy_transaction_time).substring(0, 19) : "No") : (pair.sell_transaction_time !== undefined ? String(pair.sell_transaction_time).substring(0, 19) : "No")} 
                </td>
                <td>
                    {/* alarm_received_time */}
                    { pair.sequence === pair.sell_position ? (pair.buy_alarm_received_time !== undefined ? String(pair.buy_alarm_received_time).substring(0, 19) : "No") : (pair.sell_alarm_received_time !== undefined ? String(pair.sell_alarm_received_time).substring(0, 19) : "No")}
                </td>
                <td>
                    {/* alarm sent time */}
                    { pair.sequence === pair.sell_position ? (pair.buy_alarm_sent_time !== undefined ? String(pair.buy_alarm_sent_time).substring(0, 19) : "No") : (pair.sell_alarm_sent_time !== undefined ? String(pair.sell_alarm_sent_time).substring(0, 19) : "No")}
                </td>
            </tr>
            {/* ============= BUY  BUY OR TWO COLUMNS ================ */}
            <tr style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
            <td>{/* position */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_position !== undefined ? pair.buy_position : "No") : (pair.sell_position !== undefined ? pair.sell_position : "No")}
                </td>
                <td>
                    {/* qty */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_crypto_qty !== undefined ? parseFloat(pair.buy_crypto_qty).toFixed(8).toLocaleString("ko-KR", locale_opt) : "No") : (pair.sell_crypto_qty !== undefined ? parseFloat(pair.sell_crypto_qty).toFixed(8).toLocaleString("ko-KR", locale_opt) : "No")}
                </td>
                
                <td>
                    {/* traded price */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_current_crypto_price !== undefined ? parseFloat(pair.buy_current_crypto_price).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_current_crypto_price !== undefined ? parseFloat(pair.sell_current_crypto_price).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                    
                </td>
                <td>
                    {/* net traded amount */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_total_traded_amt !== undefined ? parseFloat(pair.buy_total_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_total_traded_amt !== undefined ? parseFloat(pair.sell_total_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                   
                </td>
                <td>
                    {/* Fee paid */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_gross_fee !== undefined ? parseFloat(pair.buy_gross_fee).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_gross_fee !== undefined ? parseFloat(pair.sell_gross_fee).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                </td>
                <td> 
                    {/* Gross traded amount */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_gross_traded_amt !== undefined ? parseFloat(pair.buy_gross_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_gross_traded_amt !== undefined ? parseFloat(pair.sell_gross_traded_amt).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                </td>
                {/*#################################################################################################*/}
                <td>
                    {/* before balance crypto*/}
                    { pair.sequence !== pair.sell_position ? (pair.buy_initial_wallet_crypto !== undefined ? parseFloat(pair.buy_initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No") : (pair.sell_initial_wallet_crypto !== undefined ? parseFloat(pair.sell_initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No")}
                    
                </td>
                <td>
                    {/* before balance fiat */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_initial_wallet_fiat !== undefined ? parseFloat(pair.buy_initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_initial_wallet_fiat !== undefined ? parseFloat(pair.sell_initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")}
                </td>
                <td>
                    {/* after balance crypto */}
                       
                    { pair.sequence !== pair.sell_position ? (pair.buy_total_wallet_crypto !== undefined ? parseFloat(pair.buy_total_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No") : (pair.sell_total_wallet_crypto !== undefined ? parseFloat(pair.sell_total_wallet_crypto).toLocaleString("ko-KR", locale_opt)+" "+pair.coin_type : "No")}    
                </td>
                <td>
                    {/* after balance  fiat*/}
                    { pair.sequence !== pair.sell_position ? (pair.buy_total_wallet_fiat !== undefined ? parseFloat(pair.buy_total_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No") : (pair.sell_total_wallet_fiat !== undefined ? parseFloat(pair.sell_total_wallet_fiat).toLocaleString("ko-KR", locale_opt)+" "+pair.currency_fiat : "No")} 
                </td>
                <td>
                    {/* transaction time */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_transaction_time !== undefined ? String(pair.buy_transaction_time).substring(0, 19) : "No") : (pair.sell_transaction_time !== undefined ? String(pair.sell_transaction_time).substring(0, 19) : "No")} 
                </td>
                <td>
                    {/* alarm_received_time */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_alarm_received_time !== undefined ? String(pair.buy_alarm_received_time).substring(0, 19) : "No") : (pair.sell_alarm_received_time !== undefined ? String(pair.sell_alarm_received_time).substring(0, 19) : "No")}
                </td>
                <td>
                    {/* alarm sent time */}
                    { pair.sequence !== pair.sell_position ? (pair.buy_alarm_sent_time !== undefined ? String(pair.buy_alarm_sent_time).substring(0, 19) : "No") : (pair.sell_alarm_sent_time !== undefined ? String(pair.sell_alarm_sent_time).substring(0, 19) : "No")}
                </td>
            </tr>
        </React.Fragment>
    ));

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div>
                        <h5>Transaction Pairs​</h5>
                    </div>
                    <br />
                    <div style={{ display: "flex" }}>
                        &emsp;<h6>Simple</h6>
                        &emsp;
                        <Switch onColor="#86d3ff" handleDiameter={15} uncheckedIcon={false} checkedIcon={false} height={20} width={40} onChange={switchToggle} checked={toggle} />
                        &emsp;
                        <h6>Compound</h6>
                    </div>
                    <br />
                    <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                        <div style={{ margin: "0.5em" }}>
                            <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchanges} />
                            &emsp;
                            <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} />
                            &emsp;
                            <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups} />
                            &emsp;
                            <StrategyInputDiv send_setstrategy_name={setStrategy} />
                            &emsp;
                            <TFDiv opt_tfs={opt_tfs} send_settfs={setTfs} />
                            &emsp;
                            <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                        </div>
                        <br />
                        <AscDesc newOld={newOld} send_setNewOld={setNewOld} />
                    </div>
                    <div className="second_div">
                        <br />
                        <table>
                            <thead style={{ background: "#0070c0" }}>
                                <tr>
                                    <th rowSpan={"2"}>#</th>
                                    <th rowSpan={"2"}>Exchange</th>
                                    <th rowSpan={"2"}>Strategy</th>
                                    <th rowSpan={"2"}>Strategy Group</th>
                                    <th rowSpan={"2"}>Asset</th>
                                    <th rowSpan={"2"}>TF</th>
                                    <th rowSpan={"2"}>Position</th>
                                    <th rowSpan={"2"}>Crypto Qty</th>
                                    <th rowSpan={"2"}>Trade Price</th>
                                    <td rowSpan={"2"}>Net Traded Amount</td>
                                    <th rowSpan={"2"}>Fee Paid</th>
                                    <th rowSpan={"2"}>Gross Traded Amount</th>
                                    <td colSpan={"2"}>Profit Amount</td>
                                    <td colSpan={"2"}>Cum. Profit</td>
                                    <td colSpan={"2"}>ROR</td>
                                    <td colSpan={"2"}>Holding Period Return</td>
                                    <th colSpan={"2"}>Before Balance</th>
                                    <th colSpan={"2"}>After Balance</th>
                                    <th rowSpan={"2"}>Traded Time</th>
                                    <td rowSpan={"2"}>Ordered Time</td>
                                    <th rowSpan={"2"}>Triggered Time</th>
                                </tr>
                                <tr>
                                    <td>Crypto</td>
                                    <td>Fiat</td>
                                    <td>Crypto</td>
                                    <td>Fiat</td>
                                    <td>Crypto</td>
                                    <td>Fiat</td>
                                    <td>Crypto</td>
                                    <td>Fiat</td>
                                    <td>Crypto</td>
                                    <td>Fiat</td>
                                    <td>Crypto</td>
                                    <td>Fiat</td>
                                </tr>
                            </thead>
                            <tbody>{displayCharts}</tbody>
                        </table>
                    </div>

                    <div key={remountComponent}>
                    <ReactPaginate
                        previousLabel={pairs.length === 0 ? "" : "Previous"}
                        nextLabel={pairs.length === 0 ? "" : "Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBtns"}
                        previousLinkClassName={"previousBtn"}
                        nextLinkClassName={"nextBtn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        style={{ width: "100%" }}
                    />
                </div>
                </div>
            </div>
            <div style={{width : "100%" }}>
                <Modal_list_full
					open={modalOpen}
					close={closeModal}
					header={
						<>
							<p>
								Detail Records
							</p>
							<button className="close" onClick={closeModal}>
								{" "}
								&times;{" "}
							</button>
						</>
					}
					footer={
							<>
								<button className="close" onClick={closeModal}>
									{" "}
									close{" "}
								</button>
							</>
					}>
					{/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
					<div>
						<div style={{margin: "0em 0em 0em 0em"  , display : "flex" , width : "100%" }}>
                            <table>
                                <thead>
                                    <tr style={{ background: "black" }}>
                                        <th></th>
                                        <th colSpan="6">Strategy Information</th>
                                        <th colSpan="2">Initial Balance</th>
                                        <th colSpan="2">End Balance</th>
                                        <th colSpan="3">Price</th>
                                        <th colSpan="9">Trading Information​</th>
                                        <th colSpan="3">Time Information​</th>
                                    </tr>
                                    <tr style={{ background: "#4472c4" }}>
                                        <th>#</th>
                                        <th>Exchange</th>
                                        <th>Group</th>
                                        <th>Strategy</th>
                                        <th>TF</th>
                                        <th>Position (bot)</th>
                                        <th>Coin</th>
                                        <th>Crypto</th>
                                        <th>Fiat</th>
                                        <th>Crypto</th>
                                        <th>Fiat</th>
                                        <th>Trigger</th>
                                        <th>Alarm</th>
                                        <th>Trading</th>
                                        <th>Operation</th>
                                        <th>Order Type</th>
                                        <th>Crypto Qty</th>
                                        <th>Fiat Qty</th>
                                        <th>Fee</th>
                                        <th>Percent</th>
                                        <th>Result</th>
                                        <th>Msg 1</th>
                                        <th>Msg 2</th>
                                        <th>Transaction</th>
                                        <th>Alarm Received</th>
                                        <th>Alarm Sent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datalogs.map((k, i) => (
                                        <tr className="detail_tr " key={i} style={{background: i%2 === 0 ? "#A9CCE3 " :"#85C1E9" ,cursor: "pointer" }}>
                                            <td className="detail_td">{i + 1}</td>
                                            <td className="detail_td">{k.exchange_name}</td>
                                            <td className="detail_td">{k.strategy_group}</td>
                                            <td className="detail_td">{k.strategy_name}</td>
                                            <td className="detail_td">{k.time_frame}</td>
                                            <td className="detail_td">
                                                {k.position}
                                            </td>
                                            <td className="detail_td">{k.coin_type}</td>
                                            <td className="detail_td">{parseFloat(k.initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.total_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.total_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.trigger_price).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.price).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.current_crypto_price).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{k.operation_type}</td>
                                            <td className="detail_td">{k.order_type}</td>
                                            <td className="detail_td">{parseFloat(k.crypto_qty).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.fiat_qty).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{parseFloat(k.fee_paid).toLocaleString("ko-KR", locale_opt)}</td>
                                            <td className="detail_td">{k.percent}</td>
                                            <td className="detail_td">{k.msg}</td>
                                            <td className="detail_td">{k.msg_dtl}</td>
                                            <td className="detail_td">{k.msg_dtl_2}</td>
                                            <td className="detail_td">{String(k.transaction_time).substring(0, 19)}</td>
                                            <td className="detail_td">{k.alarm_received_time}</td>
                                            <td className="detail_td">{k.alarm_sent_time}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
						</div>
					</div>
				</Modal_list_full>
			</div> 
        
        </div>
    );
};

export default Records;

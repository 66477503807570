import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { ExchangesSelector, locale_opt, c_opt_strategy_grp, c_opt_tfs, c_opt_order_type, c_opt_position } from "../../../common/Commons";
import { c_opt_order_qty_percent, c_opt_pair_is} from "../../../common/Commons";



function SellOrder({ opt_coins , exchangesSelect}) {
    //alert(exchangesSelect);
    
    const [pair_is, setPair_is] = useState("yes");
    const [position, setPosition] = useState("BUY");
    const [leverage_size, setLeverageSize] = useState("1");
    
    const [bot_cnt, setBot_cnt] = useState("1");
    const [coins, setCoins] = useState("ada");
    const [groups, setGroups] = useState("1");
    const [orderType, setOrderType] = useState("limit");

    const [trigger_price, setTrigger_price] = useState("");
    const [order_price, setOrder_price] = useState("");
    const [order_qty, setOrder_qty] = useState("");

    const [limit_order_price, setLimit_order_price] = useState("");
    const [limit_order_qty, setLimit_order_qty] = useState("");

    const [market_order_price, setMarket_order_price] = useState("");
    
    const [auto_limit_triger_price, setAutoLimit_triger_price] = useState("");
    const [auto_limit_order_price, setAutoLimit_order_price] = useState("");
    
    const [auto_market_trigger_price, setAutoMarket_triger_price] = useState("");
    const [auto_market_order_qty, setAutoMarket_order_price] = useState("");


    const [qty_percent, setQty_percent] = useState("q");

    const [tfs, setTfs] = useState("1");
    const [strategy_input, setStrategy_input] = useState("");
    const [tp, setTp] = useState("10");
    const [sl, setSl] = useState("10");
    const [order_perc, setOrderPercent] = useState("50");
    

    const [c_price, setC_price] = useState("");
    const [c_crypto, setC_crypto] = useState("");
    const [c_fiat, setC_fiat] = useState("");

    const opt_pair_is = c_opt_pair_is
    const opt_position = c_opt_position
    const opt_groups = c_opt_strategy_grp;
    const opt_tfs = c_opt_tfs;
    const opt_order_type = c_opt_order_type;
    const opt_order_percent = c_opt_order_qty_percent;
    const [startegyList, SetstartegyList]  = useState([]);


    function SetstrategyInputname(input_strategy_name){
        document.getElementById("input_strategy_name").value = input_strategy_name;
        setStrategy_input(input_strategy_name);
       
        SetstartegyList([]);
    }

    function getStartegyNames(inputStartegyName){

        //alert(inputStartegyName)
        setStrategy_input(inputStartegyName)
        let groups_name
        groups === "1" ? groups_name = "MLC" : groups === "2" ? groups_name = "P2V" : groups_name = "TBT"
        
        const data = {
            bot_id: "f_bot_stk",
            msg: "getStartegyNames", 

            exchanges : exchangesSelect,
            strategy_grp : groups,
            coin_ticker : coins.toUpperCase(),
            strategy_name: inputStartegyName,
            
            time_frame : tfs,
        };
        
        //alert(JSON.stringify(data))

        axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
        .then((res) => {
            //alert(JSON.stringify(res.data.result));
            if (res.data.result.toString().length > 0 ){
                SetstartegyList(res.data.result)
            }else{
                return false;
            }
        })
        .catch((err) => {
            alert("ERROR")
            return false;
        })
    }


    const Fire = () => {
        if (window.confirm("Confirm the order?")) {

            if (strategy_input == "")
            {
                alert("Please input startegy name !")
                return false;
            }
          
            let time = dayjs().format("YYYY-MM-DD hh:mm:ss");

            let groups_name
            groups === "1" ? groups_name = "MLC" : groups === "2" ? groups_name = "P2V" : groups_name = "TBT"

            let exchange_name_to_trade;
            let coin_type_to_trade;
            if (exchangesSelect === "1") {
                exchange_name_to_trade = "BINANCE" + ":" + coins.toUpperCase() + "USDT" ;
                coin_type_to_trade = coins.toUpperCase() ;
            } else if (exchangesSelect === "2") {
                exchange_name_to_trade = "UPBIT" + ":" + coins.toUpperCase() + "KRW" ;
                coin_type_to_trade = coins.toUpperCase() ;
            } else if (exchangesSelect === "3") {
                exchange_name_to_trade = "BITTHUMB" + ":" + coins.toUpperCase() + "KRW" ;
                coin_type_to_trade = coins.toUpperCase() ;
            } else if (exchangesSelect === "4") {
                exchange_name_to_trade = "OKEX" + ":" + coins.toUpperCase() + "USDT" ;
                coin_type_to_trade = coins.toUpperCase() ;
            }else if (exchangesSelect === "5") {
                exchange_name_to_trade = "MEXC" + ":" + coins.toUpperCase() + "USDT" ;
                coin_type_to_trade = coins.toUpperCase() ;
            }else if (exchangesSelect === "6") {
                exchange_name_to_trade = "BITGET" + ":" + coins.toUpperCase() + "USDT" ;
                coin_type_to_trade = coins.toUpperCase()  ;
            }
            

            const this_data = {
                    getmodel : "botschedulert",
                    gettogo : "trading_view",

                    params : {
                        trading_type : "stk-future",
                        strategy_group: groups,
                        //strategy_name: groups_name+" "+strategy_input+" "+tfs,
                        strategy_name: strategy_input,
                        position: position,
                        exchange_name: exchange_name_to_trade,
                        coin_type: coin_type_to_trade,
                        time_frame: tfs,
                        price: order_price,
                        order_type: orderType,
                        operation_type: "manual_open",
                        alarm_sent_time: String(new Date(time) / 1000),

                        trigger_price  : trigger_price ,
                        pair_is :pair_is ,

                        tp_percent: tp ,
                        sl_percent: sl ,
                        order_perc: order_perc ,

                        leverage_size : leverage_size,

                    }
            };
            //alert(JSON.stringify(this_data))
            //return false;
            axios
                .post(process.env.REACT_APP_TV_SERVER, this_data)
                .then((res) => {
                    window.location.reload();
                })
                .catch((err) => console.log(err));
            
            
        }
    }
    // update abit
  function check_bot_count_validate(){

        let groups_name
        groups === "1" ? groups_name = "MLC" : groups === "2" ? groups_name = "P2V" : groups_name = "TBT"
        
        const data = {
            bot_id: "f_bot_stk",
            msg: "check_bot_count_validate", 

            exchanges : exchangesSelect,
            strategy_grp : groups,
            coin_ticker : coins.toUpperCase(),
            strategy_name: groups_name+" "+strategy_input+" "+tfs,
            
            time_frame : tfs,
        };
        
        axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
        .then((res) => {
            //alert(JSON.stringify(res.data.msg));
            if (res.data.msg === "true"){
                Fire();

            }else{
            alert("ERROR ! : please check bot count !");
            return false;
            }
        })
        .catch((err) => {
            alert("ERROR")
            return false;
        })
    }

    function Get_price(){
        const data = {
            bot_id: "f_bot_stk",
            msg: "get_price_balance",
            what: "get_c_price",
            exchange: exchangesSelect,
            coin: coins,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                setC_price(res.data.c_price);
            })
            .catch((err) => {
                console.log(err);
            });
    }

   

    const Bot_count = () => {
        return(
            <>
            <span>Bot Count: </span>
            <input
                type={"number"}
                style={{ width: "45px" }}
                value={bot_cnt}
                onChange={(e) => {
                    setBot_cnt(e.target.value);
                }}></input>
            &emsp;&emsp;&emsp;&emsp;
            </>
        )
    }

    const Line_2_limit = () => {
        return (
            <>
                <span>Order Price: </span>
                <input
                   id="limit_order_price"
                    type={"number"}
                    min={limit_order_price}
                    step={0.1} 
                    style={{ width: "100px" }}
                    onChange={(e) => {
                        setOrder_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6" ? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
            </>
        );
    };

    const Line_2_market = () => {
        return (
            <>
            </>
        );
    };

    const Line_2_auto_limit = () => {
        return (
            <>
                <span>Trigger Price: </span>
                <input
                   id="auto_limit_triger_price"
                   type={"number"}
                   min={auto_limit_triger_price}
                   skip={0.1}
                   style={{ width: "100px" }}
                    onChange={(e) => {
                        setTrigger_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4"  || exchangesSelect === "5" || exchangesSelect === "6"? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
                <span>Order Price: </span>
                <input
                    id="auto_limit_order_price"
                    type={"number"}
                    min={auto_limit_order_price}
                    step={0.1}
                    style={{ width: "100px" }}
                    onChange={(e) => {
                        setOrder_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6" ? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
            </>
        );
    };

    const Line_2_auto_market = () => {
        return (
            <>
                <span>Trigger Price: </span>
                <input
                    id="auto_market_trigger_price"
                    type={"number"}
                    min={auto_market_trigger_price}
                    skip={0.1}
                    style={{ width: "100px" }}
                    onChange={(e) => {
                        setTrigger_price(e.target.value);
                        setOrder_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4"  || exchangesSelect === "5"  || exchangesSelect === "6" ? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
            </>
        );
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            Get_price()
        }, 3000)
        return () => clearInterval(intervalId)
    }, [exchangesSelect, coins , startegyList]);
    
    useEffect(() => {
        setTrigger_price("");
        setOrder_price("");
        setOrder_qty("");

        if (orderType === "limit") {
            document.getElementById("limit_order_price").value = c_price;
            //document.getElementById("limit_order_qty").value = "";

            setOrder_price(c_price);
        } else if (orderType === "market") {
            //document.getElementById("market_order_qty").value = "";
        } else if (orderType === "autolimit") {
            document.getElementById("auto_limit_triger_price").value = c_price;
            document.getElementById("auto_limit_order_price").value = c_price;
            //document.getElementById("auto_limit_order_qty").value = "";
            
            setOrder_price(c_price);
            setTrigger_price(c_price);
        } else if (orderType === "automarket") {
            document.getElementById("auto_market_trigger_price").value = c_price;
            //document.getElementById("auto_market_order_qty").value = "";
            
            setTrigger_price(c_price);
        }
    }, [orderType]);

    useEffect(() => {
        const data = {
            bot_id: "f_bot_stk",
            msg: "get_price_balance",
            what: "get_info",
            exchange: exchangesSelect,
            coin: coins,
        };
        //alert(JSON.stringify(data))
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                setC_price(res.data.c_price);

                setLimit_order_price(res.data.c_price)
                setLimit_order_qty(res.data.c_price)

                setMarket_order_price(res.data.c_price)
                
                setAutoLimit_triger_price(res.data.c_price)
                setAutoLimit_order_price(res.data.c_price)

                setAutoMarket_triger_price(res.data.c_price)
                setAutoMarket_order_price(res.data.c_price)

                let c_c_f = JSON.parse(String(res.data.c_balance));

                let exchange_style, where_style, fiat_style;
                if (exchangesSelect === "1") {
                    exchange_style = "binance_";
                    where_style = +0; // 4
                    fiat_style = "USDT";
                } else if (exchangesSelect === "2") {
                    exchange_style = "upbit_";
                    where_style = +5; // 9 
                    fiat_style = "KRW";
                } else if (exchangesSelect === "3") {
                    exchange_style = "bithumb_";
                    where_style = +10; // 14
                    fiat_style = "KRW";
                } else if (exchangesSelect === "4") {
                    exchange_style = "okex_";
                    where_style = +15; // 19
                    fiat_style = "USDT";
                } else if (exchangesSelect === "5") {
                    exchange_style = "mexc_";
                    where_style = +20; // 19
                    fiat_style = "USDT";
                } else if (exchangesSelect === "6") {
                    exchange_style = "bitget_";
                    where_style = +25; // 19
                    fiat_style = "USDT";
                }
                //console.log("***********************************************");
                //console.log("c_c_f : " + JSON.stringify(c_c_f));
                //console.log("***********************************************");
                c_c_f[exchange_style  + where_style].forEach((element) => {
                    if (element.crypto === coins.toUpperCase()) {
                        setC_crypto(element.free);
                    }
                    if (element.crypto === fiat_style) {
                        setC_fiat(element.free);
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exchangesSelect, coins, groups]);


    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div>
                        <h5>Create Order</h5>
                        
                        <div>
                            <span>Coin Price : {parseFloat(c_price).toLocaleString("ko-KR", locale_opt)}</span>
                            &emsp;&emsp;
                            <span>Crypto : {parseFloat(c_crypto).toLocaleString("ko-KR", locale_opt)}</span>
                            &emsp;&emsp;
                            <span>Fiat : {parseFloat(c_fiat).toLocaleString("ko-KR", locale_opt)}</span>
                        </div>
                        <br/>
                        <div style={{ display: "flex" , justifyContent: 'left' , margin: "0em 0em 1.2em 0em"}} >
                            <div style={{width: "10%" }}>
                                <span style={{ color: "#FFC000" }}>Position:&nbsp;&nbsp;</span>
                                <select
                                    onChange={(e) => {
                                        setPosition(e.target.value);
                                    }}>
                                    {opt_position.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{width: "20%" }}>
                                <span style={{ color: "#FFC000" }}>Leverage:&nbsp;&nbsp;</span>
                                <input
                                    id ="input_leverage_size"
                                    style={{ width: "100px"  }}
                                    type={"text"}
                                    placeholder="1"
                                    onChange={(e) => {
                                        setLeverageSize(e.target.value.trim());
                                    }}>
                                 </input>
                            </div>
                        </div>
                        <div style={{ display: "flex"}} >
                            <div style={{width: "10%" }}>
                                <span style={{ color: "#FFC000" }}>Pair&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <select
                                    onChange={(e) => {
                                        setPair_is(e.target.value);
                                    }}>
                                    {opt_pair_is.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* {pair_is === "yes" ? Bot_count() : null}     
                                                    */}
                            <div style={{width: "10%" }}>
                                <span>Asset: </span>
                                <select
                                    onChange={(e) => {
                                        setCoins(e.target.value);
                                    }}>
                                    {opt_coins.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{width: "10%" }}>
                                <span>Strategy: </span>
                                <select
                                    onChange={(e) => {
                                        setGroups(e.target.value);
                                    }}>
                                    {opt_groups.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{width: "15%" }}>
                                <span>Time Frame: </span>
                                <select
                                    onChange={(e) => {
                                        setTfs(e.target.value);
                                    }}>
                                    {opt_tfs.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                                
                            <div style={{width: "55%" }}>
                                <div>
                                    <span>Strategy Name: </span>
                                    <input
                                        id ="input_strategy_name"
                                        style={{ width: "300px"  }}
                                        type={"text"}
                                        placeholder="MLC xxxxxx 15"
                                        onChange={(e) => {
                                            getStartegyNames(e.target.value.trim());
                                        }}>
                                    </input>
                                </div>
                                <div>
                                    <table className="table_none_border" style={{textAlign : "right", width: "300px"  }}>
                                        <tbody className="table_none_border">
                                            {startegyList&&startegyList.map((item) => (
                                                <tr>
                                                <td style={{ cursor: "pointer" }} className="table_none_border" key={item}  onClick={() => {
                                                        SetstrategyInputname(item);
                                                    }}>
                                                {item}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                        
                                </div>
                            </div>
                            
                        </div>
                        {/* LINE 2 */}
                        <br/>
                        <div className="div_flex">
                            <span>Order Type: </span>
                            <select
                                onChange={(e) => {
                                    setOrderType(e.target.value);
                                }}>
                                {opt_order_type.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>&emsp;&emsp;
                            <div>
                                <span>T/P: </span>
                                <input
                                    id ="input_tp"
                                    style={{ width: "50px"  }}
                                    type={"number"}
                                    defaultValue={10}
                                    onChange={(e) => {
                                        setTp(e.target.value.trim());
                                    }}>
                                </input>%
                            </div>&emsp;&emsp;
                            <div>
                                <span>S/L: </span>
                                <input
                                    id ="input_sl"
                                    style={{ width: "50px"  }}
                                    type={"number"}
                                    defaultValue={10}
                                    onChange={(e) => {
                                        setSl(e.target.value.trim());
                                    }}>
                                </input>%
                            </div>
                            &emsp;&emsp;
                            <div>
                                <span>Oder Percent </span>
                                <input
                                    id ="input_order_perc"
                                    style={{ width: "50px"  }}
                                    type={"number"}
                                    defaultValue={50}
                                    onChange={(e) => {
                                        setOrderPercent(e.target.value.trim());
                                    }}>
                                </input>%
                            </div>
                            &emsp;&emsp;
                            {orderType === "limit" ? Line_2_limit() : orderType === "market" ? Line_2_market() : orderType === "autolimit" ? Line_2_auto_limit() : Line_2_auto_market()}
                            &emsp;&emsp;
                            <button style={{ background: "orange", color: "white", width: "110px"}}  onClick={() => check_bot_count_validate()}>
                                Fire
                            </button>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
    );
}
export default SellOrder;

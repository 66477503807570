import React, { useState, useEffect } from "react";
import axios from "axios";

import ReactPaginate from "react-paginate";
import { c_opt_exchanges_all, c_opt_strategy_grp_name_all, c_opt_order_type_all, locale_opt, locale_opt_8 } from "../../../common/Commons";
import { C_coins, AscDesc, ExchangeDiv, CoinsDiv, GroupDiv, StrategyInputDiv, DateFromToDiv } from "../../../common/Commons";

import "./logRecords.css";

function LogRecordsTrabo() {
    const pairs = [1];

    // select box valuable
    const [exchange_name, setExchange_name] = useState("all");
    const [coins, setCoins] = useState("all");
    const [opt_coins, setOpt_coins] = useState([]);
    const [groups, setGroups] = useState("all");
    const [strategy, setStrategy] = useState("");
    const [operation, setOperation] = useState("all");
    const [selResults, setSelResults] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [newOld, setNewOld] = useState("desc");

    const [datalogs, setDatalogs] = useState([]);

    const [remountComponent, setRemountComponent] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    // common
    const opt_exchanges = c_opt_exchanges_all;
    const opt_groups = c_opt_strategy_grp_name_all;
    // common

    const otp_operation = [
        { value: "all", name: "All" },
        { value: "auto", name: "AUTO" },
        { value: "manual", name: "MANUAL" },
        { value: "close", name: "CLOSE" },
        { value: "terminate", name: "TERMINATE" },
    ];

    const otp_selResults = [
        { value: "all", name: "ALL" },
        { value: "finished_all", name: "Finished All" },
        { value: "error_all", name: "Error All" },
    ];
    // END SELECT

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        const limit_cnt = 30;
        const data = {
            bot_id: "s_bot",
            msg: "get_trabo_log_records",
            pgn: pageNumber,
            limit_cnt: limit_cnt,
            exchange_name: exchange_name,
            coins: coins,
            strategy_group_name: groups,
            strategy: strategy,
            operation_type: operation,
            selResults: selResults,
            dateFrom: dateFrom,
            dateTo: dateTo,
            newOld: newOld,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                if (res.data.result.length !== 0) {
                    const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
                    setPageCount(page_cnt_math);
                    setDatalogs(res.data.result);
                } else {
                    setPageCount(0);
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [pageNumber, exchange_name, coins, groups, strategy, operation, selResults, dateFrom, dateTo, newOld]);

    useEffect(() => {
        changePage({ selected: 0 });
        setRemountComponent(Math.random());
    }, [exchange_name, coins, groups, strategy, selResults, dateFrom, dateTo, newOld]);

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    return (
        <div className="first_div">
            <div className="second_div">
                <h3>Transaction Log</h3>
                <br />
                <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                    <div>
                        <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name} />
                        &emsp;
                        <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} />
                        &emsp;
                        <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups} />
                        &emsp;
                        <StrategyInputDiv send_setstrategy_name={setStrategy} />
                        &emsp;
                        <span>OPERATION: </span>
                        <select
                            onChange={(e) => {
                                setOperation(e.target.value);
                            }}>
                            {otp_operation.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>{" "}
                        &emsp;
                        <span>Results: </span>
                        <select
                            onChange={(e) => {
                                setSelResults(e.target.value);
                            }}>
                            {otp_selResults.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <div>
                        <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                    </div>
                    <br />
                    <AscDesc newOld={newOld} send_setNewOld={setNewOld} />
                    <br />
                </div>
                <table>
                    <thead style={{ background: "#4472c4" }}>
                        <tr>
                            <th>#</th>
                            <th>Pair ID</th>
                            <th>exchange_name</th>
                            <th>Stragety Group</th>
                            <th>Stragety Name</th>
                            <th>Bot Count</th>
                            <th>Operation Type</th>
                            <th>Crypto Type</th>
                            <th>Trading Profit Percernt</th>
                            <th>Trading Profit Crypto</th>
                            <th>Share Rate</th>
                            <th>Paid Profit AMT</th>
                            <th>Paid Profit Percent</th>
                            <th>MSG</th>
                            <th>Transaction</th>
                            <th>Sent Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datalogs.map((k, i) => (
                            <tr className="logTr" key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F"  ,cursor: "pointer", hover: { background: "red" } }}>
                                <td>{i + 1}</td>
                                <td>{k.pair_id}</td>
                                <td>{k.exchange_name}</td>
                                <td>{k.strategy_group_name === '1' ? 'MLC' : k.strategy_group_name === '2' ? 'P2V' : 'TBT'}</td>
                                <td>{k.strategy_name}</td>
                                <td>{k.bot_cnt}</td>
                                <td>{k.operation_type}</td>
                                <td>{k.crypto_name}</td>
                                <td>{parseFloat(k.trading_profit_percent).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.trading_profit_crypto).toLocaleString("ko-KR", locale_opt_8)}</td>
                                <td>{k.share_rate}</td>
                                <td>{parseFloat(k.paid_profit_amount).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.paid_profit_percent).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{k.msg}</td>
                                <td>{k.trading_date}</td>
                                <td>{k.insert_date}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div key={remountComponent}>
                    <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    );
}

export default LogRecordsTrabo;

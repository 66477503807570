import React, { useState, useEffect } from "react";
import axios from "axios";


function AddUser({callbackAddFinishEvent}) {
	const python_server_url = process.env.REACT_APP_PYTHON_SERVER;

    const [fname, setFname] = useState(null);
    const [lname, setLname] = useState(null);
    const [email, setEmail] = useState(null);
    const [user_id, setUserId] =useState(null);
    const [password, setPassword] =useState(null);
    const [repassword, setRepassword] =useState(null);
    const [secret_key, setSecretkey] = useState(null);
    const [otp_url, setOtpUrl] = useState(null);
    const [otp_code, setOtpCode] = useState(null);

    const makeSecretInform = () => {
        if (user_id !== "" && password !== "" && (password === repassword)) {
            const info_total = { msg: "get_secret_key_and_otp_url", id: user_id, pw: password };
            axios
                .post(`${python_server_url}/auth`, info_total)
                .then((res) => {
                    const re_dt = res.data;
                    if (re_dt["msg"] === "1") {
                        setSecretkey(re_dt["secret_key"]);
                        setOtpUrl(re_dt["otp_url"]);
                    } else {
                        alert("System Can not generate key !");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("Pleae check data!");
        }

    }

    const create_admin = () => {
        console.log("create_admin !!!!")
        //alert(user_id);
        if (fname !== "" && lname !== "" && email !== "" && user_id !== "" && password !== "" && secret_key !== "" && otp_code !== "") {
          const info_total = { msg: "create_admin", id: user_id, pw: password,fname: fname, lname: lname ,email: email ,secret_key : secret_key};
          axios
              .post(`${python_server_url}/auth`, info_total)
              .then((res) => {
                  const re_dt = res.data;
                  if (re_dt["msg"] === "1") {
                    callbackAddFinishEvent();
                    
                  } else {
                      alert("User is not created! , Please check data !");
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
        } else {
            alert("User is not created! , Please check data !");
        }
      }

    return (
        <div className="first_div">
            <br />
            <div className="second_div" >
				<div style={{ display: "flex", margin: "0px 0px 1em" }}>
                    <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Add New User</h5>
                </div>
            </div>
            <div style={{ width : "100%" }}>
                <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "20%" }}>
                        <span>First Name</span><br></br>
                    </div>
                    <div style={{ width : "80%" }}>
                        <input id='fname' type="text" style={{ width: "50%" }} onChange={(e) => setFname(e.target.value)}/>
                    </div>
                </div>
                <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "20%" }}>
                        <span>Last Name</span><br></br>
                    </div>
                    <div style={{ width : "80%" }}>
                        <input id='lname' type="text" style={{ width: "50%" }} onChange={(e) => setLname(e.target.value)}/>
                    </div>
                </div>
                <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "20%" }}>
                        <span>Email</span><br></br>
                    </div>
                    <div style={{ width : "80%" }}>
                        <input id='email' type="text" style={{ width: "50%" }} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                </div>
                <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "20%" }}>
                        <span>User Id</span><br></br>
                    </div>
                    <div style={{ width : "80%" }}>
                        <input id='user_id' type="text" style={{ width: "50%" }} onChange={(e) => setUserId(e.target.value)}/>
                    </div>
                </div>
                <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "20%" }}>
                        <span>Password</span><br></br>
                    </div>
                    <div style={{ width : "40%" }}>
                        <input id='password' type="text" style={{ width: "100%" }} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div style={{ width : "40%" }}>
                        <input id='repassword' type="text" style={{ margin: "0px 0px 0px 5px" , width: "100%" }} onChange={(e) => setRepassword(e.target.value)}/>
                    </div>
                </div>
                {/* makeSecretInform */}
                <div style={{margin: "2.5em 0px 2.5em 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{width : "100%" }}>
                        <button className="btn btn-info btn-sm" 
                        onClick={() => makeSecretInform()} >Genernate Privite Secret Information</button>
                    </div>
                </div>

                {/* opt information */}
                <div hidden={otp_url == null ? true : false} style={{margin: "0px 0px 0px 2.5em"  ,textAlign : "left" , width : "50%" }}>
                    <div style={{display : "flex" , width : "100%" }}>
                        <div style={{ width : "20%" }}>
                            <span>Otp Secret Key</span><br></br>
                        </div>
                        <div style={{textAlign : "center" ,width : "40%" }}>
                            <img src={otp_url} alt="" width="200px" height="200px" />
                        </div>
                        <div style={{width : "40%" }}>
                            <span>Manual Code : {secret_key}</span><br></br>
                        </div>
                    </div>
                    <div style={{margin: "2.5em 0px 0px 0px"  ,display : "flex" ,  width : "100%" }}>
                        <div style={{ width : "20%" }}>
                            <span>Otp Code</span><br></br>
                        </div>
                        <div style={{justifyContent : "center" ,width : "80%" }}>
                            <input id='otp_code' type="text" style={{ margin: "0px 0px 0px 0px" , width: "50%" }} onChange={(e) => setOtpCode(e.target.value)}/>
                        </div>
                    </div>
                </div>

                {/* save button */}
                <div hidden={otp_url == null ? true : false} style={{margin: "2.5em 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "100%" }}>
                        <button className="btn btn-info btn-sm" 
                        onClick={() => create_admin() } >Save User</button>
                    </div>
                </div>
                
            </div>

        </div>
    );
}

export default AddUser;

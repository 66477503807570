import React, { useState, useEffect } from "react";
import axios from "axios";

import { c_opt_exchanges, c_opt_strategy_grp_name } from "../../../common/Commons";
import { C_coins, ExchangeDiv, CoinsDiv, DateFromToDiv } from "../../../common/Commons";

function DailyBalanceHistory() {
    const [exchange_name, setExchange_name] = useState("binance");
    const [coins, setCoins] = useState("ada");
    const [opt_coins, setOpt_coins] = useState([]);
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");

    const [balanceStatus, setBalanceStatus] = useState(null);

    // common
    const opt_exchanges = c_opt_exchanges;

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    function Tbody({ ll, bg_color }) {
        return (
            <>
                {balanceStatus[ll].map((name, i) => (
                    <tr key={i} style={{ background: bg_color }}>
                        <td>{name.date}</td>
                        <td>{name.initial}</td>
                        <td>{name.deposit_hap}</td>
                        <td>{name.withdraw_hap}</td>
                        <td>{name.total}</td>
                        <td>{name.change}</td>
                    </tr>
                ))}
            </>
        );
    }

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    useEffect(() => {
        const data = {
            bot_id: "p_bot_stk",
            msg: "pbot_daily_balance_history",
            exchange_name: exchange_name,
            ticker_name: coins,
            dateFrom: dateFrom,
            dateTo: dateTo,
        };

        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                setBalanceStatus(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exchange_name, coins, dateFrom, dateTo]);

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                        <div>
                            <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name} />
                            &emsp;
                            <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} _type="asset" />
                            &emsp;
                            <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                        </div>
                    </div>
                    <br />

                    <div style={{ display: "flex", marginLeft: "-1em" }}>
                        &emsp;&emsp;
                        <div style={{ width: "31%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>MLC</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus && balanceStatus ? <Tbody ll="mlc" bg_color="#142440" /> : null}</tbody>
                            </table>
                        </div>
                        &emsp;&emsp;
                        <div style={{ width: "31%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>P2V</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus && balanceStatus !== null ? <Tbody ll="p2v" bg_color="#0B2F24" /> : null}</tbody>
                            </table>
                        </div>
                        &emsp;&emsp;
                        <div style={{ width: "31%" }}>
                            <div style={{ display: "flex" }}>
                                <h5>TBT</h5>
                            </div>
                            <table>
                                <thead>
                                    <tr style={{ background: "#6CA0EC" }}>
                                        <th>Date</th>
                                        <th>Initial Balance</th>
                                        <th>Deposit</th>
                                        <th>Withdraw</th>
                                        <th>Ending Balance</th>
                                        <th>Change (%)</th>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{balanceStatus && balanceStatus ? <Tbody ll="tbt" bg_color="#271137" /> : null}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DailyBalanceHistory;

import React from 'react';
import "./Modal_list_full.css";

const Modal_list_full = ( props ) => {
    // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
    const { open, close, header, footer } = props;

    return (
        // 모달이 열릴때 openModal 클래스가 생성된다.
        <div className={ open ? 'openModal modal' : 'modal' } style={{ color: "black"}}>
            { open ? (  
                <section>
                    <header>
                        {header}
                    </header>
                    <main>
                        {props.children}
                    </main>
                    <footer>
                        {footer}
                    </footer>
                </section>
            ) : null }
        </div>
    )
}

export default Modal_list_full
import React, { useState, useEffect } from "react";
import axios from "axios";


function UpdateUser({currentUserId,update_task_type , callbackUpdateFinishEvent}) {
	const python_server_url = process.env.REACT_APP_PYTHON_SERVER;

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [user_id, setUserId] =useState("");
    const [password, setPassword] =useState("");
    const [repassword, setRepassword] =useState("");
    const [secret_key, setSecretkey] = useState("");
    const [otp_url, setOtpUrl] = useState("");
    const [otp_code, setOtpCode] = useState("");


    useEffect(() => {
        //alert("UpdateUser useEffect, currentUserId :" + currentUserId);
        //alert("UpdateUser useEffect, update_task_type :" + update_task_type);
        setUserId(currentUserId);
        get_user_info();
        if (update_task_type !== "delete")
        {
            makeSecretInform();
        }

    }, []);


    const get_user_info = () => {
        if (currentUserId !== "" ) {
            const info_total = { msg: "get_user_info", id: currentUserId };
            axios
                .post(`${python_server_url}/auth`, info_total)
                .then((res) => {
                    const re_dt = res.data;
                    if (re_dt["msg"] === "1") {
                        setUserId(re_dt["result"]["emp_login"]);
                        setFname(re_dt["result"]["fname"]);
                        setLname(re_dt["result"]["lname"]);
                        setEmail(re_dt["result"]["email"]);
                        setSecretkey(re_dt["secret"]);
                    } else {
                        alert("System Can not generate key !");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("Pleae check data!");
        }

    }

    const makeSecretInform = () => {
        if (currentUserId !== "") {
            const info_total = { msg: "get_secret_key_and_otp_url", id: currentUserId };
            axios
                .post(`${python_server_url}/auth`, info_total)
                .then((res) => {
                    const re_dt = res.data;
                    if (re_dt["msg"] === "1") {
                        setSecretkey(re_dt["secret_key"]);
                        setOtpUrl(re_dt["otp_url"]);
                    } else {
                        alert("System Can not generate key !");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("Can not load user id!");
        }

    }

    const update_admin = () => {
        console.log("update_admin !!!!")
        //alert("UpdateUser update_admin, update_task_type :" + update_task_type);
        //alert("UpdateUser update_admin, user_id :" + user_id);
        if ( user_id !== ""  && password == repassword ) {
          var info_total = "" ;
          if (update_task_type === "update_password" ){
            info_total = { msg: "update_admin_password", id: user_id, pw: password,fname: fname, lname: lname ,email: email};
         
          }else if (update_task_type === "update_otp") {
            info_total = { msg: "update_admin_otp", id: user_id, otp_code: otp_code , secret_key : secret_key};

          }else {
            info_total = { msg: "delete_admin", id: user_id};
          }

          //alert("info_total : " + JSON.stringify(info_total))
          if (info_total !== "" ){ 
            axios
            .post(`${python_server_url}/auth`, info_total)
            .then((res) => {
                const re_dt = res.data;
                if (re_dt["msg"] === "1") {
                    

                    if (update_task_type === "update_password" ){
                        alert("Password is updated !");
                     
                      }else if (update_task_type === "update_otp") {
                        alert("Otp is updated !");
            
                      }else {
                        alert("User is deleted !")
                      }

                    callbackUpdateFinishEvent();
                  
                } else {
                    alert("User is not created! , Please check data !");
                }
            })
            .catch((err) => {
                console.log(err);
            });

          }else{
            alert("Please check data !");
          }

         
        } else {
            alert("User is not created! , Please check data !");
        }
      }

    return (
        <div className="first_div">
            <br />
            <div className="second_div" >
				<div style={{ display: "flex", margin: "0px 0px 1em" }}>
                    <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Update & Delete User</h5>
                </div>
            </div>
            <div style={{ width : "100%" }}>
                <div hidden={update_task_type == "update_password" ? false : true} style={{margin: "0px 0px 0px 0px"  ,textAlign : "left" , width : "100%" }}>

                    <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                        <div style={{ width : "20%" }}>
                            <span>First Name</span><br></br>
                        </div>
                        <div style={{ width : "80%" }}>
                            <input id='fname' value={fname} type="text" style={{ width: "50%" }} onChange={(e) => setFname(e.target.value)}/>
                        </div>
                    </div>
                    <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                        <div style={{ width : "20%" }}>
                            <span>Last Name</span><br></br>
                        </div>
                        <div style={{ width : "80%" }}>
                            <input id='lname' value={lname} type="text" style={{ width: "50%" }} onChange={(e) => setLname(e.target.value)}/>
                        </div>
                    </div>
                    <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                        <div style={{ width : "20%" }}>
                            <span>Email</span><br></br>
                        </div>
                        <div style={{ width : "80%" }}>
                            <input id='email' value={email} type="text" style={{ width: "50%" }} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                    </div>
                    <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                        <div style={{ width : "20%" }}>
                            <span>User Id</span><br></br>
                        </div>
                        <div style={{ width : "80%" }}>
                            <input id='user_id' value={user_id}  type="text" style={{ width: "50%" }} onChange={(e) => setUserId(e.target.value)}/>
                        </div>
                    </div>
                    <div style={{margin: "0px 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                        <div style={{ width : "20%" }}>
                            <span>Password</span><br></br>
                        </div>
                        <div style={{ width : "40%" }}>
                            <input id='password'  type="text" style={{ width: "100%" }} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div style={{ width : "40%" }}>
                            <input id='repassword' type="text" style={{ margin: "0px 0px 0px 5px" , width: "100%" }} onChange={(e) => setRepassword(e.target.value)}/>
                        </div>
                    </div>
                    
                </div>
                
                {/* opt information */}
                <div hidden={update_task_type == "update_otp" ? false : true} style={{margin: "0px 0px 0px 2.5em"  ,textAlign : "left" , width : "50%" }}>
                    <div style={{display : "flex" , width : "100%" }}>
                        <div style={{ width : "20%" }}>
                            <span>Otp Secret Key</span><br></br>
                        </div>
                        <div style={{textAlign : "center" ,width : "40%" }}>
                            <img src={otp_url} alt="" width="200px" height="200px" />
                        </div>
                        <div style={{width : "40%" }}>
                            <span>Manual Code : {secret_key}</span><br></br>
                        </div>
                    </div>
                    <div style={{margin: "2.5em 0px 0px 0px"  ,display : "flex" ,  width : "100%" }}>
                        <div style={{ width : "20%" }}>
                            <span>Otp Code</span><br></br>
                        </div>
                        <div style={{justifyContent : "center" ,width : "80%" }}>
                            <input id='otp_code' type="text" style={{ margin: "0px 0px 0px 0px" , width: "50%" }} onChange={(e) => setOtpCode(e.target.value)}/>
                        </div>
                    </div>
                </div>

                {/* update button */}
                <div hidden={update_task_type == "delete" ? true : false}  style={{margin: "2.5em 0px 0px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "100%" }}>
                        <button className="btn btn-info btn-sm" 
                        onClick={() => update_admin() } >Update User</button>
                    </div>
                </div>
                {/* delete button */}
                <div hidden={update_task_type == "delete" ? false : true}  style={{margin: "2.5em 0px 1px 2.5em"  , display : "flex" , width : "50%" }}>
                    <div style={{ width : "100%" }}>
                        <button className="btn btn-info btn-sm" 
                        onClick={() => update_admin() } >Delete User</button>
                    </div>
                </div>
                
            </div>

        </div>
    );
}

export default UpdateUser;

import React, { useState, useEffect } from "react";

import Register from "./Register"
import Records from "./Records"
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function WalletAssetRecords({handleLogoutapp}) {
    const [title, setTile] = useState()

    useEffect(() => {
        setTile("Manual Wallet Asset")
    }, []);


    return (
        <div>
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <Register />
            <Records />
        </div>
    );

}

export default WalletAssetRecords;

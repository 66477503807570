import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import Modal from "../../common/Modal";
import HeaderNew from "../../headerFooterSide/HeaderNew";

function ApiMange_Future({handleLogoutapp}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [modalInputValue_1, setModalInputValue_1] = useState();
    const [modalInputValue_2, setModalInputValue_2] = useState();
    const [datalogs, setDatalogs] = useState([]);

    const openModal = (_id, exchanges, grp, p_key, s_key) => {
        setModalOpen(true);
        setModalData([_id, exchanges, grp]);
        setModalInputValue_1(p_key);
        setModalInputValue_2(s_key);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const Change_keys = () => {
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
        const data = {
            msg: "put_api_keys_future",
            _id: modalData[0],
            p_key: modalInputValue_1,
            s_key: modalInputValue_2,
        };

        axios
            .post(`${python_server_url}/admin_manager`, data)
            .then((res) => {
                alert("Completed Updated");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                alert("Sorry.. Failed");
                window.location.reload();
            });
    };

    useEffect(() => {
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
        const data = {
            msg: "get_api_keys_future",
        };

        axios
            .post(`${python_server_url}/admin_manager`, data)
            .then((res) => {
                setDatalogs(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    return (
        <div>
            {/* tile */}
            <h1></h1>
            <div className="first_div">
                <div className="second_div">
                    <h5 style={{ color: "white" }}>Future API Keys</h5>
                    <br />
                    <table style={{ width: "99.5%" }}>
                        <thead style={{ background: "#6CA0EC" }}>
                            <tr>
                                <th>Exchanges</th>
                                <th>GROUP</th>
                                <th>P_KEY</th>
                                <th>S_KEY</th>
                                <th>Update Date</th>
                                <th>Created Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datalogs.map((k, i) => (
                                <tr style={{ cursor: "pointer", background: `${k.exchanges === "BINANCE" ? "#337F63" : k.exchanges === "UPBIT" ? "#C55A11" : k.exchanges === "BITHUMB" ? "#BF9000" : "#0070C0"}` }} key={i} onClick={() => openModal(k.id, k.exchanges, k.grp, k.public_key, k.secret_key)}>
                                    <td>{k.exchanges}</td>
                                    <td>{k.grp}</td>
                                    <td>{k.public_key === null ? "X" : k.public_key}</td>
                                    <td>{k.secret_key === null ? "X" : k.secret_key}</td>
                                    <td>{k.update_date}</td>
                                    <td>{k.created_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <br />
            </div>
            <br />
            <div>
                <Modal
                    open={modalOpen}
                    close={closeModal}
                    header={"KEY CHANGE - (" + modalData[1] + " " + modalData[2] + ")"}
                    footer={
                        modalInputValue_1 === null || modalInputValue_2 === null ? (
                            <>
                                <span style={{ fontSize: "13px", color: "red" }}>Key값을 넣어 주세요 &emsp;</span>
                                <button className="close" onClick={closeModal}>
                                    close
                                </button>
                            </>
                        ) : (
                            <>
                                <button style={{ background: "green" }} onClick={() => Change_keys()}>
                                    save
                                </button>
                                <button className="close" onClick={closeModal}>
                                    close
                                </button>
                            </>
                        )
                    }>
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <label htmlFor="p_key"> Public Key : </label>
                    <input type="text" id="p_key" name="p_key" style={{ width: "300px" }} defaultValue={modalInputValue_1} onChange={(e) => setModalInputValue_1(e.target.value)} />
                    <br />
                    <br />
                    <label htmlFor="p_key"> Secret Key : </label>
                    <input type="text" id="s_key" name="s_key" style={{ width: "300px" }} defaultValue={modalInputValue_2} onChange={(e) => setModalInputValue_2(e.target.value)} />
                </Modal>
            </div>
        </div>
    );
}

export default ApiMange_Future;

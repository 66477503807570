import React, { useState, useEffect } from "react";
import axios from "axios";

import BotStatus from "./BotStatus";
import BotStatus_new from "./BotStatus_new";
import CurrentBalance from "./CurrentBalance";
import Record from "./Record";

import { ExchangesSelector } from "../../../common/Commons";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

import "./botStatusCurrentBalance.css";
import { C_coins } from "../../../common/Commons";
import SellOrder from "./SellOrder";


function BotStatusCurrentBalance({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [exchangesSelect, setExchangesSelect] = useState(sessionStorage.getItem('sbot_dashboard_ex'));
    const [balanceStatus, setBalanceStatus] = useState(null);
    const [bsInsertDate, setBsInsertDate] = useState("");
    const [botCnt, setBotCnt] = useState([]);
    const [botStatus, setbotStatus] = useState([]);

    const [opt_coins, setOpt_coins] = useState(null);
    
    function coinsSet(_num) {
        setOpt_coins(_num)
    }

    useEffect(() => {
        C_coins(coinsSet)
    }, []);

    const CtrObject = (res) => {
        
        // get balance status
        //const bsj = JSON.parse(res.result_1.balance_data);
        const bsj = res.result_1.balance_data;
        const binance_list = [];
        const upbit_list = [];
        const bithumb_list = [];
        const okex_list = [];
        const mexc_list = [];
        const bitget_list = [];

        for (let i = 0; i <= 2; i++) {
            if (sessionStorage.getItem('sbot_dashboard_ex') =="1"){
                bsj["binance_" + i].forEach((element) => {
                    res.result_2.forEach((e2) => {
                        if (e2.exchanges_str === "BINANCE" && e2.strategy_grp === String(i + 1) && element.crypto === e2.coin_ticker) {
                            element.strategy_count = e2.strategy_count;
                            element.startegy_short_order_count = e2.startegy_short_order_count;
                            
                            //alert(element.strategy_count );
                            
                        }
                        if (e2.exchanges_str === "BINANCE" && e2.strategy_grp === String(1) && element.crypto === e2.coin_ticker) 
                        {
                            element.c_price = e2.c_price;
                            
                        }
                    });
                    binance_list.push(element);
                });
            }
            
            if (sessionStorage.getItem('sbot_dashboard_ex') =="2"){
                bsj["upbit_" + (i + 5)].forEach((element) => {
                res.result_2.forEach((e2) => {
                    if (e2.exchanges_str === "UPBIT" && e2.strategy_grp === String(i + 1) && element.crypto === e2.coin_ticker) {
                        element.strategy_count = e2.strategy_count;
                        element.startegy_short_order_count = e2.startegy_short_order_count;
                      
                    }
                    if (e2.exchanges_str === "UPBIT" && e2.strategy_grp === String(1) && element.crypto === e2.coin_ticker) 
                    {
                        element.c_price = e2.c_price;
                        
                    }
                });
                upbit_list.push(element);
            });
            }
            
            if (sessionStorage.getItem('sbot_dashboard_ex') =="3"){
                bsj["bithumb_" + (i + 10)].forEach((element) => {
                    res.result_2.forEach((e2) => {
                        if (e2.exchanges_str === "BITHUMB" && e2.strategy_grp === String(i + 1) && element.crypto === e2.coin_ticker) {
                            element.strategy_count = e2.strategy_count;
                            element.startegy_short_order_count = e2.startegy_short_order_count;
                           
                        }
                        if (e2.exchanges_str === "BITHUMB" && e2.strategy_grp === String(1) && element.crypto === e2.coin_ticker) 
                        {
                            element.c_price = e2.c_price;
                            
                        }
                    });
                    bithumb_list.push(element);
                });
            }
            
            if (sessionStorage.getItem('sbot_dashboard_ex') =="4"){
                bsj["okex_" + (i + 15)].forEach((element) => {
                    res.result_2.forEach((e2) => {
                        if (e2.exchanges_str === "OKEX" && e2.strategy_grp === String(i + 1) && element.crypto === e2.coin_ticker) {
                            element.strategy_count = e2.strategy_count;
                            element.startegy_short_order_count = e2.startegy_short_order_count;
                           
                        }
                        if (e2.exchanges_str === "OKEX" && e2.strategy_grp === String(1) && element.crypto === e2.coin_ticker) 
                        {
                            element.c_price = e2.c_price;
                            
                        }
                    });
                    okex_list.push(element);
                });
            }
            
            if (sessionStorage.getItem('sbot_dashboard_ex') =="5"){
                bsj["mexc_" + (i + 20)].forEach((element) => {
                    res.result_2.forEach((e2) => {
                        if (e2.exchanges_str === "MEXC" && e2.strategy_grp === String(i + 1) && element.crypto === e2.coin_ticker) {
                            element.strategy_count = e2.strategy_count;
                            element.startegy_short_order_count = e2.startegy_short_order_count;
                           
                        }
                        if (e2.exchanges_str === "MEXC" && e2.strategy_grp === String(1) && element.crypto === e2.coin_ticker) 
                        {
                            element.c_price = e2.c_price;
                            
                        }
                    });
                    mexc_list.push(element);
                });
            }
            if (sessionStorage.getItem('sbot_dashboard_ex') =="6"){ 
                bsj["bitget_" + (i + 25)].forEach((element) => {
                res.result_2.forEach((e2) => {
                    if (e2.exchanges_str === "BITGET" && e2.strategy_grp === String(i + 1) && element.crypto === e2.coin_ticker) {
                        element.strategy_count = e2.strategy_count;
                        element.startegy_short_order_count = e2.startegy_short_order_count;
                       
                    }
                    if (e2.exchanges_str === "BITGET" && e2.strategy_grp === String(1) && element.crypto === e2.coin_ticker) 
                    {
                        element.c_price = e2.c_price;
                        
                    }
                });
                bitget_list.push(element);
            });
            }
            
        }

        console.log("CtrObject end 1 !!!!")
        const bsArray = [binance_list, upbit_list, bithumb_list, okex_list, mexc_list, bitget_list];
        setBalanceStatus(bsArray);
        console.log("bsArray : " + (JSON.stringify(bsArray)))

        console.log("CtrObject end 2 !!!!")
        setBotCnt(res.result_2);

        console.log("CtrObject end 3 !!!!")
        setbotStatus(res.result_3);
    };

   
    function Check_CtrObject() {
        const info_total = { bot_id: "s_bot", msg: "get_bot_status_current_balance" ,exchanges : sessionStorage.getItem('sbot_dashboard_ex')};
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
            .then((res) => {
                CtrObject(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
       
        Check_CtrObject();
        const intervalId = setInterval(() => {
            Check_CtrObject();
        }, 7000); // 5000
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        sessionStorage.setItem("sbot_dashboard_ex", exchangesSelect);
        Check_CtrObject()
        //alert("BotStatusCurrentBalance.js , exchangesSelect : " + exchangesSelect)
    }, [exchangesSelect]);
    

    useEffect(() => {
        setTile("Auto Trading - Bot Status & Current Balance")
    }, []);

    return (
        <div>
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <ExchangesSelector exchangesSelect={exchangesSelect} send_esSet={setExchangesSelect} session_name={ "sbot_dashboard_ex" }/>

            <CurrentBalance exchangesSelect={exchangesSelect} bsInsertDate={bsInsertDate} balanceStatus={balanceStatus && balanceStatus[exchangesSelect - 1]} />
            <br />
            {!opt_coins && <p> Loading...</p>}
            {opt_coins && (
                <>
                <SellOrder opt_coins={opt_coins && opt_coins} exchangesSelect={exchangesSelect} />
                </>
            )}

            <br />
            {/* 
            <BotStatus exchangesSelect={exchangesSelect} botCnt={botCnt} balanceStatus={balanceStatus && balanceStatus[exchangesSelect - 1]} />
            <br />
            */}
            <BotStatus_new botStatus={botStatus} exchangesSelect={exchangesSelect} botCnt={botCnt} balanceStatus={balanceStatus && balanceStatus[exchangesSelect - 1]} />
            <br />
            <Record />
        </div>
    );
}

export default BotStatusCurrentBalance;

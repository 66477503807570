import React, { useState, useEffect } from "react";
import "./arbitrage.css";

import axios from "axios";
import dayjs from "dayjs";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { locale_opt } from "../../common/Commons";

function ABotStatusTodayDetail( { arbitrage_bod_id } ) {
  console.log("ABotStatusTodayDetail");
  console.log("ABotStatusTodayDetail arbitri_bot_id : "  + arbitrage_bod_id);


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const

  const [dataTodayArbitraseLog, setDataTodayArbitraseLog] = useState([]);
  const [newOld, setNewOld] = useState("desc");


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // function

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // useEffect

  // get today transaction log
  useEffect(() => {

    console.log("useEffect");

    const data = {
        bot_id: "arbitrage",
        msg: "get_arbitrage_log_detail",
        arbitrage_bod_id: arbitrage_bod_id,
        newOld: newOld
    };


    console.log("ABotStatusTodayDetail data : "  + data);

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/get_arbitrage_log_detail`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {

            // case ==>  return dict(msg="1", result=[arbitrage_data_list])
            console.log("res.data.result  : " + res.data.result)
            console.log("res.data.result  : " + JSON.stringify(res.data.result))
           
            res.data.result.map((arbitrage_list,i) => {
              arbitrage_list.map((arbitrage,i) => {
                console.log(arbitrage.arbitrage_root.arbitrage_bod_id);
              });
            });

            setDataTodayArbitraseLog(res.data.result);
        } else {
          setDataTodayArbitraseLog([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }, [arbitrage_bod_id]);


  ///////////////////////////////////////////////////////////////////////////////////
  // function

 

  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {/* botstatus content */}
      <div className="first_div">
        <div className="second_div_arbit_detail" style={{ justifyContent: 'center'} }>
        
          <div style={{width: "99%" }}>
            <div>
                <h6 style={{ margin: "0.5em 0px 0px 0em" }}>Detail Log</h6>
            </div>
             {dataTodayArbitraseLog.map((arbitrage_list,i) => (
                arbitrage_list.map((arbitrage,j) => (
                <div className="div_arbit"  style={{margin: "3.5em 0px 0px 0px",background: "#3f1f47" }}>
                  <><><table>
                    <thead>
                      <tr style={{ background: "#6bbaaC" }}>
                        <th>
                          Transaction Root Id
                        </th>
                        <th>
                          Arbitrage Bod ID
                        </th>
                        <th>
                          Net Profit Amount
                        </th>
                        <th>
                          Net Profit%
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{arbitrage.arbitrage_root.transaction_root_id}</td>
                        <td>{arbitrage.arbitrage_root.arbitrage_bod_id}</td>
                        <td>​{arbitrage.arbitrage_root.profit_amount}&nbsp;USDT</td>
                        <td>​{arbitrage.arbitrage_root.profit_rate}&nbsp;%</td>
                      </tr>
                    </tbody>
                  </table><br></br></><table>
                      <><thead>
                        <tr style={{ background: "#6CA0EC" }}>
                          <th>
                            Buy Traded date time
                          </th>
                          <th>
                            Coin
                          </th>
                          <th>
                            Buy Exchage
                          </th>
                          <th>
                            Speed Injected
                          </th>
                          <th>
                            Traded Amount
                          </th>
                          {/*
                          <th>
                            Traded Price
                          </th>
                          */}
                          <th>
                            Order Type
                          </th>
                          <th>
                            Before Fiat
                          </th>
                          <th>
                            After Fiat
                          </th>
                          <th>
                            Before Cripto Qty
                          </th>
                          <th>
                            Afer Cripto Qty
                          </th>
                        </tr>
                      </thead><tbody>
                          <tr>
                            <td>{arbitrage.buy_arbitrage.create_date_time}</td>
                            <td>{arbitrage.buy_arbitrage.coin.toUpperCase()}</td>
                            <td>{arbitrage.buy_arbitrage.buy_exchange_id}</td>
                            <td>{arbitrage.buy_arbitrage.seed_injected}</td>
                            <td>{arbitrage.buy_arbitrage.traded_amount}</td>
                            {/*
                            <td>{arbitrage.buy_arbitrage.traded_price}</td>
                            */}
                            <td>{arbitrage.buy_arbitrage.order_type}</td>
                            <td>{arbitrage.buy_arbitrage.initial_wallet_fiat}</td>
                            <td>{arbitrage.buy_arbitrage.total_wallet_fiat}</td>
                            <td>{arbitrage.buy_arbitrage.initial_wallet_crypto}</td>
                            <td>{arbitrage.buy_arbitrage.total_wallet_crypto}</td>
                          </tr>
                        </tbody></>
                    </table><><br></br>
                      <table>
                        <thead>
                          <tr style={{ background: "#6CA0EC" }}>
                            <th>
                              Sell Traded date time
                            </th>
                            <th>
                              Coin
                            </th>
                            <th>
                              Sell Exchage
                            </th>
                            <th>
                              Speed Injected
                            </th>
                            <th>
                              Traded Amount
                            </th>
                            <th>
                              Traded Price
                            </th>
                            <th>
                              Order Type
                            </th>
                            <th>
                              Before Fiat
                            </th>
                            <th>
                              Afer Fiat
                            </th>
                            <th>
                              Before Cripto Qty
                            </th>
                            <th>
                              Afer Cripto Qty
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{arbitrage.sell_arbitrage.create_date_time}</td>
                            <td>{arbitrage.sell_arbitrage.coin}</td>
                            <td>{arbitrage.sell_arbitrage.sell_exchange_id}</td>
                            <td>{arbitrage.sell_arbitrage.seed_injected}</td>
                            <td>{arbitrage.sell_arbitrage.traded_amount}</td>
                            <td>{arbitrage.sell_arbitrage.traded_price}</td>
                            <td>{arbitrage.sell_arbitrage.order_type}</td>
                            <td>{arbitrage.sell_arbitrage.initial_wallet_fiat}</td>
                            <td>{arbitrage.sell_arbitrage.total_wallet_fiat}</td>
                            <td>{arbitrage.sell_arbitrage.initial_wallet_crypto}</td>
                            <td>{arbitrage.sell_arbitrage.total_wallet_crypto}</td>
                          </tr>
                        </tbody>
                      </table></></>
                    </div>
              ))
              ))}
          </div>
      </div> {/* end second div */}                                
    </div>  {/* end first div */}
  </div> 
);
}

export default ABotStatusTodayDetail;


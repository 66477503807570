import React, { useState } from "react";
import "./pairingRecords.css";

import Switch from "react-switch";

import { c_opt_exchanges_all, c_opt_strategy_grp_all, c_opt_tfs_all } from "../../../common/Commons";

const Chart = ({ opt_coins }) => {
    // OPTIONS
    const [exchanges, setExchanges] = useState("all");
    const [coins, setCoins] = useState("all");
    const [groups, setGroups] = useState("all");
    const [strategy, setStrategy] = useState("");
    const [tfs, setTfs] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [newOld, setNewOld] = useState("desc");

    // common
    const opt_exchanges = c_opt_exchanges_all
    const opt_groups = c_opt_strategy_grp_all
    const opt_tfs = c_opt_tfs_all
    // common

    // END OPTION
    const [toggle, setToggle] = useState(false);
    const switchToggle = (checked) => {
        setToggle(checked);
    };
    
    return (
        <div>
            <div className="first_div">
                <h4>Paring Charts​</h4>
                <br />
                <div style={{ display: "flex" }}>
                    &emsp;<h6>Simple</h6>
                    &emsp;
                    <Switch onColor="#86d3ff" handleDiameter={15} uncheckedIcon={false} checkedIcon={false} height={20} width={40} onChange={switchToggle} checked={toggle} />
                    &emsp;
                    <h6>Compound</h6>
                </div>
                <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                    <div>
                        <span>Exchange:</span>
                        <select
                            onChange={(e) => {
                                setExchanges(e.target.value);
                            }}>
                            {opt_exchanges.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <span>Coin: </span>
                        <select
                            onChange={(e) => {
                                setCoins(e.target.value);
                            }}>
                            {opt_coins.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <span>Group: </span>
                        <select
                            onChange={(e) => {
                                setGroups(e.target.value);
                            }}>
                            {opt_groups.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <span>Strategy: </span>
                        <input
                            type="text"
                            placeholder="Typing Strategy Name"
                            onChange={(e) => {
                                setStrategy(e.target.value);
                            }}
                        />
                        &emsp;
                        <span>TF: </span>
                        <select
                            onChange={(e) => {
                                setTfs(e.target.value);
                            }}>
                            {opt_tfs.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <span>From: </span>
                        <input type="date" name="from" style={{ margin: "0 1em 0 0" }} onChange={(e) => setDateFrom(e.target.value)} />
                        <span>To: </span>
                        <input type="date" name="to" onChange={(e) => setDateTo(e.target.value + " 23:59:59")} />
                    </div>
                    <br />
                </div>

            </div>
        </div>
    );
};

export default Chart;

import React, { useState, useEffect } from "react";
import axios from "axios";

import "./arbitrage.css";
import ArbitrageTicker from "../liveTicker/ArbitrageTicker";
import ABotStatus from "./ABotStatus";
import ABotStatusTodayMain from "./ABotStatusTodayMain";
import HeaderNew from "../../headerFooterSide/HeaderNew";

function Arbitrage({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [usdKrw, setUsdKrw] = useState()
    const [afterResult, setAfterResult] = useState([])
    const [afterResult_ArbitrageCoins, setAfterResult_ArbitrageCoins] = useState([])

    function get_live_ticker() {
        const info_total = { msg: "get_live_ticker" };
        axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/live_ticker`, info_total)
        .then((res) => {
            const result = res.data.result
            const result2 = res.data.result2
            setUsdKrw(result[0].c_price)
            result.shift()
            setAfterResult(result)
            setAfterResult_ArbitrageCoins(result2)
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        setTile("Arbitrage  Dashboard")
        get_live_ticker()
        const intervalId = setInterval(() => {
            get_live_ticker()
        }, 2000)

        return () => clearInterval(intervalId)
    }, []);

    return (
        <>
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <div className="main">
            </div>
            <ArbitrageTicker usdKrw={usdKrw && usdKrw} afterResult={afterResult && afterResult} afterResult_ArbitrageCoins={afterResult_ArbitrageCoins && afterResult_ArbitrageCoins} />
            <ABotStatus />
            <ABotStatusTodayMain/>
            
        </>
    );
}

export default Arbitrage;

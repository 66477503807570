import React, { useState, useEffect } from "react";

import sentinel_main_img from "../../images/sentinel.svg";
import "./login.css";

import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

import TradingViewDemo from "./TradingViewDemo";

import BackTestingDemo_V2 from "../../components/pages/backtesting/BackTestingDemo_V2";

const Login = ({ handleLogin }) => {
    // window.history.pushState(null, null, "/")
    axios.defaults.withCredentials = false;

    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
    const [auth, setAuth] = useState(null);
    const [click_cnt, setClick_cnt] = useState(0);
    const [showTradingView, set_showTradingView] = useState(false);

    const goToTradingView = () => {
        set_showTradingView(true)
    }

    const Go_add_member = () => {
        if (click_cnt < 10) {
            setClick_cnt(click_cnt+1)
        }else{
            setClick_cnt(0)
            window.location.replace("/addmember")
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem("user_id") === null) {
            handleLogin(false);
        } else {
            handleLogin(true);
        }
        Aos.init({});
    }, [handleLogin]);

    const set_all = (event) => {
        event.preventDefault();
        // console.dir(event)

        const id = document.getElementById("input_id").value;
        const pw = document.getElementById("input_pwd").value;

        const data = { msg: "login_first", id, pw, auth };

        axios
            .post(`${python_server_url}/auth`, data)
            .then((res) => {
                if (res.data.msg === "1") {
                    sessionStorage.setItem("user_id", data.id);
                    
                    sessionStorage.setItem("sbot_dashboard_ex", "1");
                    sessionStorage.setItem("sbot_daily_balance_ex", "1");
                    sessionStorage.setItem("sbot_daily_balance_history_ex", "1");
                    sessionStorage.setItem("sbot_manualtrading_sell", "1");
                    sessionStorage.setItem("sbot_manualtrading_buy", "1");
                    sessionStorage.setItem("sbot_walletAssetRegister", "1");
                    sessionStorage.setItem("sbot_walletAssetRecords", "all");


                    sessionStorage.setItem("pbot_dashboard_ex", "1");
                    sessionStorage.setItem("pbot_daily_balance_ex", "1");
                    sessionStorage.setItem("pbot_daily_balance_history_ex", "1");
                    sessionStorage.setItem("pbot_manualtrading_sell", "1");
                    sessionStorage.setItem("pbot_manualtrading_buy", "1");
                    sessionStorage.setItem("pbot_walletAssetRegister", "1");
                    sessionStorage.setItem("pbot_walletAssetRecords", "all");


                    sessionStorage.setItem("pbot_stk_dashboard_ex", "1");
                    sessionStorage.setItem("pbot_stk_daily_balance_ex", "1");
                    sessionStorage.setItem("pbot_stk_daily_balance_history_ex", "1");
                    sessionStorage.setItem("pbot_stk_manualtrading_sell", "1");
                    sessionStorage.setItem("pbot_skt_manualtrading_buy", "1");
                    sessionStorage.setItem("pbot_stk_walletAssetRegister", "1");
                    sessionStorage.setItem("pbot_stk_walletAssetRecords", "all");



                    sessionStorage.setItem("fbot_dashboard_ex", "1");
                    sessionStorage.setItem("fbot_daily_balance_ex", "1");
                    sessionStorage.setItem("fbot_daily_balance_history_ex", "1");
                    sessionStorage.setItem("fbot_manualtrading_sell", "1");
                    sessionStorage.setItem("fbot_manualtrading_buy", "1");
                    sessionStorage.setItem("fbot_walletAssetRegister", "1");
                    sessionStorage.setItem("fbot_walletAssetRecords", "all");


                    sessionStorage.setItem("fbot_stk_dashboard_ex", "1");
                    sessionStorage.setItem("fbot_stk_daily_balance_ex", "1");
                    sessionStorage.setItem("fbot_stk_daily_balance_history_ex", "1");
                    sessionStorage.setItem("fbot_stk_manualtrading_sell", "1");
                    sessionStorage.setItem("fbot_stk_manualtrading_buy", "1");
                    sessionStorage.setItem("fbot_stk_walletAssetRegister", "1");
                    sessionStorage.setItem("fbot_stk_walletAssetRecords", "all");



                    sessionStorage.setItem("mbot_dashboard_ex", "1");
                    sessionStorage.setItem("mbot_daily_balance_ex", "1");
                    sessionStorage.setItem("mbot_daily_balance_history_ex", "1");
                    sessionStorage.setItem("mbot_manualtrading_sell", "1");
                    sessionStorage.setItem("mbot_manualtrading_buy", "1");
                    sessionStorage.setItem("mbot_walletAssetRegister", "1");
                    sessionStorage.setItem("mbot_walletAssetRecords", "all");

                    //window.location.replace("/liveTicker");
                    window.location.replace("/DashBoard");
                } else {
                    alert("Wrong information. Please try again");
                }
            })
            .catch((err) => {
                console.log(err);
                alert("Wrong information. Please try again");
            });
    };

    return (
        <>
        {showTradingView && (<BackTestingDemo_V2></BackTestingDemo_V2>)}
        <div className="login_bg">
            <div className="login_content_div">
                <div onClick={() => goToTradingView()}>
                   Demo !!! Click This!
                </div>

                <div className="login_img_div">
                    <img src={sentinel_main_img} alt="" style={{ width: "250px", margin: "1em" }} 
                    data-aos="fade-up" data-aos-easing="ease-in" data-aos-duration="1000" onClick={() => Go_add_member()}/>
                </div>

                <div data-aos="fade-down" data-aos-easing="ease-in" data-aos-duration="1000">
                    <form onSubmit={set_all}>
                        <div className={"login_input_div"}>
                            <input id={"input_id"} className={"login"} type={"text"} autoComplete="username" style={{ textTransform: "lowercase" }} required />
                            <span className="floating-label" style={{ color: "#fff" }}>
                                {" "}
                                ID{" "}
                            </span>
                        </div>
                        <br />
                        <div className="login_input_div">
                            <input id={"input_pwd"} className="login" type="password" autoComplete="current-password" required />
                            <span className="floating-label" style={{ color: "#fff" }}>
                                {" "}
                                Password{" "}
                            </span>
                        </div>
                        <br />
                        <div className="login_input_div">
                            <input className="login" type="text" onChange={(e) => setAuth(e.target.value)} required maxLength="6" />
                            <span className="floating-label" style={{ color: "#fff" }}>
                                OTP Code
                            </span>
                        </div>
                        <br />
                        <div className="submit">
                            <button type="submit" className="btn btn-primary" style={{ height: "30px", width: "63%" }} onClick={set_all}>
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    );
};

export default Login;

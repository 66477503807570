import React from "react";

import "./liveTicker.css";
import { locale_opt } from "../../common/Commons";

function SbotTicker({ afterResult }) {
    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ display: "flex", margin: "0px 0px 1em" }}>
                        <h5 style={{ margin: "0px 3.5em 0px 0px" }}>Ticker</h5>
                    </div>

                    <div style={{ display: "flex",  width: "95%", margin: "0 auto"}}>
                        <div style={{ width: "16.5%" }}>
                            <h6>Binance</h6>
                            <table>
                                <thead>
                                    <tr style={{ background: "#4472C4" }}>
                                        <th>Asset</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {afterResult &&
                                        afterResult.map((v, i) =>
                                            v.exchanges === "1" ? (
                                                <tr key={i} style={{ background: i%2 === 0 ? "#0B2F24​" :"#114B39" }}>
                                                    <td style={{ width: "30%" }}>{v.coin_ticker.toUpperCase()}</td>
                                                    <td>{parseFloat(v.c_price)}</td>
                                                </tr>
                                            ) : null
                                        )}
                                </tbody>
                            </table>
                        </div>
                        &emsp;
                        <div style={{ width: "16.5%" }}>
                            <h6>Upbit</h6>
                            <table>
                                <thead>
                                    <tr style={{ background: "#4472C4" }}>
                                        <th>Asset</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {afterResult &&
                                        afterResult.map((v, i) =>
                                            v.exchanges === "2" ? (
                                                <tr key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                                    <td style={{ width: "30%" }}>{v.coin_ticker.toUpperCase()}</td>
                                                    <td>{parseFloat(v.c_price)}</td>
                                                </tr>
                                            ) : null
                                        )}
                                </tbody>
                            </table>
                        </div>
                        &emsp;
                        <div style={{ width: "16.5%" }}>
                            <h6>Bithumb</h6>
                            <table>
                                <thead>
                                    <tr style={{ background: "#4472C4" }}>
                                        <th>Asset</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {afterResult &&
                                        afterResult.map((v, i) =>
                                            v.exchanges === "3" ? (
                                                <tr key={i} style={{ background: i%2 === 0 ? "#271137​" :"#795115" }}>
                                                    <td style={{ width: "30%" }}>{v.coin_ticker.toUpperCase()}</td>
                                                    <td>{parseFloat(v.c_price).toLocaleString("ko-KR", locale_opt)}</td>
                                                </tr>
                                            ) : null
                                        )}
                                </tbody>
                            </table>
                        </div>
                        &emsp;
                        <div style={{ width: "16.5%" }}>
                            <h6>OKex</h6>
                            <table>
                                <thead>
                                    <tr style={{ background: "#4472C4" }}>
                                        <th>Asset</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {afterResult &&
                                        afterResult.map((v, i) =>
                                            v.exchanges === "4" ? (
                                                <tr key={i} style={{ background: i%2 === 0 ? "#271137​" :"#3C1B55" }}>
                                                    <td style={{ width: "30%" }}>{v.coin_ticker.toUpperCase()}</td>
                                                    <td>{parseFloat(v.c_price).toLocaleString("ko-KR", locale_opt)}</td>
                                                </tr>
                                            ) : null
                                        )}
                                </tbody>
                            </table>
                        </div>
                        &emsp;
                        <div style={{ width: "16.5%" }}>
                            <h6>Mexc</h6>
                            <table>
                                <thead>
                                    <tr style={{ background: "#4472C4" }}>
                                        <th>Asset</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {afterResult &&
                                        afterResult.map((v, i) =>
                                            v.exchanges === "5" ? (
                                                <tr key={i} style={{ background: i%2 === 0 ?  "#0B2F24​" :"#114B39"  }}>
                                                    <td style={{ width: "30%" }}>{v.coin_ticker.toUpperCase()}</td>
                                                    <td>{parseFloat(v.c_price).toLocaleString("ko-KR", locale_opt)}</td>
                                                </tr>
                                            ) : null
                                        )}
                                </tbody>
                            </table>
                        </div>
                        &emsp;
                        <div style={{ width: "16.5%" }}>
                            <h6>Bitget</h6>
                            <table>
                                <thead>
                                    <tr style={{ background: "#4472C4" }}>
                                        <th>Asset</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {afterResult &&
                                        afterResult.map((v, i) =>
                                            v.exchanges === "6" ? (
                                                <tr key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                                    <td style={{ width: "30%" }}>{v.coin_ticker.toUpperCase()}</td>
                                                    <td>{parseFloat(v.c_price).toLocaleString("ko-KR", locale_opt)}</td>
                                                </tr>
                                            ) : null
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div> 
                    <br />
                </div>
            </div>
        </div>
    );
}

export default SbotTicker;

import React, { useState, useEffect } from "react";
import axios from "axios";

import { ExchangesSelector, locale_opt, c_opt_strategy_grp, c_opt_tfs, c_opt_order_type, c_opt_order_qty_percent, c_opt_pair_is } from "../../../common/Commons";

function BuyOrder({ opt_coins }) {
    const [exchangesSelect, setExchangesSelect] = useState(sessionStorage.getItem("mbot_manualtrading_buy"));

    const [pair_is, setPair_is] = useState("yes");
    const [bot_cnt, setBot_cnt] = useState("1");
    const [coins, setCoins] = useState("ada");
    const [groups, setGroups] = useState("1");
    const [orderType, setOrderType] = useState("limit");

    const [trigger_price, setTrigger_price] = useState("");
    const [order_price, setOrder_price] = useState("");

    const [limit_order_price, setLimit_order_price] = useState("");
    const [limit_order_qty, setLimit_order_qty] = useState("");

    const [market_order_price, setMarket_order_price] = useState("");
    
    const [auto_limit_triger_price, setAutoLimit_triger_price] = useState("");
    const [auto_limit_order_price, setAutoLimit_order_price] = useState("");
    
    const [auto_market_trigger_price, setAutoMarket_triger_price] = useState("");
    const [auto_market_order_qty, setAutoMarket_order_price] = useState("");

    const [order_qty, setOrder_qty] = useState(0);

    const [qty_percent, setQty_percent] = useState("q");

    const [tfs, setTfs] = useState("1");
    const [strategy_input, setStrategy_input] = useState("");

    const [c_price, setC_price] = useState("");
    const [c_crypto, setC_crypto] = useState("");
    const [c_fiat, setC_fiat] = useState("");

    const opt_pair_is = c_opt_pair_is
    const opt_groups = c_opt_strategy_grp;
    const opt_tfs = c_opt_tfs;
    const opt_order_type = c_opt_order_type;
    const opt_order_percent = c_opt_order_qty_percent;

    const Fire = () => {
        if (strategy_input !== "" && order_qty !== 0) {
            let groups_name
            groups === "1" ? groups_name = "MLC" : groups === "2" ? groups_name = "P2V" : groups_name = "TBT"
            
            let order_upbit_buy = 0

            // upbit : limit, market 매수시 - 주문할 돈 ; okex - market 매수 - 주문할 돈
            if (orderType === "limit" || orderType === "autolimit"){
                if (qty_percent === "p"){
                    order_upbit_buy = parseFloat(order_price) * parseFloat(order_qty)
                }else if (qty_percent === "q") {
                    order_upbit_buy = parseFloat(order_price) * parseFloat(order_qty)
                }
            }
            else if (orderType === "market" || orderType === "automarket"){
                if (qty_percent === "p"){
                    order_upbit_buy = parseFloat(c_price * parseFloat(order_qty))
                }else if (qty_percent === "q") {
                    order_upbit_buy = order_qty * c_price
                }
            }

            const data = {
                bot_id: "m_bot",
                msg: "manual_trading",
                what: "long_short",
                position: "BUY",
                pair_is: pair_is,
                bot_cnt: pair_is === "yes" ? "1/"+bot_cnt : "0/0",
                exchange_no: exchangesSelect,
                coin_type: coins.toUpperCase(),
                strategy_group: groups,
                time_frame: tfs,
                strategy_name: groups_name+" "+strategy_input+" "+tfs,
                order_type: orderType,
                operation_type: "manual",
                trigger_price: orderType === "autolimit" || orderType === "automarket" ? trigger_price : "",
                price: orderType === "market" ? c_price : order_price,
                order_upbit_qty_price: (exchangesSelect === "2" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6") ? order_upbit_buy : "",
                crypto_qty: parseFloat(order_qty).toFixed(4),
                initial_wallet_crypto: parseFloat(c_crypto),
                initial_wallet_fiat: parseFloat(c_fiat),
            };
            axios
                .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
                .then((res) => {
                    alert(res.data);
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("Strategy Name Invalid");
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const data = {
                bot_id: "m_bot",
                msg: "manual_trading",
                what: "get_c_price",
                exchange: exchangesSelect,
                coin: coins,
            };
            axios
                .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
                .then((res) => {
                    setC_price(res.data.c_price);
                })
                .catch((err) => {
                    console.log(err);
                });
        }, 2000)
        return () => clearInterval(intervalId)
    }, [exchangesSelect, coins]);
    
    useEffect(() => {
        setTrigger_price("");
        setOrder_price("");
        setOrder_qty("");
        if (orderType === "limit") {
            document.getElementById("limit_order_price").value = c_price;
            document.getElementById("limit_order_qty").value = "";
        } else if (orderType === "market") {
            document.getElementById("market_order_qty").value = "";
        } else if (orderType === "autolimit") {
            document.getElementById("auto_limit_triger_price").value = c_price;
            document.getElementById("auto_limit_order_price").value = c_price;
            document.getElementById("auto_limit_order_qty").value = "";
        } else if (orderType === "automarket") {
            document.getElementById("auto_market_trigger_price").value = c_price;
            document.getElementById("auto_market_order_qty").value = "";
        }
    }, [orderType]);

    useEffect(() => {
        const data = {
            bot_id: "m_bot",
            msg: "manual_trading",
            what: "get_info",
            exchange: exchangesSelect,
            coin: coins,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
               
                setC_price(res.data.c_price);

                setLimit_order_price(res.data.c_price)
                setLimit_order_qty(res.data.c_price)

                setMarket_order_price(res.data.c_price)
                
                setAutoLimit_triger_price(res.data.c_price)
                setAutoLimit_order_price(res.data.c_price)

                setAutoMarket_triger_price(res.data.c_price)
                setAutoMarket_order_price(res.data.c_price)

                let c_c_f = JSON.parse(String(res.data.c_balance));
                //alert(JSON.stringify(c_c_f))

                let exchange_style, where_style, fiat_style;
                if (exchangesSelect === "1") {
                    exchange_style = "binance_";
                    where_style = +4; // 4
                    fiat_style = "USDT";
                } else if (exchangesSelect === "2") {
                    exchange_style = "upbit_";
                    where_style = +9; // 9 
                    fiat_style = "KRW";
                } else if (exchangesSelect === "3") {
                    exchange_style = "bithumb_";
                    where_style = +14; // 14
                    fiat_style = "KRW";
                } else if (exchangesSelect === "4") {
                    exchange_style = "okex_";
                    where_style = +19; // 19
                    fiat_style = "USDT";
                }else if (exchangesSelect === "5") {
                    exchange_style = "mexc_";
                    where_style = +24; // 24
                    fiat_style = "USDT";
                }else if (exchangesSelect === "6") {
                    exchange_style = "bitget_";
                    where_style = +28; // 28
                    fiat_style = "USDT";
                }

                c_c_f[exchange_style + where_style].forEach((element) => {
                    
                    //alert(JSON.stringify(coins.toUpperCase()))
                    //alert(JSON.stringify(element.crypto))
                    //alert(JSON.stringify(element.free))
                    if (element.crypto === coins.toUpperCase()) {
                        setC_crypto(element.free);
                    }
                    if (element.crypto === fiat_style) {
                        setC_fiat(element.free);
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exchangesSelect, coins, groups]);

    const Bot_count = () => {
        return(
            <>
            <span>Bot Count: </span>
            <input
                type={"number"}
                style={{ width: "45px" }}
                value={bot_cnt}
                onChange={(e) => {
                    setBot_cnt(e.target.value);
                }}></input>
            &emsp;&emsp;&emsp;&emsp;
            </>
        )
    }

    const Line_2_limit = () => {
        return (
            <>
                <span>Order Price: </span>
                <input
                    id="limit_order_price"
                    type={"number"}
                    min={limit_order_price}
                    step={0.1} 
                    style={{ width: "100px" }}
                    onChange={(e) => {
                        setOrder_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4"  || exchangesSelect === "5"  || exchangesSelect === "6" ? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
                <span>Order Qty: </span>
                <input
                    id="limit_order_qty"
                    type={"number"}
                    min={40}
                    skip={0.1}
                    style={{ width: "100px" }}
                    placeholder= {qty_percent === "p" ? "MAX 99.9": ""}
                    onChange={(e) => {
                        
                        qty_percent === "p" ? setOrder_qty((parseFloat(c_crypto) * (parseFloat(e.target.value) / 100)) ) : setOrder_qty(e.target.value)
                    }}></input>
                &emsp;
                <select
                    onChange={(e) => {
                        setQty_percent(e.target.value);
                    }}>
                    {opt_order_percent.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
                &emsp;&emsp;&emsp;&emsp;
                {qty_percent === "p" ? (
                    parseFloat(order_price) * parseFloat(order_qty) > 0 ? (
                        <span>
                            Total Crypto: {parseFloat(order_qty).toLocaleString("ko-KR", locale_opt)} {coins}
                            &emsp;&emsp; Total Amount: {parseFloat(parseFloat(order_price) * parseFloat(order_qty)).toLocaleString("ko-KR", locale_opt)}
                            {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6" ? "\t usdt" : "\t krw"}
                        </span>
                    ) : null
                ) : parseFloat(order_price) * parseFloat(order_qty) > 0 ? (
                    <span>
                        Total Crypto: {parseFloat(order_qty).toLocaleString("ko-KR", locale_opt)} {coins}
                        &emsp;&emsp; Total Amount: {parseFloat(parseFloat(order_price) * parseFloat(order_qty)).toLocaleString("ko-KR", locale_opt)}
                        {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6" ? "\t usdt" : "\t krw"}
                    </span>
                ) : null}
            </>
        );
    };

    const Line_2_market = () => {
        return (
            <>
                <span>Order Qty: </span>
                <input
                    id="market_order_qty"
                    type={"number"}
                    min={market_order_price}
                    style={{ width: "100px" }}
                    step={0.1} 
                    placeholder= {qty_percent === "p" ? "MAX 99.9": ""}
                    onChange={(e) => {
                        qty_percent === "p" ? setOrder_qty((((parseFloat(c_fiat) * parseFloat(e.target.value) / 100))/c_price).toFixed(6)) : setOrder_qty(e.target.value)
                    }}></input>
                &emsp;
                <select
                    onChange={(e) => {
                        setQty_percent(e.target.value);
                    }}>
                    {opt_order_percent.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
                &emsp;&emsp;&emsp;&emsp;
                <span>Projection Amount: {parseFloat(order_qty).toLocaleString("ko-KR", locale_opt)}</span>
                <span> {coins}</span>
            </>
        );
    };

    const Line_2_auto_limit = () => {
        return (
            <>
                <span>Trigger Price: </span>
                <input
                    id="auto_limit_triger_price"
                    type={"number"}
                    min={auto_limit_triger_price}
                    skip={0.1}
                    style={{ width: "100px" }}
                    onChange={(e) => {
                        setTrigger_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6"? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
                <span>Order Price: </span>
                <input
                    id="auto_limit_order_price"
                    type={"number"}
                    min={auto_limit_order_price}
                    step={0.1}
                    style={{ width: "100px" }}
                    onChange={(e) => {
                        setOrder_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6" ? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
                <span>Order Qty: </span>
                <input
                    id="auto_limit_order_qty"
                    type={"number"}
                    min={40}
                    skip={0.1}
                    style={{ width: "100px" }}
                    placeholder= {qty_percent === "p" ? "MAX 99.9": ""}
                    onChange={(e) => {
                        qty_percent === "p" ? setOrder_qty((parseFloat(c_fiat) * (parseFloat(e.target.value) / 100)) / order_price) : setOrder_qty(e.target.value)
                    }}></input>
                &emsp;
                <select
                    onChange={(e) => {
                        setQty_percent(e.target.value);
                    }}>
                    {opt_order_percent.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
                &emsp;&emsp;&emsp;&emsp;
                {qty_percent === "p" ? (
                    parseFloat(order_price) * parseFloat(order_qty) > 0 ? (
                        <span>
                            Total Crypto: {parseFloat(order_qty).toLocaleString("ko-KR", locale_opt)} {coins}
                            &emsp;&emsp; Total Amount: {parseFloat(parseFloat(order_price) * parseFloat(order_qty)).toLocaleString("ko-KR", locale_opt)}
                            {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6" ? "\t usdt" : "\t krw"}
                        </span>
                    ) : null
                ) : parseFloat(order_price) * parseFloat(order_qty) > 0 ? (
                    <span>
                        Total Crypto: {parseFloat(order_qty).toLocaleString("ko-KR", locale_opt)} {coins}
                        &emsp;&emsp; Total Amount: {parseFloat(parseFloat(order_price) * parseFloat(order_qty)).toLocaleString("ko-KR", locale_opt)}
                        {exchangesSelect === "1" || exchangesSelect === "4"|| exchangesSelect === "5"|| exchangesSelect === "6" ? "\t usdt" : "\t krw"}
                    </span>
                ) : null}
            </>
        );
    };

    const Line_2_auto_market = () => {
        return (
            <>
                <span>Trigger Price: </span>
                <input
                    id="auto_market_trigger_price"
                    type={"number"}
                    min={auto_market_trigger_price}
                    skip={0.1}
                    style={{ width: "100px" }}
                    onChange={(e) => {
                        setTrigger_price(e.target.value);
                        setOrder_price(e.target.value);
                    }}></input>
                {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5" || exchangesSelect === "6" ? "\t USDT" : "\t KRW"}
                &emsp;&emsp;&emsp;&emsp;
                <span>Order Qty: </span>
                <input
                    id="auto_market_order_qty"
                    type={"number"}
                    min={40}
                    skip={1}
                    style={{ width: "100px" }}
                    placeholder= {qty_percent === "p" ? "MAX 99.9": ""}
                    onChange={(e) => {
                        qty_percent === "p" ? setOrder_qty((parseFloat(c_fiat) * (parseFloat(e.target.value) / 100)) / order_price) : setOrder_qty(e.target.value)
                    }}></input>
                &emsp;
                <select
                    onChange={(e) => {
                        setQty_percent(e.target.value);
                    }}>
                    {opt_order_percent.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
                &emsp;&emsp;&emsp;&emsp;
                {qty_percent === "p" ? (
                    parseFloat(order_price) * parseFloat(order_qty) > 0 ? (
                        <span>
                            Total Crypto: {parseFloat(order_qty).toLocaleString("ko-KR", locale_opt)} {coins}
                            &emsp;&emsp; Total Amount: {parseFloat(parseFloat(order_price) * parseFloat(order_qty)).toLocaleString("ko-KR", locale_opt)}
                            {exchangesSelect === "1" || exchangesSelect === "4"  || exchangesSelect === "5"  || exchangesSelect === "6" ? "\t usdt" : "\t krw"}
                        </span>
                    ) : null
                ) : parseFloat(order_price) * parseFloat(order_qty) > 0 ? (
                    <span>
                        Total Crypto: {parseFloat(order_qty).toLocaleString("ko-KR", locale_opt)} {coins}
                        &emsp;&emsp; Total Amount: {parseFloat(parseFloat(order_price) * parseFloat(order_qty)).toLocaleString("ko-KR", locale_opt)}
                        {exchangesSelect === "1" || exchangesSelect === "4" || exchangesSelect === "5"  || exchangesSelect === "6"  ? "\t usdt" : "\t krw"}
                    </span>
                ) : null}
            </>
        );
    };


    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div>
                        <h5>Buy Order</h5>
                        <div>
                            <ExchangesSelector exchangesSelect={exchangesSelect} send_esSet={setExchangesSelect} session_name={"mbot_manualtrading_buy"} />
                        </div>
                        <div>
                            <span>Coin Price : {parseFloat(c_price).toLocaleString("ko-KR", locale_opt)}</span>
                            &emsp;&emsp;
                            <span>Crypto : {parseFloat(c_crypto).toLocaleString("ko-KR", locale_opt)}</span>
                            &emsp;&emsp;
                            <span>Fiat : {parseFloat(c_fiat).toLocaleString("ko-KR", locale_opt)}</span>
                        </div>
                        <br />
                        <div>
                            <span style={{ color: "#FFC000" }}>PAIR: </span>
                            <select
                                onChange={(e) => {
                                    setPair_is(e.target.value);
                                }}>
                                {opt_pair_is.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            &emsp;&emsp;&emsp;&emsp;
                            {/* {pair_is === "yes" ? Bot_count() : null}                             */}
                            <span>Asset: </span>
                            <select
                                onChange={(e) => {
                                    setCoins(e.target.value);
                                }}>
                                {opt_coins.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            &emsp;&emsp;&emsp;&emsp;
                            <span>Strategy: </span>
                            <select
                                onChange={(e) => {
                                    setGroups(e.target.value);
                                }}>
                                {opt_groups.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            &emsp;&emsp;&emsp;&emsp;
                            <span>Time Frame: </span>
                            <select
                                onChange={(e) => {
                                    setTfs(e.target.value);
                                }}>
                                {opt_tfs.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            &emsp;&emsp;&emsp;&emsp;
                            <span>Strategy Name: </span>
                            <input
                                type={"text"}
                                style={{ width: "300px" }}
                                placeholder="MLC xxxxxx 15"
                                onChange={(e) => {
                                    setStrategy_input(e.target.value.trim());
                                }}></input>
                        </div>
                        {/* LINE 2 */}
                        {/* LINE 2 */}
                        <br />
                        <div>
                            <span>Order Type: </span>
                            <select
                                onChange={(e) => {
                                    setOrderType(e.target.value);
                                }}>
                                {opt_order_type.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            &emsp;&emsp;&emsp;&emsp;
                            {orderType === "limit" ? Line_2_limit() : orderType === "market" ? Line_2_market() : orderType === "autolimit" ? Line_2_auto_limit() : Line_2_auto_market()}
                            &emsp;&emsp;&emsp;&emsp;
                            <button className="btn btn-warning btn-sm" onClick={() => Fire()}>
                                Fire
                            </button>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
    );
}
export default BuyOrder;

import React, { useState, useEffect ,useCallback} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import DailyBalance from "./abot_DailyBalance";
import DailyBalanceHistory from "./abot_DailyBalanceHistory";
import  LedgerHistory from "./abot_LedgerHistory";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function AbotbalanceLedger({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [usdKrw, setUsdKrw] = useState()
    const [whatRecords, setWhatRecords] = useState("1");

    function Click_change(id, value) {
        //let cc = [0,1,2,3,4]
        let cc = [0,1]

        
        cc.splice(id, 1)
        document.getElementById("bl_"+id).style.color = ""
        document.getElementById("bl_"+id).style.textDecoration = "underline"
        cc.forEach(element => {
            document.getElementById("bl_"+element).style.color = "white"
            document.getElementById("bl_"+element).style.textDecoration = ""
        });
        setWhatRecords(value)
        
    }

    useEffect(() => {
        setTile("Arbitrage Dashboard");
    }, []);

    return (
        <div>
           <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />

            <div className="first_div">
                <div className="second_div">
                    <div style={{ fontSize: "20px", color: "#6CA0EC", textAlign: "left"}}>
                        <input type="radio" id="DailyBalance" name="DailyBalance" value="1" style={{ display: "none" }} checked={whatRecords === "1"} onChange={(e) => Click_change(0, e.target.value)} />
                        &nbsp;
                        <label id="bl_0" htmlFor="DailyBalance" style={{ cursor: "pointer", color: "", textDecoration: "underline" }}>
                            Daily Balance
                        </label>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <input type="radio" id="DailyBalanceHistory" name="DailyBalanceHistory" value="2" style={{ display: "none" }} checked={whatRecords === "2"} onChange={(e) => Click_change(1, e.target.value)} />
                        &nbsp;
                        <label id="bl_1" htmlFor="DailyBalanceHistory" style={{ cursor: "pointer", color: "white" }}>
                            Daily Balance History
                        </label>  
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <input type="radio" id="LedgerHistory" name="LedgerHistory" value="3" style={{ display: "none" }} checked={whatRecords === "3"} onChange={(e) => Click_change(2, e.target.value)} />
                        &nbsp;
                        <label id="bl_2" htmlFor="LedgerHistory" style={{ cursor: "pointer", color: "white" }}>
                            Ledger History
                        </label>
                    </div>
                    <div>

                        <Router>{whatRecords === "1" ? <DailyBalance /> : whatRecords === "2" ? <DailyBalanceHistory /> : whatRecords === "3" ? <LedgerHistory /> : null}</Router>
                
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default AbotbalanceLedger;

import React, { useState , useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./sendPascal.css";

import LogRecordsTrabo from "./LogRecordsTrabo";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function SendPascalMain({handleLogoutapp}) {
    const [whatRecords, setWhatRecords] = useState("1");
    const [title, setTile] = useState()

    useEffect(() => {
        setTile("Send Data to Pascal")
    }, []);

    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />

            <div className="first_div">
                <div className="second_div">
                   
                </div>
            </div>
            <div>
                <LogRecordsTrabo />
            </div>
            <br />
        </div>
    );
}

export default SendPascalMain;

import React, { useState , useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import DailyBalance from "./DailyBalance";
import DailyBalanceHistory from "./DailyBalanceHistory";
import DailyBalanceChart from "./DailyBalanceChart";
import LedgerHistory from "./LedgerHistory";
import LedgerHistoryChart from "./LedgerHistoryChart";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function BalanceLedger({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [whatRecords, setWhatRecords] = useState("1");

    function Click_change(id, value) {
        let cc = [0,1,2]

        cc.splice(id, 1)
        document.getElementById("bl_"+id).style.color = ""
        document.getElementById("bl_"+id).style.textDecoration = "underline"
        cc.forEach(element => {
            document.getElementById("bl_"+element).style.color = "white"
            document.getElementById("bl_"+element).style.textDecoration = ""
        });
        setWhatRecords(value)
    }

    useEffect(() => {
        setTile("Balance & Ledger")
    }, []);


    return (
        <div>
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />

            <div className="first_div">
                <div className="second_div">
                    <div style={{ fontSize: "18px", color: "#6CA0EC", textAlign: "center"}}>
                        <input type="radio" id="DailyBalance" name="DailyBalance" value="1" style={{ display: "none" }} checked={whatRecords === "1"} onChange={(e) => Click_change(0, e.target.value)} />
                        &nbsp;
                        <label id="bl_0" htmlFor="DailyBalance" style={{ cursor: "pointer", color: "", textDecoration: "underline" }}>
                            Daily Balance
                        </label>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <input type="radio" id="DailyBalanceHistory" name="DailyBalanceHistory" value="2" style={{ display: "none" }} checked={whatRecords === "2"} onChange={(e) => Click_change(1, e.target.value)} />
                        &nbsp;
                        <label id="bl_1" htmlFor="DailyBalanceHistory" style={{ cursor: "pointer", color: "white" }}>
                            Daily Balance History
                        </label>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        
                        <input type="radio" id="LedgerHistory" name="LedgerHistory" value="3" style={{ display: "none" }} checked={whatRecords === "3"} onChange={(e) => Click_change(2, e.target.value)} />
                        &nbsp;
                        <label id="bl_2" htmlFor="LedgerHistory" style={{ cursor: "pointer", color: "white" }}>
                            Ledger History
                        </label>
                        
                    </div>
                </div>
            </div>

            <div>
                <Router>{whatRecords === "1" ? <DailyBalance /> : whatRecords === "2" ? <DailyBalanceHistory /> : whatRecords === "3" ? <LedgerHistory /> : <LedgerHistory />}</Router>
            </div>
        </div>
    );
}

export default BalanceLedger;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import TempData from "./data2";

const StockChart = ({timeframe}) => {

    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    const [minRangeDate, setMinRangeDate] = useState(0);
    const [maxRangeDate, setMaxRangeDate] = useState(0);

    const [ohlc, setOhlc] = useState([]);
    const [ema1, setEma1] = useState([]);

    const [upper_band, setUpper_Band] = useState([]);
    const [middle_band, setMiddle_Band] = useState([]);
    const [lower_band, setLower_Band] = useState([]);
    const [buy_point, setBuy_Point] = useState([]);
    const [sell_point, setSell_Point] = useState([]);

    const [volume, setVolume] = useState([]);
    const [groupingUnits, setGroupingUnits] = useState([]);
    const [candleData, setCandleData] = useState([]);


    const [xAxis_min, set_xAxis_min] = useState(0);
    const [xAxis_max, set_xAxis_max] = useState(0);

    /*
    let xAxis_min = 0;
    let xAxis_max = 0;

    function setxAxis_MinMax(min, max){

        xAxis_min = min
        xAxis_max = max
        console.log("setxAxis_MinMax , xAxis_min : " + xAxis_min)
        console.log("setxAxis_MinMax , xAxis_max : " + xAxis_max)

    }
    */

    function convertEpocToDateString(epoctime) {
        let milliseconds = epoctime;
        let myDate = new Date(milliseconds);
        let dateStr = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate() + " " + myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds()
        return dateStr
    }

    function loadCandleData() {
    /*
        {"band_sma":34586.8645,"close":34615,"closetime":1698944159999,"dev":1.5,"ema_10":34598.6377211169,
        "ema_120":34677.0186616776,"ema_20":34583.4358111451,"ema_5":34606.761586329,"ema_60":34600.7471709665,"high":34621.93,
        "length":20,"long_entry":false,"long_exit":false,"low":34594.33,"lower_band":34548.965562606,"middle_band":34586.8645,
        "open":34594.33,"opentime":1698944100000,"priceunit":"usdt","short_entry":true,"short_exit":false,"sma_10":34600.47,"sma_120":34673.8836666667,
        "sma_20":34586.8645,"sma_5":34611.004,"sma_60":34590.842,"squenceNumber":1556,"symbol":"btc",
        "timestamp":"1m","upper_band":34624.763437394,"volume":33.17179}
        */

       
       
         

        if (candleData.length > 0)
        {
          
            
            var temp_ohlc = [];
            var temp_volume = [];
            var temp_ema5 = [];
            var temp_upper_band = [];
            var temp_middle_band = [];
            var temp_lower_band = [];
            var temp_buy_point = [];
            var temp_sell_point = [];
            
            var date;
            var dataLength = candleData.length;
                // set the allowed units for data grouping
            var     temp_groupingUnits = [
                [
                    "week", // unit name
                    [1] // allowed multiples
                ],
                ["month", [1, 2, 3, 4, 6]]
            ];
            
            var i = 0
            //console.log("timeframe : " + timeframe)
            //console.log("candleData.length : " + candleData.length)
           
            //var i = candleData.length - Math.floor((candleData.length * 0.9))
            var tempMinIndex ;
            var tempMaxIndex ;
            tempMinIndex = parseInt(dataLength * 0.85)
            tempMaxIndex = dataLength - 1

            //console.log("loadCandleData, tempMinIndex : " + tempMinIndex)
            //console.log("loadCandleData, tempMaxIndex : " + tempMaxIndex)
            setMinRangeDate(candleData[tempMinIndex].closetime)
            setMaxRangeDate(candleData[tempMaxIndex].closetime)


            for (i; i < dataLength; i++) {

                //console.log("candleData[1556]: " + JSON.stringify(candleData[1556].closetime))
                //console.log("candleData[1556].short_entry: " + JSON.stringify(candleData[1556].short_entry))


                /*
                //console.log("candleData[i].closetime : " + candleData[i].closetime)
                //var temp_Date = convertEpocToDateString(candleData[i].closetime)
                //console.log("temp_Date : " + temp_Date)
                //date = new Date(temp_Date).getTime();
                */
                // utc --> ca time
                //date = new Date(candleData[i].closetime - (3600000*8)).getTime();;
                date = candleData[i].closetime
                //console.log("date : " + date)
            

                temp_ohlc.push([
                    date, // the date
                    candleData[i].open, // open
                    candleData[i].high, // high
                    candleData[i].low, // low
                    candleData[i].close // close
                ]);
                temp_volume.push([
                    date, // the date
                    candleData[i].volume // 
                ]);
                temp_ema5.push([
                    date, // the date
                    candleData[i].ema_5 // t
                ]);
                temp_upper_band.push([
                    date, // the date
                    candleData[i].upper_band // 
                ]);
                temp_middle_band.push([
                    date, // the date
                    candleData[i].middle_band // 
                ]);
                temp_lower_band.push([
                    date, // the date
                    candleData[i].lower_band // 
                ]);
                
                if (timeframe == "time_frame_1") {


                    if (JSON.stringify(candleData[i].long_entry) == "true") {
                          
                        var tempJson_long = {
                            x: date,
                            title : JSON.stringify(candleData[i].close)
                        }
                        
                        temp_buy_point.push(tempJson_long);
                        //console.log("temp_buy_point : "  + JSON.stringify(temp_buy_point))
                    };


                    if (JSON.stringify(candleData[i].short_entry) == "true") {
                        var tempJson_short = {
                            x: date,
                            title : JSON.stringify(candleData[i].close)
                        }
                        temp_sell_point.push(tempJson_short);
                        //console.log("temp_sell_point : "  + JSON.stringify(temp_sell_point))
                    };
                }
                
            }
            
            //var tempMin =  Math.ceil(dataLength * 0.8)
            //var tempMax = dataLength 
            //console.log(" tempMin : " + tempMin)
            //console.log(" tempMax : " +  tempMax)
            //set_xAxis_min(candleData[tempMin].closetime)
            //set_xAxis_max(candleData[tempMax-1].closetime)
            

        }

        setOhlc(temp_ohlc);
        setEma1(temp_ema5);
        //console.log("temp_ema5==>")
        //console.log(temp_ema5)
        setUpper_Band(temp_upper_band);
        setMiddle_Band(temp_middle_band);
        setLower_Band(temp_lower_band);
        

        setBuy_Point(temp_buy_point);
        setSell_Point(temp_sell_point);

        //console.log("temp_volume==>")
        //console.log(temp_volume)
        setVolume(temp_volume);

        setGroupingUnits(temp_groupingUnits);
        /*
        const data = [
            [1601324000000, 613, 617, 597, 597, 149800],
            [1601437600000, 614, 622, 611, 615, 164700]
        ];
        const ema = [
            [1601324000000, 613],
            [1601437600000, 622]
        ];
        */
    }

    function get_candle() {

        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING;
        //console.log("python_server_url : " + python_server_url)
        //console.log("get_candle !")
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            timeframe: "time_frame_1",
            
            timeframe: window.sessionStorage.getItem("timeframe"),
        };
        //console.log("python_server_url ===> ")
        //console.log(python_server_url)
        //console.log("data ===> ")
        //console.log(JSON.stringify(data))
        //var url ="get_candle_ai2"
        var url ="get_candle_backtesting_original"
        axios
            .post(`${python_server_url}/${url}`, data)
            .then((res) => {
                //console.log(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    //console.log("recevice ! ,  getCandleData !")
                    //console.log(JSON.stringify(res.data.data))
                    setCandleData(res.data.data)

                } else {
                    setCandleData([])
                }
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });

    }
    useEffect(() => {
        get_candle();
        const intervalId = setInterval(() => {
            get_candle();
        }, 10000); //10 sec
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        loadCandleData()
    }, [candleData]);

    useEffect(() => {
        get_candle()
        //console.log("timeframe :" + timeframe)
    }, [timeframe]);

    
    var redrawEnabled = true;
    const options = {

        chart: {
            height: 500,
            zooming: {
                mouseWheel: false
            },
            /*
            zooming: {
                mouseWheel: {
                  enabled: false
                }
              },
              */
            backgroundColor: "#1c1b2b",
            borderRadius: 15,

            events: {
                load: function () {
                    //console.log("StockChart is loaded");
                    var chart = this
                    var xAxis = chart.xAxis[0]
                    var xExtremes = xAxis.getExtremes()
                    var newMin = xExtremes.dataMin * 0.9
                    var newMax = xExtremes.dataMax * 1.1
                    //console.log(` xExtremes.dataMin : ${xExtremes.dataMin}`);

                },
                redraw: function() {
                    if (redrawEnabled) {
                        var sum = 0;
                        redrawEnabled = false;
    

                        this.update({
                            subtitle: {
                               // text: sum
                               text : ""
                            }
                        });
                        
                        
                        var chart = this
                        //console.log(` minRangeDate : ${minRangeDate}`);
                        //console.log(` maxRangeDate : ${maxRangeDate}`);
                        //chart.xAxis[0].setExtremes(minRangeDate,maxRangeDate)
                    
                        redrawEnabled = true;
                    }
                }
            }
        },
        
        colors: ['gray', 'green', 'blue', 'violet', 'yellow'],
        rangeSelector: {
            selected: 4
        },
        scrollbar: {
          enabled: false,
        },
        navigator: {
          enabled: true,
        },

        credits: {
            enabled: false
        },

        title: {
            text: "[ BackTesting ]",
            style: {
                color: "#ffff",
            },
        },
        xAxis: {
            //min : xAxis_min,
            //max : xAxis_max,
            labels: {
                style: {
                    color: 'white'
                }
            },
            events: {
                setExtremes(e) {
                    //console.log(` xAxis, setExtremes : ${'<b>Set extremes:</b> e.min: ' + Highcharts.dateFormat(null, e.min) +  ' | e.max: ' + Highcharts.dateFormat(null, e.max) + ' | e.trigger: ' + e.trigger}`);

                }
            }
        },

        yAxis: [{
            labels: {
                style : {
                    color : "white",
                    fontSize : " 0.7em",
                    align: 'right',

                }
            },
            height: '80%',
            resize: {
                enabled: true
            },
            gridLineColor:'#e6e6e6',
            gridLineWidth: 0.1,
            lineColor: false,
        },
        {
            labels: {
                align: 'left'
            },
            height: '80%',
            resize: {
                enabled: true
            }
        }, {
            labels: {
                align: 'left'
            },
            top: '80%',
            height: '20%',
            offset: 0
        }
        ],
        tooltip: {
            positioner: function () {
                return {
                    x: this.chart.plotLeft,
                    y: this.chart.plotTop
                };
            },
            shared: true,
            headerFormat: '',
            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
            valueDecimals: 2,
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.8)'
        },
        tooltip: {
            enabled: false
        },
        /*
        tooltip: {
            formatter: function () {
                return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>, in series ' + this.series.name;
            }
        },
        */
        /*
        tooltip: {
            shape: 'square',
            headerShape: 'callout',
            borderWidth: 0,
            shadow: false,
            positioner: function (width, height, point) {
                const chart = this.chart;
                let position;

                if (point.isHeader) {
                    position = {
                        x: Math.max(
                            // Left side limit
                            chart.plotLeft,
                            Math.min(
                                point.plotX + chart.plotLeft - width / 2,
                                // Right side limit
                                chart.chartWidth - width - chart.marginRight
                            )
                        ),
                        y: point.plotY
                    };
                } else {
                    position = {
                        x: point.series.chart.plotLeft,
                        y: point.series.yAxis.top - chart.plotTop
                    };
                }

                return position;
            }
        },
        */
        series: [{
            type: 'candlestick',
            id: 'coin-ohlc',
            name: 'coin-ohlc',
            data: ohlc,
            events: {
            	afterAnimate: function () {
                    //alert(xAxis_min)
                    /*
                    const chart = this.chart;
                    var xAxis = chart.xAxis[0]

                    console.log("afterAnimate : xAxis_min : " +xAxis_min )
                    console.log("afterAnimate : xAxis_max : " +xAxis_max )
                    xAxis.setExtremes(parseInt(xAxis_min.toString()), parseInt(xAxis_max.toString()));
                    dd*/

              }
            }
        },
        {
            type: 'flags',
            name: 'Buy Point',
            id : "buy",
            data: buy_point,
            onSeries: "coin-ohlc",
            shape: 'squarepin',
            //shape: 'flag',
            color: 'blue', 
            fillColor: 'blue', 
            y: -60,
            height: 30,
            width: 40,
            style: { // text style
                color: 'white',
                fontSize : 10
            },
            states: {
                hover: {
                    fillColor: 'blue' 
                }
            }
        },
        {
            type: 'flags',
            name: 'Sell Point',
            id : "sell",
            data: sell_point,
            onSeries: "coin-ohlc",
            shape: 'squarepin',
            //shape: 'flag',
            color: 'red', 
            fillColor: 'red', 
            y: -60,
            height: 30,
            width: 40,
            style: { // text style
                color: 'white',
                fontSize : 10
            },
            states: {
                hover: {
                    fillColor: 'red', 
                }
            }
        }
        ,
        /*
        {
            type: 'line',
            id: "Ema1",
            name: "Ema1",
            data: ema1,
            yAxis: 0,
            color :"red"
           
        },
        {
            type: "line",
            id: "bb_upper",
            name: "bb_upper",
            data: upper_band,
            yAxis: 0,
            color : "#FFBF00",
            lineWidth : 0.5,
        },
        {
            type: "line",
            id: "bb_middle",
            name: "bb_middle",
            data: middle_band,
            yAxis: 0,
            color : "#FFBF00",
            lineWidth : 0.5,
        },
        {
            type: "line",
            id: "bb_lower",
            name: "bb_lower",
            data: lower_band,
            yAxis: 0,
            color : "#FFBF00",
            lineWidth : 0.5,
        }
        /*,
        
        {
            type: 'column',
            id: 'coin-volume',
            name: 'Volume',
            data: volume,
            yAxis: 1
        }
        */
       ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 800
                },
                chartOptions: {
                    rangeSelector: {
                        inputEnabled: false
                    }
                }
            }]
        }
        ,
        rangeSelector: {
            selected: 1,
            buttons: [
                {
                    type: "minutes",
                    count: 1,
                    text: "All",
                    title: "View All",
                },
            ],
        },
        plotOptions: {
            flags: {
                accessibility: {
                    exposeAsGroupOnly: true,
                    description: 'Flagged events.'
                }
            },
            /*
            scatter: {
                marker: {
                    radius: 2.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                jitter: {
                   // x: 0.005
                }
            },
            */
            line: {
                dashStyle: "Solid",
                lineWidth: 1,
               // lineColor: "#20a0b1",
            },
            candlestick: {
                lineColor: "#FB1809",
                color: "#FB1809",
                upColor: "#4EA64A",
                upLineColor: "#4EA64A",
            },
            bb: {
                lineWidth: 1,
                lineColor: "#20a0b1",
                bottomLine: {
                    styles: {
                        lineWidth: 0.5,
                        lineColor: "#fcfc27",
                    },
                },
                topLine: {
                    styles: {
                        lineWidth: 0.5,
                        lineColor: "#fcfc27",
                    },
                },
            },
            series: {
                borderColor: "red",
                marker: {
                    enabled: false,
                    radius: 0,
                },

                point: {
                    events: {
                        mouseOver: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            //console.log(`plotOptions , mouseOver, to ${point.x}, ${point.y}`);
                            //self.handleMouseOver(point);
                        },

                        selection: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            //console.log(`plotOptions  selection , to ${point.x}, ${point.y}`);
                            // self.handleMouseOver(point);
                        },
                    }
                }
            }
        }
    };

    (function (H) {
        Highcharts.Chart.prototype.callbacks.push(function (chart) {
            H.addEvent(chart.container, 'wheel', function (e) {
                /*
                var xAxis = chart.xAxis[0],
                    extremes = xAxis.getExtremes(),
                    newMin = extremes.min;
                console.log("wheel");
                console.log(extremes);
                console.log(newMin);


                if (e.deltaY < 0) {
                    xAxis.setExtremes(extremes.min - (extremes.min * 0.001), extremes.max, true);
                }
                else {
                    xAxis.setExtremes(extremes.min + (extremes.min * 0.001), extremes.max, true);
                }
                */
            });

            H.addEvent(chart.container, 'selection', function (e) {
                console.log("selection !!");
            });


            H.addEvent(chart.container, 'click', function (e) {
                e = chart.pointer.normalize(e);
                console.log(`Clicked11 chart at ${e.chartX}, ${e.chartY}`);

            });

            H.addEvent(chart.xAxis[0], 'afterSetExtremes', function (e) {

                //console.log(` xAxis, setExtremes : ${'<b>Set extremes:</b> e.min: ' + Highcharts.dateFormat(null, e.min) +' | e.max: ' + Highcharts.dateFormat(null, e.max) + ' | e.trigger: ' + e.trigger}`);
                //console.log(`load Set extremes to ${e.min}, ${e.max}`);

                //var extremes_min = e.min;
                //var extremes_max = e.max;
                //var extremes_start = extremes_max - 960000;
                //chart.xAxis[0].setExtremes(extremes_start, extremes_max);

                //var extremes_min_date_str = convertEpocToDateString(extremes_min);
                //console.log("extremes_min_date_str : " + extremes_min_date_str);
                //var extremes_max_date_str = convertEpocToDateString(extremes_max);
                //console.log("extremes_max_date_str : " + extremes_max_date_str);

                //console.log("new Date(extremes_start_date).getTime() : " + new Date(extremes_max_date_str).getTime());
                //console.log("extremes_min : " + extremes_min);
                //console.log("extremes_max : " + extremes_max);
                //console.log("extremes_start : " + extremes_start);

                //chart.xAxis[0].setExtremes(new Date(extremes_start_date).getTime(), new Date(extremes_max_date).getTime());
                //chart.xAxis[0].setExtremes(data[0][1].x, data[0][2000].x, true, false);

            });

            /*
            H.addEvent(H.Chart, 'load', function(e) {
            const chart = e.target;
            H.addEvent(chart.container, 'click', function(e) {
                e = chart.pointer.normalize(e);
                console.log(`Clicked chart at ${e.chartX}, ${e.chartY}`);
            });
            H.addEvent(chart.xAxis[0], 'afterSetExtremes', function(e) {
                console.log(`load Set extremes to ${e.min}, ${e.max}`);
            });
            });

            */

        });
    }(Highcharts));

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
                containerProps={{ style: { height: "50%" } }}
            />
        </div>
    );
};
export default StockChart;
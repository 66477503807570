import React, { useState, useEffect } from "react";

import { C_coins } from "../../../common/Commons";
import SellOrder from "./SellOrder";
import BuyOrder from "./BuyOrder";
import Record from "./Record";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function ManualTrading({handleLogoutapp}) {
    const [title, setTile] = useState()

    const [opt_coins, setOpt_coins] = useState(null);
    
    function coinsSet(_num) {
      
        setOpt_coins(_num)
    }

    useEffect(() => {
       
        C_coins(coinsSet)
    }, []);
    
    useEffect(() => {
        setTile("Manual Trading")
    }, []);

    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <div style={{ margin: "0 0 0.5em 1em", display: "flex" }}>
                <h1></h1>
            </div>
            {!opt_coins && <p> Loading...</p>}
            {opt_coins && (
                <>
                <BuyOrder opt_coins={opt_coins && opt_coins} />
                <br />
                <SellOrder opt_coins={opt_coins && opt_coins} />
                <br />
                <Record />
                </>
            )}
        </div>
    );
}
export default ManualTrading;

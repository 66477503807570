import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { locale_opt, locale_opt_8 } from "../../common/Commons";
import { DateFromToDiv_B } from "../../common/Commons";

import RevenueStatus from "./RevenueStatus";
import AssetStatus from "./AssetStatus";

import "./DashBoard";
import "./Dashbord.css";


import { Data } from "./Data";

function RevenuMain() {
   
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [datalogs_spot, setDatalogsSpot] = useState([]);
    const [datalogs_future, setDatalogsFuture] = useState([]);
    const [totoal_value, setTotalValue] = useState("");
    const [datalogsMontly, setDatalogsMontly] = useState(null);
    
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [chartYear, setChartYear] = useState(new Date());
    const [chartMonth, setChartMonth] = useState("");
    const [errorMode, setErrorMode] = useState("normal");
   

    useEffect(() => {
    }, []);
    

    return (
        <div>
            <AssetStatus />
            <RevenueStatus />
        </div>
    );
}

export default RevenuMain;

import React, { useState, useEffect } from "react";



function Fee_Future({ feeTotal_Future  ,feeSave_Future }) {

    const [assetList, setAssetList] = useState([]);
    const [idList, setIdList] = useState([]);
    const [current_future_percent_market, set_current_future_percent_market] = useState(0.0)
    const [current_future_percent_limit, set_current_future_percent_limit] = useState(0.0)

    const handleAssets = (id, value) => {
        //alert(id)
        //alert(value)
        let newArr_id = [...idList]; // copying the old datas array
        newArr_id[id] = id; 

        let newArr_asset = [...assetList]; // copying the old datas array
        newArr_asset[id] = String(value); 

        setIdList(newArr_id);
        //alert(JSON.stringify(idList));
        setAssetList(newArr_asset);
        //alert(JSON.stringify(assetList));
      };

    return (
        <div className="first_div">
            <br />
            <div className="second_div">
                <h5>Transaction Fee (Future)​</h5>
            </div>
            <div style={{ border: "1px solid #203864", textAlign:"center",verticalAlign:"middel" ,margin: "0 0 0.5em 3em" }}>
                <div style={{ marginTop: "0.0em", width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ marginTop: "0.0em", width: "100%", justifyContent: "center" }}>
                        <div>
                            <table>
                            <thead style={{ background: "#4472c4" }}>
                                <tr style={{ width: "100%" }}> 
                                    <th>Exchange</th>
                                    <th>Market(%)</th>
                                    <th>Limit(%)</th>
                                    <th>Save</th>
                                </tr>
                            </thead>
                            <tbody>
                            {feeTotal_Future &&
                                feeTotal_Future.map((v, i) => (
                                    <tr key={i} style={{ background: i%2 === 0 ? "#114736" :"#233E6F" }}>
                                        <td style={{ width: "100px" }}>{v.code_value}</td>
                                        <td style={{ width: "200px" }}>
                                            <input class="inbput_market" id={v.common_id + "inbput_market"} type="number" name="fee" defaultValue={v.future_percent_market} style={{ width: "100%" }}  
                                            onChange={(e) => set_current_future_percent_market(e.target.value)}/>
                                        </td>
                                        <td style={{ width: "200px" }}>
                                            <input class="inbput_limit" id={v.common_id + "inbput_limit"} type="number" name="fee" defaultValue={v.future_percent_limit} style={{ width: "100%" }} 
                                            onChange={(e) => set_current_future_percent_limit(e.target.value)}/>
                                        </td>
                                        <td style={{ width: "100px" }}>
                                            <button class="btn_save save btn_m" onClick={() => feeSave_Future(v.common_id,
                                            document.getElementById(v.common_id + "inbput_market").value,document.getElementById(v.common_id + "inbput_limit").value,
                                            current_future_percent_market,current_future_percent_limit)}>
                                            Save
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>
                       
            </div>
        </div>
    );
}

export default Fee_Future;

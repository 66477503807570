import React from "react";

import "./sidenav.css";
import sentinel_main_img from "../../images/sentinel.svg";

import SidenavItems from "./SidenavItems";

function Sidenav() {
    function go_home(e) {
        //window.location.href = "/liveTicker";
        window.location.href = "/DashBoard";
    }

    return (
        <div className=" dl_sidenav" style={{ zIndex: "5" }}>
            <div className="sidenav_img_div">
                <img src={sentinel_main_img} alt="" style={{ width: "120px", margin: "1em", cursor: "pointer" }} onClick={go_home} />
            </div>
            <div className="sidenav_menu_div">
                <SidenavItems />
            </div>
        </div>
    );
}

export default Sidenav;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";

import "./logRecords.css";
import { c_opt_exchanges_all } from "../../../common/Commons";
import { ExchangeDiv, DateFromToDiv, AscDesc } from "../../../common/Commons";

function LogRecordsBot() {
    const pairs = [1];

    const [datalogs, setDatalogs] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [exchanges, setExchanges] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [newOld, setNewOld] = useState("desc");

    const [remountComponent, setRemountComponent] = useState();

    function DataLogChange(data) {

        data.forEach((element) => {
            //let jj = JSON.parse(String(element));
            //alert(JSON.stringify(element.g_1));
            element["g_1"] = element.g_1;
            element["g_2"] = element.g_2;
            element["g_3"] = element.g_3;

            delete element.status_data;
        });
        setDatalogs(data);
    }

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const opt_exchanges = c_opt_exchanges_all;

    useEffect(() => {
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
        const limit_cnt = 5;

        const data = {
            bot_id: "p_bot_stk",
            msg: "get_tradingbot_log_records",
            pgn: pageNumber,
            limit_cnt: limit_cnt,
            exchanges: exchanges === "all" ? "all" : exchanges === "binance" ? "1" : exchanges === "bithumb" ? "2" : exchanges === "upbit" ? "3" :  exchanges === "okex" ? "4" :  exchanges === "mexc" ? "5" : "6",
            dateFrom: dateFrom,
            dateTo: dateTo,
            newOld: newOld,
        };

        axios
            .post(`${python_server_url}/bots`, data)
            .then((res) => {
                //alert(JSON.stringify(res))
                if (res.data.result_data !== 0) {
                    //alert(JSON.stringify(res.data.result_data))
                    DataLogChange(res.data.result_data);
                    const page_cnt_math = Math.ceil(res.data.result_cnt / limit_cnt);
                    setPageCount(page_cnt_math);
                } else {
                    setDatalogs([]);
                    setPageCount(0);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [pageNumber, exchanges, dateFrom, dateTo, newOld]);

    useEffect(() => {
        changePage({ selected: 0 });
        setRemountComponent(Math.random());
    }, [exchanges, dateFrom, dateTo, newOld]);

    return (
        <div className="first_div">
            <div className="second_div">
                <h3>Trading Bot Log</h3>
                <br />
                <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                    <div>
                        <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchanges} />
                        &emsp;
                        <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                        <br />
                        <br />
                        <AscDesc newOld={newOld} send_setNewOld={setNewOld} />
                    </div>
                    <br />
                    <table>
                        <thead style={{ background: "#4472c4" }}>
                            <tr>
                                <th>#</th>
                                <th>Exchanges</th>
                                <th>MLC</th>
                                <th>P2V</th>
                                <th>TBT</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datalogs.map((k, i) => (
                                <tr key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                    <td>{i + 1}</td>
                                    <td>{k.exchanges}</td>
                                    <td style={{ width: "420px" }}>
                                        <pre style={{ textAlign: "left" }}>{JSON.stringify(k.g_1, null, 5)}</pre>
                                    </td>
                                    <td style={{ width: "420px" }}>
                                        <pre style={{ textAlign: "left" }}>{JSON.stringify(k.g_2, null, 5)}</pre>
                                    </td>
                                    <td style={{ width: "420px" }}>
                                        <pre style={{ textAlign: "left" }}>{JSON.stringify(k.g_3, null, 5)}</pre>
                                    </td>
                                    <td style={{ width: "120px" }}>{k.insert_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div key={remountComponent}>
                    <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Previous"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    );
}

export default LogRecordsBot;

import React, { useState, useEffect } from "react";
import "./CoinManage.css";

import axios from "axios";
import dayjs from "dayjs";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { locale_opt } from "../../../common/Commons";
import Modal from "../../../common/Modal";

function CoinManageSpot() {
  const pairs = [1];
  
  const [coins, setCoins] = useState([]);
  const [newOld, setNewOld] = useState("desc");

  const [remountComponent, setRemountComponent] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ui component
  
  ////////////////////////////////////////////////////////////////////////////
  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInputValue, setModalInputValue] = useState("");
  // Modal
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
      setModalOpen(false);
  };

  function ChangeContents() {
      createCoin(modalInputValue);
      setModalOpen(false);
  }
  ////////////////////////////////////////////////////////////////////////////

  // edit button
  const [editButtons, setEditButtons] = useState([]);
  const handleEdit = (index,coin,button_text) => {
    console.log("handleEdit index :" + index);
    console.log("handleEdit coin :" + coin);
    console.log("handleEdit button_text :" + button_text);
    let newArr = [...editButtons]; 
    if ( button_text === "Edit" ) {
        newArr[index] = "Save"; 
    }else{
      newArr[index] = "Edit"; 
      updateCoin(index,coin);
    }
   // replace e.target.value with whatever you want to change it to
    setEditButtons(newArr);
    console.log("editButtons :" + newArr);
  };

  const [b_to_trabo_list, set_b_to_trabo_list] = useState([]);
  const handle_b_to_trabo = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...b_to_trabo_list]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      set_b_to_trabo_list(newArr);
      console.log("b_to_trabo_list :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      set_b_to_trabo_list(newArr);
      console.log("b_to_trabo_list :" + newArr);
    }

  };

  const [b_binance_list, set_b_binance_list] = useState([]);
  const handle_b_binance_list = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...b_binance_list]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      set_b_binance_list(newArr);
      console.log("b_binance_list :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      set_b_binance_list(newArr);
      console.log("b_binance_list :" + newArr);
    }

  };

  const [b_upbit_list, set_b_upbit_list] = useState([]);
  const handle_b_upbit_list = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...b_upbit_list]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      set_b_upbit_list(newArr);
      console.log("b_upbit_list :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      set_b_upbit_list(newArr);
      console.log("b_upbit_list :" + newArr);
    }

  };


  const [b_bithumb_list, set_b_bithumb_list] = useState([]);
  const handle_b_bithumb_list = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...b_bithumb_list]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      set_b_bithumb_list(newArr);
      console.log("b_bithumb_list :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      set_b_bithumb_list(newArr);
      console.log("b_bithumb_list :" + newArr);
    }

  };

  const [b_okex_list, set_b_okex_list] = useState([]);
  const handle_b_okex_list = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...b_okex_list]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      set_b_okex_list(newArr);
      console.log("b_okex_list :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      set_b_okex_list(newArr);
      console.log("b_okex_list :" + newArr);
    }

  };

  const [b_mexc_list, set_b_mexc_list] = useState([]);
  const handle_b_mexc_list = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...b_mexc_list]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      set_b_mexc_list(newArr);
      console.log("b_mexc_list :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      set_b_mexc_list(newArr);
      console.log("b_mexc_list :" + newArr);
    }

  };

  const [b_bitget_list, set_b_bitget_list] = useState([]);
  const handle_b_bitget_list = (selected_value, index) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...b_bitget_list]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      set_b_bitget_list(newArr);
      console.log("b_bitget_list :" + newArr);
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      set_b_bitget_list(newArr);
      console.log("b_bitget_list :" + newArr);
    }

  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // useEffect

  useEffect(() => {
    changePage({ selected: 0 });
    setRemountComponent(Math.random());
  }, [newOld]);


  useEffect(() => {
    const limit_cnt = 20;

    const data = {
        msg: "get_coin_list",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        dateFrom: "",
        dateTo: "",
        newOld: newOld,
    };

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/admin_manager`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {

          // case ==>  return dict(msg="1", result=[arbitrage_data_list])
          //console.log("res.data.result  : " + res.data.result)
          //console.log("res.data.result  : " + JSON.stringify(res.data.result))
          /*
          res.data.result.map((temp_list,i) => {
            temp_list.map((temp_item,i) => {
              console.log(temp_item);
            });
          });
          */

          setCoins(res.data.result);
          makeSubData(res.data.result);
      } else {
        setCoins([]);
      }
    })
    .catch((err) => {
        console.log(err);
    });
  }, [pageNumber, newOld]);


  ///////////////////////////////////////////////////////////////////////////////////
  // function
  


  function makeSubData(coins){
   
    let newArr_Buttons = [];
    let newArr_b_to_trabo = [];
    let newArr_b_binance = [];
    let newArr_b_upbit = [];
    let newArr_b_bithumb = [];
    let newArr_b_okex= [];
    let newArr_b_mexc= [];
    let newArr_b_bitget= [];
    
    

    for (let index in coins){
      newArr_Buttons[index] = "Edit";
      newArr_b_binance[index] =  coins[index].b_binance;
      newArr_b_upbit[index] =  coins[index].b_upbit;
      newArr_b_bithumb[index] =  coins[index].b_bithumb;
      newArr_b_okex[index] =  coins[index].b_okex;
      newArr_b_mexc[index] =  coins[index].b_mexc;
      newArr_b_bitget[index] =  coins[index].b_bitget;
      newArr_b_to_trabo[index] =  coins[index].b_to_trabo;
    }

    setEditButtons(newArr_Buttons);

    set_b_binance_list(newArr_b_binance);
    console.log("newArr_b_binance :" + newArr_b_binance)
    set_b_upbit_list(newArr_b_upbit);
    set_b_bithumb_list(newArr_b_bithumb);
    set_b_okex_list(newArr_b_okex);
    set_b_mexc_list(newArr_b_mexc);
    set_b_bitget_list(newArr_b_bitget);
    console.log("newArr_b_to_trabo :" + newArr_b_to_trabo)
    set_b_to_trabo_list(newArr_b_to_trabo);
  }

  // update abit
  function updateCoin(index,coin){

    // make data list to update

    const data = {
        msg: "update_coin", 
        which: "update",
        b_binance: b_binance_list[index],
        b_upbit: b_upbit_list[index],
        b_bithumb: b_bithumb_list[index],
        b_okex: b_okex_list[index],
        b_mexc: b_mexc_list[index],
        b_bitget: b_bitget_list[index],
        b_to_trabo: b_to_trabo_list[index],
        
        coin : coin,
        
    };
    console.log("update_coins : data " + data )
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/admin_manager`, data)
    .then((res) => {
        console.log(res.data)

        if (res.data.result === "ok"){
          //alert("ok")
          if (res.data.coin_list.length !== 0) {
            setCoins(res.data.coin_list);
            makeSubData(res.data.coin_list);
          } else {
              setPageCount(0);
              setCoins([]);
          }

        }else{
          alert("ERROR ! : please check! coin exigst or not !")
        }
    })
    .catch((err) => {
        console.log(err);
        alert("ERROR")
    })
    // .finally(() => {
    //    window.location.reload();
    // });
  }
  // create new abit
  
  function makeSubData(coins){
   
    let newArr_Buttons = [];
    let newArr_b_to_trabo = [];
    let newArr_b_binance = [];
    let newArr_b_upbit = [];
    let newArr_b_bithumb = [];
    let newArr_b_okex= [];
    let newArr_b_mexc= [];
    let newArr_b_bitget= [];
    
    

    for (let index in coins){
      newArr_Buttons[index] = "Edit";
      newArr_b_binance[index] =  coins[index].b_binance;
      newArr_b_upbit[index] =  coins[index].b_upbit;
      newArr_b_bithumb[index] =  coins[index].b_bithumb;
      newArr_b_okex[index] =  coins[index].b_okex;
      newArr_b_mexc[index] =  coins[index].b_mexc;
      newArr_b_bitget[index] =  coins[index].b_bitget;
      newArr_b_to_trabo[index] =  coins[index].b_to_trabo;
    }

    setEditButtons(newArr_Buttons);

    set_b_binance_list(newArr_b_binance);
    console.log("newArr_b_binance :" + newArr_b_binance)
    set_b_upbit_list(newArr_b_upbit);
    set_b_bithumb_list(newArr_b_bithumb);
    set_b_okex_list(newArr_b_okex);
    set_b_mexc_list(newArr_b_mexc);
    set_b_bitget_list(newArr_b_bitget);
    console.log("newArr_b_to_trabo :" + newArr_b_to_trabo)
    set_b_to_trabo_list(newArr_b_to_trabo);
  }

  // update abit
  function createCoin(coin){

    // make data list to update
    //alert(coin)
    const data = {
        msg: "create_coin", 
        which: "insert",
        coin : coin,
        
    };
    console.log("update_coins : data " + data )
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/admin_manager`, data)
    .then((res) => {
        console.log(res.data)

        if (res.data.result === "ok"){
          //alert("ok")
          if (res.data.coin_list.length !== 0) {
            setCoins(res.data.coin_list);
            makeSubData(res.data.coin_list);
          } else {
              setPageCount(0);
              setCoins([]);
          }

        }else{
          alert("ERROR ! : please check! coin exigst or not !")
        }
    })
    .catch((err) => {
        console.log(err);
        alert("ERROR")
    })
    // .finally(() => {
    //    window.location.reload();
    // });

  }
  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {/* botstatus content */}
      <div className="first_div">
        <div className="second_div">
          <div style={{ display: "flex", margin: "0px 0px 10px 0px" }}>
              <h5 style={{ margin: "0px 2.5em 0px 0px" }}>Coin Settings For Spot</h5>
              &emsp;
              <h6>(updated : {dayjs().format("YYYY-MM-DD dddd hh:mm:ss")})</h6>
              <div style={{ display: "flex", margin: "0px 0px 0px 530px" }}>
                <button className="btn btn-success btn-sm"  style={{ width: '100px',display: "float"}} onClick={() => openModal() }>
                 Add Coin
                </button>
              </div>
          </div>
          <div style={{ display: "flex", marginLeft: "-1em" }}>
            &emsp;&emsp;
            <div style={{ width: "95%" }}>
                <table>
                    <thead style={{ background: "#142440​" }}>
                        <tr>
                            <th rowSpan={2}>Coin</th>
                            <th colSpan={7} style={{ background: "#0B2F24" }}>Binance</th>
                            <th colSpan={7} style={{ background: "#271137​" }}>Upbit</th>
                            <th colSpan={7} style={{ background: "#0B2F24" }}>Bithumb</th>
                            <th colSpan={7} style={{ background: "#271137​" }}>Okex</th>
                            <th colSpan={7} style={{ background: "#0B2F24" }}>Mexc</th>
                            <th colSpan={7} style={{ background: "#271137​" }}>Bitget</th>
                            <th rowSpan={2}>To Pascal</th>
                            <th rowSpan={2}>Created</th>
                            <th rowSpan={2}>Edit</th>
                        </tr>
                        <tr>
                            <th style={{ background: "#0B2F24" }}>Enable</th>
                            <th style={{ background: "#0B2F24" }}>Base Precision</th>
                            <th style={{ background: "#0B2F24" }}>Quote Precision</th>
                            <th style={{ background: "#0B2F24" }}>Price Min</th>
                            <th style={{ background: "#0B2F24" }}>Price Max</th>
                            <th style={{ background: "#0B2F24" }}>Crypto Min</th>
                            <th style={{ background: "#0B2F24" }}>Crypto Max</th>

                            <th style={{ background: "#271137​" }}>Enable</th>
                            <th style={{ background: "#271137​" }}>Base Precision</th>
                            <th style={{ background: "#271137​" }}>Quote Precision</th>
                            <th style={{ background: "#271137​" }}>Price Min</th>
                            <th style={{ background: "#271137​" }}>Price Max</th>
                            <th style={{ background: "#271137​" }}>Crypto Min</th>
                            <th style={{ background: "#271137​" }}>Crypto Max</th>
                            
                            <th style={{ background: "#0B2F24" }}>Enable</th>
                            <th style={{ background: "#0B2F24" }}>Base Precision</th>
                            <th style={{ background: "#0B2F24" }}>Quote Precision</th>
                            <th style={{ background: "#0B2F24" }}>Price Min</th>
                            <th style={{ background: "#0B2F24" }}>Price Max</th>
                            <th style={{ background: "#0B2F24" }}>Crypto Min</th>
                            <th style={{ background: "#0B2F24" }}>Crypto Max</th>
                            
                            <th style={{ background: "#271137​" }}>Enable</th>
                            <th style={{ background: "#271137​" }}>Base Precision</th>
                            <th style={{ background: "#271137​" }}>Quote Precision</th>
                            <th style={{ background: "#271137​" }}>Price Min</th>
                            <th style={{ background: "#271137​" }}>Price Max</th>
                            <th style={{ background: "#271137​" }}>Crypto Min</th>
                            <th style={{ background: "#271137​" }}>Crypto Max</th>
                            
                            <th style={{ background: "#0B2F24" }}>Enable</th>
                            <th style={{ background: "#0B2F24" }}>Base Precision</th>
                            <th style={{ background: "#0B2F24" }}>Quote Precision</th>
                            <th style={{ background: "#0B2F24" }}>Price Min</th>
                            <th style={{ background: "#0B2F24" }}>Price Max</th>
                            <th style={{ background: "#0B2F24" }}>Crypto Min</th>
                            <th style={{ background: "#0B2F24" }}>Crypto Max</th>
                            
                            <th style={{ background: "#271137​" }}>Enable</th>
                            <th style={{ background: "#271137​" }}>Base Precision</th>
                            <th style={{ background: "#271137​" }}>Quote Precision</th>
                            <th style={{ background: "#271137​" }}>Price Min</th>
                            <th style={{ background: "#271137​" }}>Price Max</th>
                            <th style={{ background: "#271137​" }}>Crypto Min</th>
                            <th style={{ background: "#271137​" }}>Crypto Max</th>

                        </tr>
                    </thead>
                    <tbody>
                        {coins.map((k, i) => (
                            
                            <tr key={i}>
                                <td style={{ background: i%2 === 0 ? "#271137​" :"#271137​" }}>{k.coin_ticker.toUpperCase()}</td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                    <input type="checkbox" id={i+"_binace"} style={{ color: "#dbfaf1" }} disabled={editButtons[i] === "Edit" ? "disabled" : ""} 
                                      checked={b_binance_list[i] === "true" ? true : false} 
                                      onChange={(e) => handle_b_binance_list(e.currentTarget.checked, i)}/>
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.binance_base_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.binance_quote_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.binance_price_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.binance_price_max}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.binance_crypto_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.binance_crypto_max}
                                </td>


                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                    <input type="checkbox" id={i+"_upbit"}  disabled={editButtons[i] === "Edit" ? "disabled" : ""} 
                                      checked={b_upbit_list[i] === "true" ? true : false} 
                                      onChange={(e) => handle_b_upbit_list(e.currentTarget.checked, i)}/>
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.upbit_base_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.upbit_quote_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.upbit_price_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.upbit_price_max}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.upbit_crypto_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.upbit_crypto_max}
                                </td>


                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                    <input type="checkbox" id={i+"_bithumb"}  disabled={editButtons[i] === "Edit" ? "disabled" : ""} 
                                      checked={b_bithumb_list[i] === "true" ? true : false} 
                                      onChange={(e) => handle_b_bithumb_list(e.currentTarget.checked, i)}/>
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.bithumb_base_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.bithumb_quote_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.bithumb_price_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.bithumb_price_max}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.bithumb_crypto_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.bithumb_crypto_max}
                                </td>


                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                    <input type="checkbox" id={i+"_okex"}  disabled={editButtons[i] === "Edit" ? "disabled" : ""} 
                                      checked={b_okex_list[i] === "true" ? true : false} 
                                      onChange={(e) => handle_b_okex_list(e.currentTarget.checked, i)}/>
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.okex_base_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.okex_quote_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.okex_price_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.okex_price_max}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.okex_crypto_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.okex_crypto_max}
                                </td>


                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                    <input type="checkbox" id={i+"_mexc"}  disabled={editButtons[i] === "Edit" ? "disabled" : ""} 
                                      checked={b_mexc_list[i] === "true" ? true : false} 
                                      onChange={(e) => handle_b_mexc_list(e.currentTarget.checked, i)}/>
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.mexc_base_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.mexc_quote_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.mexc_price_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.mexc_price_max}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.mexc_crypto_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#0B2F24" :"#114B39" }}>
                                  {k.mexc_crypto_max}
                                </td>


                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                    <input type="checkbox" id={i+"_bitget"}  disabled={editButtons[i] === "Edit" ? "disabled" : ""} 
                                      checked={b_bitget_list[i] === "true" ? true : false} 
                                      onChange={(e) => handle_b_bitget_list(e.currentTarget.checked, i)}/>
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.bitget_base_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.bitget_quote_precision}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.bitget_price_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.bitget_price_max}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.bitget_crypto_min}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                  {k.bitget_crypto_max}
                                </td>


                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                    <input type="checkbox" id={i+"_operation"}  disabled={editButtons[i] === "Edit" ? "disabled" : ""} 
                                      checked={b_to_trabo_list[i] === "true" ? true : false} 
                                      onChange={(e) => handle_b_to_trabo(e.currentTarget.checked, i)}/>
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                    {moment(k.created).format('YYYY-MM-DD HH:mm:ss')}
                                </td>
                                <td style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                    <button id={i+"_edit"}  style={{ width: '100px',display: "float"}} className={editButtons[i] === "Edit" ? "btn btn-primary btn-sm" : "btn btn-success btn-sm"} onClick={(e) => handleEdit(i, k.coin_ticker,e.currentTarget.innerText)}>{editButtons[i] === 'Edit' ? "Edit" : "Save"}</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                   
                </table>
            </div>
          </div>
        </div> {/* end second div */}
      </div>  {/* end first div */}
      <br />
      <div>
          <Modal
              open={modalOpen}
              close={closeModal}
              header={
                  <>
                      <p>
                        Enter New Coin Name
                      </p>
                      <button className="close" onClick={closeModal}>
                          {" "}
                          &times;{" "}
                      </button>
                  </>
              }
              footer={
                  modalInputValue === "" ? (
                      <span style={{ fontSize: "11px", color: "red" }}>{/*Pelase Input Coin Name! &emsp;*/}</span>
                  ) : (
                      <>
                          <button style={{ background: "green" }} onClick={(e) => ChangeContents()}>
                              Save
                          </button>
                          <button className="close" onClick={closeModal}>
                              {" "}
                              close{" "}
                          </button>
                      </>
                  )
              }>
              {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
              <label htmlFor="bot_modal_input">Coin Name : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <input type="text" id="bot_modal_input" name="bot_modal_input" autoFocus onChange={(e) => setModalInputValue(e.target.value)} />
          </Modal>
      </div>
    </div> 
    
);
}

export default CoinManageSpot;


import React, { useState, useEffect } from "react";
import axios from "axios";

import { c_opt_exchanges_all, c_opt_strategy_grp_name_all, locale_opt, locale_opt_8 } from "../../../common/Commons";
import { C_coins, ExchangeDiv, CoinsDiv, CoinsDiv2, GroupDiv, StrategyInputDiv, DateFromToDiv } from "../../../common/Commons";

function LedgerHistory() {
    // select box valuable
    const [datalogs, setDatalogs] = useState([]);

    const [exchange_name, setExchange_name] = useState("all");
    const [coins, setCoins] = useState("all");
    const [opt_coins, setOpt_coins] = useState([]);
    const [groups, setGroups] = useState("all");
    const [strategy, setStrategy] = useState("");
    const [event_type, setEvent_type] = useState("all");
    const [selResults, setSelResults] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");

    // common
    const opt_exchanges = c_opt_exchanges_all;
    const opt_groups = c_opt_strategy_grp_name_all;
    // common

    const otp_event_type = [
        { value: "all", name: "All" },
        { value: "s_market", name: "S-MARKET" },
        { value: "s_limit", name: "S-LIMIT" },
        { value: "m_market", name: "M-MARKET" },
        { value: "m_limit", name: "M-LIMIT" },
        { value: "auto_limit", name: "AUTO LIMIT" },
        { value: "auto_market", name: "AUTO MARKET" },
        { value: "deposit", name: "DEPOSIT" },
        { value: "withdraw", name: "WITHDRAW" },
    ];

    const otp_selResults = [
        { value: "all", name: "ALL" },
        { value: "buy", name: "BUY" },
        { value: "sell", name: "SELL" }
    ];
    // END SELECT

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    useEffect(() => {
        const data = {
            bot_id: "m_bot",
            msg: "mbot_ledger_history",
            exchange_name: exchange_name,
            ticker_name: coins,
            strategy_group_name: groups,
            strategy_name: strategy,
            event_type: event_type,
            position: selResults,
            dateFrom: dateFrom,
            dateTo: dateTo,
        };

        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                // console.log(res.data.result);
                setDatalogs(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exchange_name, coins, groups, strategy, event_type, selResults, dateFrom, dateTo]);

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                        <div>
                            <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name} />
                            &emsp;
                            <CoinsDiv2 exchange_name={exchange_name} opt_coins={opt_coins} send_setcoins={setCoins} _type="asset_all"/>
                            &emsp;
                            <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups} />
                            {/*&emsp;
                            <StrategyInputDiv send_setstrategy_name={setStrategy} />*/}
                            &emsp;
                            <span>Event Type: </span>
                            <select
                                onChange={(e) => {
                                    setEvent_type(e.target.value);
                                }}>
                                {otp_event_type.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>{" "}
                            &emsp;
                            <span>Position: </span>
                            <select
                                onChange={(e) => {
                                    setSelResults(e.target.value);
                                }}>
                                {otp_selResults.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <br />
                        <div>
                            <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                        </div>
                    </div>
                    <br />
                    <table>
                        <thead style={{ background: "#6CA0EC" }}>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Time</th>
                                <th>Event Type</th>
                                <th>Position (Bot)</th>
                                <th>Group</th>
                                <th>Asset</th>
                                <th>Initial Balance</th>
                                <th>Event Amount</th>
                                <th>End Balance</th>
                                <th>Change Amount</th>
                                <th>Change (%)</th>
                            </tr>
                        </thead>
                        <tbody style={{ background: "#283964" }}>
                            {datalogs.map((k, i) => (
                                <tr key={i}>
                                    <td>{i === 0 ? k.date : datalogs[i-1].date === k.date ? null : k.date}</td>
                                    <td>{k.time}</td>
                                    <td>{k.order_type === null ? "-" : k.order_type}</td>
                                    <td>{k.position === null ? "-" : k.position}</td>
                                    <td>{k.strategy_group_name === null ? "-" : k.strategy_group_name}</td>
                                    <td>{k.ticker_name === null ? "-" : k.ticker_name}</td>
                                    <td>{coins === "usdt" || coins === "krw" ? k.initial_fiat : k.initial_balance}</td>
                                    <td>{coins === "usdt" || coins === "krw" ? "-" : k.event_amount}</td>
                                    <td>{coins === "usdt" || coins === "krw" ? k.end_fiat : k.end_balance}</td>
                                    <td>{coins === "usdt" || coins === "krw" ? (parseFloat(k.end_fiat - k.initial_fiat).toLocaleString("ko-KR", locale_opt_8)) : (parseFloat(k.end_balance - k.initial_balance).toLocaleString("ko-KR", locale_opt_8))}</td>
                                    <td>{k.balance_change_percent === null ? "-" : parseFloat(k.balance_change_percent).toLocaleString("ko-KR", locale_opt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default LedgerHistory;

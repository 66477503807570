import React, { useState, useEffect } from "react";
import axios from "axios";
import HeaderNew from "../../headerFooterSide/HeaderNew";
import { c_opt_exchanges_all, c_opt_strategy_grp_name_all, locale_opt, locale_opt_8 } from "../../common/Commons";
import { C_coins, ExchangeDiv, ExchangeDiv_buy , ExchangeDiv_sell,CoinsDiv, GroupDiv, StrategyInputDiv, DateFromToDiv } from "../../common/Commons";

function IndividualJournal({handleLogoutapp}) {
  // select box valuable
  const [title, setTile] = useState()
  const [datalogs, setDatalogs] = useState([]);
  const [usdt_accumulate_total_profit,set_usdt_accumulate_total_profit] = useState(0.0)
  const [krw_accumulate_total_profit,set_krw_accumulate_total_profit] = useState(0.0)

  const [exchange_name_buy, setExchange_name_buy] = useState("all");
  const [exchange_name_sell, setExchange_name_sell] = useState("all");
  const [coins, setCoins] = useState("all");
  const [opt_coins, setOpt_coins] = useState([]);
  const [groups, setGroups] = useState("all");
  const [strategy, setStrategy] = useState("");
  const [event_type, setEvent_type] = useState("all");
  const [selResults, setSelResults] = useState("all");
  const [dateFrom, setDateFrom] = useState("all");
  const [dateTo, setDateTo] = useState("all");
  const [today_date,set_today_date] = useState("");

  // common
  const opt_exchanges = c_opt_exchanges_all;
  const opt_groups = c_opt_strategy_grp_name_all;
  // common

  const otp_event_type = [
      { value: "all", name: "All" },
      { value: "s_market", name: "S-MARKET" },
      { value: "s_limit", name: "S-LIMIT" },
      { value: "m_market", name: "M-MARKET" },
      { value: "m_limit", name: "M-LIMIT" },
      { value: "auto_limit", name: "AUTO LIMIT" },
      { value: "auto_market", name: "AUTO MARKET" },
      { value: "deposit", name: "DEPOSIT" },
      { value: "withdraw", name: "WITHDRAW" },
  ];

  const otp_selResults = [
      { value: "all", name: "ALL" },
      { value: "buy", name: "BUY" },
      { value: "sell", name: "SELL" }
  ];
  // END SELECT

  function coinsSet(_num) {
      setOpt_coins(_num);
  }
  function isEmptyObject(obj){
      if(obj.construct === Object && __dirname.isEemp(obj)) {
          return true;
      }
      return false;
  }


  useEffect(() => {
      const data = {
          bot_id: "a_bot",
          msg: "abot_daily_journal",
          exchange_name_buy: exchange_name_buy,
          exchange_name_sell: exchange_name_sell,
          ticker_name: coins,
          dateFrom: dateFrom,
          dateTo: dateTo,
      };

      axios
          .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
          .then((res) => {
              console.log(res.data.result1);
              //alert(isEmptyObject(res.data.result1))
              //alert(JSON.stringify(res.data.result1))
              //alert(res.data.result2)
              //alert(res.data.result3)
              //alert(res.data.result4)

              
              setDatalogs(res.data.result1);
              set_usdt_accumulate_total_profit(res.data.result2)
              set_krw_accumulate_total_profit(res.data.result3)
              set_today_date(res.data.result4)
          })
          .catch((err) => {
              console.log(err);
          });
  }, [exchange_name_buy,exchange_name_sell, coins, groups, strategy, event_type, selResults, dateFrom, dateTo]);


  useEffect(() => {
    setTile("Individual Journal")
    C_coins(coinsSet);
    
  }, []);

    return (
        <div>
          {/* tile */}
          <HeaderNew handleLogoutapp={handleLogoutapp} Title={title} />
          {/* botstatus content */}
            <div className="first_div">
                <div className="second_div">
                    <h3></h3>
                    <br />
                    <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                        <div>
                            <ExchangeDiv_buy opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name_buy}  />
                            <ExchangeDiv_sell opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name_sell}  />
                            &emsp;
                            <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} />
                        </div>
                        <br />
                        <div>
                            <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                        </div>
                    </div>
                    <br />
                    <br />
                    {datalogs && datalogs.map((k, i) => (
                      <table>
                        <tbody>
                          <tr>
                            <td className="text_color_back" colSpan="6" style={{ textAlign: "left", fontWeight:"bold", verticalAlign: "middle", background: "#9dc3e6" }}>
                              Coin : {k['sell_arbitrage'].coin} , Sold @ {k['sell_arbitrage'].sell_exchange_name} , Bought @ {k['buy_arbitrage'].buy_exchange_name}
                            </td>
                          </tr>
                          <tr>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Ave.Targeted Profit​</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].profit_gole_ration}</td>
                            
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Ave.Gross Profit</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].profit_gross_rate}</td>

                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Ave. Net Profit​</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].profit_net_rate}</td>

                          </tr>

                          {/* Beginning Balance*/}
                          <tr>
                            <td className="text_color_back" colSpan="6" style={{ textAlign: "left",fontWeight:"bold", verticalAlign: "middle", background: "#9dc3e6" }}>
                            Beginning Balance
                            </td>
                          </tr>
                          
                          {/* Fiat*/}
                          <tr>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Initial Wallet of KRW</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].krw_begin_init_fiat} KRW​</td>
                            
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Initial Wallet of USDT</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].usdt_begin_init_fiat} USDT</td>

                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Initial Wallet of {k['arbitrage_root'].binance_ok_begin_init_val_unit}</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].binance_ok_begin_init_val} {k['arbitrage_root'].binance_ok_begin_init_val_unit}</td>

                          </tr>
                          {/* Crypto*/}
                          <tr>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Initial Crypto of KRW</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].krw_begin_init_crypto} {k['arbitrage_root'].krw_begin_init_crypto_unit}</td>
                            
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Initial Crypto of USDT</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].usdt_begin_init_crypto} {k['arbitrage_root'].usdt_begin_init_crypto_unit}</td>

                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Initial Crypto of {k['arbitrage_root'].total_begin_init_crypto_unit}</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].total_begin_init_crypto} {k['arbitrage_root'].total_begin_init_crypto_unit}</td>

                          </tr>

                          {/* Ending Balance*/}
                          <tr>
                            <td className="text_color_back" colSpan="6" style={{ textAlign: "left",fontWeight:"bold",  verticalAlign: "middle", background: "#9dc3e6" }}>
                            Ending Balance
                            </td>
                          </tr>
                          
                          {/* Fiat*/}
                          <tr>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>After Wallet of KRW</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].krw_final_value} KRW​</td>
                            
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>After Wallet of USDT</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].usdt_final_value} USDT</td>

                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>After Wallet of {k['arbitrage_root'].binance_ok_end_init_val_unit}</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].binance_ok_end_init_val} {k['arbitrage_root'].binance_ok_end_init_val_unit}</td>

                          </tr>
                          {/* Crypto*/}
                          <tr>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>After Crypto of KRW</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].krw_end_init_crypto} {k['arbitrage_root'].krw_end_init_crypto_unit}</td>
                            
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>After Crypto of USDT</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].usdt_end_init_crypto} {k['arbitrage_root'].usdt_end_init_crypto_unit}</td>

                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>After Crypto of {k['arbitrage_root'].total_end_init_crypto_unit}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].total_end_init_crypto} {k['arbitrage_root'].total_end_init_crypto_unit}</td>

                          </tr>

                          {/* Expense*/}
                          <tr>
                            <td className="text_color_back" colSpan="6" style={{ textAlign: "left",fontWeight:"bold",  verticalAlign: "middle", background: "#9dc3e6" }}>
                            Expense (Transaction Fee)
                            </td>
                          </tr>
                          
                          {/* Fiat*/}
                          <tr>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>KRW Spent</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].krw_fee} {k['arbitrage_root'].krw_fee_unit}</td>
                            
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>USDT Spent</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].usdt_fee} {k['arbitrage_root'].usdt_fee_unit}</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>{k['arbitrage_root'].binance_ok_end_total_val_unit} Spent</td>
                            <td className="text_color_back" style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].binance_ok_end_total_val} {k['arbitrage_root'].binance_ok_end_total_val_unit}</td>

                          </tr>



                          {/* Daily Summer*/}
                          <tr>
                            <td className="text_color_back" colSpan="6" style={{ textAlign: "left",fontWeight:"bold",  verticalAlign: "middle", background: "#9dc3e6" }}>
                            Daily Summer
                            </td>
                          </tr>
                          
                          {/* 1 */}
                          <tr>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>KRW Change</td>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].krw_change_fiat} {k['arbitrage_root'].krw_change_fiat_unit}</td>
                            
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>USDT Change</td>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].usdt_change_fiat} {k['arbitrage_root'].usdt_change_fiat_unit}</td>

                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>{k['arbitrage_root'].binance_ok_end_total_val_unit} Change</td>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].binance_ok_end_total_val} {k['arbitrage_root'].binance_ok_end_total_val_unit}</td>

                          </tr>
                          {/* 2 */}
                          <tr>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Pending KRW Order</td>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].krw_peding_fiat} {k['arbitrage_root'].krw_peding_fiat_unit}</td>
                            
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Pending BTC Order</td>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].usdt_peding_fiat} {k['arbitrage_root'].usdt_peding_fiat_unit}</td>

                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#f2f2f2" }}>Neet KRW</td>
                            <td className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#ffffff" }}>{k['arbitrage_root'].total_net_profit} {k['arbitrage_root'].total_net_profit_unit}</td>

                          </tr>

                          {/* Finish  line */}
                          <tr>
                            <td colSpan="6" className="text_color_back"  style={{ textAlign: "center", verticalAlign: "middle", background: "#ed7d31" }}>
                            </td>
                          </tr>


                        </tbody>
                      </table>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default IndividualJournal;

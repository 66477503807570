import React, { useState, useEffect } from "react";
import axios from "axios";

import { c_opt_strategy_grp } from "../../../common/Commons";
import { C_coins, ExchangesSelector,ExchangesSelector_Future, CoinsDiv, GroupDiv } from "../../../common/Commons";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function Register() {
    const [exchangesSelect, setExchangesSelect] = useState(sessionStorage.getItem("sbot_walletAssetRegister"));
    const [opt_coins, setOpt_coins] = useState(null);

    // DATA
    const [nowDate, setNowDate] = useState("");
    const [nowHour, setNowHour] = useState("");
    const [nowMin, setNowMin] = useState("");
    const [nowSec, setNowSec] = useState("");
    const [coins, setCoins] = useState("ada");
    const [groups, setGroups] = useState("1");
    const [c_crypto, setC_crypto] = useState("");
    const [c_fiat, setC_fiat] = useState("");
    const [opt_transaction_click, setOpt_transaction_click] = useState("deposit");
    const [opt_transaction_data, setOpt_transaction_data] = useState("");
    
    // common
    const opt_groups = c_opt_strategy_grp;
    // common

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    const opt_transaction = [
        { value: "deposit", name: "Deposit" },
        { value: "withdraw", name: "Withdraw" },
    ];

    function Now_time() {
        const today = new Date()
        //const this_date = today.toISOString().slice(0, 10)
        //document.getElementById("register_date").value = this_date
        //setNowDate(dateString)

        var year = today.getFullYear();
        var month = ('0' + (today.getMonth() + 1)).slice(-2);
        var day = ('0' + today.getDate()).slice(-2);
        var dateString = year + '-' + month  + '-' + day;
        setNowDate(dateString)

        document.getElementById("register_date").value = dateString
        document.getElementById("register_hour").value = today.getHours()
        document.getElementById("register_min").value = today.getMinutes()
        document.getElementById("register_sec").value = today.getSeconds()
        
        
        setNowHour(today.getHours())
        setNowMin(today.getMinutes())
        setNowSec(today.getSeconds())

    }

    function Register_data(){
        const data = {
            bot_id: "m_bot",
            msg: "wallet_asset_register_records",
            which: "register",
            exchange: exchangesSelect,
            exchange_name: exchangesSelect === "1" ? "BINANCE" : exchangesSelect === "2" ? "UPBIT" : exchangesSelect === "3" ? "BITHUMB" : exchangesSelect === "4" ? "OKEX" : exchangesSelect === "5" ? "MEXC" : "BITGET",
            registered_date: nowDate !== "" ? nowDate+" "+nowHour+":"+ +nowMin+":"+nowSec : "",
            initial_fiat: c_fiat,
            initial_balance: c_crypto,
            asset: coins,
            strategy_grp: groups,
            strategy_grp_name: groups === "1" ? "MLC" : groups === "2" ? "P2V" :  groups === "3" ? "TBT" : groups === "4" ? "A_C" : "MANUAL",
            transaction_type: opt_transaction_click,
            transaction_data: opt_transaction_data
        };

        axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
        .then((res) => {
            console.log(res.data)
            if (res.data.result === "OK"){
                alert("OK")
            }else{
                alert("ERROR")
            }
        })
        .catch((err) => {
            console.log(err);
            alert("ERROR")
        })
        .finally(() => {
            window.location.reload();
        });
    }

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    useEffect(() => {
        const data = {
            bot_id: "m_bot",
            msg: "manual_trading",
            what: "get_info",
            exchange: exchangesSelect,
            coin: coins,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                let c_c_f = JSON.parse(String(res.data.c_balance));

                let exchange_style, where_style;
                if (exchangesSelect === "1") {
                    exchange_style = "binance_";
                    where_style = -1;
                } else if (exchangesSelect === "2") {
                    exchange_style = "upbit_";
                    where_style = +5;
                } else if (exchangesSelect === "3") {
                    exchange_style = "bithumb_";
                    where_style = +10;
                } else if (exchangesSelect === "4") {
                    exchange_style = "okex_";
                    where_style = +15;
                } else if (exchangesSelect === "5") {
                    exchange_style = "mexc_";
                    where_style = +20;
                } else if (exchangesSelect === "6") {
                    exchange_style = "bitget_";
                    where_style = +25;
                }

                c_c_f[exchange_style + (parseInt(groups) + where_style)].forEach((element) => {
                    if (element.crypto === coins.toUpperCase()) {
                        setC_crypto(element.free);
                    }
                    if (element.crypto === "USDT" || element.crypto === "KRW") {
                        setC_fiat(element.free)
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exchangesSelect, coins, groups]);


    return (
        <div>
        <div className="first_div">
            <div className="second_div">
            </div>
            <ExchangesSelector exchangesSelect={exchangesSelect} send_esSet={setExchangesSelect} session_name={"sbot_walletAssetRegister"} />

            <div style={{ fontSize: "11px", color: "#6CA0EC", margin: "0.5em" }}>
                {/* <h6>Crypto : {c_crypto}  {c_fiat}</h6> */}
                {/* <br /> */}
                Date :<input id="register_date" type="date" onChange={(e) => setNowDate(e.target.value)}/>
                &emsp;
                <input id="register_hour" type="number" size="5" onChange={(e) => setNowHour(e.target.value)}/> H &emsp;
                <input id="register_min" type="number" size="5" onChange={(e) => setNowMin(e.target.value)}/> M &emsp;
                <input id="register_sec" type="number" size="5" onChange={(e) => setNowSec(e.target.value)}/> S &emsp;
                <button className="btn btn-warning btn-sm" onClick={() => Now_time()}>Now</button> &emsp;
                {!opt_coins && <p> Loading...</p>}
                {opt_coins && <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} _type={"asset"} />}
                &emsp;
                {!opt_groups && <p> Loading...</p>}
                {opt_groups && <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups} _type={"noall"}/>}
                
                &emsp;
                <span>Transaction: </span>
                <select
                    onChange={(e) => {
                        setOpt_transaction_click(e.target.value);
                    }}>
                    {opt_transaction.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
                &emsp;
                <input type="number" size="15" onChange={(e) => setOpt_transaction_data(e.target.value)}/>
                &emsp;
                <button className="btn btn-info btn-sm" onClick={() => Register_data()}>Save Record</button> &emsp;
            </div>
            <br />
        </div>
        </div>
    );
}

export default Register;

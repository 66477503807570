import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { locale_opt, locale_opt_8 } from "../../common/Commons";
import { DateFromToDiv_B } from "../../common/Commons";
import "./DashBoard";
import "./Dashbord.css";


import { Data } from "./Data";

function BotStatus() {
   
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [datalogs_spot, setDatalogsSpot] = useState([]);
    const [datalogs_spot_stk, setDatalogsSpot_stk] = useState([]);
    const [datalogs_future, setDatalogsFuture] = useState([]);
    const [datalogs_future_stk, setDatalogsFuture_stk] = useState([]);
    const [totoal_value, setTotalValue] = useState("");
    const [datalogsMontly, setDatalogsMontly] = useState(null);
    
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [chartYear, setChartYear] = useState(new Date());
    const [chartMonth, setChartMonth] = useState("");
    const [searchMode, setSearchMode] = useState("all");
   
    function getDataAll() {

        const limit_cnt = 30;
        const data = {
            bot_id: "common_bot",
            msg: "get_bot_satus",
        };
        
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/common_bot`, data)
            .then((res) => {
                //alert()
                if (res.data.msg == "ok") {
                    //console.log("#####################################")
                    //console.log(JSON.stringify(res.data))
                    //console.log("#####################################")
                    setDatalogsSpot(res.data.data_array_spot);
                    setDatalogsSpot_stk(res.data.data_array_spot_stk);
                    setDatalogsFuture(res.data.data_array_future);
                    setDatalogsFuture_stk(res.data.data_array_future_stk);
                } else {
                    setDatalogsSpot([]);
                    setDatalogsSpot_stk([]);
                    setDatalogsFuture([]);
                    setDatalogsFuture_stk([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }


    useEffect(() => {
        //getDataAll()
        const intervalId_coin = setInterval(() => {
            getDataAll()
        }, 2000)
        return () => clearInterval(intervalId_coin);
    }, []);


    

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ display: "flex", margin: "0.2em 0em 0em 2em" }}>
                        <h5 style={{ margin: "0px 3.5em 0px 0px" }}> Bot Status</h5>
                    </div>
                    <div style={{ display: "flex", margin: "0.5em 0em 0em 2em" }}>
                        <div style={{ width: "45%" }}>
                            <div>
                                <span>[Spot] An Open Transaction</span>
                            </div>
                            <div style={{ margin: "0.5em 0em 0em 0em" }}>
                                <table>
                                    <thead>
                                        <tr style={{ background: "#4472C4" }}>
                                            <th>Type</th>
                                            <th>Exchange</th>
                                            <th>Strategy Goup</th>
                                            <th>Coin</th>
                                            <th>Strategy Name</th>
                                            <th>Qty</th>
                                            <th>Trading Receive Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datalogs_spot.map((k, i) => (
                                            <tr key={i} >
                                                <td>{k.type}</td>
                                                <td>{k.exchange_name}</td>
                                                <td>{k.strategy_group_name}</td>
                                                <td>{k.coin_type}</td>
                                                <td>{k.strategy_name}</td>
                                                <td>{k.crypto_qty}</td>
                                                <td>{k.alarm_sent_time}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div style={{ width: "45%" , margin: "0em 0em 0em 13em" }}>
                            <div>
                                <span>[Spot STK] An Open Transaction</span>
                            </div>
                            <div style={{ margin: "0.5em 0em 0em 0em" }}>
                                <table>
                                <thead>
                                        <tr style={{ background: "#4472C4" }}>
                                            <th>Type</th>
                                            <th>Exchange</th>
                                            <th>Strategy Goup</th>
                                            <th>Coin</th>
                                            <th>Strategy Name</th>
                                            <th>Qty</th>
                                            <th>Trading Receive Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datalogs_spot_stk.map((k, i) => (
                                            <tr key={i} >
                                                <td>{k.type}</td>
                                                <td>{k.exchange_name}</td>
                                                <td>{k.strategy_group_name}</td>
                                                <td>{k.coin_type}</td>
                                                <td>{k.strategy_name}</td>
                                                <td>{k.crypto_qty}</td>
                                                <td>{k.alarm_sent_time}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", margin: "0.5em 0em 0em 2em" }}>
                        <div style={{ width: "45%" }}>
                            <div>
                            <span>[Future] An Open Transaction</span>
                            </div>
                            <div style={{ margin: "0.5em 0em 0em 0em" }}>
                                <table>
                                    <thead>
                                        <tr style={{ background: "#4472C4" }}>
                                            <th>Type</th>
                                            <th>Exchange</th>
                                            <th>Strategy Goup</th>
                                            <th>Coin</th>
                                            <th>Strategy Name</th>
                                            <th>Qty</th>
                                            <th>Trading Receive Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datalogs_future.map((k, i) => (
                                            <tr key={i} >
                                                <td>{k.type}</td>
                                                <td>{k.exchange_name}</td>
                                                <td>{k.strategy_group_name}</td>
                                                <td>{k.coin_type}</td>
                                                <td>{k.strategy_name}</td>
                                                <td>{k.crypto_qty}</td>
                                                <td>{k.alarm_sent_time}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div style={{ width: "45%" , margin: "0em 0em 0em 13em" }}>
                            <div>
                                <span>[Future STK] An Open Transaction</span>
                            </div>
                            <div style={{ margin: "0.5em 0em 0em 0em" }}>
                                <table>
                                <thead>
                                        <tr style={{ background: "#4472C4" }}>
                                            <th>Type</th>
                                            <th>Exchange</th>
                                            <th>Strategy Goup</th>
                                            <th>Coin</th>
                                            <th>Strategy Name</th>
                                            <th>Qty</th>
                                            <th>Trading Receive Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datalogs_future_stk.map((k, i) => (
                                            <tr key={i} >
                                                <td>{k.type}</td>
                                                <td>{k.exchange_name}</td>
                                                <td>{k.strategy_group_name}</td>
                                                <td>{k.coin_type}</td>
                                                <td>{k.strategy_name}</td>
                                                <td>{k.crypto_qty}</td>
                                                <td>{k.alarm_sent_time}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
}

export default BotStatus;

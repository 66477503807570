import React, { useState, useEffect } from "react";
import Highcharts, { color } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import "../../../App.css";

const HighChart_Sentinel = ({options}) => {

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
            />
        </div>
        
    );
};
export default HighChart_Sentinel;
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { locale_opt, locale_opt_8 } from "../../common/Commons";
import { DateFromToDiv_B } from "../../common/Commons";
import "./DashBoard";
import "./Dashbord.css";


import { Data } from "./Data";
//import BarChart from "./RevenuStatus_BarChart";

function RevenueStatus() {
   
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [datalogs, setDatalogs] = useState([]);
    const [datalogsMontly, setDatalogsMontly] = useState(null);
    
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();


    const [chartYear, setChartYear] = useState(new Date());
    const [chartMonth, setChartMonth] = useState("");

    const [searchMode, setSearchMode] = useState("all");
   
    function getDataAll() {

        const limit_cnt = 30;
        const data = {
            bot_id: "common_bot",
            msg: "get_revenu_status",
            pgn: pageNumber,
            limit_cnt: limit_cnt,
            dateFrom: dateFrom,
            dateTo: dateTo,
            search_mode: searchMode ,
        };
        //alert(JSON.stringify(data))
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/common_bot`, data)
            .then((res) => {
                //alert(JSON.stringify(res.data))
                if (res.data.msg == "ok") {
                    const page_cnt_math = Math.ceil(res.data.count / limit_cnt);
                    setPageCount(page_cnt_math);
                    setDatalogs(res.data.result);
                } else {
                    setPageCount(0);
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    function getDataMontly() {

        const dateFrom_year = '2023-01-01 00:00:00'
        const dateTo_year = '2023-12-31 23:59:59'
        const data = {
            bot_id: "common_bot",
            msg: "get_revenu_status_monthly",
            dateFrom: dateFrom_year,
            dateTo: dateTo_year,
        };
        //alert(JSON.stringify(data))
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/common_bot`, data)
            .then((res) => {
                
                if (res.data.msg == "ok") {
                    setDatalogsMontly(res.data.result)
                    console.log(res.data.result)
                } else {
                    setDatalogsMontly([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    useEffect(() => {
        getDataAll()
        getDataMontly()
    }, [pageNumber,  dateFrom, dateTo,searchMode]);
    

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ display: "flex", margin: "0.2em 0em 0em 2em" }}>
                        <h5 style={{ margin: "0px 3.5em 0px 0px" }}> Revenue Status</h5>
                    </div>
                    <div style={{ display: "flex", margin: "0.5em 0em 0em 2em" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ margin: "0.5em 0em 0em 0em" }}>
                                <button className={`btn btn-sm Dasbord_Button ${searchMode ==='all' ? 'btn-success' : 'btn-secondary'}`} onClick={() => setSearchMode('all')}>
                                    All
                                </button>
                                <button className={`btn btn-sm Dasbord_Button ${searchMode ==='spot' ? 'btn-success' : 'btn-secondary'}`}   onClick={() => setSearchMode('spot')}>
                                    Spot
                                </button>
                                <button className={`btn btn-sm Dasbord_Button ${searchMode ==='future' ? 'btn-success' : 'btn-secondary'}`}   onClick={() => setSearchMode('future')}>
                                    Future
                                </button>
                                <DateFromToDiv_B send_data_from={setDateFrom} send_data_to={setDateTo} />
                            </div>
                            <div style={{ margin: "5em 0em 0em 0em" }}>
                                <table>
                                    <thead>
                                        <tr style={{ background: "#4472C4" }}>
                                            <th style={{ width: "35%" }}>Type</th>
                                            <th style={{ width: "30%" }}>Count</th>
                                            <th style={{ width: "35%" }}>Probit Rate</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {datalogs.map((k, i) => (
                                        <tr key={i} >
                                            <td>{k.type}</td>
                                            <td>{k.count}</td>
                                            <td>{k.crypto_profit_ror_total}</td>
                                        </tr>
                                    ))}
                                    </tbody>

                                    
                                </table>
                            </div>
                        </div>
                        <div style={{ width: "50%" , margin: "0.2em 0em 0em 7em" }}>
                            <div style={{ float: "right" }}> 
                                <button className="btn btn-primary btn-sm Year_Button"  onClick={() => window.location.reload()}>
                                        2023
                                </button>
                                {/*
                                <select value={chartMonth} onChange={setChartMonth}>
                                    <option value="0">Weeky</option>
                                    <option value="1">1M</option>
                                    <option value="2">2M</option>
                                    <option value="3">3M</option>
                                    <option value="4">4M</option>
                                    <option value="5">5M</option>
                                    <option value="6">6M</option>
                                    <option value="7">7M</option>
                                    <option value="8">8M</option>
                                    <option value="9">9M</option>
                                    <option value="10">10M</option>
                                    <option value="11">11M</option>
                                    <option value="12">12M</option>
                                </select>
                                 */}
                            </div>
                             {/*
                            <div>
                            {datalogsMontly && datalogsMontly  ? (
                                <>
                                        <BarChart Data={datalogsMontly} />
                                </>
                            ) : null}
                            </div>
                            */}
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
}

export default RevenueStatus;

import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import Modal from "../../common/Modal";
import HeaderNew from "../../headerFooterSide/HeaderNew";
import ApiMange_Spot from "./ApiMange_Spot";
import ApiMange_Spot_STK from "./ApiMange_Spot_STK";

import ApiMange_Future from "./ApiMange_Future";
import ApiMange_Future_STK from "./ApiMange_Future_STK";

function ApiMange({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [modalInputValue_1, setModalInputValue_1] = useState();
    const [modalInputValue_2, setModalInputValue_2] = useState();
    const [datalogs, setDatalogs] = useState([]);

    const openModal = (_id, exchanges, grp, p_key, s_key) => {
        setModalOpen(true);
        setModalData([_id, exchanges, grp]);
        setModalInputValue_1(p_key);
        setModalInputValue_2(s_key);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const Change_keys = () => {
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
        const data = {
            msg: "put_api_keys",
            _id: modalData[0],
            p_key: modalInputValue_1,
            s_key: modalInputValue_2,
        };

        axios
            .post(`${python_server_url}/admin_manager`, data)
            .then((res) => {
                alert("Completed Updated");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                alert("Sorry.. Failed");
                window.location.reload();
            });
    };

    useEffect(() => {
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
        const data = {
            msg: "get_api_keys",
        };

        axios
            .post(`${python_server_url}/admin_manager`, data)
            .then((res) => {
                setDatalogs(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    useEffect(() => {
        setTile("API Management")
    }, []);


    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <br />
            <ApiMange_Spot />
            <br />
            <ApiMange_Spot_STK />
            <br />
            <ApiMange_Future/>
            <br />
            <ApiMange_Future_STK/>
        </div>
    );
}

export default ApiMange;

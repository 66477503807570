import React, { useState, useEffect } from "react";
import axios from "axios";

import ReactPaginate from "react-paginate";
import { locale_opt, c_opt_exchanges_all, c_opt_strategy_grp_all, c_opt_tfs_all, c_opt_order_type_all } from "../../../common/Commons";
import { C_coins, AscDesc, ExchangeDiv, CoinsDiv, GroupDiv, StrategyInputDiv, TFDiv, DateFromToDiv } from "../../../common/Commons";

import "./logRecords.css";

function LogRecordsTransaction() {
    const pairs = [1];

    // select box valuable
    const [exchanges, setExchanges] = useState("all");
    const [coins, setCoins] = useState("all");
    const [opt_coins, setOpt_coins] = useState([]);
    const [groups, setGroups] = useState("all");
    const [strategy, setStrategy] = useState("");
    const [positions, setPositions] = useState("all");
    const [tfs, setTfs] = useState("all");
    const [operation, setOperation] = useState("all");
    const [orderType, setOrderType] = useState("all");
    const [selResults, setSelResults] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [newOld, setNewOld] = useState("desc");

    const [datalogs, setDatalogs] = useState([]);

    const [remountComponent, setRemountComponent] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    // common
    const opt_exchanges = c_opt_exchanges_all;
    const opt_groups = c_opt_strategy_grp_all;
    const opt_tfs = c_opt_tfs_all;
    // common

    const otp_positions = [
        { value: "all", name: "All" },
        { value: "buy", name: "BUY" },
        { value: "sell", name: "SELL" },
    ];

    const otp_operation = [
        { value: "all", name: "All" },
        { value: "manual", name: "manual" },
    ];

    const opt_order_type = c_opt_order_type_all;

    const otp_selResults = [
        { value: "all", name: "ALL" },
        { value: "ordered", name: "Ordered" },
        { value: "finished", name: "Finished" },
        { value: "canceled", name: "Canceled" },
        { value: "error", name: "Error" },
    ];
    // END SELECT

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        const limit_cnt = 30;
        const data = {
            bot_id: "m_bot",
            msg: "get_transaction_log_records",
            pgn: pageNumber,
            limit_cnt: limit_cnt,
            exchanges: exchanges,
            coins: coins,
            groups: groups,
            strategy: strategy,
            positions: positions,
            tfs: tfs,
            operation_type: operation,
            order_type: orderType,
            selResults: selResults,
            dateFrom: dateFrom,
            dateTo: dateTo,
            newOld: newOld,
        };
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                if (res.data.result.length !== 0) {
                    const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
                    setPageCount(page_cnt_math);
                    setDatalogs(res.data.result[1]);
                } else {
                    setPageCount(0);
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [pageNumber, exchanges, coins, groups, strategy, positions, orderType, tfs, operation, selResults, dateFrom, dateTo, newOld]);

    useEffect(() => {
        changePage({ selected: 0 });
        setRemountComponent(Math.random());
    }, [exchanges, coins, groups, strategy, positions, orderType, tfs, selResults, dateFrom, dateTo, newOld]);

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    return (
        <div className="first_div">
            <div className="second_div">
                <h5>Transaction Log</h5>
                <br />
                <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                    <div>
                        <ExchangeDiv opt_exchanges={opt_exchanges} send_setexchanges={setExchanges} />
                        &emsp;
                        <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} />
                        &emsp;
                        <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups} />
                        &emsp;
                        <StrategyInputDiv send_setstrategy_name={setStrategy} />
                        &emsp;
                        <span>Position: </span>
                        <select
                            onChange={(e) => {
                                setPositions(e.target.value);
                            }}>
                            {otp_positions.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <TFDiv opt_tfs={opt_tfs} send_settfs={setTfs} />
                        &emsp;
                        <span>OPERATION: </span>
                        <select
                            onChange={(e) => {
                                setOperation(e.target.value);
                            }}>
                            {otp_operation.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>{" "}
                        &emsp;
                        <span>Order Type: </span>
                        <select
                            onChange={(e) => {
                                setOrderType(e.target.value);
                            }}>
                            {opt_order_type.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <span>Results: </span>
                        <select
                            onChange={(e) => {
                                setSelResults(e.target.value);
                            }}>
                            {otp_selResults.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <div>
                        <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                    </div>
                    <br />
                    <AscDesc newOld={newOld} send_setNewOld={setNewOld} />
                    <br />
                </div>
                <table>
                    <thead>
                        <tr style={{ background: "black" }}>
                            <th></th>
                            <th colSpan="6">Strategy Information</th>
                            <th colSpan="2">Initial Balance</th>
                            <th colSpan="2">End Balance</th>
                            <th colSpan="3">Price</th>
                            <th colSpan="9">Trading Information​</th>
                            <th colSpan="3">Time Information​</th>
                        </tr>
                        <tr style={{ background: "#4472c4" }}>
                            <th>#</th>
                            <th>Exchange</th>
                            <th>Group</th>
                            <th>Strategy</th>
                            <th>TF</th>
                            <th>Position (bot)</th>
                            <th>Coin</th>
                            <th>Crypto</th>
                            <th>Fiat</th>
                            <th>Crypto</th>
                            <th>Fiat</th>
                            <th>Trigger</th>
                            <th>Alarm</th>
                            <th>Trading</th>
                            {/*<th>Operation</th>*/}
                            <th>Order Type</th>
                            <th>Crypto Qty</th>
                            <th>Fiat Qty</th>
                            <th>Fee</th>
                            <th>Percent</th>
                            <th>Result</th>
                            <th>Msg 1</th>
                            <th>Msg 2</th>
                            <th>Transaction</th>
                            <th>Alarm Received</th>
                            <th>Alarm Sent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datalogs.map((k, i) => (
                            <tr className="logTr" key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F", cursor: "pointer", hover: { background: "red" } }}>
                                <td>{i + 1}</td>
                                <td>{k.exchange_name}</td>
                                <td>{k.strategy_group}</td>
                                <td>{k.strategy_name}</td>
                                <td>{k.time_frame}</td>
                                <td>
                                    {k.position} {k.bot_cnt !== null ? `(${k.bot_cnt})` : "()"}
                                </td>
                                <td>{k.coin_type}</td>
                                <td>{parseFloat(k.initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.total_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.total_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.trigger_price).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.price).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.current_crypto_price).toLocaleString("ko-KR", locale_opt)}</td>
                                {/*<td>{k.operation_type}</td>*/}
                                <td>{k.order_type}</td>
                                <td>{parseFloat(k.crypto_qty).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.fiat_qty).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.fee_paid).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{k.percent}</td>
                                <td>{k.msg}</td>
                                <td>{k.msg_dtl}</td>
                                <td>{k.msg_dtl_2}</td>
                                <td>{String(k.transaction_time).substring(0, 19)}</td>
                                <td>{k.alarm_received_time}</td>
                                <td>{k.alarm_sent_time}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div key={remountComponent}>
                    <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    );
}

export default LogRecordsTransaction;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Modal from "../../../common/Modal";
import KendoExcel from "../../../common/KendoExcel";

import { c_opt_strategy_grp_all, locale_opt } from "../../../common/Commons";
import { C_coins, ExchangesSelector, AscDesc, CoinsDiv, GroupDiv, DateFromToDiv } from "../../../common/Commons";

function Records() {
    const pairs = [1];

    const [exchangesSelect, setExchangesSelect] = useState(sessionStorage.getItem("sbot_walletAssetRecords"));
    const [opt_coins, setOpt_coins] = useState(null);
    const [getRecords, setGetRecords] = useState([]);
    const [eachBalance, setEachBalance] = useState([]);
    const [depositTotal, setDepositTotal] = useState(0);
    const [withdrawTotal, setWithdrawTotal] = useState(0);
    const [balanceTotal, setBalanceTotal] = useState(0);

    // OPTIONS
    const [coins, setCoins] = useState("all");
    const [groups, setGroups] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [newOld, setNewOld] = useState("desc");

    // common
    const opt_groups = c_opt_strategy_grp_all;
    // common

    // PAGEING
    const [remountComponent, setRemountComponent] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    // MODAL
    const [modalOpen, setModalOpen] = useState(false);

    const [record_id, setRecord_id] = useState(1);
    const [record_exchange, setRecord_exchange] = useState(1);
    const [record_time, setRecord_time] = useState(1);
    const [record_coins, setRecord_coins] = useState(1);
    const [record_groups, setRecord_groups] = useState(1);
    const [opt_transaction_deposit, setOpt_transaction_deposit] = useState(0);
    const [opt_transaction_withdraw, setOpt_transaction_withdraw] = useState(0);
    
    const openModal = (name) => {
        setModalOpen(true);
        console.log(name)
        setRecord_id(name.id)
        setRecord_exchange(name.exchange_name)
        setRecord_time(name.registered_date)
        setRecord_coins(name.asset)
        setRecord_groups(name.strategy_grp_name)
        name.deposit !== "0" ? setOpt_transaction_deposit(name.deposit) : setOpt_transaction_withdraw(name.withdraw)
        
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    function ChangeContents(_type){
        const data = {
            bot_id: "m_bot",
            msg: "wallet_asset_register_records",
            which: _type,
            id: record_id,
            exchange: record_exchange === "BINANCE" ? "1" : record_exchange === "UPBIT" ? "2" : record_exchange === "BITHUMB" ? "3" : record_exchange === "OKEX" ? "4" :record_exchange === "MEXC" ? "5" : "6",
            exchange_name: record_exchange,
            registered_date: record_time,
            asset: record_coins,
            strategy_grp: groups === "1" ? "MLC" : groups === "2" ? "P2V" :  groups === "3" ? "TBT" : groups === "4" ? "A_C" : "MANUAL",
            strategy_grp_name: record_groups,
            transaction_deposit: opt_transaction_deposit,
            transaction_withdraw: opt_transaction_withdraw
        };

        axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
        .then((res) => {
            console.log(res.data)
            if (res.data.result === "OK"){
                //alert("OK")
            }else{
                alert("ERROR")
            }
        })
        .catch((err) => {
            console.log(err);
            alert("ERROR")
        })
        .finally(() => {
            window.location.reload();
        });
    }
    // MODAL END

    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    
    useEffect(() => {
        const limit_cnt = 30;
        C_coins(coinsSet);

        const data = {
            bot_id: "m_bot",
            msg: "wallet_asset_register_records",
            limit_cnt: limit_cnt,
            pgn: pageNumber,
            which: "records",
            exchange: exchangesSelect,
            asset: coins,
            strategy_grp: groups,
            dateFrom: dateFrom,
            dateTo: dateTo,
            newOld: newOld,
        };

        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                setDepositTotal(0)
                setWithdrawTotal(0)
                setBalanceTotal(0)
                if (res.data.result.length !== 0) {
                setPageCount();
                    const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt)
                    setPageCount(page_cnt_math);
                    
                    let result = res.data.result
                    
                    setGetRecords(result);
                    
                    //if (coins !== "all" && groups !== "all") {
                    if (true) {
                        let sum_deposit = 0;
                        let sum_withdraw = 0;
                        let balance = []
            
                        result.forEach((element) => {
                            sum_deposit += parseFloat(element.deposit);
                            sum_withdraw += parseFloat(element.withdraw);
                        });
                        
                        if (newOld === "desc") {
                            result = [...result].reverse();
                        }
                        
                        for(let i=0; i<result.length; i++){
                            if (i === 0) {
                                balance.push(result[i].deposit-result[i].withdraw)
                            }else{
                                const real_b = (result[i].deposit-result[i].withdraw) + balance[i-1]
                                balance.push(real_b)
                            }
                        }
            
                        if (newOld === "desc") {
                            setEachBalance(balance.reverse())
                        }else{
                            setEachBalance(balance)
                        }
                        
                        setDepositTotal(sum_deposit);
                        setWithdrawTotal(sum_withdraw);
                        setBalanceTotal(sum_deposit - sum_withdraw);
                        }

                } else {
                    setPageCount(0);
                    setGetRecords([]);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("ERROR");
            });
    }, [pageNumber, exchangesSelect, coins, groups, dateFrom, dateTo, newOld]);

    useEffect(() => {
        changePage({ selected: 0 });
        setRemountComponent(Math.random());
    }, [exchangesSelect, coins, groups, dateFrom, dateTo, newOld]);

    return (
        <div className="first_div">
            <div className="second_div">
                <div style={{ display: "flex" }}>
                    <h5>Wallet Asset Records</h5>
                    &emsp;&emsp;
                    <button className="btn btn-success btn-sm" style={{ marginTop: "0.2em", fontSize: "12px" }} onClick={() => window.location.reload()}>
                        Refresh
                    </button>
                    &emsp;&emsp;
                    {getRecords.length !== 0 && <KendoExcel file_name={"walletRecords"} table_datas={getRecords}/>}
                    
                </div>
            </div>
            <ExchangesSelector exchangesSelect={exchangesSelect} send_esSet={setExchangesSelect} session_name={"sbot_walletAssetRecords"} _type={"all"} />

            <div style={{ fontSize: "11px", color: "#6CA0EC", margin: "0.5em" }}>
                <div>
                    {!opt_coins && <p> Loading...</p>}
                    {opt_coins && <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} _type={"asset_all"} />}
                    &emsp;
                    {!opt_groups && <p> Loading...</p>}
                    {opt_groups && <GroupDiv opt_groups={opt_groups} send_setgroups={setGroups} _type={"all"} />}
                    &emsp;
                    <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                </div>
                <div>
                    <AscDesc newOld={newOld} send_setNewOld={setNewOld} />
                </div>
                <br />
                <div style={{ display: "flex", margin: "1em", float: "right" }}>
                    &emsp;
                    <h6>Deposit 합계 : {depositTotal.toLocaleString("ko-KR", locale_opt)}</h6>
                    &emsp;
                    <h6>Withdraw 합계 : {withdrawTotal.toLocaleString("ko-KR", locale_opt)}</h6>
                    &emsp;
                    <h6>최종 합계 : {balanceTotal.toLocaleString("ko-KR", locale_opt)}</h6>
                </div>
                <div>
                    <table>
                        <thead>
                            <tr style={{ background: "#4472c4" }}>
                                <th>#</th>
                                <th>Date</th>
                                <th>Exchange</th>
                                <th>Asset</th>
                                <th>Strategy</th>
                                <th>Deposit</th>
                                <th>Withdraw</th>
                                {(coins !== "all" && groups !== "all") ? <th>Balance</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {getRecords.map((k, i) => (
                                <tr className="logTr" key={i} onClick={() => openModal(k)} style={{background: i%2 === 0 ? "#142440​" :"#233E6F" , cursor: "pointer", hover: { background: "red" } }}>
                                    <td>{i + 1}</td>
                                    <td>{k.registered_date}</td>
                                    <td>{k.exchange_name}</td>
                                    <td>{k.asset.toUpperCase()}</td>
                                    <td>{k.strategy_grp_name.toUpperCase()}</td>
                                    <td>{parseFloat(k.deposit).toLocaleString("ko-KR", locale_opt)}</td>
                                    <td>{parseFloat(k.withdraw).toLocaleString("ko-KR", locale_opt)}</td>
                                    {(coins !== "all" && groups !== "all") ? 
                                        <td>{parseFloat(eachBalance[i]).toLocaleString("ko-KR", locale_opt)}</td> 
                                        : null}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div key={remountComponent}>
                        <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                    </div>
                </div>
            </div>

            <div>
                <Modal
                    open={modalOpen}
                    close={closeModal}
                    header={
                        <>
                            <p>Editor</p>
                            <button className="close" onClick={closeModal}>
                                {" "}
                                &times;{" "}
                            </button>
                        </>
                    }
                    footer={
                        <>
                            <button style={{ background: "red", marginRight: "17em" }} onClick={(e) => ChangeContents("delete")}>
                                Delete
                            </button>
                            <button style={{ background: "green" }} onClick={(e) => ChangeContents("update")}>
                                Save
                            </button>
                            <button className="close" onClick={closeModal}>
                                close
                            </button>
                        </>
                    }>
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <div style={{ width: "25%", display: "flex" }}>
                        <div style={{ marginTop: "0.2em" }}>
                            <label htmlFor="bot_modal_date" style={{ fontSize: "18px" }}>
                                DATE:{" "}
                            </label>
                            <label htmlFor="bot_modal_date" style={{ fontSize: "18px" }}>
                                Exchange:{" "}
                            </label>
                            <label htmlFor="bot_modal_date" style={{ fontSize: "18px" }}>
                                Asset:{" "}
                            </label>
                            <label htmlFor="bot_modal_date" style={{ fontSize: "18px" }}>
                                Group:{" "}
                            </label>
                            <label htmlFor="bot_modal_date" style={{ fontSize: "18px" }}>
                                Deposit:{" "}
                            </label>
                            <label htmlFor="bot_modal_date" style={{ fontSize: "18px" }}>
                                Withdraw:{" "}
                            </label>
                        </div>
                        <div style={{ marginLeft: "1em" }}>
                            <input type="text" size="30" id="bot_modal_date" name="bot_modal_date" autoFocus defaultValue={record_time} onChange={(e) => setRecord_time(e.target.value)} />
                            <input type="text" size="30" id="bot_modal_input" name="bot_modal_input" autoFocus defaultValue={record_exchange} onChange={(e) => setRecord_exchange(e.target.value)} />
                            <input type="text" size="30" id="bot_modal_input" name="bot_modal_input" autoFocus defaultValue={record_coins} onChange={(e) => setRecord_coins(e.target.value)} />
                            <input type="text" size="30" id="bot_modal_input" name="bot_modal_input" autoFocus defaultValue={record_groups} onChange={(e) => setRecord_groups(e.target.value)} />
                            <input type="text" size="30" id="bot_modal_input" name="bot_modal_input" autoFocus defaultValue={opt_transaction_deposit} onChange={(e) => setOpt_transaction_deposit(e.target.value)} />
                            <input type="text" size="30" id="bot_modal_input" name="bot_modal_input" autoFocus defaultValue={opt_transaction_withdraw} onChange={(e) => setOpt_transaction_withdraw(e.target.value)} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default Records;

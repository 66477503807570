import React, { useState, useEffect } from "react";
import axios from "axios";
import "./feeAndSharing.css";

import Fee_Spot from "./Fee_Spot";
import Fee_Future from "./Fee_Future";
import Sharing from "./Sharing"
import Sharing_Future from "./Sharing_Future"
import HeaderNew from "../../../headerFooterSide/HeaderNew";

function FeeAndSharing({handleLogoutapp}) {
  const [title, setTile] = useState()
  const [feeTotal_Spot, setfeeTotal_Spot] = useState([])
  const [feeTotal_Future, setfeeTotal_Future] = useState([])
  const [shareRate, setShareRate] = useState([])
  const [shareRate_Future, setShareRate_Future] = useState([])

  const feeSave_Spot = (_id, pre_market, pre_limit,spot_percent_market,spot_percent_limit) => {
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;

    var temp_spot_percent_market = 0.0 
    var temp_spot_percent_limit = 0.0 

    if (spot_percent_market <= 0.0 ){
      temp_spot_percent_market = pre_market
    }else{
      temp_spot_percent_market = spot_percent_market
    }

    if (spot_percent_limit <= 0.0 ){
      temp_spot_percent_limit = pre_limit
    }else{
      temp_spot_percent_limit = spot_percent_limit
    }
    
    
    const data = {
        msg: "put_exchange_fee_spot",
        id: _id,
        spot_percent_market: temp_spot_percent_market ,
        spot_percent_limit: temp_spot_percent_limit
    };
    //alert(JSON.stringify(data))

    axios
        .post(`${python_server_url}/admin_manager`, data)
        .then((res) => {
          if (res.data.msg == "fail") {
            alert(res.data.result) ;
            getDataAll();
            window.location.reload();

          }else {
            alert(res.data.result) ;
            getDataAll();
            window.location.reload();
          }
        })
        .catch((err) => {
            console.log(err);
        });
  }
  
  const feeSave_Future = (_id,pre_market, pre_limit,future_percent_market, future_percent_limit) => {
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;


    var temp_future_percent_market = 0.0 
    var temp_future_percent_limit = 0.0 

    if (future_percent_market <= 0.0 ){
      temp_future_percent_market = pre_market
    }else{
      temp_future_percent_market = future_percent_market
    }

    if (future_percent_limit <= 0.0 ){
      temp_future_percent_limit = pre_limit
    }else{
      temp_future_percent_limit = future_percent_limit
    }
    
    const data = {
        msg: "put_exchange_fee_future",
        id: _id,
        future_percent_market: temp_future_percent_market ,
        future_percent_limit: temp_future_percent_limit ,
    };
    //alert(JSON.stringify(data))

    axios
        .post(`${python_server_url}/admin_manager`, data)
        .then((res) => {
          if (res.data.msg == "fail") {
            alert(res.data.result) ;
            getDataAll();
            window.location.reload();

          }else {
            alert(res.data.result) ;
            getDataAll();
            window.location.reload();
          }
        })
        .catch((err) => {
            console.log(err);
        });
  }

  const shareRateSave = (_id, rate) => {
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
    console.log("rate : " + rate);
    const data = {
        msg: "put_share_rate",
        id: _id,
        rate: rate
    };
    axios
        .post(`${python_server_url}/admin_manager`, data)
        .then((res) => {
          alert("Update completed.")
          console.log(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
  }

  const shareRateSave_Future = (_id, rate) => {
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
    console.log("rate : " + rate);
    const data = {
        msg: "put_share_rate_future",
        id: _id,
        rate: rate
    };
    axios
        .post(`${python_server_url}/admin_manager`, data)
        .then((res) => {
          alert("Update completed.")
          console.log(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
  }

  function getDataAll(){

    //alert("aaa")
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;

   
    const data = {msg: "get_exchange_fee_spot"};
    axios
        .post(`${python_server_url}/admin_manager`, data)
        .then((res) => {
          setfeeTotal_Spot(res.data.result)
          console.log(JSON.stringify(res))
        })
        .catch((err) => {
            console.log(err);
        });

    
    const data_future = {msg: "get_exchange_fee_future"};
    axios
        .post(`${python_server_url}/admin_manager`, data_future)
        .then((res) => {
          setfeeTotal_Future(res.data.result)

          console.log(JSON.stringify(res))
        })
        .catch((err) => {
            console.log(err);
        });
    
    const data_2 = {msg: "get_share_rate"}
    axios
        .post(`${python_server_url}/admin_manager`, data_2)
        .then((res) => {
          setShareRate(res.data.result)
        })
        .catch((err) => {
            console.log(err);
        });

    const data_3 = {msg: "get_share_rate_future"}
    axios
        .post(`${python_server_url}/admin_manager`, data_3)
        .then((res) => {
          console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
          console.log(JSON.stringify(res))
          setShareRate_Future(res.data.result)
        })
        .catch((err) => {
            console.log(err);
        });
  }


  useEffect(() => {
    setTile("Fee & Sharing")
    getDataAll();
  }, []);
  
  return (
    <div>
      {/* tile */}
      <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
      <div className="first_div" style={{  display: "flex", margin: "0px 10px 10px 0px"}}>
          <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
            <Fee_Spot feeTotal_Spot={feeTotal_Spot && feeTotal_Spot}  feeSave_Spot={feeSave_Spot}  />
          </div>
          <div style={{  width: "50%", display: "flex", justifyContent: "center" }}>
            <Fee_Future feeTotal_Future={feeTotal_Future && feeTotal_Future} feeSave_Future={feeSave_Future} />
          </div>
      </div>
      <br />
      <div className="first_div" style={{ display: "flex", margin: "0px 0px 10px 0px"}}>
          <div style={{  width: "100%", display: "flex", justifyContent: "center" }}>
            <Sharing shareRate={shareRate && shareRate}  shareRateSave={shareRateSave}  />
          </div>
      </div>
      <br />
      <div className="first_div" style={{ display: "flex", margin: "0px 0px 10px 0px"}}>
          <div style={{  width: "100%", display: "flex", justifyContent: "center" }}>
            <Sharing_Future shareRate={shareRate_Future && shareRate_Future}  shareRateSave={shareRateSave_Future}  />
          </div>
      </div>
    </div>
  );
}

export default FeeAndSharing;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import { c_opt_strategy_grp_all, locale_opt } from "../../common/Commons";
import "../../../App.css";
import Modal from "../../common/Modal";
import HighChart_Sentinel from "./HighChart_Sentinel";


const BackTestingDemo_V2 = ({}) => {
    
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [timeFrom, setTimeFrom] = useState("");
    const [timeTo, setTimeTo] = useState("");

    ///////////////////////////////////////////////////////////////////
    const [modalOpen, setModalOpen] = useState(true);
    const [modalData, setModalData] = useState([]);
    const [modalInputValue_1, setModalInputValue_1] = useState();
    const [modalInputValue_2, setModalInputValue_2] = useState();
    const [datalogs, setDatalogs] = useState([]);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
 
     ///////////////////////////////////////////////////////////////////

    const [profitText,setProfitText] = useState("")
    const [strategyName,setstrategyName] = useState("")

    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    const [minRangeDate, setMinRangeDate] = useState(0);
    const [maxRangeDate, setMaxRangeDate] = useState(0);

    const [startPoint,setStartPoint] = useState("")
    const [asset,setAsset] = useState({})
    const [ohlc, setOhlc] = useState([]);
    const [ema1, setEma1] = useState([]);

    const [upper_band, setUpper_Band] = useState([]);
    const [middle_band, setMiddle_Band] = useState([]);
    const [lower_band, setLower_Band] = useState([]);
    const [buy_point, setBuy_Point] = useState([]);
    const [sell_point, setSell_Point] = useState([]);
    const [realtime_start_point, setRealtime_start_point] = useState([]);

    const [volume, setVolume] = useState([]);
    const [groupingUnits, setGroupingUnits] = useState([]);
    const [candleData, setCandleData] = useState([]);

    const [xAxis_min, set_xAxis_min] = useState(0);
    const [xAxis_max, set_xAxis_max] = useState(0);

    //const [url, set_url] = useState("get_candle_backtesting_realtime")
    const [url, set_url] = useState("get_candle_backtesting_result_array")

    /*
    let xAxis_min = 0;
    let xAxis_max = 0;

    function setxAxis_MinMax(min, max){

        xAxis_min = min
        xAxis_max = max
        console.log("setxAxis_MinMax , xAxis_min : " + xAxis_min)
        console.log("setxAxis_MinMax , xAxis_max : " + xAxis_max)

    }
    */

    function convertEpocToDateString(epoctime) {
        let milliseconds = epoctime;
        let myDate = new Date(milliseconds-(60000*8));
        
        let dateStr = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate() + " " + myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds()
        return dateStr
    }

    function ToLocalDate (inDate) {
        var date = new Date();
        date.setTime(inDate.valueOf() - 60000 * inDate.getTimezoneOffset());
        return date;
    }
    
    function loadCandleData() {
    /*
        {"band_sma":34586.8645,"close":34615,"closetime":1698944159999,"dev":1.5,"ema_10":34598.6377211169,
        "ema_120":34677.0186616776,"ema_20":34583.4358111451,"ema_5":34606.761586329,"ema_60":34600.7471709665,"high":34621.93,
        "length":20,"long_entry":false,"long_exit":false,"low":34594.33,"lower_band":34548.965562606,"middle_band":34586.8645,
        "open":34594.33,"opentime":1698944100000,"priceunit":"usdt","short_entry":true,"short_exit":false,"sma_10":34600.47,"sma_120":34673.8836666667,
        "sma_20":34586.8645,"sma_5":34611.004,"sma_60":34590.842,"squenceNumber":1556,"symbol":"btc",
        "timestamp":"1m","upper_band":34624.763437394,"volume":33.17179}
        */

        if (candleData.length > 0)
        {
            
            closeModal()
            
            var temp_ohlc = [];
            var temp_volume = [];
            var temp_ema5 = [];
            var temp_upper_band = [];
            var temp_middle_band = [];
            var temp_lower_band = [];
            var temp_buy_point = [];
            var temp_sell_point = [];
            var temp_realtime_start_point = [];
            var date;
            var dataLength = candleData.length;
                // set the allowed units for data grouping
            var     temp_groupingUnits = [
                [
                    "week", // unit name
                    [1] // allowed multiples
                ],
                ["month", [1, 2, 3, 4, 6]]
            ];
            
            var i = 0
            //console.log("timeframe : " + timeframe)
            //console.log("candleData.length : " + candleData.length)
           
            //var i = candleData.length - Math.floor((candleData.length * 0.9))
            var tempMinIndex ;
            var tempMaxIndex ;
            tempMinIndex = parseInt(dataLength * 0.85)
            tempMaxIndex = dataLength - 1

            //console.log("loadCandleData, tempMinIndex : " + tempMinIndex)
            //console.log("loadCandleData, tempMaxIndex : " + tempMaxIndex)
            setMinRangeDate(candleData[tempMinIndex].closetime)
            setMaxRangeDate(candleData[tempMaxIndex].closetime)

            for (i; i < dataLength; i++) {
                //console.log("candleData[1556]: " + JSON.stringify(candleData[1556].closetime))
                //console.log("candleData[1556].short_entry: " + JSON.stringify(candleData[1556].short_entry))

                /*
                //console.log("candleData[i].closetime : " + candleData[i].closetime)
                //var temp_Date = convertEpocToDateString(candleData[i].closetime)
                //console.log("temp_Date : " + temp_Date)
                //date = new Date(temp_Date).getTime();
                */
                // utc --> ca time
                date = new Date(candleData[i].closetime - (3600000*8)).getTime();;
                date = candleData[i].closetime
                //console.log("date : " + date)
                

                temp_ohlc.push([
                    date, // the date
                    candleData[i].open, // open
                    candleData[i].high, // high
                    candleData[i].low, // low
                    candleData[i].close // close
                ]);
                
                
                if (JSON.stringify(candleData[i].long_entry) == "true") {
                          
                    var tempJson_long = {
                        x: date,
                        title :  "LONG  " + JSON.stringify(candleData[i].close)
                    }
                    
                    temp_buy_point.push(tempJson_long);
                    //console.log("temp_buy_point : "  + JSON.stringify(temp_buy_point))
                };


                if (JSON.stringify(candleData[i].short_entry) == "true") {
                    var tempJson_short = {
                        x: date,
                        title : "SHORT  " + JSON.stringify(candleData[i].close)
                    }
                    temp_sell_point.push(tempJson_short);
                    //console.log("temp_sell_point : "  + JSON.stringify(temp_sell_point))
                };

                if (JSON.stringify(candleData[i].realtime_start) == "true") {
                    setStartPoint(candleData[i].closetime)

                    var tempJson_short = {
                        x: date,
                        title : "Start"
                    }
                    temp_realtime_start_point.push(tempJson_short);
                };
            }
            //var tempMin =  Math.ceil(dataLength * 0.8)
            //var tempMax = dataLength 
            //console.log(" tempMin : " + tempMin)
            //console.log(" tempMax : " +  tempMax)
            //set_xAxis_min(candleData[tempMin].closetime)
            //set_xAxis_max(candleData[tempMax-1].closetime)
        }

        setOhlc(temp_ohlc);
        setEma1(temp_ema5);
        //console.log("temp_ema5==>")
        //console.log(temp_ema5)
        setUpper_Band(temp_upper_band);
        setMiddle_Band(temp_middle_band);
        setLower_Band(temp_lower_band);
        
        setBuy_Point(temp_buy_point);
        setSell_Point(temp_sell_point);

        //console.log("temp_volume==>")
        //console.log(temp_volume)
        setVolume(temp_volume);
        setGroupingUnits(temp_groupingUnits);
        setRealtime_start_point(temp_realtime_start_point);
        /*
        const data = [
            [1601324000000, 613, 617, 597, 597, 149800],
            [1601437600000, 614, 622, 611, 615, 164700]
        ];
        const ema = [
            [1601324000000, 613],
            [1601437600000, 622]
        ];
        */
    }

    function get_candle_realtime() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            timeframe: "time_frame_1",
        };
        //console.log("python_server_url ===> ")
        //console.log(python_server_url)
        //console.log("data ===> ")
        //console.log(JSON.stringify(data))

        //var turl = "get_candle_backtesting_result_array"
        var turl = "get_candle_backtesting_result_array_demo"

        axios
            .post(`${python_server_url}/${turl}`, data)
            .then((res) => {
                //console.log(JSON.stringify(res.data))
                //alert(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    //console.log("recevice ! ,  getCandleData !")
                    //console.log(JSON.stringify(res.data.data))
                    setCandleData(res.data.data)


                } else {
                    setCandleData([])
                }
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });
    }
    function get_asset() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
        };
        //console.log("python_server_url ===> ")
        //console.log(python_server_url)
        //console.log("data ===> ")
        //console.log(JSON.stringify(data))

        axios
            .post(`${python_server_url}/get_asset_demo_v2`, data)
            .then((res) => {
                //console.log(JSON.stringify(res.data))
                //alert(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    //console.log("recevice ! ,  getCandleData !")
                    //console.log("get_asset, respoinse : " + JSON.stringify(res.data.data))

                    setAsset(res.data.data)

                    var temp_date = new Date(parseInt(res.data.data.backtesting_from_date));
                    var temp_month = parseInt(temp_date.getMonth()) + 1

                    var temp_month_init =  temp_month
                    var temp_month_str = ""
                    if (temp_month_init < 10){
                        temp_month_str =  "0" + temp_month_init.toString()
                    }else{
                        temp_month_str =  temp_month_init.toString()
                    }

                    var temp_date_init =  temp_date.getDate() 
                    var temp_date_init_str = ""
                    if (temp_date_init < 10){
                        temp_date_init_str =  "0" + temp_date_init.toString()
                    }else{
                        temp_date_init_str =  temp_date_init.toString()
                    }

                    var temp_full_date = temp_date.getFullYear() + "-" + temp_month_str + "-" +  temp_date_init_str
                    setDateFrom(temp_full_date)
                    //console.log("temp_full_date_from ==> " + temp_full_date)

                    var temp_minit_init =  parseInt(temp_date.getMinutes())
                    var temp_minit_str = ""
                    if (temp_minit_init < 10){
                        temp_minit_str =  "0" + temp_minit_init.toString()
                    }else{
                        temp_minit_str =  temp_minit_init.toString()
                    }

                    var temp_tome_init =  parseInt(temp_date.getHours())
                    var temp_time_str = ""
                    if (temp_tome_init < 10){
                        temp_time_str =  "0" + temp_tome_init.toString()
                    }else{
                        temp_time_str =  temp_tome_init.toString()
                    }

                    var temp_full_time = temp_time_str + ":" + temp_minit_str
                    setTimeFrom(temp_full_time)
                    //console.log("temp_full_time_from ==> " + temp_full_time)

                    ///////////////////////////////////////////////////

                    var temp_date_to = new Date(parseInt(res.data.data.backtesting_to_date));
                    var temp_month_to = parseInt(temp_date_to.getMonth()) + 1

                    var temp_month_init_to =  temp_month_to
                    var temp_month_str_to = ""
                    if (temp_month_init_to < 10){
                        temp_month_str_to =  "0" + temp_month_init_to.toString()
                    }else{
                        temp_month_str_to =  temp_month_init_to.toString()
                    }


                    var temp_date_init_to =  temp_date_to.getDate() 
                    var temp_date_init_str_to = ""
                    if (temp_date_init_to < 10){
                        temp_date_init_str_to =  "0" + temp_date_init_to.toString()
                    }else{
                        temp_date_init_str_to =  temp_date_init_to.toString()
                    }

                    var temp_full_date_to = temp_date_to.getFullYear() + "-" + temp_month_str_to + "-" +  temp_date_init_str_to
                    setDateTo(temp_full_date_to)

                    //console.log("temp_full_date_to ==> " + temp_full_date_to)

                    var temp_minit_init_to =  parseInt(temp_date.getMinutes())
                    var temp_minit_str_to = ""
                    if (temp_minit_init_to < 10){
                        temp_minit_str_to =  "0" + temp_minit_init_to.toString()
                    }else{
                        temp_minit_str_to =  temp_minit_init_to.toString()
                    }

                    var temp_tome_init_to =  parseInt(temp_date.getHours())
                    var temp_time_str_to = ""
                    if (temp_tome_init_to < 10){
                        temp_time_str_to =  "0" + temp_tome_init_to.toString()
                    }else{
                        temp_time_str_to =  temp_tome_init_to.toString()
                    }

                    
                    var temp_full_time_to = temp_time_str_to + ":" + temp_minit_str_to
                    setTimeTo(temp_full_time_to)
                    //console.log("temp_full_time_to ==> " + temp_full_time_to)


                } else {
                    setAsset({})
                }
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });
    }
    useEffect(() => {
        get_candle_realtime();
        get_asset();
        const intervalId = setInterval(() => {
            get_candle_realtime();
            get_asset();
        }, 5000); //10 sec
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        loadCandleData()
    }, [candleData]);

    useEffect(() => {
        document.title = `Pascal Trading Live`;
        get_candle_realtime()
    }, []);




    useEffect(() => {

        var tempStr = "<br><p style='text-align:center'><span style='color:#f1c40f'>" 
        tempStr = tempStr + "[Strategy Name : 23-12-02-A]"
        tempStr = tempStr + "</span></p>"
        setstrategyName(tempStr)   
        
        //{"crypto_profit":"0","current_crypto_balance":1,"current_fiat_balance":50000,"fiat_profit":"0","init_crypto_balance":1,"init_fiat_balance":50000,"minus_trade_count":0,"pluse_trade_count":0,"profitable_trade":"0"

        let ProfitText = ""
        //ProfitText = ProfitText + "<br><p style='text-align:center'><span style='color:#f1c40f'>" + "Fiat Profit:" + parseInt(asset.fiat_profit).toFixed(3) +"%"
        
        //ProfitText = ProfitText + "<br><p style='text-align:center'><span style='color:#f1c40f'>" + "profit_ratio_total:" + parseInt(asset.profit_ratio_total).toFixed(3).toLocaleString("ko-KR", locale_opt) + "%"
        //ProfitText = ProfitText + "</span></p>"

        ProfitText = ProfitText + "<p></p><p></p><p></p>"
        ProfitText = ProfitText + "<p style='text-align:center'><span style='color:white'>"
        ProfitText = ProfitText +  "Date : " + dateFrom + " " + timeFrom + " ~ " + dateTo + " " + timeTo
        ProfitText = ProfitText + "</span></p>"

        
        ProfitText = ProfitText + "<br><p style='text-align:center'><span style='color:#f1c40f'>"
        ProfitText = ProfitText +  "Crypto Profit: " + parseFloat(asset.profit_coin_ratio).toFixed(5).toLocaleString("ko-KR", locale_opt) + "%"
       // ProfitText = ProfitText +  ", Fiat Profit: " + parseFloat(asset.profit_fiat_ratio).toFixed(5).toLocaleString("ko-KR", locale_opt) + "%"
        ProfitText = ProfitText + "</span></p>"

        ProfitText = ProfitText + "<p style='text-align:center'><span style='color:#f1c40f'>"
        ProfitText = ProfitText +  "Total Trades: " + parseFloat(asset.tradeCount).toLocaleString("ko-KR", locale_opt)
        ProfitText = ProfitText +  ", Percent Profitable: " + parseFloat(asset.profitable_trade).toFixed(3).toLocaleString("ko-KR", locale_opt)+ "%"
        ProfitText = ProfitText + "</span></p>"

        //ProfitText = ProfitText + "<p style='text-align:center'><span style='color:#32B3B9'>" + "Init Fiat Balance: " +  parseFloat(asset.init_fiat_balance).toFixed(2).toLocaleString("ko-KR", locale_opt) + " USDT"
        //ProfitText = ProfitText + ", Current Fiat Balance: " + parseFloat(asset.current_fiat_balance).toFixed(2).toLocaleString("ko-KR", locale_opt)  + " USDT"
        //ProfitText = ProfitText + "</span></p>"

        //ProfitText = ProfitText + "<p style='text-align:center'><span style='color:#32B3B9'>" + "Init Crypto Balance: " +  parseFloat(asset.init_crypto_balance).toFixed(8).toLocaleString("ko-KR", locale_opt) + " BTC"
        //ProfitText = ProfitText + ", Current Crypto Balance: " + parseFloat(asset.current_crypto_balance).toFixed(8).toLocaleString("ko-KR", locale_opt) + " BTC"
        //ProfitText = ProfitText + "</span></p>"

        //ProfitText = ProfitText + "<p style='text-align:center'><span style='color:#32B3B9'>" + "Init Total Asset:" + parseFloat(asset.init_asset).toLocaleString("ko-KR", locale_opt) + " USDT"
        //ProfitText = ProfitText + ", Current Total Asset:" +  parseFloat(asset.current_asset).toLocaleString("ko-KR", locale_opt) + " USDT"

        setProfitText(ProfitText)

    },[asset,dateFrom,dateTo]);
    
    var redrawEnabled = true;
    const options = {

        chart: {

            height: 600,
            zooming: {
                mouseWheel: false
            },
            /*
            zooming: {
                mouseWheel: {
                  enabled: false
                }
              },
              */
            backgroundColor: "#1c1b2b",
            borderRadius: 15,

            events: {
                load: function () {
                        
                },
                redraw: function() {
                    if (redrawEnabled) {
                        var sum = 0;
                        redrawEnabled = false;
    

                        this.update({
                            subtitle: {
                                useHTML : true,
                               // text: sum
                               // text : "BTC"
                               // text : JSON.stringify(asset)
                              
                               style: {
                                color: "#ffff",
                                fontSize: '11px' 
                                },

                               text : profitText
                             
                            }
                        });
                        redrawEnabled = true;

                        var chart = this
                        //console.log(` minRangeDate : ${minRangeDate}`);
                        //console.log(` maxRangeDate : ${maxRangeDate}`);
                        //chart.xAxis[0].setExtremes(minRangeDate,maxRangeDate)

                        /////////////////////////////////////////////////////////////////////////////////////////
                        // 
                        /*
                        var labelText = strategyName
                        this.renderer.text(labelText, 80, 80,true)
                        .attr({
                            zIndex: 20
                        })
                        .css({
                            fontSize: '13px',
                            color : '#05114B'
                        })
                        .add();

                        //rect( [x] [, y] [, width] [, height] [, r] [, strokeWidth])
                        this.renderer.rect(75, 65, 200, 40, 2)
                        .attr({
                            'stroke-width': 2,
                            stroke: 'black',
                            fill: '#3249B9',
                            zIndex: 4
                        })
                        .add();
                        */
                        /////////////////////////////////////////////////////////////////////////////////////////

                    }
                }
            }
        },

        colors: ['gray', 'green', 'blue', 'violet', 'yellow'],
       
        scrollbar: {
          enabled: false,
        },
        
        navigator: {
          enabled: true,
        },

        credits: {
            enabled: false
        },

        title: {
            text: "[ BTC/USDT AI Trading ]",
            style: {
                color: "#ffff",
            },
        },
        xAxis: {
            //min : xAxis_min,
            //max : xAxis_max,
            labels: {
                style: {
                    color: 'white'
                }
            },
            events: {
                setExtremes(e) {
                    //console.log(` xAxis, setExtremes : ${'<b>Set extremes:</b> e.min: ' + Highcharts.dateFormat(null, e.min) +' | e.max: ' + Highcharts.dateFormat(null, e.max) + ' | e.trigger: ' + e.trigger}`);
                    //console.log("startPoint " + startPoint)
                }
            },
        },

        yAxis: [
        
            {
            labels: {
                style : {
                    color : "white",
                    fontSize : " 0.7em",
                    align: 'right',

                }
            },
            height: '80%',
            resize: {
                enabled: true
            },
            gridLineColor:'#e6e6e6',
            gridLineWidth: 0.1,
            lineColor: false,
        },
        {
            labels: {
                align: 'left'
            },
            height: '80%',
            resize: {
                enabled: true
            }
        }, {
            labels: {
                align: 'left'
            },
            top: '80%',
            height: '20%',
            offset: 0
        },
       
        ],
        tooltip: {
            positioner: function () {
                return {
                    x: this.chart.plotLeft,
                    y: this.chart.plotTop
                };
            },
            shared: true,
            headerFormat: '',
            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
            valueDecimals: 2,
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.8)'
        },
        tooltip: {
            enabled: false
        },
       
        series: [{
            type: 'candlestick',
            id: 'coin-ohlc',
            name: 'coin-ohlc',
            data: ohlc,
            events: {
            	afterAnimate: function () {
                    //alert(xAxis_min)
                    /*
                    const chart = this.chart;
                    var xAxis = chart.xAxis[0]

                    console.log("afterAnimate : xAxis_min : " +xAxis_min )
                    console.log("afterAnimate : xAxis_max : " +xAxis_max )
                    xAxis.setExtremes(parseInt(xAxis_min.toString()), parseInt(xAxis_max.toString()));
                    */

              }
            }
        }
        ,
        {
            type: 'flags',
            name: 'Buy Point',
            id : "buy",
            data: buy_point,
            onSeries: "coin-ohlc",
            shape: 'squarepin',
            //shape: 'flag',
            //shape: 'http://localhost:5100/static/image/youtube-667451_1280.png',
            legendImage: 'http://localhost:5100/static/image/youtube-667451_1280.png',
            color: 'blue', 
            fillColor: 'blue',

            //fillColor: '#1c1b2b',
            y: 30,
            height: 30,
            width: 80,
            style: { // text style
                color: 'white',
                fontSize : 10
            },
            states: {
                hover: {
                    fillColor: 'blue' 
                }
            }
        },
        {
            type: 'flags',
            name: 'Sell Point',
            id : "sell",
            data: sell_point,
            onSeries: "coin-ohlc",
            shape: 'squarepin',
            //shape: 'flag',
            color: 'red', 
            fillColor: 'red', 
            y: -60,
            height: 30,
            width: 80,
            style: { // text style
                color: 'white',
                fontSize : 10
            },
            states: {
                hover: {
                    fillColor: 'red', 
                }
            }
        },
        {
            type: 'flags',
            name: 'Start Point',
            id : "realtime_start_point",
            data: realtime_start_point,
            //onSeries: "coin-ohlc",
            //shape: 'flag',
            color: 'yellow', 
            fillColor: 'yellow', 
            width: 100,
            style: { // text style
                color: 'blue',
                fontSize : 5
            },
            states: {
                hover: {
                    fillColor: 'yellow', 
                }
            }
        },

       ],
        
        
        rangeSelector: {
            enabled: false
        },
        plotOptions: {
            flags: {
                accessibility: {
                    exposeAsGroupOnly: true,
                    description: 'Flagged events.'
                }
            },
            line: {
                dashStyle: "Solid",
                lineWidth: 1,
               // lineColor: "#20a0b1",
            },
            candlestick: {
                lineColor: "#FB1809",
                color: "#FB1809",
                upColor: "#4EA64A",
                upLineColor: "#4EA64A",
            },
            bb: {
                lineWidth: 1,
                lineColor: "#20a0b1",
                bottomLine: {
                    styles: {
                        lineWidth: 0.5,
                        lineColor: "#fcfc27",
                    },
                },
                topLine: {
                    styles: {
                        lineWidth: 0.5,
                        lineColor: "#fcfc27",
                    },
                },
            },
            series: {
                borderColor: "red",
                marker: {
                    enabled: false,
                    radius: 0,
                },

                point: {
                    events: {
                        mouseOver: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            //console.log(`plotOptions , mouseOver, to ${point.x}, ${point.y}`);
                            //self.handleMouseOver(point);
                        },

                        selection: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            //console.log(`plotOptions  selection , to ${point.x}, ${point.y}`);
                            // self.handleMouseOver(point);
                        },
                    }
                }
            }
        }
    };


    return (
        <div>
            {candleData.length > 0 && options && options ? 
                <>
                <HighChart_Sentinel options={options}></HighChart_Sentinel> 
                <div>
                    <Modal
                        open={modalOpen}
                        close={closeModal}
                        header={""}
                        footer={
                            <button className="close" onClick={closeModal}>
                                        close
                            </button>
                        }>
                        <label style={{ textAlign: "center",justifyContent :"center" }} htmlFor=""> Getting trading data ........</label>
                    
                    </Modal>
                </div>
                </>
            : null}
            
        </div>
        
    );
};
export default BackTestingDemo_V2;
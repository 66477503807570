import React, { } from "react";
import SharingTable from "./SharingTable"


function Sharing_Future({ shareRate, shareRateSave }) {
 
  
  return (
      <div className="first_div">
          <br />
          <div className="second_div">
              <h5>Profit Sharing Rates(Future)​​</h5>
          </div>
          <br />
          <div style={{ margin: "0 0 0em 0em", display: "flex" }}>
              <div style={{ width: "50%" }}>
                  Binance
                  <div style={{ border: "1px solid #203864", textAlign:"center",verticalAlign:"middel" }}>
                      <SharingTable exchanges={"1"} shareRate={shareRate} shareRateSave={shareRateSave} />
                  </div>
              </div>
              &emsp;&emsp;&emsp;
              <div style={{ width: "50%" }}>
                  Upbit
                  <div style={{ border: "1px solid #203864", textAlign:"center",verticalAlign:"middle" }}>
                      <SharingTable exchanges={"2"} shareRate={shareRate} shareRateSave={shareRateSave} />
                  </div>
              </div> 
          </div>
          <div style={{ margin: "0 0 0em 0em", display: "flex" }}>
              <div style={{ width: "50%" }}>
                  Bithumb
                  <div style={{ border: "1px solid #203864", textAlign:"center",verticalAlign:"middle"  }}>
                      <SharingTable exchanges={"3"} shareRate={shareRate} shareRateSave={shareRateSave} />
                  </div>
              </div>
            &emsp;&emsp;&emsp;
              <div style={{ width: "50%" }}>
                  Okex
                  <div style={{ border: "1px solid #203864", textAlign:"center",verticalAlign:"middle"  }}>
                  <SharingTable exchanges={"4"} shareRate={shareRate} shareRateSave={shareRateSave}/>
                  </div>
              </div>
          </div>
          <div style={{ margin: "0 0 0em 0em", display: "flex" }}>
              <div style={{ width: "50%" }}>
                  Mexc
                  <div style={{ border: "1px solid #203864", textAlign:"center",verticalAlign:"middle"  }}>
                  <SharingTable exchanges={"5"} shareRate={shareRate} shareRateSave={shareRateSave}/>
                  </div>
              </div>
            &emsp;&emsp;&emsp;
              <div style={{ width: "50%" }}>
                  Bitget
                  <div style={{ border: "1px solid #203864", textAlign:"center",verticalAlign:"middle"  }}>
                  <SharingTable exchanges={"6"} shareRate={shareRate} shareRateSave={shareRateSave}/>
                  </div>
              </div>
          </div>
          <br />
      </div>
  );
}

export default Sharing_Future;

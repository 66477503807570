import React, { useState, useEffect } from "react";

import axios from "axios";
import dayjs from "dayjs";

import "./botStatusCurrentBalance.css";
import { locale_opt } from "../../../common/Commons";

function BotStatus_new({ botStatus , exchangesSelect, botCnt, balanceStatus }) {

    //alert(exchangesSelect)
    //alert(JSON.stringify(balanceStatus))
    // edit button
    const [editButtons, setEditButtons] = useState([""]);

    const handleEdit = (id,mode) => {
        console.log("handleEdit mode :" + mode);
        console.log("handleEdit id :" + id);
        let newArr = [...editButtons]; 
        if ( mode === "edit" ) {
            newArr[id] = "edit"; 
            //alert(id)
            setEditButtons(newArr);
            
        }else{
           //alert(id)
          newArr[id] = "save"; 
          setEditButtons(newArr);
        }
       // replace e.trigger.value with whatever you want to change it to
       
      };


      function setInput_trigger_price(id,value){
        value = value.replace(".","")
        document.getElementById(id).value = value;
      }

      const save_trigger_price = (title,id) => {
        if (window.confirm(title)) {

            let temp_trigger_price = document.getElementById("buy_pair_trigger_price" + id).value

            //alert(temp_trigger_price);

            if (temp_trigger_price == ""  ) {
                alert("Please check trigger price !");
                return false;
            }
            
            const data = {
                bot_id: "s_bot",
                msg: "set_trigger_price_for_tradingview",
                buy_pair_trigger_price: temp_trigger_price,
                id: id,
            };
            //alert(JSON.stringify(data))

            axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                //alert(res.data.msg);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
            
        }
    };

    function setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id){

        let time = dayjs().format("YYYY-MM-DD hh:mm:ss");
        let order_type ="market";

        if (msg == "close"){
            order_type ="market";
        }else if( msg =="trigger"){
            order_type ="automarket";
        }
        else {
            order_type ="";
        }

        //alert(strategy_group);
        const this_data = {
                getmodel : "botScheduleCtl_model",
                gettogo : "trading_view",

                params : {
                    id : id,
                    buy_pair_id : buy_pair_id,
                    strategy_group: strategy_group_no,
                    strategy_name: strategy_name,
                    exchange_name: exchanges_str + ":" + coin_type + fiat_type,
                    coin_type: coin_type + fiat_type,
                    time_frame: time_frame,
                    position: "BUY",
                    price: "0",
                    buy_pair_trigger_price : buy_pair_trigger_price ,
                    operation_type: msg,
                    order_type: order_type,
                    alarm_sent_time: String(new Date(time) / 1000)
                }
        };
        //alert(JSON.stringify(this_data))
        
        axios
            .post(process.env.REACT_APP_TV_SERVER, this_data)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => console.log(err));
        

    }
    const forceControl = (title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_id) => {
        if (window.confirm(title)) {
            let buy_pair_trigger_price = document.getElementById("buy_pair_trigger_price" + id).value
            if(msg == "trigger" ) {

                if(buy_pair_trigger_price == "" ) {

                    if (window.confirm("Do you want to abort trading date ?\n\n(You set space for trigger price)")) {
                        setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
                    }

                }else {
                    setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
                }


            }else {
                setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
            }            
        }
    };

    function CPrice({grp, ticker}){
        let result = ""
        //alert(grp)
        //alert(ticker)

        //alert(JSON.stringify(balanceStatus));
        //alert(JSON.stringify(balanceStatus));
        balanceStatus.map((k, i) => {
            if (k.grp === grp && k.crypto === ticker) {
                result = parseFloat(k.c_price).toLocaleString("ko-KR", locale_opt)
            } 
        }) 
        return result
    }

    function ChangePrice({sell_price,grp, ticker}){
        let current_price = 0.0
        let result = ""
        //alert(grp)
        //alert(ticker)
        //alert(sell_price)

        //alert(JSON.stringify(balanceStatus));
        //alert(JSON.stringify(balanceStatus));
        balanceStatus.map((k, i) => {
            if (k.grp === grp && k.crypto === ticker) {
                current_price = parseFloat(k.c_price)
            } 
        }) 
        
        let temp_val = 0.0
        temp_val = current_price - parseFloat(sell_price)
        result = temp_val.toFixed(6).toString()
        
        return result
    }

    useEffect(() => {
        //alert(editButtons);
    }, [editButtons]);

    

    return (
        <div className="first_div">
            <div className="second_div">
                <div>
                    <h5>Trading Strategy Status</h5>
                </div>
                &emsp;
                <table className="">
                    <thead style={{ background: "BLACK" }}>
                        <tr>
                            <th style={{ width: "3%" }}>STRATEGY</th>
                            <th style={{ width: "4%" }}>TICKER</th>
                            <th style={{ width: "2%" }}>COUNT</th>
                            <th>BOT 1</th>
                            <th>BOT 2</th>
                            <th>BOT 3</th>
                            <th>BOT 4</th>
                            <th>BOT 5</th>
                        </tr>
                    </thead>
                   
                    <tbody>
                    {
                        botStatus.map((bot,a) => (
                            Object.keys(bot).toString()=== exchangesSelect ? (
                                
                                // exchange
                                bot[Object.keys(bot)].map((strategy_group,b) => (

                                    // strategy group , mlc
                                    strategy_group[Object.keys(strategy_group)].map((coins,c) => (

                                        //contents -->  cointents[0]- btc , cointents[1] : {'bot_count': 4, 'bot_content': [{'id)
                                        Object.entries(coins).map((coin_contents,d) => (
                                        
                                            <tr style={{ background: `${Object.keys(strategy_group).toString().toUpperCase() === "MLC" ? "#0070C0" : Object.keys(strategy_group).toString().toUpperCase() === "P2V" ? "#1E8062" : "#7030A0"}` }}>    
                                            <td>{Object.keys(strategy_group).toString().toUpperCase()}</td>
                                            <td>{coin_contents[0].toString().toUpperCase()}</td>
                                            <td>{coin_contents[1]['bot_count']}</td>
                                            {
                                                
                                                Array.from({length: Number(coin_contents[1]['bot_content'].length)}, (v, i) => i).map((name_2, temp_index) => (
                                                    <>
                                                    {coin_contents[1]['bot_content'][temp_index]&&coin_contents[1]['bot_content'][temp_index] ? (
                                                        <td key={temp_index} style={{ width: "250px" }}>
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                textAlign: "center",
                                                            }}>
                                                            <span>{coin_contents[1]['bot_content'][temp_index].strategy_name}</span>
                                                            <span>({coin_contents[1]['bot_content'][temp_index].alarm_sent_time})</span>
                                                            <span>
                                                                {" "}
                                                                {coin_contents[1]['bot_content'][temp_index].percent + "%"} ({coin_contents[1]['bot_content'][temp_index].crypto_qty} {coin_contents[1]['bot_content'][temp_index].coin_type})
                                                            </span>
                                                            <span>
                                                                {" "}
                                                                SELL PRICE : {coin_contents[1]['bot_content'][temp_index].current_crypto_price} {coin_contents[1]['bot_content'][temp_index].fiat_type}
                                                            </span>
                                                            <span>
                                                                {" "}
                                                                AMOUNT : {coin_contents[1]['bot_content'][temp_index].fiat_qty_real} {coin_contents[1]['bot_content'][temp_index].fiat_type}
                                                            </span>
                                                            <span style={{ color: "#FFC000" }}>
                                                                Current Price : <CPrice grp={coin_contents[1]['bot_content'][temp_index].strategy_group_name} ticker={coin_contents[1]['bot_content'][temp_index].coin_type} /> {coin_contents[1]['bot_content'][temp_index].fiat_type}
                                                            </span>
                                                            <span style={{ color: "#0000FF" }}>
                                                                Change  : <ChangePrice sell_price={coin_contents[1]['bot_content'][temp_index].current_crypto_price} grp={coin_contents[1]['bot_content'][temp_index].strategy_group_name} ticker={coin_contents[1]['bot_content'][temp_index].coin_type} /> {coin_contents[1]['bot_content'][temp_index].fiat_type}
                                                            </span>

                                                            
                                                            <div style={{ display: "flex" ,margin: "0em 0em 2em 0em" }}>
                                                                {/* trigger price */}
                                                                <div style={{margin: "2.5em 0px 0px 0em" , width : "60%" }}>
                                                                Trigger Price : <input type="text" style={{ margin: "0px 0px 0px 0px" , width: "50%" }}  id={"buy_pair_trigger_price" + coin_contents[1]['bot_content'][temp_index].id} defaultValue={coin_contents[1]['bot_content'][temp_index].buy_pair_trigger_price} disabled={editButtons[coin_contents[1]['bot_content'][temp_index].id] === "edit" ? "" : "disabled"} onChange={(e) => setInput_trigger_price(e.target.id, e.target.value)}/>
                                                                </div>
                                                                {/* edit button */}
                                                                <div hidden={editButtons[coin_contents[1]['bot_content'][temp_index].id] === "edit" ? true : false}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                    <div style={{ width : "100%" }}>
                                                                        <button style={{ background: "green", color: "white", width: "90px", margin: "auto" }}
                                                                        onClick={(e) => handleEdit(coin_contents[1]['bot_content'][temp_index].id,"edit")} >Edit</button>
                                                                    </div>
                                                                </div>
                                                                {/* save button 
                                                                {/*
                                                                <div hidden={editButtons[coin_contents[1]['bot_content'][temp_index].id] === "edit" ? false : true}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                    <div style={{ width : "100%" }}>
                                                                        <button style={{ background: "orange", color: "white", width: "90px", margin: "auto" }}
                                                                        onClick={(e) => save_trigger_price("Do you want to set trigger price ?" ,coin_contents[1]['bot_content'][temp_index].id) } >Save</button>
                                                                    </div>
                                                                </div>
                                                                */}
                                                                <div hidden={editButtons[coin_contents[1]['bot_content'][temp_index].id] === "edit" ? false : true}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                    <div style={{ width : "100%" }}>
                                                                        <button style={{ background: "orange", color: "white", width: "90px", margin: "auto" }}
                                                                        onClick={() => forceControl("Save Trigger Price?", "trigger", coin_contents[1]['bot_content'][temp_index].exchange_name,coin_contents[1]['bot_content'][temp_index].strategy_group,coin_contents[1]['bot_content'][temp_index].strategy_name,coin_contents[1]['bot_content'][temp_index].coin_type , coin_contents[1]['bot_content'][temp_index].time_frame, coin_contents[1]['bot_content'][temp_index].fiat_type,coin_contents[1]['bot_content'][temp_index].id,coin_contents[1]['bot_content'][temp_index].buy_pair_id)}>Save</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            

                                                            <div style={{ display: "flex" }}>
                                                                <button
                                                                    style={{ background: "green", color: "white", width: "110px", margin: "auto" }}
                                                                    onClick={() => forceControl("Close position?", "close", coin_contents[1]['bot_content'][temp_index].exchange_name,coin_contents[1]['bot_content'][temp_index].strategy_group,coin_contents[1]['bot_content'][temp_index].strategy_name, coin_contents[1]['bot_content'][temp_index].coin_type,coin_contents[1]['bot_content'][temp_index].time_frame, coin_contents[1]['bot_content'][temp_index].fiat_type , coin_contents[1]['bot_content'][temp_index].id, coin_contents[1]['bot_content'][temp_index].buy_pair_id)}>
                                                                                                                                                                
                                                                    Close Position
                                                                </button>
                                                                

                                                                <button
                                                                    style={{ background: "red", color: "white", width: "110px", margin: "auto" }}
                                                                    onClick={() => forceControl("Terminate position?", "terminate", coin_contents[1]['bot_content'][temp_index].exchange_name,coin_contents[1]['bot_content'][temp_index].strategy_group,coin_contents[1]['bot_content'][temp_index].strategy_name,coin_contents[1]['bot_content'][temp_index].coin_type , coin_contents[1]['bot_content'][temp_index].time_frame, coin_contents[1]['bot_content'][temp_index].fiat_type, coin_contents[1]['bot_content'][temp_index].id, coin_contents[1]['bot_content'][temp_index].buy_pair_id)}>
                                                                    Terminate
                                                                </button>
                                                            </div>
                                                        </div>
                                                        </td>) : (
                                                        <td>X</td>
                                                    )}</>
                                                
                                                ))
                                            }
                                            </tr>
                                        ))//Object.entries(coins).map((coin_contents,d)
                                    )) //strategy_group[Object.keys(strategy_group)].map((coins,c
                                ))
                            ) : null
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BotStatus_new;



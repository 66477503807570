import React, { useState, useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import TempData from "./data2";

const StockChart_Prediction = ({timeframe}) => {

    const [closeData, setcloseData] = useState([]);
    const [candleData, setCandleData] = useState([]);

    const options = {

        chart: {
            zoomType: 'xy',
            backgroundColor: "#1c1b2b",
            borderRadius: 15,
            height: 500,

            events: {
                load: function () {
                    console.log("StockChart is loaded");
                    var chart = this
                    var xAxis = chart.xAxis[0]
                    var xExtremes = xAxis.getExtremes()
                    var newMin = xExtremes.dataMin * 0.9
                    var newMax = xExtremes.dataMax * 1.1

                    //console.log(` xExtremes.dataMin : ${xExtremes.dataMin}`);


                }
            }
        },
        navigator: {
            enabled: false
        },

        credits: {
            enabled: false
        },

        title: {
            text: "[ Prediction ]",
            style: {
                color: "#ffff",
            },
        },
        xAxis: {
            events: {
                setExtremes(e) {

                    //console.log(` xAxis, setExtremes : ${'<b>Set extremes:</b> e.min: ' + Highcharts.dateFormat(null, e.min) +  ' | e.max: ' + Highcharts.dateFormat(null, e.max) + ' | e.trigger: ' + e.trigger}`);
                }
            }
        },

        yAxis: [{
            labels: {
                align: 'left'
            },
            height: '80%',
            resize: {
                enabled: true
            }
        }],
        tooltip: {
            positioner: function () {
                return {
                    x: this.chart.plotLeft,
                    y: this.chart.plotTop
                };
            },
            shared: true,
            headerFormat: '',
            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
            valueDecimals: 2,
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.8)'
        },
       
        series: [
        {
            type: "line",
            id: "Close",
            name: "Close",
            data: closeData,
            yAxis: 0,
        },
        ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 800
                },
                chartOptions: {
                    rangeSelector: {
                        inputEnabled: false
                    }
                }
            }]
        }
        ,
        rangeSelector: {
            selected: 1,
            buttons: [
                {
                    type: "minutes",
                    count: 1,
                    text: "All",
                    title: "View All",
                },
            ],
        },
        plotOptions: {
         
            line: {
                dashStyle: "Solid",
                lineWidth: 1,
                lineColor: "#20a0b1",
            },
            
            series: {
                borderColor: "black",
                marker: {
                    enabled: false,
                    radius: 0,
                },

                point: {
                    events: {
                        mouseOver: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            console.log(`plotOptions , mouseOver, to ${point.x}, ${point.y}`);
                            //self.handleMouseOver(point);
                        },

                        selection: function () {
                            const self = this.series.chart.component;
                            const point = {
                                x: this.x,
                                y: this.y
                            };

                            console.log(`plotOptions  selection , to ${point.x}, ${point.y}`);
                            // self.handleMouseOver(point);
                        },
                    }
                }
            }
        }
    };

    (function (H) {
        Highcharts.Chart.prototype.callbacks.push(function (chart) {
            H.addEvent(chart.container, 'wheel', function (e) {
                var xAxis = chart.xAxis[0],
                    extremes = xAxis.getExtremes(),
                    newMin = extremes.min;
                console.log("wheel");
                console.log(extremes);
                console.log(newMin);


                if (e.deltaY < 0) {
                    xAxis.setExtremes(extremes.min - (extremes.min * 0.001), extremes.max, true);
                }
                else {
                    xAxis.setExtremes(extremes.min + (extremes.min * 0.001), extremes.max, true);
                }
            });

            H.addEvent(chart.container, 'selection', function (e) {
                //console.log("selection !!");
            });


            H.addEvent(chart.container, 'click', function (e) {
                e = chart.pointer.normalize(e);
                //console.log(`Clicked11 chart at ${e.chartX}, ${e.chartY}`);

            });

            H.addEvent(chart.xAxis[0], 'afterSetExtremes', function (e) {

                //console.log(` xAxis, setExtremes : ${'<b>Set extremes:</b> e.min: ' + Highcharts.dateFormat(null, e.min) +' | e.max: ' + Highcharts.dateFormat(null, e.max) + ' | e.trigger: ' + e.trigger}`);
                //console.log(`load Set extremes to ${e.min}, ${e.max}`);

                //var extremes_min = e.min;
                //var extremes_max = e.max;
                //var extremes_start = extremes_max - 960000;
                //chart.xAxis[0].setExtremes(extremes_start, extremes_max);

                //var extremes_min_date_str = convertEpocToDateString(extremes_min);
                //console.log("extremes_min_date_str : " + extremes_min_date_str);
                //var extremes_max_date_str = convertEpocToDateString(extremes_max);
                //console.log("extremes_max_date_str : " + extremes_max_date_str);

                //console.log("new Date(extremes_start_date).getTime() : " + new Date(extremes_max_date_str).getTime());
                //console.log("extremes_min : " + extremes_min);
                //console.log("extremes_max : " + extremes_max);
                //console.log("extremes_start : " + extremes_start);

                //chart.xAxis[0].setExtremes(new Date(extremes_start_date).getTime(), new Date(extremes_max_date).getTime());
                //chart.xAxis[0].setExtremes(data[0][1].x, data[0][2000].x, true, false);
            });

            /*
            H.addEvent(H.Chart, 'load', function(e) {
            const chart = e.target;
            H.addEvent(chart.container, 'click', function(e) {
                e = chart.pointer.normalize(e);
                console.log(`Clicked chart at ${e.chartX}, ${e.chartY}`);
            });
            H.addEvent(chart.xAxis[0], 'afterSetExtremes', function(e) {
                console.log(`load Set extremes to ${e.min}, ${e.max}`);
            });
            });

            */

        });
    }(Highcharts));


    function loadCandleData() {

        //console.log("candleData : " + candleData)

        var temp_closeData = [],
            squenceNumber,
            dataLength = candleData.length,
            i = 0;

        for (i; i < dataLength*0.5; i += 1) {
            squenceNumber = candleData[i].squenceNumber;
            //console.log("squenceNumber : " + squenceNumber)

            temp_closeData.push([
                squenceNumber, // squenceNumber
                candleData[i].close // t
            ]);
            

        }
        setcloseData(temp_closeData);
       
    }

    function get_prediction() {

        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI_TRADING;
        console.log("get_prediction !")
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            timeframe: window.sessionStorage.getItem("timeframe"),
        };
        console.log(JSON.stringify(data))

        axios
            .post(`${python_server_url}/get_prediction`, data)
            .then((res) => {
                //console.log(JSON.stringify(res.data))
                //alert(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    console.log("recevice ! ,  getCandleData !")
                    //console.log(JSON.stringify(res.data.data))
                    setCandleData(res.data.data)

                } else {
                    setCandleData([])
                }
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });

    }

    useEffect(() => {
        get_prediction();
        const intervalId = setInterval(() => {
            get_prediction();
        }, 30000); //30 sec
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        loadCandleData()
    }, [candleData]);

    useEffect(() => {
        get_prediction()
        //console.log("timeframe :" + timeframe)
    }, [timeframe]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
            />
        </div>
    );
};
export default StockChart_Prediction;
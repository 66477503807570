import React, { useState, useEffect } from "react";

import sentinel_main_img from "../../images/sentinel.svg";
import "./login.css";

import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

const IframeTradingView = () => {
    return (
      <div>
        <iframe src="/back_testing/back_testing_spot" />
      </div>
    );
  };


const TradingViewDemo = ({ handleLogin }) => {
    // window.history.pushState(null, null, "/")
    axios.defaults.withCredentials = false;

    return (
        <> 
        <div className="login_bg">
            <div className="login_content_div">
             <IframeTradingView></IframeTradingView>
            </div>
        </div>
        </>
    );
};

export default TradingViewDemo;

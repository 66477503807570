import React, { useState, useEffect , useCallback} from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

import Header from "./components/headerFooterSide/Header";
import Sidenav from "./components/headerFooterSide/Sidenav";
import PagesPath from "./components/pages/PagesPath";

import Login from "./components/auth/Login";
import Addmember from "./components/auth/Addmember";
import Demo from "./components/auth/Demo";

import BackTestingDemo_V2 from "./components/pages/backtesting/BackTestingDemo_V2";
// import ProfitChart from "./components/pages/profitChart/ProfitChart";

function App() {
    const [login, setLogin] = useState(null);
    const thisurl = window.location.pathname;

    const handleLoginapp = (success) => {
        setLogin(success);
    };

    const handleLogoutapp = (status) => {
        
        setLogin(status);
    };
    const onTest = useCallback(() => {
        alert("aaaa");
    });

    useEffect(() => {
        /*
        if (login === false) {
            sessionStorage.removeItem("menuId");
            document.title = `Sentinel`;
        }else{
            document.title = `Sentinel ${sessionStorage.getItem("user_id")}`;
        }
        */

        document.title = `Sentinel`;
    }, [login]);

    return (
        
        <Router>
            {login ? (
                <div className="wrapper">
                    <Sidenav />
                    <div className="main_content_wrapper">
                        {/*<Header handleLogoutapp={handleLogoutapp} />*/}
                        <PagesPath  handleLogoutapp={handleLogoutapp}/>
                    </div>
                </div>
            ) : thisurl === "/live_trading" ? (
                    <Route exact path="/live_trading" component={BackTestingDemo_V2} />
            // ) : thisurl === "/profitchart" ? (
            //         <Route exact path="/profitchart" component={ProfitChart} />
            ) : thisurl === "/addmember" ? (
                <Route exact path="/addmember" component={Addmember} />
                // ) : thisurl === "/profitchart" ? (
                //         <Route exact path="/profitchart" component={ProfitChart} />
                ) : (
                    <>
                        <Login handleLogin={handleLoginapp} />
                    </>

                )
            
            }
        </Router>
    );
}

export default App;

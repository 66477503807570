import * as React from "react";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";

function KendoExcel({ file_name, table_datas }) {
    const data = process(table_datas, {}).data;
    const _exporter = React.createRef();
    const exportExcel = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };

    return (
        <div>
            <button className="btn btn-warning btn-sm" style={{ marginTop: "0.5em", fontSize: "12px" }} onClick={exportExcel}>
                Export Record
            </button>

            {/* <ExcelExport data={data} fileName={file_name + ".xlsx"} ref={_exporter}>
                <ExcelExportColumn field="created_date" title="created_date" locked={true} width={200} />
                <ExcelExportColumn field="asset" title="asset" width={350} />
            </ExcelExport> */}

            <ExcelExport data={data} fileName={file_name + ".xlsx"} ref={_exporter}>
                {table_datas.lengh !== 0
                    ? Object.keys(table_datas[0]).map((t, i) => {
                          return <ExcelExportColumn key={i} field={t} title={t} locked={true} width={200} />;
                      })
                    : null}
            </ExcelExport>
        </div>
    );
}

export default KendoExcel;

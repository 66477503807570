import React, { useState, useEffect ,useCallback} from "react";
import axios from "axios";

import "./Dashbord.css";
import HeaderNew from "../../headerFooterSide/HeaderNew";
import BotStatus from "./BotStatus";
import TradingLog from "./TradingLog";
import ErrorStatus from "./ErrorStatus";
import RevenuMain from "./RevenuMain";

function DashBoard({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [displayMode, setDisplayMode] = useState("normal");

    useEffect(() => {
        setTile("Daily Dash Bord")
    }, []);

   
   
    function toggleDisplayChart(){
        if ( displayMode ===  "normal" ) {
            setDisplayMode("chart")
        }else{

            setDisplayMode("normal")
        }
    }

    return (
        <>

        <div>
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <div className="first_div">
                <div className="second_div">
                    <div style={{ display: "flex",float: "right" , margin: "2em 0em 0em 0em" }}>
                        <button  onClick={() => toggleDisplayChart() } className={`btn btn-sm Dasbord_Button ${displayMode ==='normal' ? 'btn-primary' : 'btn-secondary'}`}>
                                    View Revene
                         </button>
                    </div>
                    
                </div>
                <br />
            </div>
            <BotStatus />
            <TradingLog />

            {(displayMode === "chart" ? <RevenuMain /> : null )}

            
        </div>
           
        </>
    );
}

export default DashBoard;

import React, { } from "react";
import "./header.css";

/**
 * function aa(props) {} 으로 변수 받을때는 받은 변수 안에 오브젝트로 던저진 애 이름으로 확인 가능
 * const aa = ({ props }) => {} 으로 변수 받을때는 ({ }) 안에 이름과 같이 던저야 받는다..
*/ 

function Header(props) {

  const logoutSession = () => {
    if (sessionStorage.getItem("user_id") !== null) {
          props.handleLogout(false);
          sessionStorage.clear()
          alert("Logout success.");
        }
  };

  return (
    <>
     {/* <p style={{ marginLeft: "auto" }}> Welcome : {sessionStorage.getItem("user_id")} </p> */}
      <button className="btn btn-danger btn-sm" onClick={logoutSession} style={{ marginLeft: "auto" }}>Logout</button>
    </>
  );
}

export default Header;

import React, { useState, useEffect ,useCallback} from "react";
import axios from "axios";

import "./Dashbord.css";
import ArbitrageTicker from "./ArbitrageTicker";
import SbotTicker from "./SbotTicker";
import HeaderNew from "../../headerFooterSide/HeaderNew";

function LiveTicker({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [usdKrw, setUsdKrw] = useState()
    const [afterResult, setAfterResult] = useState([])
    const [afterResult_ArbitrageCoins, setAfterResult_ArbitrageCoins] = useState([])

    function get_live_ticker() {
        const info_total = { msg: "get_live_ticker" };
        //const info_total = { msg: "get_live_ticker_arbitrade" };
        axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/live_ticker`, info_total)
        .then((res) => {
            const result = res.data.result
            const result2 = res.data.result2
            setUsdKrw(result[0].c_price)
            result.shift()
            setAfterResult(result)
            setAfterResult_ArbitrageCoins(result2)
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        setTile("Daily Dash Bord")
        get_live_ticker()
        const intervalId = setInterval(() => {
            get_live_ticker()
        }, 3000)

        return () => clearInterval(intervalId)
    }, []);

   

    return (
        <>
            
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <ArbitrageTicker usdKrw={usdKrw && usdKrw} afterResult={afterResult && afterResult}  afterResult_ArbitrageCoins={afterResult_ArbitrageCoins && afterResult_ArbitrageCoins} />
            <SbotTicker afterResult={afterResult && afterResult} />
        </>
    );
}

export default LiveTicker;

import React, { useState, useEffect ,useRef} from "react";
import axios from "axios";

import ReactPaginate from "react-paginate";
import { locale_opt, c_opt_exchanges_all, c_opt_strategy_grp_all, c_opt_tfs_all, c_opt_order_type_all } from "../../../common/Commons";
import { C_coins, AscDesc, ExchangeDiv_Trading_Record, CoinsDiv, GroupDiv, StrategyInputDiv, TFDiv, DateFromToDiv } from "../../../common/Commons";

// import "./logRecords.css";

function Record({exchangesSelect ,send_esSet}) {
    //alert("Record top" + exchangesSelect)
    var exchange_name_var = "all" ;

    const selectReference = useRef(null);
    const pairs = [1];
    // select box valuabl
    const [processing_yn, setProcessing] = useState("false"); 
    const [exchange_name, setExchanges] = useState(sessionStorage.getItem('fbot_dashboard_exchange_name'));
    const [coins, setCoins] = useState(sessionStorage.getItem('fbot_dashboard_coins'));
    const [opt_coins, setOpt_coins] = useState([]);
    const [groups, setGroups] = useState(sessionStorage.getItem('fbot_dashboard_groups'));
    const [strategy, setStrategy] =  useState(sessionStorage.getItem('fbot_dashboard_strategy'));
    const [positions, setPositions] = useState(sessionStorage.getItem('fbot_dashboard_positions'));
    const [tfs, setTfs] = useState(sessionStorage.getItem('fbot_dashboard_tfs'));
    const [orderType, setOrderType] = useState(sessionStorage.getItem('fbot_dashboard_orderType'));
    const [selResults, setSelResults] = useState(sessionStorage.getItem('fbot_dashboard_selResults'));
    const [dateFrom, setDateFrom] = useState(sessionStorage.getItem('fbot_dashboard_dateFrom'));
    const [dateTo, setDateTo] = useState(sessionStorage.getItem('fbot_dashboard_dateTo'));
    const [newOld, setNewOld] = useState(sessionStorage.getItem('fbot_dashboard_newOld'));

    const [datalogs, setDatalogs] = useState([]);

    const [remountComponent, setRemountComponent] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    // common
    const opt_exchanges = c_opt_exchanges_all;
    const opt_groups = c_opt_strategy_grp_all;
    const opt_tfs = c_opt_tfs_all;
    // common

    const otp_positions = [
        { value: "all", name: "All" },
        { value: "buy", name: "BUY" },
        { value: "sell", name: "SELL" }
    ];

    const otp_order_type = c_opt_order_type_all;

    const otp_selResults = [
        { value: "all", name: "ALL" },
        { value: "success_all", name: "Successful" },
        { value: "opened_all", name: "Opened All" },
        { value: "closed_all", name: "Closed All" },
        { value: "error_all", name: "Error All" },
        //{ value: "buy_first", name: "Buy First" },
        //{ value: "buy_missing", name: "Buy Missing" },
        //{ value: "empty_transaction", name: "Empty Transaction" },
        //{ value: "invalid_key", name: "Invalid Key" },
        //{ value: "no_api", name: "No API" },
        //{ value: "no_crypto", name: "No Crypto" },
        //{ value: "no_fiat", name: "No Fiat" },
        //{ value: "skipped_transaction", name: "Skipped Transaction" },
        //{ value: "too_many_bots", name: "Too many bots" },
        //{ value: "transaction_failed", name: "Transaction Failed" },
    ];
    // END SELECT

    function saveExchangeName(exchange_name_) {
        setExchanges(exchange_name_);
        sessionStorage.setItem("fbot_dashboard_exchange_name", exchange_name_);

        resetValue()

        console.log("*****************************")
        console.log("saveExchangeName : " + exchange_name)
        console.log("*****************************")

    }
    function send_setexchanges(temp_exchange) {
        saveExchangeName(temp_exchange)

        // set BotStatusCurrentBalance.js , send_esSet()
        let exchange_code = "0" ;
        
        if (temp_exchange.toUpperCase() == "ALL") {
            exchange_code = "1" ;
        }
        else {
            if (temp_exchange.toUpperCase() == "BINANCE") {
                exchange_code = "1" ;
            }
            if (temp_exchange.toUpperCase() == "UPBIT") {
                exchange_code = "2" ;
            }
            if (temp_exchange.toUpperCase() == "BITHUMB") {
                exchange_code = "3" ;
            }
            if (temp_exchange.toUpperCase() == "OKEX") {
                exchange_code = "4" ;
            }
            if (temp_exchange.toUpperCase() == "MEXC") {
                exchange_code = '5' ;
            }
            if (temp_exchange.toUpperCase() == "BITGET") {
                exchange_code = "6" ;
            }

            console.log("Record.js send_setexchanges, after exchange_name : " + exchange_name)
            send_esSet(exchange_code);
            //selectReference.current.focus();
            //alert("Record.js ,send_esSet, to BotStatusCurrentBalance.js , selectReference.current.focus(); : " + exchange_name)
        }
    }
    function coinsSet(_num) {
        setOpt_coins(_num);
    }

    function resetValue(){

        setCoins("all")
        setOpt_coins([])
        setGroups("all")
        setStrategy("")
        setPositions("all")
        setTfs("all")
        setOrderType("all")
        setSelResults("all")
        setDateFrom("all")
        setDateTo("all")
        setNewOld("desc")

        sessionStorage.setItem("fbot_dashboard_coins", "all");
        sessionStorage.setItem("fbot_dashboard_groups", "all");
        sessionStorage.setItem("fbot_dashboard_strategy", "");
        sessionStorage.setItem("fbot_dashboard_positions", "all");
        sessionStorage.setItem("fbot_dashboard_tfs", "all");
        sessionStorage.setItem("fbot_dashboard_orderType", "all");
        sessionStorage.setItem("fbot_dashboard_selResults", "all");
        sessionStorage.setItem("fbot_dashboard_dateFrom", "all");
        sessionStorage.setItem("fbot_dashboard_dateTo", "all");
        sessionStorage.setItem("fbot_dashboard_newOld", "desc");

    }

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const Abort_btn = (id, msg, _strategy_name, _order_type,transaction_id) => {
        return (
            <>  
                { msg === "ordered"  || msg === "pending" ? (
                    <button type="button" className="btn btn-sm"  style={{ backgroundColor: "orange", color: "white" }} onClick={() => abort_btn_click(id,msg, _strategy_name, _order_type,transaction_id)}>
                        Abort
                    </button>
                ) : (
                    null
                )}
            </>
        );
    };

    const abort_btn_click = (id, _b_msg, _strategy_name, _order_type,transaction_id) => {

        if (transaction_id == "") {
            const data = {
                bot_id: "f_bot_stk",
                msg: "abort_trading",
                id: id,
            };
            axios
                .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
                .then((res) => {
                    alert(res.data.msg);
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });

        }else{
            // cance trading data that is ordered to real exchage !
            const data = {
                bot_id: "f_bot_stk",
                msg: "abort_trading_to_real_exchange",
                id: id,
                transaction_id: transaction_id,
            };
            axios
                .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
                .then((res) => {
                    alert(res.data.msg);
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        
    };

    function get_loading_traindg() {
        //alert("get_loading_traindg!! : " + exchangesSelect);
        let exchanges_ = ""
        let coins_ = ""
        let groups_ = ""
        let strategy_ = ""
        let positions_ = ""
        let tfs_ = ""
        let operation_type_ = "all"
        let order_Type_ = ""
        let selResults_ = ""
        let dateFrom_ = ""
        let dateTo_ = ""
        let newOld_ = "desc"

        console.log ("sessionStorage.getItem('fbot_dashboard_strategy') : " +  sessionStorage.getItem('fbot_dashboard_strategy') )

        if ( sessionStorage.getItem('fbot_dashboard_exchange_name')  != 'null'  ){
            exchanges_ = sessionStorage.getItem('fbot_dashboard_exchange_name') ;
           }
        else {
            exchanges_ = "all" ;
        }

        if (  sessionStorage.getItem('fbot_dashboard_coins') != 'null' ){
            coins_ = sessionStorage.getItem('fbot_dashboard_coins') ;
           }
        else {
            coins_ = "all" ;
        }

        if (  sessionStorage.getItem('fbot_dashboard_groups') != 'null'  ){
            groups_ = sessionStorage.getItem('fbot_dashboard_groups') ; 
           }
        else {
            groups_ = "all" ;
        }

        if ( sessionStorage.getItem('fbot_dashboard_strategy')  != 'null' ){
            strategy_ = sessionStorage.getItem('fbot_dashboard_strategy') ;
            console.log("bbb")
           }
        else {
            strategy_ = "" ;
            console.log("aaa") 
        }

        if ( sessionStorage.getItem('fbot_dashboard_positions') != 'null' ){
            positions_ = sessionStorage.getItem('fbot_dashboard_positions') ;
           }
        else {
            positions_ = "all" ;
        }
        if ( sessionStorage.getItem('fbot_dashboard_tfs') != 'null'  ){
            tfs_ = sessionStorage.getItem('fbot_dashboard_tfs') ;
           }
        else {
            tfs_ = "all" ;
        }
        if ( sessionStorage.getItem('fbot_dashboard_orderType') != 'null'  ){
                order_Type_ = sessionStorage.getItem('fbot_dashboard_orderType') ;
           }
        else {
            order_Type_ = "all" ;

        }

        if ( sessionStorage.getItem('fbot_dashboard_selResults') != 'null' ){
            selResults_ = sessionStorage.getItem('fbot_dashboard_selResults') ;
           }
        else {
            selResults_ = "all" ;
        }


        if ( sessionStorage.getItem('fbot_dashboard_dateFrom') != 'null'){
            
            dateFrom_ = sessionStorage.getItem('fbot_dashboard_dateFrom') ;
           }
        else {
            dateFrom_ = "all" ;
        }

        if ( sessionStorage.getItem('fbot_dashboard_dateTo') != 'null'){
            
            dateTo_ = sessionStorage.getItem('fbot_dashboard_dateTo') ;
           }
        else {
            dateTo_ = "all" ;
        }

        if ( sessionStorage.getItem('fbot_dashboard_newOld') != 'null'){
            
            newOld_ = sessionStorage.getItem('fbot_dashboard_newOld') ;
           }
        else {
            newOld_ = "desc" ;
        }

        

        const limit_cnt = 30;
        const data = {
            bot_id: "f_bot_stk",
            msg: "get_transaction_log_records",
            pgn: pageNumber,
            limit_cnt: limit_cnt,
            exchanges: exchanges_,
            coins: coins_,
            groups: groups_,
            strategy: strategy_,
            positions: positions_,
            tfs: tfs_,
            operation_type: operation_type_,
            order_type: order_Type_,
            selResults: selResults_,
            dateFrom: dateFrom_,
            dateTo: dateTo_,
            newOld: newOld_,
        };
        //alert(JSON.stringify(data));
        console.log("Record.js, get_loading_traindg !! ")
        console.log(JSON.stringify(data))
        console.log("Record.js, processing!! ")
        console.log(processing_yn)
        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                setProcessing("false");
                if (res.data.result.length !== 0) {
                    const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
                    setPageCount(page_cnt_math);
                    setDatalogs(res.data.result[1]);
                } else {
                    setPageCount(0);
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getProfit(initial_wallet_crypto , total_wallet_crypto){
        let temp_prifit = 0.0
        temp_prifit = total_wallet_crypto - initial_wallet_crypto 
        temp_prifit = temp_prifit.toPrecision(5)
        return temp_prifit
    }


    useEffect(() => {
        setProcessing("true");
        console.log(processing_yn)

        changePage({ selected: 0 });
        setRemountComponent(Math.random());

        sessionStorage.setItem("fbot_dashboard_coins", coins);
        sessionStorage.setItem("fbot_dashboard_groups", groups);
        sessionStorage.setItem("fbot_dashboard_strategy", strategy);
        sessionStorage.setItem("fbot_dashboard_positions", positions);
        sessionStorage.setItem("fbot_dashboard_tfs", tfs);
        sessionStorage.setItem("fbot_dashboard_orderType", orderType);
        sessionStorage.setItem("fbot_dashboard_selResults", selResults);
        sessionStorage.setItem("fbot_dashboard_dateFrom", dateFrom);
        sessionStorage.setItem("fbot_dashboard_dateTo", dateTo);
        sessionStorage.setItem("fbot_dashboard_newOld", newOld);

        console.log("****************************")
        console.log("Record.js useEffect !!!")
        console.log("Record.js coins : " + sessionStorage.getItem('fbot_dashboard_coins'))
        console.log("****************************")

        get_loading_traindg();
    }, [exchange_name, coins, groups, strategy, positions, tfs, orderType, selResults, dateFrom, dateTo, newOld]);

    useEffect(() => {
        console.log("****************************")
        console.log("Record.js useEffect , exchangesSelect : " + exchangesSelect)
        console.log("****************************")
        if (exchangesSelect === "1") {
            saveExchangeName("binance")
        } else if (exchangesSelect === "2") {
            saveExchangeName("upbit")
        } else if (exchangesSelect === "3") {
            saveExchangeName("bithumb")
        } else if (exchangesSelect === "4") {
            saveExchangeName("okex")
        }else if (exchangesSelect === "5") {
            saveExchangeName("mexc")
        }else if (exchangesSelect === "6") {
            saveExchangeName("bitget")
        }
        
        resetValue()

        console.log("****************************")
        console.log("Record.js useEffect , exchangesSelect : " + exchangesSelect)
        console.log("Record.js useEffect , sessionStorage.getItem('fbot_dashboard_tfs') : " + sessionStorage.getItem('fbot_dashboard_tfs'))
        console.log("****************************")
        //alert("Record.js useEffect exchange_name : " + exchange_name)
    },[exchangesSelect]);

    useEffect(() => {
        console.log("****************************")
        console.log("Record.js useEffect [] . !!!")
        console.log("****************************")
        get_loading_traindg();
        const intervalId = setInterval(() => {
            console.log("processing_yn : "  + processing_yn)
            if (processing_yn === "false") {
                //alert("setInterval go !!");
                get_loading_traindg();
            } 
            console.log("get_loading_traindg() !!!!!")
        }, 7000)
    
        return () => clearInterval(intervalId);
    }, []);



    useEffect(() => {
        console.log("****************************")
        console.log("Record.js useEffect C_coins. !!!")
        console.log("****************************")
        console.log("exchange_name : " + exchange_name)
        C_coins(coinsSet);
        const intervalId_coin = setInterval(() => {
            C_coins(coinsSet);
            console.log("C_coins !!!!!")
            console.log("exchange_name : " + exchange_name)
            console.log("exchangesSelect : " + exchangesSelect)
        }, 2000)
        return () => clearInterval(intervalId_coin);
    }, []);


    return (
        <div className="first_div">
            <div className="second_div">
                <h5>Future Trading Log</h5>
                <br />
                <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                    <div>
                        {
                        <ExchangeDiv_Trading_Record exchange_name={exchange_name} opt_exchanges={opt_exchanges} send_setexchanges={send_setexchanges} />
                        }
                        &emsp;
                        <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} />
                        &emsp;
                        <GroupDiv groups={groups} opt_groups={opt_groups} send_setgroups={setGroups} />
                        &emsp;
                        <StrategyInputDiv strategy={strategy} send_setstrategy_name={setStrategy} />
                        &emsp;
                        <span>Position: </span>
                        <select ref={selectReference} 
                            onChange={(e) => {
                                setPositions(e.target.value);
                            }} value={positions} >
                            {otp_positions.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <TFDiv tfs={tfs} opt_tfs={opt_tfs} send_settfs={setTfs} />
                        &emsp;
                        <span>Order Type: </span>
                        <select
                            onChange={(e) => {
                                setOrderType(e.target.value);
                            }} value={orderType}>
                            {otp_order_type.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        &emsp;
                        <span>Results: </span>
                        <select
                            onChange={(e) => {
                                setSelResults(e.target.value);
                            }} value={selResults}>
                            {otp_selResults.map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <div>
                        <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                    </div>
                    <br />
                    <AscDesc newOld={newOld} send_setNewOld={setNewOld} />
                    <br />
                </div>
                <table>
                    <thead >
                        <tr>
                            <th>#</th>
                            <th colSpan="6">Strategy Information</th>
                            <th colSpan="1">Initial Balance</th>
                            <th colSpan="1">End Balance</th>
                            <th colSpan="2">Price</th>
                            <th colSpan="8">Trading Information​</th>
                            <th colSpan="3">Time Information​</th>
                        </tr>
                        <tr style={{ background: "#4472c4" }}>
                            <th>#</th>
                            <th>Exchange</th>
                            <th>Group</th>
                            <th>Strategy</th>
                            <th>TF</th>
                            <th>Position</th>
                            <th>Coin</th>
                            <th>Crypto</th>
                            {/*<th>Fiat</th>*/}
                            <th>Crypto</th>
                            {/*<th>Fiat</th>*/}
                            {/*<th>Trigger</th>*/}
                            <th>Order</th>
                            <th>Trade</th>
                            {/*<th>Operation</th>*/}
                            <th>Order Type</th>
                            <th>Crypto Qty</th>
                            <th>Leverage</th>
                            <th>Status</th>
                            {/*<th style={{ background: "#117081" }}>Fee(Sentinel)</th>*/}
                            {/*<th style={{ background: "#117081" }}>PNL(Sentinel)</th>*/}
                            {/*<th style={{ background: "#447200" }}>Fee(Exchange)</th>*/}
                            <th style={{ background: "#447200" }}>PNL</th>
                            <th>Action</th>
                            <th>Msg 1</th>
                            <th>Traded</th>
                            <th>Order</th>
                            <th>Triggered</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datalogs.map((k, i) => (
                            <tr className="logTr" key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" ,cursor: "pointer", hover: { background: "red" } }}>
                                <td>{i + 1}</td>
                                <td>{k.exchange_name}</td>
                                <td>{k.strategy_group === "1" ? "MLC" : k.strategy_group === "2" ? "P2P" : k.strategy_group === "3" ?  "TBT" : "None" }</td>
                                <td>{k.strategy_name}</td>
                                <td>{k.time_frame}</td>
                                {/*<td>
                                    {k.position} {k.bot_cnt !== null ? `(${k.bot_cnt})` : "()"}
                                </td>
                                */}
                                <td>
                                    {k.position}
                                </td>
                                <td>{k.coin_type}</td>
                                <td>{parseFloat(k.initial_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                {/*<td>{parseFloat(k.initial_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>*/}
                                <td>{parseFloat(k.total_wallet_crypto).toLocaleString("ko-KR", locale_opt)}</td>
                                {/*<td>{parseFloat(k.total_wallet_fiat).toLocaleString("ko-KR", locale_opt)}</td>*/}
                                {/*<td>{parseFloat(k.trigger_price).toLocaleString("ko-KR", locale_opt)}</td>*/}
                                <td>{parseFloat(k.price).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.current_crypto_price).toLocaleString("ko-KR", locale_opt)}</td>
                                {/*<td>{k.operation_type}</td>*/}
                                <td>{k.order_type}</td>
                                <td>{parseFloat(k.crypto_qty).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{parseFloat(k.Leverage).toLocaleString("ko-KR", locale_opt)}</td>
                                <td>{k.msg}</td>
                                {/*<td>{parseFloat(k.fee_paid).toLocaleString("ko-KR", locale_opt)}</td>*/}
                                {/*<td>{getProfit(parseFloat(k.initial_wallet_crypto),parseFloat(k.total_wallet_crypto))}</td>*/}
                                {/*<td>{parseFloat(k.fee_real_paid).toLocaleString("ko-KR", locale_opt)}</td>*/}
                                <td>{k.msg === "closed" ? getProfit(parseFloat(k.initial_wallet_crypto),parseFloat(k.total_wallet_crypto)) : null}</td>
                                <td>{k.msg === "pending" || k.msg === "ordered" ? Abort_btn(k.id ,k.msg, k.strategy_name, k.order_type,k.transaction_id) : null}</td>
                                <td>{k.msg_dtl}</td>
                                <td>{String(k.transaction_time).substring(0, 19)}</td>
                                <td>{k.alarm_received_time}</td>
                                <td>{k.alarm_sent_time}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div key={remountComponent}>
                    <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    );
}

export default Record;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import "./sidenavItems.css";

const SidenavItems = () => {
    const [hoveredItem, setHoveredItem] = useState("");
    const resetHover = () => setHoveredItem("");

    const [sideName, setSideName] = useState([]);
    const [sideSubName, setSideSubName] = useState([]);
    const [groupId, setGroupId] = useState(null);


    let localSub = sessionStorage.getItem("menuId");
    let localMenuPid = sessionStorage.getItem("menuPid");

    useEffect(() => {
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
        let sideName_array = [];
        let sideSubName_array = [];

        axios
            .post(`${python_server_url}/pages`, { msg: "side_menu" })
            .then((result) => {
                result.data.forEach((i) => {
                    i.menu_pid === 0 ? sideName_array.push(i) : sideSubName_array.push(i);
                });
                setSideName(sideName_array);
                setSideSubName(sideSubName_array);
            })
            .catch((err) => console.log(err));
    }, []);
    
    const saveGroupId = (id) => {
        sessionStorage.setItem("menuId", id);
        console.log("saveGroupId id :" + id)
        console.log("saveSubMenuPid id :" + (parseInt(id)+1))
        saveSubMenuPid((parseInt(id)+1))
        setGroupId(id);
    };
    const saveSubMenuPid = (menuPid) => {
        sessionStorage.setItem("menuPid", menuPid);
        setHoveredItem(menuPid)
        //saveSubMenuPid(menuPid);
    };

    return (
       
        <div>
        <style>
        {`
          .hovered {
            color: #00B0F0;
          }
        `}
      </style>
            {sideName.map((res, index) => {
                return (
                    <React.Fragment key={index}>
                        <NavLink key={index} to={`/${res.menu_source}`} exact className={res.menu_group_id === groupId? "linkStyle_select" : "linkStyle"}  onClick={() => saveGroupId(res.menu_group_id)}>
                            {res.menu_name}
                        </NavLink>

                        {res.menu_group_id === groupId || res.menu_group_id === parseInt(localSub, 10)
                            ? sideSubName.map((res2, index) => {
                                  if (res2.menu_group_id === groupId || res2.menu_group_id === parseInt(localSub, 10)) {
                                      return (
                                          <NavLink key={index} to={`/${res.menu_source}/${res2.menu_source}`} exact className={res2.menu_pid === hoveredItem? "subLinkStyle_select" : "subLinkStyle"} activeClassName="sub-is-active">
                                            {/* <span  className={hoveredItem === res2.menu_name ? "hovered" : undefined} 
                                                        onMouseEnter={() => setHoveredItem(res2.menu_name)}
                                                                onMouseLeave={resetHover}>{res2.menu_name} 
                                                                onClick={() => saveSubMenuPid(res2.menu_name)}
                                                </span>*/}
                                            <div  className={hoveredItem === res2.menu_pid ? "hovered" : undefined} 
                                                                onClick={() => saveSubMenuPid(res2.menu_pid)} >{res2.menu_name} 
                                                                
                                            </div>
                                          </NavLink>
                                      );
                                  } else {
                                      return null;
                                  }
                              })
                            : ""}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default SidenavItems;

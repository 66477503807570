import React, { useState, useEffect } from "react";
import "./arbitrage.css";

import axios from "axios";
import dayjs from "dayjs";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { locale_opt } from "../../common/Commons";
import Modal from "../../common/Modal";

function ABotStatus() {
  console.log("ABotStatus");

  const pairs = [1];
  
  const [dataArbitrase, setDataArbitrase] = useState([]);
  const [newOld, setNewOld] = useState("desc");

  const [remountComponent, setRemountComponent] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [errorLog, setErrorLog] = useState("");
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ui component
  

  const [currentOperationStatus, setCurrentOperationStatus] = useState([]);
  const handleOperation = (selected_value, index,id) => {
    console.log("selected_value :" + selected_value);
    console.log("index :" + index);
    let newArr = [...currentOperationStatus]; // copying the old datas array

    if (selected_value) {
      newArr[index] = "true"; // replace e.target.value with whatever you want to change it to
      setCurrentOperationStatus(newArr);
      console.log("currentOperationStatus :" + newArr);
      updateAbitOperation(id, "true");
    } else {
      // 체크 해제
      newArr[index] = "false"; // replace e.target.value with whatever you want to change it to
      setCurrentOperationStatus(newArr);
      console.log("currentOperationStatus :" + newArr);
      updateAbitOperation(id, "false");
    }
  
  };

  /*
  const [currentOperationStatus, setCurrentOperationStatus] = useState([]);
  const handleOperation = (checked, index) => {
    console.log("checked :" + checked);
    console.log("index :" + id);
    if (checked) {
      console.log("checked before currentOperationStatus :" + currentOperationStatus);
      setCurrentOperationStatus([...currentOperationStatus, id]);
  
      console.log("checked after currentOperationStatus :" + currentOperationStatus);
    } else {
      // 체크 해제
      console.log("checked! before currentOperationStatus :" + currentOperationStatus);
      setCurrentOperationStatus(currentOperationStatus.filter((el) => el !== id));
      console.log("checked! after currentOperationStatus :" + currentOperationStatus);
    }
  };
  */

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // useEffect

  useEffect(() => {
    changePage({ selected: 0 });
    setRemountComponent(Math.random());
  }, [newOld]);


  useEffect(() => {
    const limit_cnt = 20;

    const data = {
        bot_id: "arbitrage",
        msg: "get_setting_arbitrage",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        dateFrom: "",
        dateTo: "",
        newOld: newOld,
    };

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_setting`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            setDataArbitrase(res.data.result[1]);
            makeSubData(res.data.result[1]);
        } else {
            setPageCount(0);
            setDataArbitrase([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }, [pageNumber, newOld]);

  useEffect(() => {
    get_arbitrage_setting()
    const intervalId = setInterval(() => {
      get_arbitrage_setting()
      console.log("get_arbitrage_setting() !!!!!")
    }, 2000)

    return () => clearInterval(intervalId)
  }, []);


  ///////////////////////////////////////////////////////////////////////////////////
  // function

  // Modal 2
  const openModal = (id) => {
      get_arbitrage_error_log(id)
      console.log("ehererer");
      setModalOpen(true);
  };

  const closeModal = () => {
      setModalOpen(false);
  };

  function get_arbitrage_error_log(id) {
    const limit_cnt = 20;

    const data = {
         id: id,
        msg: "get_arbitrage_error_log",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        dateFrom: "",
        dateTo: "",
        newOld: newOld,
    };

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_setting`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {
            const log = res.data.result;
            console.log("log : "  + log)
            setErrorLog(log);
        } else {
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }

  function get_arbitrage_setting() {
    const limit_cnt = 20;

    const data = {
        bot_id: "arbitrage",
        msg: "get_setting_arbitrage",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        dateFrom: "",
        dateTo: "",
        newOld: newOld,
    };
    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/arbitrage_setting`, data)
    .then((res) => {
        if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[0].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            setDataArbitrase(res.data.result[1]);
            makeSubData(res.data.result[1]);
        } else {
            setPageCount(0);
            setDataArbitrase([]);
        }
    })
    .catch((err) => {
        console.log(err);
    });
  }

  function go_page(topage) {
    window.location.href = topage;
  }

  function makeSubData(dataArbitrase_){
    let newArr_operation = [];
    for (let key in dataArbitrase_){
      console.log(key+"------:"+dataArbitrase_[key].operation);
      newArr_operation[key] =  dataArbitrase_[key].operation;
    }
    
    console.log("newArr_operation :" + newArr_operation)
    setCurrentOperationStatus(newArr_operation);
  }
  

  // update abit
  function updateAbitOperation(id, operation){

    // make data list to update
    const limit_cnt = 20;
    const data = {
        bot_id: "arbitrage",
        msg: "update_arbitrage_operation", 
        which: "update",
        pgn: pageNumber,
        limit_cnt: limit_cnt,
        newOld: newOld,
        
        id : id,
        operation : operation
    };
    console.log("updateAbitOperation data : "  + data )

    axios
    .post(`${process.env.REACT_APP_PYTHON_SERVER}/update_arbitrage`, data)
    .then((res) => {
        console.log(res.data)

        if (res.data.result[0] === "OK"){
          //alert("OK")
          if (res.data.result.length !== 0) {
            const page_cnt_math = Math.ceil(res.data.result[1].cnt / limit_cnt);
            setPageCount(page_cnt_math);
            setDataArbitrase(res.data.result[2]);
          } else {
              setPageCount(0);
              setDataArbitrase([]);
          }

        }else{
            alert("ERROR")
        }
    })
    .catch((err) => {
        console.log(err);
        alert("ERROR")
    })
    // .finally(() => {
    //    window.location.reload();
    // });
  }
  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {/* botstatus content */}
      <div className="first_div">
        <div className="second_div">
          <div style={{ display: "flex", margin: "0px 0px 10px 0px" }}>
              <h5>Bot Status</h5>
              &emsp;
              <h6>(updated : {dayjs().format("YYYY-MM-DD dddd hh:mm:ss")})</h6>
              <div style={{ justifyContent: 'center', margin: "0px 0px 0px 10em" }}>
                <button className="btn btn-success btn-sm"  style={{ width: '100px',display: "float"}} onClick={() => go_page("/arbitrage/a-bot") }>
                 Modify
                </button>
              </div>
          </div>
          <div style={{ display: "flex", marginLeft: "0em" }}>
            <div style={{ width: "100%" }}>
              <table>
                  <thead style={{ background: "#6CA0EC" }}>
                      <tr>
                          <th>#</th>
                          <th>Bot Id</th>
                          <th>Created</th>
                          <th>Coin</th>
                          <th>Sell</th>
                          <th>Buy</th>
                          <th>Trading Amount</th>
                          <th>Target Gap(%)</th>
                          <th>Loop Interval</th>
                          <th>Order Type</th>
                            {/*
                            <th>Price</th>
                            */}
                            <th>Flat(%)</th>
                            <th>Flat(qty)</th>
                            <th>Asset(%)</th>
                            <th>Asset(qty)</th>
                          <th>Operation on</th>
                          <th>Message</th>
                      </tr>
                  </thead>

                  <tbody>
                      {dataArbitrase.map((k, i) => (

                          <tr key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                              <td>{i + 1}</td>
                              <td>{k.id}</td>
                              <td>{moment(k.create_date_time).format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td>{k.coin.toUpperCase()}</td>
                              <td>{k.sell_exchange_id}</td>
                              <td>{k.buy_exchange_id}</td>
                              <td>{k.trading_amount}</td>
                              <td>{k.target_gap}&nbsp;%</td>
                              <td>{k.loop_interval}&nbsp;min</td>
                              <td>{k.order_type}</td>
                              {/*
                              <td>{k.price}&nbsp;USDT</td>
                              */}
                              <td>{k.fiat_percent_to_buy}&nbsp;%</td>
                              <td>{k.fiat_qty_to_buy}&nbsp;</td>
                              <td>{k.crypto_percent_to_sell}&nbsp;%</td>
                              <td>{k.crypto_qty_to_buy}&nbsp;</td>
                              <td>
                                <label className="switch">
                                    <input type="checkbox" id={i+"_operation"}  checked={currentOperationStatus[i] === "true" ? true : false}  onChange={(e) => handleOperation(e.currentTarget.checked, i , k.id)}/>
                                    <span className="slider round"></span>
                                </label>
                                </td>
                                <td><button id={i+"_view"}  style={{ width: '100px',display: "float"}} className={k.log ? "btn btn-warning btn-sm" : "btn btn-success btn-sm"} onClick={(e) => openModal(k.id)}>{"Log"}</button></td>
                          </tr>
                      ))}
                  </tbody>
              </table>
              <div key={remountComponent}>
                  <ReactPaginate previousLabel={pairs.length === 0 ? "" : "Prev"} nextLabel={pairs.length === 0 ? "" : "Next"} pageCount={pageCount} onPageChange={changePage} containerClassName={"paginationBtns"} previousLinkClassName={"previousBtn"} nextLinkClassName={"nextBtn"} disabledClassName={"paginationDisabled"} activeClassName={"paginationActive"} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div> {/* end second div */}                       
    </div>  {/* end first div */}
    <br />
    <div>
      <Modal
          open={modalOpen}
          close={closeModal}
          header={
              <>
                  <p>
                      Log
                  </p>
                  <button className="close" onClick={closeModal}>
                      {" "}
                      &times;{" "}
                  </button>
              </>
          }
          footer={
                  <>
                      <button className="close" onClick={closeModal}>
                          {" "}
                          close{" "}
                      </button>
                  </>
          }>
          {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
          <label htmlFor="bot_modal_input">Log :  {errorLog}</label>
      </Modal>
    </div>        
  </div> 
);
}

export default ABotStatus;


import React, { useState, useEffect } from "react";
import axios from "axios";
import HeaderNew from "../../../headerFooterSide/HeaderNew";
import RegisteredUsers from "./RegisteredUsers"
import AddUser from "./AddUser"
import UpdateUser from "./UpdateUser";


function UserPage({handleLogoutapp}) {
	const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
  const [title, setTile] = useState()
  const [users, setUsers] = useState([])
  const [adduser_ing, setAddUser_ing] = useState(false)
  const [updateuser_ing, setUpdateUser_ing] = useState(false)
  const [update_task_type, setUpdateTaskType] = useState(null)
  const [currentUserId, setCurrentUserId] = useState(null)
 

  
  const callbackAddStartEvent = () => {
    console.log("callbackAddStartEvent !!!!")
    setAddUser_ing(false)
    setUpdateUser_ing(false)

    setAddUser_ing(true)
    setUpdateUser_ing(false)
  }

  const callbackUpdateStartEvent = (user_id,update_task_type) => {

    setAddUser_ing(false)
    setUpdateUser_ing(false)

    //alert("callbackUpdateStartEvent , user_id : " +  user_id);
    setCurrentUserId(user_id);
    //alert("callbackUpdateStartEvent , update_task_type : " + update_task_type);
    setUpdateTaskType(update_task_type);
   
    console.log("callbackUpdateStartEvent !!!!")
    setAddUser_ing(false)
    setUpdateUser_ing(true)
  }

  const callbackUpdateFinishEvent = () => {
    console.log("callbackUpdateFinishEvent !!!!")
    setAddUser_ing(false)
    setUpdateUser_ing(false)
    window.location.reload();
  }

  const callbackAddFinishEvent = () => {
    console.log("callbackAddFinishEvent !!!!")
    setUpdateUser_ing(false)
    setAddUser_ing(false)
    window.location.reload();
  }
  useEffect(() => {
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER;
    const data = {msg: "get_users"};
    axios
        .post(`${python_server_url}/admin_manager`, data)
        .then((res) => {
          setUsers(res.data.result)
        })
        .catch((err) => {
            console.log(err);
        });
        
  }, []);

  useEffect(() => {
    setTile("Admin Management")
    setUsers(null)
    setAddUser_ing(false)
    setUpdateUser_ing(false)
  }, []);

  return (
    <div>
      {/* tile */}
      <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
      <RegisteredUsers users={users} callbackAddStartEvent={callbackAddStartEvent} callbackUpdateStartEvent={callbackUpdateStartEvent}/>
      <br></br>
      {
        adduser_ing ? <AddUser callbackAddFinishEvent={callbackAddFinishEvent}/> : updateuser_ing ? <UpdateUser currentUserId={currentUserId} update_task_type = {update_task_type} callbackUpdateFinishEvent={callbackUpdateFinishEvent}/> : null 
      }
    </div>
  );
}

export default UserPage;

import React, { useState, useEffect } from "react";

import "./liveTicker.css";
import dayjs from "dayjs";
import { locale_opt, locale_opt_8 } from "../../common/Commons";

function ArbitrageTicker({ usdKrw, afterResult ,afterResult_ArbitrageCoins }) {
    const [beforeId, setBeforeId] = useState("btc");
    const [prices, setPrices] = useState({});
    const [differBinance, setDifferBinance] = useState([]);

    function Change_tab(ticker) {
        document.getElementById(ticker).style.background = "#337F63";
        beforeId === ticker ? (document.getElementById(ticker).style.background = "#337F63") : (document.getElementById(beforeId).style.background = "");
        setBeforeId(ticker);
        Calculator_price(ticker);
    }

    function Calculator_price(ticker) {
        let a_price = {};

        if (afterResult.length !== 0) {

            console.log("afterResult : " + JSON.stringify(afterResult));

            afterResult.forEach(function (element, i) {

                console.log("element : " + JSON.stringify(element));
            
                const temp_differ_ex_id = ["1", "2", "3", "4", "5", "6"];
                const temp_differ_ex_name = ["_binance", "_upbit" , "_bithumb", "_okex", "_mexc", "_bitget"];
                temp_differ_ex_id.forEach((temp_differ_ex_id_element) => {

                    //console.log("element.exchanges : " + element.exchanges );
                    //console.log("element.coin_ticker : " + element.coin_ticker );
                    //console.log("temp_differ_ex_id_element : " + temp_differ_ex_id_element);
                    //console.log("ticker : " + ticker);
                    //console.log("usdKrw : " + usdKrw);

                    if (element.exchanges === temp_differ_ex_id_element && element.coin_ticker === ticker) {
                    
                        //console.log("ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1] : " + ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1])
                          
                       
                        if (element.exchanges === "3" && element.coin_ticker === ticker) {
                            a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]] = {
                                kp: parseFloat(element.c_price),
                                up: parseFloat(element.c_price / usdKrw),
                            };
                        } else if (element.exchanges === "2" && element.coin_ticker === ticker) {
                            a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]] = {
                                kp: parseFloat(element.c_price),
                                up: parseFloat(element.c_price / usdKrw),
                            };
                        } else if (element.exchanges === "1" && element.coin_ticker === ticker) {
                            a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]] = {
                                kp: parseFloat(element.c_price * usdKrw),
                                up: parseFloat(element.c_price),
                            };
                        } else if (element.exchanges === "4" && element.coin_ticker === ticker) {
                            a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]] = {
                                kp: parseFloat(element.c_price * usdKrw),
                                up: parseFloat(element.c_price),
                            };
                        }else if (element.exchanges === "5" && element.coin_ticker === ticker) {
                            a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]] = {
                                kp: parseFloat(element.c_price * usdKrw),
                                up: parseFloat(element.c_price),
                            };
                        }else if (element.exchanges === "6" && element.coin_ticker === ticker) {
                            a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]] = {
                                kp: parseFloat(element.c_price * usdKrw),
                                up: parseFloat(element.c_price),
                            };
                        }

                        //console.log("a_price : " + JSON.stringify(a_price));
                    }
                    else {
                        // if this is not runned on the avove rutin !
                        if(typeof(a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]]) === 'undefined' )
                        {
                            a_price[ticker + temp_differ_ex_name[parseInt(temp_differ_ex_id_element)-1]] = {
                                kp: 0.0,
                                up: 0.0,
                            };

                        }
                    }

                });
            
                /*
                if (element.exchanges === "3" && element.coin_ticker === ticker) {
                    a_price[ticker + "_bithumb"] = {
                        kp: parseFloat(element.c_price),
                        up: parseFloat(element.c_price / usdKrw),
                    };
                } else if (element.exchanges === "2" && element.coin_ticker === ticker) {
                    a_price[ticker + "_upbit"] = {
                        kp: parseFloat(element.c_price),
                        up: parseFloat(element.c_price / usdKrw),
                    };
                } else if (element.exchanges === "1" && element.coin_ticker === ticker) {
                    a_price[ticker + "_binance"] = {
                        kp: parseFloat(element.c_price * usdKrw),
                        up: parseFloat(element.c_price),
                    };
                } else if (element.exchanges === "4" && element.coin_ticker === ticker) {
                    a_price[ticker + "_okex"] = {
                        kp: parseFloat(element.c_price * usdKrw),
                        up: parseFloat(element.c_price),
                    };
                }
                */
               
            });

            console.log("a_price : " + JSON.stringify(a_price));

            /**
             * Calculator Difference Binance and Korean Companies
             * [(A-B) / [(A+B) / 2]] * 100
             **/
            let differ_list = [];
            const differ_ex = ["_bithumb", "_upbit", "_binance", "_okex", "_mexc", "_bitget"];
            const differ_b = a_price[ticker + "_binance"]["kp"];
            console.log("differ_b : " + differ_b);

            differ_ex.forEach((element_2) => {


                if (typeof(a_price[ticker + element_2]) !== 'undefined' && typeof(a_price[ticker + element_2]["kp"]) !== 'undefined' ){
                    let differ_a = a_price[ticker + element_2]["kp"];

                    console.log("ticker : " + ticker);
                    console.log("element_2 : " + element_2);
                    console.log("differ_a : " + differ_a);
                    if (differ_a !== 0 && element_2 !== "_binance") {
                        const val = parseFloat([(differ_a - differ_b) / [(differ_a + differ_b) / 2]] * 100).toLocaleString("ko-KR", locale_opt) 
                            +" %"
                            +" ("
                            +(parseFloat(differ_a-differ_b).toFixed(8).toLocaleString("ko-KR", locale_opt))
                            + " KRW)";
                        differ_list.push(val);
                    } else {
                        differ_list.push("-");
                    }
                }
                else {
                    differ_list.push("-");

                }
               
            });

            setPrices(a_price);
            setDifferBinance(differ_list);
        }
    }

    useEffect(() => {
        Calculator_price(beforeId);
    }, [afterResult]);

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <div style={{ display: "flex", margin: "0px 0px 1em" }}>
                        <h5 style={{ margin: "0px 3.5em 0px 0px" }}>Arbitrage Ticker</h5>
                        <h6 style={{ margin: "0.5em 0px 0px 0em" }}> Last updated : {dayjs().format("YYYY-MM-DD dddd hh:mm:ss")}</h6>
                    </div>
                    <div style={{ display: "flex", margin: "0px 0px 1em" }}>
                        <span>USD to KRW = {parseFloat(usdKrw).toLocaleString("ko-KR", locale_opt)} KRW</span>
                        &emsp;&emsp;&emsp;&emsp;
                        <span>KRW to USD = {parseFloat(1/usdKrw).toLocaleString("ko-KR", locale_opt_8)} USD</span>
                    </div>
                    <div>
                        <table>
                            <thead>
                                <tr style={{ background: "#" }}>
                                    {afterResult_ArbitrageCoins.map((k, i) => (
                                     k === "btc" ? 
                                        <th id={k} style={{ cursor: "pointer", background: "#337F63" }} onClick={(e) => Change_tab(e.target.id)}>{k.toUpperCase()}</th> 
                                        : <th id={k} style={{ cursor: "pointer", background: "" }} onClick={(e) => Change_tab(e.target.id)}>{k.toUpperCase()}</th>
                                     
                                    ))}
                                </tr>
                            </thead>
                        </table>

                        <table>
                            <thead>
                                <tr style={{ background: "#4472C4" }}>
                                    <th style={{ width: "10%" }}></th>
                                    <th colSpan="4" style={{ width: "30%" }}>
                                        KRW Price
                                    </th>
                                    <th colSpan="4" style={{ width: "30%" }}>
                                        USD Price
                                    </th>
                                    <th colSpan="4" style={{ width: "30%" }}>
                                        Difference
                                    </th>
                                </tr>
                            </thead>

                            {Object.keys(prices).length !== 0 && prices ? (
                                <tbody>
                                    <tr>
                                        <td>Bithumb</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_bithumb"]["kp"]).toLocaleString("ko-KR", locale_opt)} KRW</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_bithumb"]["up"]).toLocaleString("ko-KR", locale_opt)} USD</td>
                                        <td colSpan="4">{differBinance[0].toLocaleString("ko-KR", locale_opt)}</td>
                                    </tr>
                                    <tr>
                                        <td>Upbit</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_upbit"]["kp"]).toLocaleString("ko-KR", locale_opt)} KRW</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_upbit"]["up"]).toLocaleString("ko-KR", locale_opt)} USD</td>
                                        <td colSpan="4">{differBinance[1].toLocaleString("ko-KR", locale_opt)} </td>
                                    </tr>
                                    <tr>
                                        <td>Binance</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_binance"]["kp"]).toLocaleString("ko-KR", locale_opt)} KRW</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_binance"]["up"]).toLocaleString("ko-KR", locale_opt)} USD</td>
                                        <td colSpan="4">{differBinance[2].toLocaleString("ko-KR", locale_opt)}</td>
                                    </tr>
                                    <tr>
                                        <td>OKEX</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_okex"]["kp"]).toLocaleString("ko-KR", locale_opt)} KRW</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_okex"]["up"]).toLocaleString("ko-KR", locale_opt)} USD</td>
                                        <td colSpan="4">{differBinance[3].toLocaleString("ko-KR", locale_opt)}</td>
                                    </tr>
                                    <tr>
                                        <td>MEXC</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_mexc"]["kp"]).toLocaleString("ko-KR", locale_opt)} KRW</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_mexc"]["up"]).toLocaleString("ko-KR", locale_opt)} USD</td>
                                        <td colSpan="4">{differBinance[4].toLocaleString("ko-KR", locale_opt)}</td>
                                    </tr>
                                    <tr>
                                        <td>BITGET</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_bitget"]["kp"]).toLocaleString("ko-KR", locale_opt)} KRW</td>
                                        <td colSpan="4">{parseFloat(prices[beforeId + "_bitget"]["up"]).toLocaleString("ko-KR", locale_opt)} USD</td>
                                        <td colSpan="4">{differBinance[5].toLocaleString("ko-KR", locale_opt)}</td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td>Bithumb</td>
                                    </tr>
                                    <tr>
                                        <td>Upbit</td>
                                    </tr>
                                    <tr>
                                        <td>Binance</td>
                                    </tr>
                                    <tr>
                                        <td>Okex</td>
                                    </tr>
                                    <tr>
                                        <td>Mexc</td>
                                    </tr>
                                    <tr>
                                        <td>Bitget</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
}

export default ArbitrageTicker;

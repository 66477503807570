import React, { useState, useEffect } from "react";
import { C_coins } from "../../../common/Commons";
import Records from "./Records";
import Chart from "./Chart";
import HeaderNew from "../../../headerFooterSide/HeaderNew";

const ParingRecords = ({handleLogoutapp}) => {
    const [title, setTile] = useState()
    const [whichTab, setWhichTab] = useState("1");
    const [opt_coins, setOpt_coins] = useState(null);

    function coinsSet(_num) {
        setOpt_coins(_num)
    }

    useEffect(() => {
        C_coins(coinsSet)
    }, []);

    useEffect(() => {
        setTile("Paring Records")
    }, []);

    return (
        <div>
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            
            <div className="first_div">
                <div className="second_div">
                    <div className="selection_div">
                        <input type="radio" id="pair_records" name="pair_records" value="1" checked={whichTab === "1"} onChange={(e) => setWhichTab(e.target.value)} />
                        <label htmlFor="pair_records" style={{ cursor: "pointer" }}> Transaction Pairs​</label>
                        &emsp;
                        <input type="radio" id="pair_chart" name="pair_chart" value="2" checked={whichTab === "2"} onChange={(e) => setWhichTab(e.target.value)} />
                        <label htmlFor="pair_chart" style={{ cursor: "pointer" }}> Paring Charts​</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button className="btn btn-success btn-sm" onClick={() => window.location.reload()}>
                                    Refresh
                        </button>

                    </div>
                </div>
            </div>
            <div>
                {!opt_coins && <p> Loading...</p>}
                {opt_coins && (whichTab === "1" ? <Records opt_coins={opt_coins && opt_coins} /> : <Chart opt_coins={opt_coins && opt_coins} />)}
            </div>
        </div>
    );
};

export default ParingRecords;

import React, { useState, useEffect } from "react";
import axios from "axios";

import { c_opt_exchanges_all, c_opt_strategy_grp_name_all, locale_opt, locale_opt_8 } from "../../../common/Commons";
import { C_coins, ExchangeDiv, ExchangeDiv_buy , ExchangeDiv_sell,CoinsDiv, GroupDiv, StrategyInputDiv, DateFromToDiv } from "../../../common/Commons";

function LedgerHistory() {
    // select box valuable
    const [datalogs, setDatalogs] = useState([]);

    const [exchange_name_buy, setExchange_name_buy] = useState("all");
    const [exchange_name_sell, setExchange_name_sell] = useState("all");
    const [coins, setCoins] = useState("all");
    const [opt_coins, setOpt_coins] = useState([]);
    const [groups, setGroups] = useState("all");
    const [strategy, setStrategy] = useState("");
    const [event_type, setEvent_type] = useState("all");
    const [selResults, setSelResults] = useState("all");
    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");

    // common
    const opt_exchanges = c_opt_exchanges_all;
    const opt_groups = c_opt_strategy_grp_name_all;
    // common

    const otp_event_type = [
        { value: "all", name: "All" },
        { value: "s_market", name: "S-MARKET" },
        { value: "s_limit", name: "S-LIMIT" },
        { value: "m_market", name: "M-MARKET" },
        { value: "m_limit", name: "M-LIMIT" },
        { value: "auto_limit", name: "AUTO LIMIT" },
        { value: "auto_market", name: "AUTO MARKET" },
        { value: "deposit", name: "DEPOSIT" },
        { value: "withdraw", name: "WITHDRAW" },
    ];

    const otp_selResults = [
        { value: "all", name: "ALL" },
        { value: "buy", name: "BUY" },
        { value: "sell", name: "SELL" }
    ];
    // END SELECT

    function coinsSet(_num) {
        setOpt_coins(_num);
    }
    function isEmptyObject(obj){
        if(obj.construct === Object && __dirname.isEemp(obj)) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        C_coins(coinsSet);
    }, []);

    useEffect(() => {
        const data = {
            bot_id: "a_bot",
            msg: "abot_ledger_history",
            exchange_name_buy: exchange_name_buy,
            exchange_name_sell: exchange_name_sell,
            ticker_name: coins,
            dateFrom: dateFrom,
            dateTo: dateTo,
        };

        axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                console.log(res.data.result);
                //alert(isEmptyObject(res.data.result))
                //alert(JSON.stringify(res.data.result))

                console.log(JSON.stringify(res.data.result))
                
                setDatalogs(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exchange_name_buy,exchange_name_sell, coins, groups, strategy, event_type, selResults, dateFrom, dateTo]);

    return (
        <div>
            <div className="first_div">
                <div className="second_div">
                    <h3></h3>
                    <br />
                    <div style={{ fontSize: "11px", color: "#6CA0EC" }}>
                        <div>
                            <ExchangeDiv_buy opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name_buy}  />
                            <ExchangeDiv_sell opt_exchanges={opt_exchanges} send_setexchanges={setExchange_name_sell}  />
                            &emsp;
                            <CoinsDiv opt_coins={opt_coins} send_setcoins={setCoins} />
                        </div>
                        <br />
                        <div>
                            <DateFromToDiv send_data_from={setDateFrom} send_data_to={setDateTo} />
                        </div>
                    </div>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle", background: "#4472c4" }}>Date</th>
                                <th rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle", background: "#4472c4" }}>Buy Exchange</th>
                                <th rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle", background: "#4472c4" }}>Sell Exchange</th>
                                
                                <th colSpan="3" style={{ textAlign: "center", verticalAlign: "middle", background: "#002060" }}>Cpryto Buy Balance Change</th>
                                
                                <th colSpan="3" style={{ textAlign: "center", verticalAlign: "middle", background: "#7030a0" }}>Crypto Sell Balance Change</th>
                                
                                <th colSpan="3" style={{ textAlign: "center", verticalAlign: "middle", background: "#0b2f24" }}>Fiat Buy Balance Change</th>
                                
                                <th colSpan="3" style={{ textAlign: "center", verticalAlign: "middle", background: "#6d3109" }}>Fiat Sell Balance Change</th>
                              
                            </tr>
                            <tr>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#002060" }}>Before</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#002060" }}> After</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#002060" }}>Difference(%)</th>

                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#7030a0" }}>Before</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#7030a0" }}> After</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#7030a0" }}>Difference(%)</th>

                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#0b2f24" }}>Before</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#0b2f24" }}> After</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#0b2f24" }}>Difference(%)</th>

                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#6d3109" }}>Before</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#6d3109" }}> After</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", background: "#6d3109" }}>Difference(%)</th>
                              
                            </tr>
                        </thead>
                        <tbody>
                            {datalogs.map((k, i) => (
                                <tr key={i}>

                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#142440" }}>{k['arbitrage_root'].create_date_time}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#142440" }}>{k['buy_arbitrage'].buy_exchange_name}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#142440" }}>{k['sell_arbitrage'].sell_exchange_name}</td>


                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#0c1526" }}>{k['buy_arbitrage'].initial_wallet_crypto}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#0c1526" }}>{k['buy_arbitrage'].total_wallet_crypto}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#0c1526" }}>{k['buy_arbitrage'].different_wallet_crypto}( {k['buy_arbitrage'].different_wallet_crypto_percent}%)</td>


                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#190b23" }}>{k['sell_arbitrage'].initial_wallet_crypto}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#190b23" }}>{k['sell_arbitrage'].total_wallet_crypto}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#190b23" }}>{k['sell_arbitrage'].different_wallet_crypto}( {k['sell_arbitrage'].different_wallet_crypto_percent}%)</td>


                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#04100c" }}>{k['buy_arbitrage'].initial_wallet_fiat}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#04100c" }}>{k['buy_arbitrage'].total_wallet_fiat}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#04100c" }}>{k['buy_arbitrage'].different_wallet_fiat} ( {k['buy_arbitrage'].different_wallet_fiat_percent}%)</td>

                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#391a05" }}>{k['sell_arbitrage'].initial_wallet_fiat}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#391a05" }}>{k['sell_arbitrage'].total_wallet_fiat}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", background: "#391a05" }}>{k['sell_arbitrage'].different_wallet_fiat} ( {k['sell_arbitrage'].different_wallet_fiat_percent}%)</td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default LedgerHistory;
